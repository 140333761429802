<template>
  <PrimaryErrorBanner />
  <QInput
    v-model="editingCategory.name"
    :label="t('Name')"
    :disable="saving"
    autofocus
    bottom-slots
  />
  <div class="row">
    <QBtn
      color="success"
      icon="mdi-check"
      :loading="saving"
      :disable="!editingCategory.name"
      @click="saveCategory()"
    >
      {{ t('Save') }}
    </QBtn>

    <QSpace />

    <QBtn
      color="error"
      icon="mdi-close"
      :disabled="saving"
      @click="emit('cancel')"
    >
      {{ t('Cancel') }}
    </QBtn>
  </div>
</template>

<script setup lang="ts">

import useErrorHandling from '@/composables/useErrorHandling';
import type { ProductCategory } from '@/graphql/types';
import { gql, useMutation } from '@urql/vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { fillErrorsFromGraphQLError, PrimaryErrorBanner } = useErrorHandling();

const props = defineProps<{
  category: ProductCategory;
}>();

const emit = defineEmits<{
  save: [category: ProductCategory];
  cancel: [];
}>();

const editingCategory = ref(props.category);

const { executeMutation: doSave, fetching: saving } = useMutation(gql`
  mutation SaveCategory($id: ID, $name: String!) {
    category: saveProductCategory(id: $id, name: $name) { id name }
  }
`);

async function saveCategory(): Promise<void> {
  const { error, data } = await doSave(editingCategory.value);

  if (error) {
    fillErrorsFromGraphQLError(error);
  } else {
    emit('save', Object.assign(editingCategory.value, data!.category));
  }
}

</script>
