<template>
  <GlobalCloseButton @click="emit('close')" />
  <PrimaryErrorBanner />
  <BigLoading v-if="fetchingShipment" />
  <FullHeightPage v-else-if="shipment">
    <div
      ref="contentEl"
      class="col scroll-y"
    >
      <CardTitle>
        {{
          t('{id} dated {date}', {
            id: shipment.externalId,
            date: formatDate(shipment.createdAt, FORMATS.DATETIME),
          })
        }}
      </CardTitle>

      <HorizontalPropsList v-slot="{ item }">
        <component
          :is="item"
          v-if="shipment.counterparty"
          :caption="isCustomerOrder ? t('Customer') : t('Supplier')"
        >
          {{ shipment.counterparty.name }}
        </component>
        <component
          :is="item"
          v-if="shipment.carrier"
          :caption="t('Carrier Name')"
        >
          {{ shipment.carrier.name }}
        </component>
        <component
          :is="item"
          :caption="t('State')"
        >
          <QChip
            dense
            class="q-ma-none"
            :color="forShipmentState(shipment)"
            text-color="black"
          >
            {{ t('shipmentState.' + shipment.state) }}
          </QChip>
        </component>
        <component
          :is="item"
          v-if="shipment.plannedShipmentDate"
          :caption="t('Shipment Date')"
        >
          {{ formatDate(shipment.plannedShipmentDate, FORMATS.DATETIME) }}
        </component>
        <component
          :is="item"
          :caption="t('Code')"
        >
          {{ shipment.id }}
        </component>
        <component
          :is="item"
          v-if="shipment.state !== ShipmentStateEnum.COMPLETED"
          :caption="t('Container')"
        >
          <QSkeleton v-if="fetchingContainers" />
          <template v-else>
            <RouterLink
              v-for="(container) in containers.slice(0,3)"
              :key="container.id"
              :to="{ name: ROUTES.CONTAINERS_EDIT, params: { id: String(container.id) }}"
              class="q-mr-xs"
            >
              {{ container.name }}
            </RouterLink>
            <template v-if="containers.length > 3">
              {{ t('and {n} more', containers.length - 3) }}
            </template>
          </template>
        </component>
        <component
          :is="item"
          :caption="t('Products')"
        >
          {{ R.uniqBy(i => i.productPack.product.sku, shipment.items).length }}
        </component>
        <component
          :is="item"
          :caption="t('Weight')"
        >
          {{
            formatWeight(R.sum(shipment.items.map(i => i.productPack.weight * i.amount ?? 0)))
          }}
        </component>
        <component
          :is="item"
          :caption="t('Volume')"
        >
          {{ formatVolume(calculateVolumeForShipment(shipment)) }}
        </component>
      </HorizontalPropsList>

      <GraphQLReportList
        :graphql-query="itemsQuery"
        :fixed-filters="fixedItemsFilters"
        no-refresh-button
        :scroll-target="contentEl"
      >
        <template #item="{ item }">
          <ProductListItem
            :key="item.id"
            :product="item.productPack.product"
          >
            <template #top-right>
              <AmountCompletionChip
                :product-pack="item.productPack"
                :expected-amount="item.amount"
                :actual-amount="item.selectedAmount ?? 0"
              />
            </template>
          </ProductListItem>
        </template>
      </GraphQLReportList>
    </div>

    <ButtonsRow v-slot="{ buttonProps }">
      <QBtn
        v-bind="buttonProps"
        icon="mdi-shopping"
        :loading="fetchingShipment"
        :disable="!isOrderSelectable"
        @click="select"
      >
        {{ t('Selection') }}
      </QBtn>
    </ButtonsRow>
  </FullHeightPage>
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import ProductListItem from '@/components/ProductListItem.vue';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type {
  Container,
  QueryContainersByShipmentArgs,
  QueryCustomerOrderArgs,
  ReportFilterInput,
  Scalars,
  CustomerOrder, SupplierReturn,
} from '@/graphql/types';
import { ShipmentStateEnum } from '@/graphql/types';
import { forShipmentState } from '@/helpers/badgeColors';
import { calculateVolumeForShipment } from '@/helpers/shipments';
import useSelectionStore from '@/stores/selection';
import HorizontalPropsList from '@/views/Mobile/HorizontalPropsList.vue';
import { gql, useQuery } from '@urql/vue';
import * as R from 'ramda';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import AmountCompletionChip from '@/components/Mobile/AmountCompletionChip.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import BigLoading from '@/components/BigLoading.vue';
import GlobalCloseButton from '@/components/GlobalCloseButton.vue';
import FullHeightPage from '@/components/FullHeightPage.vue';
import GraphQLReportList from '@/components/Mobile/GraphQLReportList.vue';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const { formatDate, formatVolume, formatWeight } = useLocalizedFormatters();

// noinspection LocalVariableNamingConventionJS
const { fillErrorsFromGraphQLError, PrimaryErrorBanner } = useErrorHandling();

const store = useSelectionStore();
const { selectedShipments } = storeToRefs(store);

const router = useRouter();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const props = defineProps<{
  id: Scalars['ID'];
}>();

const {
  data:     shipmentData,
  fetching: fetchingShipment,
  error:    shipmentError,
} = useQuery<{ shipment: CustomerOrder | SupplierReturn }, QueryCustomerOrderArgs>({
  query:     gql`
    query GetShipmentForMobileCard($id: ID!) {
      shipment(id: $id) {
        id
        externalId
        createdAt
        plannedShipmentDate
        counterparty { id name }
        carrier { id name }
        state
        hasStockForSelection
        items {
          id
          amount
          productPack {
            id
            weight
            product { id sku photos { id url(size: SMALL) } }
          }
        }
      }
    }
  `,
  variables: computed(() => ({
    id: props.id,
  })),
});

watch(shipmentError, fillErrorsFromGraphQLError);

const shipment = computed(() => shipmentData.value?.shipment);

const isCustomerOrder = computed(() => shipment.value?.__typename === 'CustomerOrder');

const {
  data:     containersData,
  fetching: fetchingContainers,
  error:    containersError,
} = useQuery<{ containers: Container[] }, QueryContainersByShipmentArgs>({
  query:     gql`
    query GetContainersByShipmentForShipmentCardMobile($shipmentId: ID!) {
      containers: containersByShipment(shipmentId: $shipmentId) { id name }
    }
  `,
  variables: computed(() => ({
    shipmentId: props.id,
  })),
});

watch(containersError, fillErrorsFromGraphQLError);

const containers = computed(() => containersData.value?.containers || []);

const itemsQuery = gql`
  query GetShipmentItemsForMobileCard(
    $first: Int!,
    $after: Cursor,
    $query: String,
    $filter: [ReportFilterInput!],
  ) {
    result: shipmentItemsList(
      first: $first,
      after: $after,
      query: $query,
      filter: $filter,
    ) {
      edges {
        cursor
        node {
          id
          amount
          selectedAmount
          productPack {
            id
            product { id sku name photos { id url(size: SMALL) } }
            measurementUnit { id name shortName }
            quantityInMinMeasurementUnits
            minMeasurementUnit { id shortName }
            smallerProductPack { id quantity }
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const fixedItemsFilters = computed<ReportFilterInput[]>(() => ([{
  field:    'shipmentId',
  operator: '=',
  value:    JSON.stringify(shipment.value!.id),
}]));

const isOrderSelectable = computed(
  () => shipment.value && shipment.value?.hasStockForSelection
);

async function select(): Promise<void> {
  selectedShipments.value = [shipment.value!];
  await router.push({ name: ROUTES.SELECTION_ENQUEUE });
}

const contentEl = ref();

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/counts.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/selection.yaml"></i18n>

<i18n lang="yaml">
ru:
  Code: Код
  Shipment Date: Дата отгрузки
  Volume: Объем
  Returns: Возвраты
  No Returns: Нет возвратов

en:
  Code: Code
  Shipment Date: Shipment Date
  Volume: Volume
  Returns: Returns
  No Returns: No Returns
</i18n>
