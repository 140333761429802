<template>
  <QInput
    ref="input"
    dense
    :model-value="modelValue"
    :label="t('Search')"
    clearable
    v-bind="$attrs"
    @update:model-value="onSearch($event)"
    @keydown.enter="handleEnter($event.target.value)"
  >
    <template #prepend>
      <QIcon name="mdi-magnify" />
    </template>
  </QInput>
  <BlurredInput @barcode="search($event)" />
</template>

<script setup lang="ts">

import useOmniInput from '@/composables/useOmniInput';
import { useDebounceFn } from '@vueuse/core';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  modelValue: string | null;
  debounce?: number;
  blurredSearch?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:model-value', searchString: string | null): void;
}>();

function search(query: string | null): void {
  emit('update:model-value', query);
}

function onSearch(query: string | null): void {
  if (query === null) {
    // QInput возвращает null, если нажата кнопка очистки,
    // в таком случае запрашиваем данные без задержки.
    search(query);
  } else {
    debouncedSearch(query);
  }
}

const input = ref();

function handleEnter(searchString: string): void {
  search(searchString);
  input.value.blur();
}

const debouncedSearch = useDebounceFn(search, props.debounce);

const { BlurredInput } = useOmniInput({
  skip: computed(() => !props.blurredSearch),
});

</script>
