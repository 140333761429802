<template>
  <PrimaryErrorBanner />

  <QCardSection
    v-if="fetching"
    class="text-center"
  >
    <QCircularProgress
      indeterminate
      size="150px"
      font-size="0.1em"
      color="primary"
      show-value
    >
      {{ t('Loading') }}
    </QCircularProgress>
  </QCardSection>

  <template v-else-if="batch">
    <CardTitle v-if="!hideTitle">
      {{ batch.name }} ({{ batch.product.sku }} {{ batch.product.name }})
    </CardTitle>

    <QSeparator />

    <EditBatchData
      :batch="batch"
      :product="batch.product"
      :saving="saving"
      @save="save"
      @back="emit('back', batch)"
    />
  </template>

  <BaseAlert
    v-else
    type="info"
  >
    {{ t('Batch not found') }}
  </BaseAlert>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import CardTitle from '@/components/CardTitle.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import type {
  Batch, InventoryBatchDataInput,
  MutationCreateBatchForProductArgs,
  MutationSaveBatchArgs,
  Product,
} from '@/graphql/types';
import { gql, useMutation, useQuery } from '@urql/vue';
import { pick } from 'ramda';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import EditBatchData from '@/components/EditBatchData.vue';

const { t } = useI18n();

const { fillErrorsFromGraphQLError, clearErrors, PrimaryErrorBanner } = useErrorHandling();

const props = defineProps<{
  id?: string;
  product?: Product;
  hideTitle?: boolean;
}>();

const emit = defineEmits<{
  (e: 'back', batch: Batch): void;
  (e: 'saved', batch: Batch): void;
}>();

const isNewBatch = computed(() => !props.id);

const { data, error, fetching, executeQuery } = useQuery<{ batch: Batch }>({
  query:     gql`
    query GetBatch($id: ID!) { batch(id: $id) {
      id
      name
      number
      productionDate
      expirationDate
      product {
        id
        sku
        name
        accountingModel {
          id
          batchNumberRequired
          batchProductionDateRequired
          batchExpirationDateRequired
        }
      }
    } }
  `,
  variables: computed(() => ({ id: props.id })),
  pause:     isNewBatch,
});
watch(error, fillErrorsFromGraphQLError);
watch(data, data => {
  batch.value = data?.batch ?? null;
});

// eslint-disable-next-line vue/no-ref-object-reactivity-loss
const batch = ref<Batch | null>(isNewBatch.value ? {
  id:             null!,
  name:           '',
  number:         null,
  productionDate: null,
  expirationDate: null,
  product:        props.product!,
} : null);

const saving = ref(false);

async function save(batchData: InventoryBatchDataInput): Promise<void> {
  Object.assign(batch.value!, batchData);

  saving.value = true;

  await doSave();

  saving.value = false;
}

const createMutation = useMutation<{ batch: Batch }, MutationCreateBatchForProductArgs>(gql`
  mutation CreateBatchFromProductStocksChangeBatchDialog($productId: ID!) {
    batch: createBatchForProduct(productId: $productId) { id name }
  }
`);

const saveMutation = useMutation<{ batch: Batch }, MutationSaveBatchArgs>(gql`
  mutation SaveBatch(
    $id: ID!,
    $number: String,
    $productionDate: DateTime,
    $expirationDate: DateTime,
  ) {
    batch: saveBatch(
      id: $id,
      number: $number,
      productionDate: $productionDate,
      expirationDate: $expirationDate,
    ) { id product { id } }
  }
`);

async function doSave() {
  clearErrors();

  let id = props.id;

  if (!id) {
    const { data, error } = await createMutation.executeMutation({ productId: props.product!.id });

    if (error) {
      fillErrorsFromGraphQLError(error);
      return;
    }

    id = data!.batch.id;
  }

  const { data, error } = await saveMutation.executeMutation(
    pick<Batch, keyof Batch>(
      ['id', 'number', 'productionDate', 'expirationDate'],
      { ...batch.value!, id },
    ),
  );
  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  emit('saved', data!.batch);

  await executeQuery();
}

</script>

<i18n lang="yaml">
ru:
  Batch not found: Партия не найдена
en:
  Batch not found: Batch not found
</i18n>
