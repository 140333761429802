<template>
  <ExcelExport
    v-if="canImport"
    :type="ExcelImportTypeEnum.REALIZATIONS"
    :mutation-export="mutationExport"
    :options="options"
  />
</template>

<script setup lang="ts">

import useCan from '@/composables/useCan';
import { ExcelImportTypeEnum } from '@/graphql/types';
import { Ability } from '@/types/backend';
import type { ReportOptions } from '@/types/reports';
import ExcelExport from '@/views/ExcelExport.vue';
import { gql } from '@urql/vue';

defineProps<{
  options?: ReportOptions;
}>();

const canImport = useCan(Ability.ImportExcel);

const mutationExport = gql`
  mutation GenerateRealizationsFile($type: ExcelImportTypeEnum!, $searchString: String, $filter: [ReportFilterInput!]) {
    exportFilePath: generateExcelExportFile(type: $type, searchString: $searchString, filter: $filter)
  }
`;

</script>
