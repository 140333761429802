<template>
  <SupplyDetailsMobile
    v-if="preferences.terminalMode"
    :id="id"
    :title="t('Customer Return')"
    @close="router.push({ name: ROUTES.CUSTOMER_RETURNS_LIST })"
  >
    <template #not-found-banner>
      <CustomerReturnNotFoundBanner />
    </template>
  </SupplyDetailsMobile>
  <SupplyDetailsDesktop
    v-else
    :id="id"
    @load="handleSupplyLoaded"
  >
    <template #title="{ supply }">
      {{ t('Customer Return') }}
      {{
        t('{id} dated {date}', {
          id: supply.externalId,
          date: formatDate(supply.createdAt)
        })
      }}
    </template>
    <template #fields="{ supply }: { supply: CustomerReturn }">
      <div class="col-sm-3">
        <GraphQLAutocomplete
          v-model="carrierName"
          :query="carriersAutocompleteQuery"
          :label="t('Carrier Name')"
          option-label="name"
          clearable
        />
      </div>
      <div class="col-sm-3">
        <QInput
          readonly
          :label="t('Customer')"
          :model-value="supply.counterparty?.name"
          stack-label
          :placeholder="t('Not specified')"
        />
      </div>
      <div class="col-sm-3">
        <QInput
          readonly
          :label="t('Order Number')"
          :model-value="supply.orderNumber || supply.customerOrder?.externalId"
          stack-label
          :placeholder="t('Not specified')"
        />
      </div>
      <div class="col-sm-3">
        <QInput
          readonly
          :label="t('Planned Arrival Date')"
          :model-value="formatDate(supply.plannedArrivalDate)"
          stack-label
          :placeholder="t('Not specified')"
        />
      </div>
    </template>
    <template #bottom>
      <PrimaryErrorBanner />
    </template>
    <template #buttons="{supply}">
      <QBtn
        color="success"
        icon="mdi-content-save"
        :loading="saving"
        @click="save(supply)"
      >
        {{ t('Apply') }}
      </QBtn>
      <QBtn
        color="primary"
        icon="mdi-content-save"
        :loading="saving"
        @click="saveAndGoToList(supply)"
      >
        {{ t('Save') }}
      </QBtn>
    </template>
    <template #not-found-banner>
      <CustomerReturnNotFoundBanner />
    </template>
  </SupplyDetailsDesktop>
</template>

<script setup lang="ts">

import NotFoundBanner from '@/components/NotFoundBanner.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import useLocalPreferences from '@/composables/useLocalPreferences';
import type { CustomerReturn, Scalars } from '@/graphql/types';
import ROUTES from '@/router/routeNames';
import SupplyDetailsDesktop from '@/views/Arrival/Supply/SupplyDetailsDesktop.vue';
import SupplyDetailsMobile from '@/views/Arrival/Supply/SupplyDetailsMobile.vue';
import { type Component, watch, h, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import GraphQLAutocomplete from '@/components/Mobile/GraphQLAutocomplete.vue';
import { gql, useMutation } from '@urql/vue';
import navigateBack from '@/helpers/navigateBack';
import useErrorHandling from '@/composables/useErrorHandling';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

defineProps<{
  id: Scalars['ID'];
}>();

const preferences = useLocalPreferences();

useBreadcrumbs(t('Supplies'));

const { fillErrorsFromGraphQLError, clearErrors, PrimaryErrorBanner } = useErrorHandling();


const router = useRouter();

const CustomerReturnNotFoundBanner: Component = () => h(NotFoundBanner, {
  notFoundMessage: t('Customer Return not found'),
  backRoute:       { name: ROUTES.CUSTOMER_RETURNS_LIST },
  goBackMessage:   t('Go to Customer Returns List'),
});

const {
  fetching:        saving,
  error:           saveError,
  executeMutation: doSave,
} = useMutation(gql`
  mutation SaveCustomerReturn($id: ID!, $carrierName: String) {
    saveCustomerReturn(id: $id, carrierName: $carrierName) { id }
  }
`);

watch(saveError, fillErrorsFromGraphQLError);

const carrierName = ref('');

function handleSupplyLoaded(supply: CustomerReturn) {
  carrierName.value = supply.carrier?.name ?? '';
}

async function save(supply: CustomerReturn) {
  clearErrors();
  return doSave({
    id: supply.id,
    carrierName: carrierName.value,
  });
}

async function saveAndGoToList(supply: CustomerReturn) {
  const { error } = await save(supply);

  if (!error) {
    navigateBack({ name: ROUTES.CUSTOMER_RETURNS_LIST });
  }
}

const carriersAutocompleteQuery = gql`
  query GetCarriersForSelect($searchString: String) {
    result: carriers(searchString: $searchString) { id name }
  }
`;

</script>

<i18n lang="yaml">
ru:
  Customer Return not found: Возврат покупателя не найден
  Go to Customer Returns List: Перейти к списку возвратов
  Planned Arrival Date: Планируемая дата поступления

en:
  Customer Return not found: Customer Return not found
  Go to Customer Returns List: Go to Customer Returns List
  Planned Arrival Date: Planned Arrival Date
</i18n>
