<template>
  <SelectionDetailsForQueuedList v-if="!preferences.terminalMode" />
  <template v-else>
    <GlobalCloseButton @click="navigateBack({ name: ROUTES.SELECTIONS_LIST })" />
    <SelectionDetailsForDashboard
      v-bind="$attrs"
    />
  </template>
</template>

<script setup lang="ts">

import GlobalCloseButton from '@/components/GlobalCloseButton.vue';
import useLocalPreferences from '@/composables/useLocalPreferences';
import SelectionDetailsForDashboard
  from '@/views/Mobile/Selection/SelectionDetailsForDashboard.vue';
import SelectionDetailsForQueuedList from '@/views/Shipping/SelectionDetailsForQueuedList.vue';
import navigateBack from '@/helpers/navigateBack';
import ROUTES from '@/router/routeNames';

const preferences = useLocalPreferences();

</script>
