<template>
  <div class="row no-wrap items-baseline">
    <QIcon
      v-if="document.__typename === 'CustomerReturn' || document.__typename === 'SupplierReturn'"
      name="mdi-arrow-u-left-top"
      :title="title"
      class="q-mr-xs"
    />
    {{ document.externalId }}
  </div>
</template>
<script setup lang="ts">
import type {
  CustomerOrder,
  CustomerReturn,
  SupplierDelivery,
  SupplierReturn,
} from '@/graphql/types';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  document: CustomerOrder | CustomerReturn | SupplierDelivery | SupplierReturn;
}>();

const title = computed(() => {
  if (props.document.__typename === 'CustomerReturn') {
    return t('Customer Return');
  }
  if (props.document.__typename === 'SupplierReturn') {
    return t('Supplier Return');
  }

  return null;
});
</script>
