<template>
  <FullHeightPage>
    <GraphQLQueryTable
      :graphql-query="query"
      :fields="tableFields"
      :available-filters="availableFilters"
      :deletion-filter="deletionFilterForSoftDeletable"
      :row-is-deleted="entityIsDeleted"
      storage-prefix="racksList.report"
      keep-page-in-url
      sticky-header
      class="col"
    >
      <template #after-search-string>
        <QBtn
          v-if="canCreate"
          color="primary"
          :to="{ name: ROUTES.RACKS_NEW }"
          icon="mdi-table-large-plus"
        >
          {{ t('Add Rack') }}
        </QBtn>
      </template>

      <template #batch-actions="{ rows, refresh, clear }">
        <SimpleTableBatchActions :rows-count="rows.length">
          <DeletionWithValidation
            v-if="rows.some(item => item.deletedAt === null)"
            :items="rows"
            :delete="deleteRack"
            :validate="validateRack"
            @deleted="refresh()"
          >
            <template #validation-errors="{ invalidItems }">
              <BaseAlert type="error">
                {{
                  t(
                    'Racks {names} with stocks cannot be deleted',
                    {names: invalidItems.map(r => r.name).join(', ')},
                    invalidItems.length,
                  )
                }}
              </BaseAlert>
            </template>
            <template #confirmation>
              <QCardSection class="text-body1">
                {{ getConfirmMessageForDeletion(rows) }}
              </QCardSection>
            </template>
          </DeletionWithValidation>

          <PrintDialog
            v-if="rows.some(item => item.deletedAt === null)"
            v-slot="{ open }"
            v-model:barcode-type="barcodeType"
            template-name="cell-barcode"
            :title="rows[0].name"
            :instances="printInstancesByRacks(rows)"
            :no-instances-message="t('Selected racks are empty')"
            use-count
            @print="clear();"
            @count-changed="printCount=$event"
          >
            <QBtn
              class="bg-white text-black q-ml-sm"
              icon="mdi-printer"
              @click="open()"
            >
              {{ t('Print') }}
            </QBtn>
          </PrintDialog>
        </SimpleTableBatchActions>
      </template>

      <template #body-cell-id="column">
        <BodyCellLink
          :column="column"
          :to="{ name: ROUTES.RACKS_EDIT, params: { id: String(column.value) } }"
        />
      </template>
    </GraphQLQueryTable>
  </FullHeightPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import FullHeightPage from '@/components/FullHeightPage.vue';
import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import PrintDialog from '@/components/PrintDialog.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useCan from '@/composables/useCan';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type { PrintTemplateInstance } from '@/composables/usePrintService';
import type {
  MutationDeleteRackArgs,
  QuerySuppliersArgs,
  Rack,
  RackReportRow,
} from '@/graphql/types';
import { PrintBarcodeTypeEnum } from '@/graphql/types';
import * as reports from '@/helpers/reports';
import {
  createBoolean,
  createDatesRangeOperator,
  deletionFilterForSoftDeletable,
  entityIsDeleted,
} from '@/helpers/reports';
import ROUTES from '@/router/routeNames';
import type { FunctionValidationRule } from '@/types';
import { Ability } from '@/types/backend';
import type { ReportFilter, TableColumn } from '@/types/reports';
import DeletionWithValidation from '@/views/DeletionWithValidation.vue';
import SimpleTableBatchActions from '@/views/SimpleTableBatchActions.vue';
import useDocumentsPrintingState from '@/views/useDocumentsPrintingState';
import { gql, useClientHandle } from '@urql/vue';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { dateFormatter } = useLocalizedFormatters();

useBreadcrumbs(t('Racks'));

const { client: urql } = useClientHandle();

const canCreate = useCan(Ability.CreateRacks);

const state = useDocumentsPrintingState();

const barcodeType = ref(state.value.lastRackCellBarcodeType ?? PrintBarcodeTypeEnum.BARCODE);

const printCount = ref(1);

function printInstancesByRacks(rows: RackReportRow[]): PrintTemplateInstance[] {
  return rows.flatMap((r: RackReportRow) => r.cells.map(c => ({ cellId: c.id, barcodeType: barcodeType.value })))
    .map(p => {
      return { count: printCount.value, params: p, };
    });
}

watch(barcodeType, (): void => {
  state.value.lastRackCellBarcodeType = barcodeType.value;
});

const query = gql`
  query GetRacks($page: Int, $perPage: Int!, $query: String, $filter: [ReportFilterInput!], $sort: [ReportSortInput!]!) {
    result: racksReport(page: $page, perPage: $perPage, query: $query, filter: $filter, sort: $sort) {
      data {
        id
        createdAt
        prefix
        row
        number
        isEmpty
        deletedAt
        cells {
          id
          isEmpty
        }
      }
      total
    }
  }
`;

const tableFields: TableColumn<RackReportRow>[] = [
  {
    label:     t('ID'),
    name:      'id',
    field:     'id',
    mandatory: true,
    align:     'left',
    sortable:  true,
  },
  {
    label:    t('Created at'),
    name:     'createdAt',
    field:    'createdAt',
    format:    dateFormatter(FORMATS.DATETIME),
    align:    'left',
    sortable: true,
  },
  {
    label:    t('Prefix'),
    name:     'prefix',
    field:    'prefix',
    sortable: true,
  },
  {
    label:    t('Row'),
    name:     'row',
    field:    'row',
    sortable: true,
  },
  {
    label:    t('Number'),
    name:     'number',
    field:    'number',
    sortable: true,
  },
  {
    label:    t('Is empty'),
    name:     'isEmpty',
    field:    'isEmpty',
    format:   isEmpty => isEmpty ? t('Yes') : '',
    align:    'left',
  },
];

const availableFilters: ReportFilter[] = [
  {
    field:     'createdAt',
    label:     t('Created at'),
    operators: [
      createDatesRangeOperator(),
    ],
  },
  {
    field:     'prefix',
    label:     t('Prefix'),
    operators: [
      ...(['=', '!=', 'in', 'not in'] as const)
        .map(op => reports.createList(
          op,
          searchString => urql.query<{ racksPrefixes: string[] }, QuerySuppliersArgs>(
            gql`
              query GetRacksPrefixesForRacksReportFilter($searchString: String) {
                racksPrefixes(searchString: $searchString)
              }
            `,
            { searchString },
          ).then(({ data }) => data!.racksPrefixes.map(value => ({
            value,
            label: value,
          }))),
          t(`reportFilterOperator.${op}`),
        )),
      createBoolean('is null', t('Unspecified')),
    ],
  },
];

async function deleteRack(rack: Rack): Promise<void> {
  await urql.mutation<unknown, MutationDeleteRackArgs>(
    gql`mutation DeleteRack($id: ID!) { deleteRack(id: $id) }`,
    { id: rack.id! },
  );
}

function getConfirmMessageForDeletion(items: Rack[]): string {
  const notDeletedItems = items.filter(item => item.deletedAt === null);
  return t('Delete {n} racks?', { name: notDeletedItems[0].name }, notDeletedItems.length);
}

function validateRack(rack: Rack): ReturnType<FunctionValidationRule> {
  return rack.cells.every(c => c.isEmpty) ? true : 'hasNotEmptyCells';
}

</script>

<i18n lang="yaml">
ru:
  Add Rack: Добавить стеллаж
  Prefix: Префикс
  Row: Проход
  Is empty: Пустой
  Selected racks are empty: Выбранные стеллажи не содержат ячеек

  Racks {names} with stocks cannot be deleted: >
    Нельзя удалить стеллажи {names}, в которых есть остатки
    | Нельзя удалить стеллаж {names}, в котором есть остатки
    | Нельзя удалить стеллажи {names}, в которых есть остатки
    | Нельзя удалить стеллажи {names},

  Delete {n} racks?: >
    Удалить {n} стеллажей?
    | Удалить стеллаж {name}?
    | Удалить {n} стеллажа?
    | Удалить {n} стеллажей?

en:
  Add Rack: Add Rack
  Prefix: Prefix
  Row: Row
  Is empty: Is empty
  Selected racks are empty: Selected racks are empty

  Racks {names} with stocks cannot be deleted: >
    Rack {names} with stocks cannot be deleted
    | Racks {names} with stocks cannot be deleted

  Delete {n} racks?: >
    Delete rack {name}?
    | Delete {n} racks?
</i18n>
