<template>
  <FullHeightPage>
    <GraphQLReportList
      :graphql-query="query"
      :available-filters="availableFilters"
      :fixed-filters="fixedFilters"
      with-search
      storage-prefix="productsList.mobileReport"
      class="col"
    >
      <template #item="{ item }">
        <ProductListItem
          :product="item"
          :to="{ name: ROUTES.PRODUCTS_EDIT, params: { id: item.id } }"
        />
      </template>
    </GraphQLReportList>
  </FullHeightPage>
</template>

<script setup lang="ts">

import FullHeightPage from '@/components/FullHeightPage.vue';
import GraphQLReportList from '@/components/Mobile/GraphQLReportList.vue';
import ProductListItem from '@/components/ProductListItem.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import type { ReportFilterInput } from '@/graphql/types';
import { PrintBarcodeTypeEnum } from '@/graphql/types';
import type { ReportFilter } from '@/types/reports';
import useDocumentsPrintingState from '@/views/useDocumentsPrintingState';
import { gql } from '@urql/vue';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

useBreadcrumbs(t('Products'));

defineProps<{
  availableFilters: ReportFilter[];
}>();

const state = useDocumentsPrintingState();

const barcodeType = ref(state.value.lastProductPackBarcodeType ?? PrintBarcodeTypeEnum.BARCODE);

watch(barcodeType, (): void => {
  state.value.lastProductPackBarcodeType = barcodeType.value;
});

const query = gql`
  query GetProducts(
    $first: Int!,
    $after: Cursor,
    $query: String,
    $filter: [ReportFilterInput!],
  ) {
    result: productsList(
      first: $first,
      after: $after,
      query: $query,
      filter: $filter,
    ) {
      edges {
        cursor
        node {
          id
          sku
          name
          photos { id url(size: SMALL) }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const fixedFilters: ReportFilterInput[] = [{
  field:    'deletedAt',
  operator: 'is null',
  value:    'true',
}];

</script>
