<template>
  <QDialog maximized>
    <QCard class="column no-wrap">
      <div ref="fixedHeaderTarget" />
      <div class="scroll-y col column no-wrap">
        <Teleport
          :to="fixedHeaderTarget"
          :disabled="!fixedHeaderTarget || !fixedHeader"
        >
          <QToolbar class="bg-primary text-white">
            <QBtn
              flat
              round
              icon="mdi-close"
              @click="emit('close')"
            />

            <QToolbarTitle>
              {{ title }}
            </QToolbarTitle>

            <FullscreenToggle />
          </QToolbar>
        </Teleport>
        <slot />
      </div>

      <slot name="bottom" />
    </QCard>
  </QDialog>
</template>

<script setup lang="ts">

import FullscreenToggle from '@/components/FullscreenToggle.vue';
import { ref } from 'vue';

defineProps<{
  title?: string;
  fixedHeader?: boolean;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const fixedHeaderTarget = ref();

</script>
