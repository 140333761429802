<template>
  <QDialog
    v-model="isOpen"
    :persistent="saving"
    :position="preferences.usePhysicalKeyboard ? undefined : 'bottom'"
  >
    <QCard class="column no-wrap">
      <CardTitle class="text-h6">
        {{ t('Complete product scan and go to the next?') }}
      </CardTitle>

      <QSeparator />

      <QCardSection>
        <QCheckbox
          v-model="noMoreProduct"
          :disable="loadingPendingInventory || !!pendingInventoryData?.inventory"
          @click="handleNoMoreProductClick"
        >
          {{ t('No more Product') }}
          <QSpinner v-if="loadingPendingInventory" />
        </QCheckbox>
      </QCardSection>
      <QSlideTransition>
        <div v-if="showPendingInventoryError && pendingInventoryData?.inventory">
          <BaseAlert type="error">
            {{ t('There is pending inventory {id}', { id: pendingInventoryData!.inventory.id }) }}
          </BaseAlert>
        </div>
      </QSlideTransition>
      <PrimaryErrorBanner />
      <QCardActions align="right">
        <QBtn
          :label="t('No')"
          :disable="saving"
          @click="isOpen = false"
        />
        <QBtn
          :label="t('Yes')"
          color="primary"
          :loading="saving"
          @click="changeAmount"
        />
      </QCardActions>
    </QCard>
  </QDialog>

  <slot
    v-if="store.currentItem"
    name="activator"
    @click="isOpen = true"
  />
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useSelectionStore from '@/stores/selection';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import type { Inventory, QueryPendingStorageInventoryArgs } from '@/graphql/types';
import useLocalPreferences from '@/composables/useLocalPreferences';
import CardTitle from '@/components/CardTitle.vue';
import { gql, useQuery } from '@urql/vue';

const { t } = useI18n();

const store = useSelectionStore();

const { fillErrorsFromGraphQLError, PrimaryErrorBanner, clearErrors } = useErrorHandling();

const preferences = useLocalPreferences();

const isOpen = ref(false);
watch(isOpen, newValue => {
  if (newValue) {
    showPendingInventoryError.value = false;
    noMoreProduct.value = false;
  }
});

const showPendingInventoryError = ref(false);

function handleNoMoreProductClick() {
  if (!loadingPendingInventory.value && pendingInventoryData.value?.inventory) {
    showPendingInventoryError.value = true;
  }
}

const noMoreProduct = ref(false);

const saving = ref(false);

async function changeAmount(): Promise<void> {
  clearErrors();

  if (store.currentItem.takenAmount < store.currentItem.plannedAmount) {
    saving.value = true;
    const { error } = await store.takeAllProductForItem(store.currentItem, noMoreProduct.value);
    saving.value = false;

    if (error) {
      fillErrorsFromGraphQLError(error);
      return;
    }
  }

  isOpen.value = false;

  if (store.currentStorageFullySelected) {
    store.currentStorage = null;
  } else if (store.currentItem) {
    store.adjustScannedItemPosition(store.currentItem);
  }
}

const {
  data: pendingInventoryData,
  fetching: loadingPendingInventory,
} = useQuery<{ inventory: Inventory }, QueryPendingStorageInventoryArgs>({
  query: gql`
    query GetPendingInventoryForSelectionEditProductAmountDialog($storageId: ID!) {
      inventory: pendingStorageInventory(storageId: $storageId) { id }
    }
  `,
  variables: computed(() => ({ storageId: store.currentItem?.storageFrom.id })),
  pause: computed(() => !isOpen.value || !store.currentItem),
});

</script>

<i18n lang="yaml">
ru:
  No more Product: Товара больше нет
  There is pending inventory {id}: Есть незавершенная инвентаризация {id}
  Complete product scan and go to the next?: Завершить сканирование товара и перейти к следующему?

en:
  No more Product: No more Product
  There is pending inventory {id}: There is pending inventory {id}
  Complete product scan and go to the next?: Complete product scan and go to the next?

</i18n>
