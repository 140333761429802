<template>
  <div>
    <QSeparator />
    <div :class="rootClasses">
      <QBtn
        v-if="$slots['extra']"
        icon="mdi-dots-horizontal"
        v-bind="buttonProps"
        @click="extraButtons = !extraButtons"
      >
        <QMenu auto-close>
          <div :class="rootClasses">
            <slot
              name="extra"
              :button-props="buttonProps"
            />
          </div>
        </QMenu>
      </QBtn>
      <slot :button-props="buttonProps" />
    </div>
  </div>
</template>

<script setup lang="ts">

import type { QBtnProps } from 'quasar';
import type { HTMLAttributes } from 'vue';
import { computed, ref } from 'vue';

const rootClasses = 'buttons-row row no-wrap items-baseline scroll-y hide-when-empty justify-evenly';

const buttonProps = computed<QBtnProps & HTMLAttributes>(() => ({
  noCaps: true,
  flat: true,
  size: '10px',
  class: 'q-px-xs',
}));

const extraButtons = ref(false);

</script>

<style scoped lang="scss">

@import 'quasar/src/css/variables';

$max-buttons: 5;

.buttons-row {
  height: 78px;

  :deep(.q-btn) {
    color: $primary;
    width: #{100% / $max-buttons};

    &.disabled {
      opacity: 1 !important;
      color: $grey-4;
    }

    .q-btn__content {
      flex-direction: column;
      line-height: 1;

      &.q-btn__content--hidden {
        opacity: inherit;

        .q-icon {
          opacity: 0;
        }
      }

      .q-icon {
        font-size: 50px;
      }
    }
  }

  :deep(.q-spinner) {
    width: 48px;
    height: 48px;
    margin-bottom: 30px;
  }
}

</style>
