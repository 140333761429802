<template>
  <QPage class="column">
    <BaseAlert
      v-if="primaryError"
      type="error"
    >
      {{ primaryError }}
    </BaseAlert>
    <BigLoading v-if="fetching" />
    <template v-else>
      <div class="q-pa-lg q-mt-xl q-mb-lg">
        <QSelect
          v-if="selectionsCountVariants.length > 1 || selectionsCount !== 1"
          ref="select"
          v-model="selectionsCount"
          :loading="fetching"
          :options="selectionsCountVariants"
          :label="t('Selections for queue')"
          @popup-hide="blurSelect"
        />
        <div
          v-for="name in separateSelectionNames"
          :key="name"
        >
          {{ name }}
        </div>
      </div>
      <div
        v-if="selectionsCount === 1 || selection"
        class="col q-pa-lg justify-center"
      >
        <GraphQLQueryScanField
          :query="containerQuery"
          :placeholder="t('Container')"
          :not-found-message="t('Container not found')"
          :rules="[validateContainerContentsRule]"
          :loading="progress.creatingSelection"
          @scan="handleScan($event)"
        />
      </div>

      <CreateContainer
        v-if="selectionsCount === 1 || selection"
        @create="handleScan($event)"
      >
        <template #activator="{ onClick }">
          <ButtonsRow v-slot="{ buttonProps }">
            <QBtn
              v-bind="buttonProps"
              icon="mdi-close-circle-outline"
              :disable="progress.creatingSelection"
              @click="resetAndBack()"
            >
              {{ t('Cancel') }}
            </QBtn>
            <QBtn
              v-bind="buttonProps"
              :disable="progress.creatingSelection"
              icon="mdi-view-grid-plus-outline"
              @click="onClick()"
            >
              {{ t('Create Container') }}
            </QBtn>
          </ButtonsRow>
        </template>
      </CreateContainer>

      <Teleport
        v-if="selectionsCount > 1"
        to="#teleport-target-buttons-row"
      >
        <ButtonsRow v-slot="{ buttonProps }">
          <QBtn
            v-bind="buttonProps"
            icon="mdi-arrow-left"
            :disable="progress.creatingSelection"
            @click="resetAndBack()"
          >
            {{ t('Cancel') }}
          </QBtn>
          <QBtn
            v-bind="buttonProps"
            icon="mdi-text-box-plus-outline"
            :disable="progress.creatingSelection"
            @click="enqueueSelections()"
          >
            {{ t('Create {n} Selections', selectionsCount) }}
          </QBtn>
        </ButtonsRow>
      </Teleport>
    </template>
    <QDialog v-model="nonEmptyContainerWarning">
      <QCard>
        <CardTitle>{{ t('Warning') }}</CardTitle>
        <BaseAlert type="warning">
          {{ t('Container already contains other orders. Start selection into this container?') }}
        </BaseAlert>
        <QCardActions align="right">
          <QBtn
            :label="t('No')"
            @click="nonEmptyContainerWarning = false"
          />
          <QBtn
            color="primary"
            :label="t('Start Selection')"
            @click="nonEmptyContainerWarning = false; startOrCreateSelections()"
          />
        </QCardActions>
      </QCard>
    </QDialog>
    <QInnerLoading :showing="progress.creatingSelection" />
  </QPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import { useI18n } from 'vue-i18n';
import useSelectionStore from '@/stores/selection';
import CreateContainer from '@/views/Mobile/CreateContainer.vue';
import { computed, nextTick, onBeforeMount, reactive, ref, watch } from 'vue';
import * as R from 'ramda';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import type {
  Container,
  MutationEnqueueSelectionsArgs,
  QueryAvailableSelectionTasksToCreateQueryArgs,
  QuerySelectionArgs,
  Scalars,
  Selection,
  SelectionOrderInput,
} from '@/graphql/types';
import { gql, useQuery } from '@urql/vue';
import { container as di, URQL } from '@/setup/container';
import GraphQLQueryScanField from '@/components/Mobile/GraphQLQueryScanField.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import type { FunctionValidationRule } from '@/types';
import BigLoading from '@/components/BigLoading.vue';
import CardTitle from '@/components/CardTitle.vue';
import navigateBack from '@/helpers/navigateBack';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const urql = di.cradle[URQL];

const router = useRouter();

const { fillErrorsFromGraphQLError, primaryError } = useErrorHandling();

const store = useSelectionStore();

const route = useRoute();

const { container, selectedShipments, currentSelection } = storeToRefs(store);

useBreadcrumbs(() => selectedShipments.value.length > 1
  ? t('Group Selection')
  : t('Selection by Shipment Order'),
);

const progress = reactive({
  creatingSelection: false,
});

const selection = ref<Selection | null>(null);

onBeforeMount(async function created(): Promise<void> {
  currentSelection.value = null;

  if (route.params.selectionId) {
    selectedShipments.value = [];

    const { data } = await urql.query<{ selection: Selection }, QuerySelectionArgs>(
      gql`
        query GetQueuedSelection($id: ID!) {
          selection: selection(id: $id) {
            id
            shipments { id }
          }
        }
      `,
      { id: route.params.selectionId as string },
    );

    selection.value = data!.selection;
  } else if (selectedShipments.value.length === 0) {
    resetAndBack();
  } else {
    const { data } = await urql.query<{ count: number }, QueryAvailableSelectionTasksToCreateQueryArgs>(
      gql`
        query GetAvailableTasksCountToCreate($shipmentsIds: [ID!]!) {
          count: availableSelectionTasksToCreateQuery(shipmentsIds: $shipmentsIds)
        }
      `,
      { shipmentsIds: selectedShipments.value.map(so => so.id) },
    );
    maxSelectionsCount.value = data!.count;
  }
});

const select = ref();

const selectionsCount = ref(1);

const minSelectionsCount = ref(1);
const maxSelectionsCount = ref(1);

const containerQuery = gql`
  query GetContainerForEnqueueSelections($barcode: String!) {
    containerByBarcode(barcode: $barcode) {
      id
      name
      barcode
      isEmpty
      activeSelections {
        id
        state
        shipments { id }
        selectionOrders {
          id
          container { id }
          shipment { id }
          items { id selectedAmount shippedAmount }
        }
      }
    }
  }
`;

const {
  data,
  error,
  fetching,
} = useQuery<{ separateSelectionNamesByShipments: string[] }>({
  query:     gql`
    query GetSeparateSelectionNamesByShipments($shipmentsIds: [ID!]!) {
      separateSelectionNamesByShipments(shipmentsIds: $shipmentsIds)
    }
  `,
  variables: {
    shipmentsIds: selectedShipments.value.map(so => so.id),
  },
  pause:     computed(() => selectedShipments.value.length === 0),
});
watch(error, fillErrorsFromGraphQLError);
watch(data, data => {
  separateSelectionNames.value = data!.separateSelectionNamesByShipments;
  // Если товара не достаточно, то с сервера придет пустой массив возможных зон.
  minSelectionsCount.value = R.max(1, R.min(separateSelectionNames.value.length, maxSelectionsCount.value));
  selectionsCount.value = minSelectionsCount.value;
});
const separateSelectionNames = ref<string[]>([]);

const selectionsCountVariants = computed(() => {
  return R.range(minSelectionsCount.value, maxSelectionsCount.value + 1);
});

async function enqueueSelections(): Promise<void> {
  const { error } = await urql.mutation<{ ids: Scalars['ID'][] }, MutationEnqueueSelectionsArgs>(
    gql`
      mutation EnqueueSelections($selectionsCount: Int!, $shipmentsIds: [ID!]!) {
        enqueueSelections(selectionsCount: $selectionsCount, shipmentsIds: $shipmentsIds) {
          name
        }
      }
    `,
    {
      selectionsCount: selectionsCount.value,
      shipmentsIds:    selectedShipments.value.map(so => so.id),
    },
  );

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  selectionsCount.value = 1;
  await router.push({ name: ROUTES.SELECTION_DASHBOARD });
}

function blurSelect() {
  nextTick(() => {
    select.value!.blur();
  });
}

const nonEmptyContainerWarning = ref(false);

async function handleScan(storage: Container): Promise<void> {
  progress.creatingSelection = true;
  container.value            = storage;
  const containsOtherOrders = (s: Selection) => s.selectionOrders.some(
    so => ordersForSelection.value.some(o => o.shipmentId !== so.shipment.id)
      && so.container.id === storage.id
      && so.items.some(soi => soi.selectedAmount > soi.shippedAmount)
  );

  if (storage.activeSelections.some(containsOtherOrders)) {
    nonEmptyContainerWarning.value = true;
    progress.creatingSelection = false;

    return;
  }
  await startOrCreateSelections();
  progress.creatingSelection = false;
}

async function startOrCreateSelections() {
  progress.creatingSelection = true;
  if (selection.value) {
    await startSelection(ordersForSelection.value, selection.value);
  } else if (selectionsCount.value === 1) {
    if (currentSelection.value === null) {
      await startSelection(ordersForSelection.value);
    }
  } else {
    await enqueueSelections();
  }
  progress.creatingSelection = false;
}

const ordersForSelection = computed(
  () => (selection.value?.shipments ?? selectedShipments.value).map(so => ({
    shipmentId: so.id,
    containerId:     container.value!.id,
  })),
);

async function startSelection(
  selectionOrders: SelectionOrderInput[],
  selection?: Selection,
) {
  const { error } = await store.startSelection(selectionOrders, selection);

  if (error) {
    fillErrorsFromGraphQLError(error);
  } else if (currentSelection.value === null) {
    primaryError.value = t('Selection not created. Check product stocks');
  } else {
    await router.replace({ name: ROUTES.SELECTION_PROCESS, params: { id: currentSelection.value.id } });
  }
}

// Условия повторяются в ChangeSelectionContainerMutation::getContainer
function validateContainerContentsRule(storage: Container): ReturnType<FunctionValidationRule> {
  if (storage.activeSelections.length === 0) {
    return storage.isEmpty
      ? true
      : t('Container has products. Use another Container');
  }

  return true;
}

function resetAndBack(): void {
  container.value = null;
  if (selectedShipments.value.length === 1) {
    navigateBack({ name: ROUTES.SELECTION_DASHBOARD });
  } else {
    router.push({ name: ROUTES.SELECTION_DASHBOARD });
  }
}

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/selection.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>

<i18n lang="yaml">
ru:
  Selections for queue: Отборов для очереди
  Create Container: Создать контейнер
  Create {n} Selections: >
    Создать {n} отборов
    | Создать отбор
    | Создать {n} отбора
    | Создать {n} отборов
  Selection not created. Check product stocks: Отбор не создан. Проверьте остатки товара
  Container has products. Use another Container: >
    В контейнере находятся товары. Используйте другой контейнер
  Container already contains other orders. Start selection into this container?: >
    Контейнер уже содержит другие заказы. Начать отбор в этот контейнер?
  Start Selection: Начать отбор
en:
  Selections for queue: Selections for queue
  Create Container: Create Container
  Create {n} Selections: >
    Create Selection
    | Create {n} Selections
  Selection not created. Check product stocks: Selection not created. Check product stocks
  Container has products. Use another Container: >
    Container has products. Use another Container
  Container already contains other orders. Start selection into this container?: >
    Container already contains other orders. Start selection into this container?
  Start Selection: Start Selection
</i18n>
