<template>
  <QPage padding>
    <PrimaryErrorBanner />
    <BigLoading v-if="fetching" />
    <BaseAlert
      v-else-if="notFound"
      type="info"
      icon="mdi-run-fast"
    >
      <p>
        {{ t('Cell not found') }}
      </p>
      <QBtn
        exact
        color="primary"
        icon="mdi-account-group"
        :to="{ name: ROUTES.CELLS_LIST }"
      >
        {{ t('Go to Cells List') }}
      </QBtn>
    </BaseAlert>
    <QCard v-else>
      <CardTitle>
        {{ cell.name }}
        <template v-if="cell.id !== null">
          <QSpace />
          <QBtn
            v-if="cell.deletedAt === null"
            flat
            round
            icon="mdi-delete"
            :disable="!canDelete"
            :loading="progress.deleting"
            :title="t('Delete')"
            color="red"
            @click="deleteCell()"
          />
          <QBtn
            v-else
            flat
            round
            icon="mdi-delete-restore"
            color="success"
            :loading="progress.restoring"
            :title="t('Restore')"
            @click="restoreCell()"
          />
        </template>
      </CardTitle>

      <QCardSection>
        <div class="row q-col-gutter-sm">
          <div
            v-if="isCellOfRack"
            class="col-sm-4 col-md-2"
          >
            <LinkField
              :to="{ name: ROUTES.RACKS_EDIT, params: { id: cell.rack.id } }"
              :label="t('Rack')"
              :title="t('Edit Rack')"
            >
              {{ rackFullName }}
            </LinkField>
          </div>
          <div
            v-if="isCellOfRack"
            class="col-sm-4 col-md-2"
          >
            <QInput
              v-model.number="cellLevel"
              :label="t('Level')"
              :rules="[notEmptyRule, positiveIntegerOrNullRule]"
            />
          </div>
          <div class="col-sm-4 col-md-2">
            <QInput
              v-if="isCellOfRack"
              v-model.number="cellNumber"
              :label="t('Position')"
              :rules="[notEmptyRule, positiveIntegerOrNullRule]"
              v-bind="qErrorsFor('name')"
            />
            <QInput
              v-else
              ref="nameInput"
              v-model="cell.name"
              :readonly="!cell.isMutable"
              :label="t('Name')"
              :rules="[notEmptyRule]"
              :autofocus="!cell.name"
              v-bind="qErrorsFor('name')"
            />
          </div>
          <div class="col-sm-4 col-md-2">
            <QInput
              :model-value="cell.id"
              :label="t('Cell ID')"
              readonly
            />
          </div>
          <div class="col-sm-4 col-md-2">
            <QInput
              v-model="cell.barcode"
              :label="t('Barcode')"
              :rules="[notEmptyRule]"
              :loading="progress.generatingBarcode"
              class="no-bottom-line"
              v-bind="qErrorsFor('barcode')"
            />
          </div>
          <div class="col-sm-4 col-md-2">
            <QSelect
              v-model="cell.cellsArea"
              :readonly="!cell.isMutable"
              :options="cellsAreas"
              :loading="cellsAreasLoading"
              :disable="cellsAreasLoading"
              :label="t('Area')"
              :placeholder="t('Not specified')"
              option-label="name"
              value="name"
              class="ellipsis"
            />
          </div>
        </div>
      </QCardSection>

      <QSeparator />

      <StocksReport
        v-if="cell.id"
        :storage="cell"
        storage-prefix="cellCard.stocksList.report"
      />

      <QSeparator />

      <QCardActions>
        <QBtn
          exact
          icon="mdi-arrow-left"
          @click="navigateBack({name: ROUTES.CELLS_LIST})"
        >
          {{ t('Back') }}
        </QBtn>

        <QSpace />

        <StorageInventoryButton
          :storage="cell"
          @error="fillErrorsFromGraphQLError"
        />

        <PrintDialog
          v-if="cell.id"
          v-slot="{ open }"
          v-model:barcode-type="barcodeType"
          template-name="cell-barcode"
          use-count
          :title="cell.name"
          :instances="[{count: printCount, params: { cellId: cell.id, barcodeType }}]"
          @count-changed="printCount=$event"
        >
          <QBtn
            color="primary"
            icon="mdi-printer"
            @click="open()"
          >
            {{ t('Print') }}
          </QBtn>
        </PrintDialog>

        <QBtn
          color="success"
          icon="mdi-content-save"
          :loading="progress.applying"
          :disabled="!canSave"
          @click="save()"
        >
          {{ t('Apply') }}
        </QBtn>
        <QBtn
          color="primary"
          icon="mdi-content-save"
          :loading="progress.saving"
          :disabled="!canSave"
          @click="saveAndGoToList()"
        >
          {{ t('Save') }}
        </QBtn>
      </QCardActions>
    </QCard>
  </QPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import BigLoading from '@/components/BigLoading.vue';
import CardTitle from '@/components/CardTitle.vue';
import LinkField from '@/components/LinkField.vue';
import PrintDialog from '@/components/PrintDialog.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useValidationRules from '@/composables/useValidationRules';
import type {
  Cell,
  CellsArea,
  CellSize,
  DimensionsConstraint,
  MakeMaybe,
  MutationDeleteCellArgs,
  MutationRestoreCellArgs,
  MutationSaveCellArgs,
  QueryCellArgs,
  Scalars,
  WeightConstraint,
} from '@/graphql/types';
import { AbcClassEnum, PrintBarcodeTypeEnum } from '@/graphql/types';
import { parseCellName, stringifyParsedCellName } from '@/helpers/cellNameHelpers';
import StocksReport from '@/views/Storage/StocksReport.vue';
import useDocumentsPrintingState from '@/views/useDocumentsPrintingState';
import { gql, useClientHandle, useQuery } from '@urql/vue';
import dayjs from 'dayjs';
import type { QInput as QInputType } from 'quasar';
import { computed, onBeforeMount, onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import StorageInventoryButton from '@/components/StorageInventoryButton.vue';
import navigateBack from '@/helpers/navigateBack';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const { client: urql } = useClientHandle();

const router = useRouter();

// noinspection LocalVariableNamingConventionJS
const { fillErrorsFromGraphQLError, clearErrors, PrimaryErrorBanner, qErrorsFor } = useErrorHandling();

const { notEmptyRule, positiveIntegerOrNullRule } = useValidationRules();

const props = defineProps<{
  id?: Scalars['ID'];
}>();

const printCount = ref(1);

const notFound = ref(false);

const { data, error, fetching, executeQuery } = useQuery<{
  cell: Cell;
}, QueryCellArgs>({
  query:     gql`
    query GetCellForDesktopEdit($id: ID!) { cell(id: $id) {
      id
      name
      rack { id prefix row name }
      deletedAt
      isMutable
      barcode
      cellsArea { id name }
      isEmpty
    } }
  `,
  variables: computed(() => ({
    id: String(props.id),
  })),
  pause:     computed(() => !props.id),
});
watch(error, fillErrorsFromGraphQLError);
watch(data, data => {
  if (data?.cell) {
    cell.value = data.cell;
  } else {
    notFound.value = true;
  }
});

const cell = ref<MakeMaybe<Cell, 'id'>>({
  id:                   null,
  createdAt:            dayjs().toISOString(),
  name:                 '',
  barcode:              '',
  rack:                 null,
  cellsArea:            {} as CellsArea,
  size:                 {} as CellSize,
  abcClass:             AbcClassEnum.B,
  weightConstraint:     {} as WeightConstraint,
  dimensionsConstraint: {} as DimensionsConstraint,
  isEmpty:              true,
  stocks:               [],
  isMutable:            true,
  storageCell:          null!,
});

async function updateCell(id: Scalars['ID']) {
  // eslint-disable-next-line unicorn/prefer-ternary
  if (id === props.id) {
    await executeQuery();
  } else {
    await router.replace({
      name:   ROUTES.CELLS_EDIT,
      params: { id },
    });
  }
}

const progress = reactive({
  saving:              false,
  applying:            false,
  deleting:            false,
  restoring:           false,
  generatingBarcode:   false,
});

const state = useDocumentsPrintingState();

const barcodeType = ref(state.value.lastCellBarcodeType ?? PrintBarcodeTypeEnum.BARCODE);

const nameInput = ref<QInputType>();

const {
  data: cellsAreasResult,
  fetching: cellsAreasLoading,
} = useQuery<{ cellsAreas: CellsArea[] }>({
  query:         gql`query GetCellsAreas { cellsAreas { id name isMutable } }`,
  requestPolicy: 'cache-and-network',
});
watch(cellsAreasResult, data => {
  fillAreas(data!.cellsAreas);
});
function fillAreas(areas: CellsArea[]) {
  cellsAreas.value = areas;

  if (!cell.value.id) {
    cell.value.cellsArea = areas.find(
      area => !area.isMutable,
    ) ?? cell.value.cellsArea;
  }
}
onMounted(() => {
  if (cellsAreasResult.value) {
    fillAreas(cellsAreasResult.value.cellsAreas);
  }
});
const cellsAreas = ref<CellsArea[]>([]);

const isCellOfRack = computed(() => !!cell.value.rack);

const cellLevel = computed({
  get(): number|null {
    return parseCellName(cell.value.name)!.level;
  },
  set(level: number|null) {
    const parsedName = parseCellName(cell.value.name)!;
    cell.value.name = stringifyParsedCellName({ ...parsedName, level });
  },
});

const cellNumber = computed({
  get(): number|null {
    return parseCellName(cell.value.name)!.number;
  },
  set(number: number|null) {
    const parsedName = parseCellName(cell.value.name)!;
    cell.value.name = stringifyParsedCellName({ ...parsedName, number });
  },
});

const rackFullName = computed((): string => {
  const { prefix, row, name } = cell.value.rack!;
  return `${prefix ? `${prefix}-` : ''}${row}-${name}`;
});

const canSave = computed((): boolean => {
  const { name, barcode, cellsArea } = cell.value;
  // noinspection OverlyComplexBooleanExpressionJS
  return !!(name && barcode && cellsArea.id);
});

const canDelete = computed((): boolean => {
  return !!cell.value.id && cell.value.isMutable && cell.value.isEmpty;
});

onBeforeMount(generateBarcode);

watch(nameInput, nameInput => {
  nameInput!.validate();
});

watch(barcodeType, (): void => {
  state.value.lastCellBarcodeType = barcodeType.value;
});

async function generateBarcode(): Promise<void> {
  if (props.id) {
    return;
  }

  progress.generatingBarcode = true;

  cell.value.barcode = await urql.query<{ generateStorageStringBarcode: string }>(
    gql`query GenerateStorageStringBarcode { generateStorageStringBarcode }`,
    {},
  ).then(({ data }) => data!.generateStorageStringBarcode);

  progress.generatingBarcode = false;
}

async function deleteCell(): Promise<void> {
  progress.deleting = true;

  const { error } = await urql.mutation<unknown, MutationDeleteCellArgs>(
    gql`mutation DeleteCell($id: ID!) { deleteCell(id: $id) }`,
    { id: cell.value.id! },
  );

  progress.deleting = false;

  if (error) {
    fillErrorsFromGraphQLError(error);
  } else {
    navigateBack({ name: ROUTES.CELLS_LIST });
  }
}

async function restoreCell(): Promise<void> {
  progress.restoring = true;

  await urql.mutation<unknown, MutationRestoreCellArgs>(
    gql`mutation RestoreCell($id: ID!) { restoreCell(id: $id) { id } }`,
    { id: cell.value.id! },
  );

  progress.restoring = false;

  updateCell(cell.value.id!);
}

async function doSave(): Promise<Cell | null> {
  clearErrors();
  const { error, data } = await urql.mutation<{ saveCell: Cell }, MutationSaveCellArgs>(
    gql`
      mutation SaveCell(
        $id: ID,
        $name: String!,
        $barcode: String!,
        $areaId: ID!
      ) {
        saveCell(
          id: $id,
          name: $name,
          barcode: $barcode,
          areaId: $areaId
        ) { id }
      }
    `,
    {
      id:      cell.value.id,
      name:    cell.value.name,
      barcode: cell.value.barcode,
      areaId:  cell.value.cellsArea.id,
    },
  );

  if (error) {
    fillErrorsFromGraphQLError(error);
    return null;
  }

  return data!.saveCell;
}

async function save(): Promise<void> {
  progress.applying = true;

  const cell = await doSave();

  if (cell) {
    updateCell(cell.id);
  }

  progress.applying = false;
}

async function saveAndGoToList(): Promise<void> {
  progress.saving = true;

  if (await doSave()) {
    navigateBack({ name: ROUTES.CELLS_LIST });
  }

  progress.saving = false;
}

</script>

<i18n lang="yaml">
ru:
  Cell not found: Ячейка не найдена
  Go to Cells List: Перейти к списку ячеек
  Edit Rack: Редактировать стеллаж
  Rack: Стеллаж
  Area: Зона
  Cell ID: Код
  "Cell inventory task {id} created": Создано задание {id} на инвентаризацию ячейки
en:
  Cell not found: Cell not found
  Go to Cells List: Go to Cells List
  Edit Rack: Edit Rack
  Rack: Rack
  Area: Area
  Cell ID: ID
  "Cell inventory task {id} created": "Cell inventory task {id} created"
</i18n>
