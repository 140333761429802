<template>
  <slot
    v-if="status === 'checking'"
    name="checking"
  >
    <QSpinnerHourglass :size="size" />
  </slot>
  <slot
    v-if="!hideReady && status === 'ready'"
    name="ready"
  >
    <QIcon
      name="mdi-check-circle-outline"
      color="success"
      :size="size"
    />
  </slot>
  <slot
    v-if="status === 'unavailable'"
    name="unavailable"
    :title="title"
  >
    <QIcon
      name="mdi-alert-circle-outline"
      color="error"
      :title="title"
      :size="size"
    />
  </slot>
</template>

<script setup lang="ts">

import usePrintService from '@/composables/usePrintService';
import type { PrintAgentPrinter } from '@/graphql/types';
import type { PrinterUnion } from '@/types';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

type PrintAgentStatus = 'checking' | 'ready' | 'unavailable';

const { t } = useI18n();

const { pingPrintAgent } = usePrintService();

const props = defineProps<{
  printer: PrinterUnion;
  hideReady?: boolean;
  size?: string;
}>();

const status = ref<PrintAgentStatus | null>(null);

const title = computed(() => t('Print Agent "{name}" is unavailable', {
  name: (props.printer as PrintAgentPrinter).agentName,
}));

watch(() => props.printer, checkStatus, { immediate: true });

async function checkStatus() {
  if (props.printer?.__typename !== 'PrintAgentPrinter'
    || !props.printer.agentName
  ) {
    status.value = null;
    return;
  }

  const checkingAgent = props.printer.agentName;
  status.value = 'checking';

  const newStatus = await getStatus(props.printer.agentName);

  // Во время проверки статуса принтер мог измениться.
  // Меняем статус на полученный только если агент принтера тот же.
  if (checkingAgent === props.printer.agentName) {
    status.value = newStatus;
  }
}

async function getStatus(agentName: string): Promise<PrintAgentStatus> {
  try {
    await pingPrintAgent(agentName, 1000, 5);
    return 'ready';
  } catch {
    return 'unavailable';
  }
}

</script>

<i18n lang="yaml">
ru:
  Print Agent "{name}" is unavailable: Агент печати {name} недоступен

en:
  Print Agent "{name}" is unavailable: Print Agent "{name}" is unavailable
</i18n>
