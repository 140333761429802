<template>
  <BigLoading v-if="!selection" />

  <FullHeightPage
    v-else
    class="col"
  >
    <div class="col scroll-y">
      <HorizontalPropsList v-slot="{ item }">
        <component
          :is="item"
          :caption="t('Code')"
        >
          {{ id }}
        </component>
        <component
          :is="item"
          v-if="selection.name"
          :caption="t('Selection area')"
        >
          {{ selection.name }}
        </component>
        <component
          :is="item"
          :caption="t('Created at')"
        >
          {{ formatDate(selection.createdAt, FORMATS.DATETIME) }}
        </component>
        <component
          :is="item"
          :caption="t('Products Count')"
        >
          {{ alreadyScannedAmount }} / {{ amountInSelection }}
        </component>
        <component
          :is="item"
          :caption="t('Shipment orders')"
          class="text-no-wrap"
        >
          {{ selection.shipments.length }}
          <template v-if="selection.shipments.length < 3">
            {{ `(${selection.shipments.map(s => s.externalId).sort().join(', ')})` }}
          </template>
        </component>
        <component
          :is="item"
          :caption="t('State')"
        >
          <QChip
            v-if="selection.state === SelectionStateEnum.SELECTING"
            dense
            class="q-ma-none"
            :color="forSelectionState(selection.state)"
            text-color="black"
          >
            {{ t('selectionState.' + selection.state) }}
          </QChip>
          <QChip
            v-else
            dense
            class="q-ma-none"
            :color="forOperationState(selection)"
            text-color="black"
          >
            {{ t('selectionState.' + selection.state) }}
          </QChip>
        </component>
        <!--После WMS-1606 нужно отобразить пользователя, который последним выполнял задание -->
        <component
          :is="item"
          v-if="selection?.user"
          :caption="t('User')"
        >
          {{
            `${selection.user.lastname} ${selection.user.firstname}`
          }}
        </component>
        <component
          :is="item"
          v-if="carrier"
          :caption="t('Carrier Name')"
        >
          {{ carrier.name }}
        </component>
        <component
          :is="item"
          :caption="t('Selection Type')"
        >
          {{ t('selectionKind.' + selection.kind) }}
        </component>
        <component
          :is="item"
          v-if="singleContainer"
          :caption="t('Container')"
        >
          <RouterLink :to="{ name: ROUTES.CONTAINERS_EDIT, params: { id: String(singleContainer.id)} }">
            {{ singleContainer.name }}
          </RouterLink>
        </component>
      </HorizontalPropsList>
      <div
        v-if="isSelectionCompleted || selection.state === SelectionStateEnum.SELECTING"
        class="q-gutter-md"
      >
        <ScrollCarousel
          v-if="cells.length > 0"
          v-model="currentSlide"
          :items="cells"
          class="q-pa-sm"
          show-indicators
        >
          <template #item="{ item: cell }">
            <div class="inline column no-wrap items-center">
              <span class="text-grey-7">
                {{ t('cell') }}
              </span>
              <QItemLabel class="text-h5">
                {{ cell.name }}
              </QItemLabel>
            </div>
          </template>
        </ScrollCarousel>
        <QList>
          <ProductListItem
            v-for="({ order, item }) in selectionOrderItemsForCurrentPlace"
            :key="item.id"
            :class="item.id === selectedItem?.id ? 'bg-grey-4' : ''"
            :product="item.storageUnit.productPack.product"
            :clickable="isItemClickable"
            @click="handleItemClick(item)"
          >
            <template #top-right>
              <QItemLabel caption>
                <span class="q-mr-sm">
                  {{ order.shipment.externalId }}
                </span>
                <span>
                  <QChip
                    dense
                    size="sm"
                    :color="amountBadgeColor(item)"
                    square
                    class="q-mx-none"
                  >
                    {{ item.selectedAmount }}/{{ item.plannedAmount }}
                  </QChip>
                  {{ item.storageUnit.productPack.measurementUnit.shortName }}
                </span>
              </QItemLabel>
            </template>
          </ProductListItem>
        </QList>
      </div>
      <div
        v-else
        class="q-pt-lg q-gutter-md"
      >
        <QList>
          <ProductListItem
            v-for="item in selection.items"
            :key="item.productPack.id"
            color="primary"
            :product="item.productPack.product"
          >
            <template #top-right>
              <QItemLabel caption>
                <span class="q-ml-sm">
                  {{ item.amount }}
                  {{ item.productPack.measurementUnit.shortName }}
                </span>
              </QItemLabel>
            </template>
          </ProductListItem>
        </QList>
      </div>
    </div>
    <ButtonsRow v-slot="{ buttonProps }">
      <slot
        name="buttons"
        :button-props="buttonProps"
        :current-cell="cells[currentSlide]"
      >
        <QBtn
          v-if="!isSelectionCompleted"
          v-bind="buttonProps"
          :to="selection.selectionOrders.length > 0
            ? { name: ROUTES.SELECTION_PROCESS, params: { id: selection.id} }
            : { name: ROUTES.SELECTION_ENQUEUE, params: {selectionId: selection.id } }"
        >
          <QIcon
            color="primary"
            name="mdi-play"
          />
          {{ selection.selectionOrders.length > 0 ? t('Continue Selection') : t('Start Selection') }}
        </QBtn>
        <ConfirmsAction
          v-if="canDelete"
          should-prompt
          :confirm-text="t('Yes')"
          :cancel-text="t('No')"
          @confirmed="deleteSelection()"
        >
          <template #activator="{ prompt }">
            <QBtn
              :loading="progress.deleting"
              v-bind="buttonProps"
              icon="mdi-delete"
              @click="prompt()"
            >
              {{ t('Delete') }}
            </QBtn>
          </template>
          <template #title>
            {{ t('Delete Selection?') }}
          </template>
        </ConfirmsAction>
      </slot>
    </ButtonsRow>
  </FullHeightPage>
</template>

<script setup lang="ts">

import BigLoading from '@/components/BigLoading.vue';
import ConfirmsAction from '@/components/ConfirmsAction.vue';
import FullHeightPage from '@/components/FullHeightPage.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import ScrollCarousel from '@/components/Mobile/ScrollCarousel.vue';
import ProductListItem from '@/components/ProductListItem.vue';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import ProductForListItem from '@/graphql/fragments/ProductForListItem';
import type {
  Cell,
  QuerySelectionArgs,
  Scalars,
  Selection,
  SelectionOrderItem,
} from '@/graphql/types';
import { SelectionStateEnum } from '@/graphql/types';
import { forOperationState, forSelectionState } from '@/helpers/badgeColors';
import navigateBack from '@/helpers/navigateBack';
import ROUTES from '@/router/routeNames';
import useSelectionStore from '@/stores/selection';
import HorizontalPropsList from '@/views/Mobile/HorizontalPropsList.vue';
import { gql, useClientHandle } from '@urql/vue';
import { QChip } from 'quasar';
import * as R from 'ramda';
import { computed, onBeforeMount, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const { client: urql } = useClientHandle();

const progress = reactive({
  deleting: false,
});

const props = defineProps<{
  id: Scalars['ID'];
  cellId?: Scalars['ID'];
  itemsSelectable?: boolean;
}>();

const emit = defineEmits<{
  'update:selected-item': [item: SelectionOrderItem | null];
}>();

const store = useSelectionStore();

const selection = ref<Selection | null>(null);

const currentSlide = ref(0);
watch(() => currentSlide.value, () => { selectedItem.value = null; });

const isItemClickable = computed(() =>
  props.itemsSelectable && !isSelectionCompleted.value
  && !!store.currentStorage && !store.shouldScanContainer
  && props.cellId === cells.value[currentSlide.value].id,
);

const selectedItem = ref<SelectionOrderItem | null>(null);

watch(selectedItem, item => {
  emit('update:selected-item', item);
});

function amountBadgeColor(item: SelectionOrderItem) {
  return item.selectedAmount === item.plannedAmount ? 'green-3' : 'red-3';
}

const singleContainer = computed(() =>
  R.uniqBy(so => so.container.id, selection.value?.selectionOrders ?? []).length === 1
    ? selection.value!.selectionOrders[0].container
    : null
);

const carrier = computed(() => {
  const uniqueByCarriers = R.uniqBy(i => i.carrier?.id, selection.value!.shipments);
  return uniqueByCarriers.length === 1 ? uniqueByCarriers[0].carrier : null;
});

const cells = computed(() => {
  const cells = (selection.value?.selectionOrders ?? [])
    .flatMap(o => o.items.map(i => i.storageFrom as Cell));

  return R.sortBy<Cell>(cell => cell.name, R.uniqBy(cell => cell.id, cells));
});

const selectionOrderItemsForCurrentPlace = computed(() =>
  (selection.value?.selectionOrders ?? [])
    .flatMap(order => order.items
      .filter(i => i.storageFrom.id === cells.value[currentSlide.value].id)
      .map(item => ({ order, item })))
);

const alreadyScannedAmount = computed(() => R.sum(
  (selection.value?.movements ?? [])
    .map(m => m.storable.productPack.quantityInMinMeasurementUnits * m.amount)),
);

const amountInSelection = computed(() => R.sum(selection.value!.items.map(
  i => i.productPack.quantityInMinMeasurementUnits * i.amount,
)));

onBeforeMount(function created(): void {
  loadSelection();
});

const isSelectionCompleted = computed(() =>
  selection.value?.state === SelectionStateEnum.COMPLETED
  || selection.value?.state === SelectionStateEnum.SELECTED
);

async function loadSelection(): Promise<void> {
  const { data } = await urql.query<{ selection: Selection | null }, QuerySelectionArgs>(
    gql`
      query GetSelectionForDashboard($id: ID!) {
        selection(id: $id) {
          __typename
          id
          name
          createdAt
          shipments {
            id
            externalId
            carrier { id name }
          }
          state
          user { id firstname lastname }
          kind
          items {
            id
            productPack {
              id
              product { ...ProductForListItem }
              measurementUnit { id shortName }
              quantityInMinMeasurementUnits
            }
            amount
          }
          selectionOrders {
            id
            container { id name }
            shipment { id externalId }
            items {
              id
              plannedAmount
              selectedAmount
              storageFrom { id name }
              storageUnit {
                id
                productPack {
                  id
                  measurementUnit { id shortName }
                  product { ...ProductForListItem }
                }
              }
            }
          }
          movements {
            id
            amount
            storable {
              id
              productPack {
                id
                quantityInMinMeasurementUnits
              }
            }
          }
        }
      }
      ${ProductForListItem}
    `,
    { id: props.id as string },
  );

  selection.value = data!.selection;
  if (props.cellId) {
    currentSlide.value = cells.value.findIndex(cell => cell.id === props.cellId) ?? 0;
  }
}

function handleItemClick(item: SelectionOrderItem): void {
  selectedItem.value = selectedItem.value === item
    ? null
    : item;
}

const canDelete = computed(() => selection.value?.movements.length === 0);

async function deleteSelection(): Promise<void> {
  progress.deleting = true;
  await store.deleteSelection(selection.value!);
  progress.deleting = false;

  navigateBack({ name: ROUTES.OPERATIONS_LIST });
}

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/selection.yaml"></i18n>

<i18n lang="yaml">
ru:
  cell: ячейка
  Code: Код
  Continue Selection: Продолжить отбор
  Selection area: Зона отбора
  Selection Type: Тип отбора
  Start Selection: Начать отбор
  Products Count: Товаров
  Shipment orders: Заказов
  Subtract: Вычесть
  Reset: Сбросить
  Reset amount: Сбросить количество
  Reset amount?: Сбросить количество?

en:
  cell: cell
  Code: Code
  Continue Selection: Continue Selection
  Selection area: Selection area
  Selection Type: Selection Type
  Start Selection: Start Selection
  Products Count: Products
  Shipment orders: Shipment orders
  Subtract: Subtract
  Reset: Reset
  Reset amount: Reset amount
  Reset amount?: Reset amount?

</i18n>
