<template>
  <QInput
    :model-value="modelValue ? modelValue.label : null"
    :label="t('Select Date')"
    readonly
    dense
  >
    <QPopupProxy v-model="isOpen">
      <QDate
        mask="YYYY-MM-DD"
        :model-value="modelValue ? modelValue.value : null"
        @update:model-value="onInput($event)"
      />
    </QPopupProxy>
  </QInput>
</template>

<script setup lang="ts">

import type { FilterValue } from '@/types/reports';
import dayjs from 'dayjs';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const isOpen = ref(false);

defineProps<{
  modelValue: FilterValue<string> | null;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: FilterValue<string> | null): void;
  (e: 'save-filter'): void;
}>();

function onInput(rawValue: string | null): void {
  isOpen.value = false;

  if (!rawValue) {
    emit('update:modelValue', null);
    return;
  }

  const date = dayjs.tz(rawValue, 'UTC');

  emit('update:modelValue', rawValue ? {
    label: date.format('LL'),
    value: date.toISOString(),
  } : null);

  emit('save-filter');
}

</script>

<i18n lang="yaml">
ru:
  Select Date: Выберите дату
en:
  Select Date: Select Date
</i18n>
