<template>
  <FullHeightPage>
    <GraphQLQueryTable
      :available-filters="availableFilters"
      :graphql-query="query"
      :fields="tableFields"
      storage-prefix="realizationsList.report"
      keep-page-in-url
      sticky-header
      class="col"
    >
      <template #body-cell-id="column">
        <BodyCellLink
          :column="column"
          :to="{ name: ROUTES.REALIZATIONS_EDIT, params: { id: column.value } }"
        />
      </template>

      <template #body-cell-state="column">
        <QTd :props="column">
          <QChip
            :color="badgeColors.forOperationState(column.row)"
            text-color="black"
          >
            {{ column.value }}
          </QChip>
        </QTd>
      </template>
      <template #body-cell-externalId="column">
        <QTd :props="column">
          <QSkeleton v-if="column.loading" />
          <template v-else>
            <RouterLink
              v-for="(shipment, i) in column.value"
              :key="shipment.id"
              :to="shipmentCardRoute(shipment)"
              :class="i > 0 ? 'q-ml-sm' : ''"
            >
              {{ shipment.externalId }}
            </RouterLink>
          </template>
        </QTd>
      </template>

      <template #after-search-string="{ options }">
        <RealizationsExcelImportExport
          :options="options"
        />
      </template>
    </GraphQLQueryTable>
  </FullHeightPage>
</template>

<script setup lang="ts">

import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import FullHeightPage from '@/components/FullHeightPage.vue';
import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import getCarriersForSelect from '@/graphql/shorthands/getCarriersForSelect';
import type { Realization, RealizationItem } from '@/graphql/types';
import * as badgeColors from '@/helpers/badgeColors';
import { createDatesRangeOperator, createList, createPrimitive } from '@/helpers/reports';
import shipmentCardRoute from '@/helpers/shipmentCardRoute';
import userFullName from '@/helpers/userFullName';
import ROUTES from '@/router/routeNames';
import type { TypenameAware } from '@/types';
import type { ReportFilter, TableColumn } from '@/types/reports';
import RealizationsExcelImportExport
  from '@/views/Shipping/Shipments/RealizationsExcelImportExport.vue';
import { gql } from '@urql/vue';
import * as R from 'ramda';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

useBreadcrumbs(t('Realizations'));

const tableFields: TableColumn<TypenameAware<Realization>>[] = [
  {
    label:     t('ID'),
    name:      'id',
    field:     'id',
    mandatory: true,
    align:     'left',
  },
  {
    label:    t('Created at'),
    name:     'createdAt',
    field:    'createdAt',
    format:   d => formatDate(d, FORMATS.DATETIME),
    align:    'left',
    sortable: true,
  },
  {
    label:    t('User'),
    name:     'user',
    field:    'user',
    format:   userFullName,
    align:    'left',
  },
  {
    label:  t('State'),
    name:   'state',
    field:  'state',
    align:  'left',
    format: state => t(`realizationState.${state}`),
  },
  {
    label:    t('Counterparty'),
    name:     'counterparty',
    field:    'items',
    format:   (items?: RealizationItem[]) => R.uniq((items ?? []).map(i => i.shipment.counterparty?.name).filter(n => n !== null)).join(', '),
    align:    'left',
  },
  {
    label:    t('Carrier Name'),
    name:     'carrier',
    field:    'items',
    format:   (items?: RealizationItem[]) => R.uniq((items ?? []).map(i => i.shipment.carrier?.name ?? null).filter(n => n !== null)).join(', '),
  },
  {
    label:         t('Order Number'),
    name:          'externalId',
    field:         r => R.uniqBy(o => o.id, r.items.map(i => i.shipment)),
    requestFields: ['items'],
  },
];

const availableFilters: ReportFilter[] = [
  {
    field:     'customerName',
    label:     t('Customer'),
    operators: [
      createPrimitive('=', 'string'),
    ],
  },
  {
    field:     'supplierName',
    label:     t('Supplier'),
    operators: [
      createPrimitive('=', 'string'),
    ],
  },
  {
    field:     'productSku',
    label:     t('SKU'),
    operators: [
      createPrimitive('=', 'string'),
      createPrimitive('in', 'multiString', {
        label: t('reportFilterOperator.in'),
      }),
    ],
  },
  {
    field:     'carrier',
    label:     t('Carrier Name'),
    operators: [
      createList('in', getCarriersForSelect, t('reportFilterOperator.in')),
    ],
  },
  {
    field:     'orderExternalId',
    label:     t('Order Number'),
    operators: [
      createPrimitive('=', 'string'),
    ],
  },
  {
    field:     'createdAt',
    label:     t('Created at'),
    operators: [
      createDatesRangeOperator(),
    ],
  },
];

const query = gql`
  query GetRealizationsForReport(
    $page: Int,
    $perPage: Int!,
    $query: String,
    $filter: [ReportFilterInput!],
    $sort: [ReportSortInput!]!,
  ) {
    result: realizationsReport(
      page: $page,
      perPage: $perPage,
      query: $query,
      filter: $filter,
      sort: $sort,
    ) {
      data {
        __typename
        id
        createdAt
        user { id firstname lastname }
        state
        items {
          id
          shipment {
            id
            externalId
            carrier { id name }
            counterparty {
              id
              name
            }
          }
        }
      }
      total
    }
  }
`;

</script>

<i18n lang="yaml">
ru:
  Container Barcode: ШК контейнера
  Realizations: Отгрузки
en:
  Container Barcode: Container Barcode
  Realizations: Realizations
</i18n>
