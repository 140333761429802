<template>
  <div class="column no-wrap">
    <FiltersToolbar>
      <template #search>
        <SearchInput
          v-if="withSearch"
          v-model="searchString"
          :debounce="debounce"
          :blurred-search="blurredSearch"
        />
      </template>
    </FiltersToolbar>
    <QList separator>
      <template v-for="item in filteredItems">
        <slot
          name="item"
          v-bind="{ item }"
        />
      </template>
    </QList>
  </div>
</template>

<script setup lang="ts">

import type { ReportFilterInput } from '@/graphql/types';
import { computed, ref } from 'vue';
import SearchInput from '@/components/BaseTable/SearchInput.vue';
import useReportFilters from '@/composables/useReportFilters';
import type { ReportFilter } from '@/types/reports';

type TItem = Record<string, unknown>;

const props = defineProps<{
  items: TItem[];
  withSearch?: boolean;
  debounce?: number;
  blurredSearch?: boolean;
  availableFilters?: ReportFilter[];
  fixedFilters?: ReportFilterInput[];
  itemMeetsSearchString?: (item: TItem, searchString: string) => boolean;
}>();

// noinspection LocalVariableNamingConventionJS
const {
  itemMeetsFilters,
  DefaultMobileToolbar: FiltersToolbar,
} = useReportFilters(props.availableFilters, props.fixedFilters);

const searchString = ref<string | null>('');

const filteredItems = computed(() => props.items
  .filter(item => (props.itemMeetsSearchString?.(item, searchString.value ?? '') ?? true)
    && itemMeetsFilters(field => item[field])));

</script>
