<template>
  <BaseTable
    ref="table"
    v-bind="$attrs"
    :fields="fields"
    :rows="result.data"
    :total="result.total"
    :summary-row="result.summary"
    :loading="fetching"
    :with-search="!withoutSearch"
    :error="errorMessage"
    @refresh="refresh($event)"
  >
    <template
      v-for="slot in Object.keys($slots)"
      #[slot]="bind"
    >
      <slot
        :name="slot"
        v-bind="bind"
      />
    </template>
  </BaseTable>
</template>

<script setup lang="ts">

import BaseTable from '@/components/BaseTable.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import type { ReportOptions, ReportResult, TableColumn } from '@/types/reports';
import { useQuery } from '@urql/vue';
import {
  type DocumentNode,
  type FieldNode,
  type OperationDefinitionNode,
  parse,
  print,
  type SelectionNode,
} from 'graphql';
import { clone, equals } from 'ramda';
import { computed, ref, watch } from 'vue';

const { getPrimaryErrorFromGraphQLError } = useErrorHandling();

type TItem = Record<string, unknown>;

const props = defineProps<{
  graphqlQuery: DocumentNode;
  fields: TableColumn<TItem>[];
  transformItem?: (item: TItem) => TItem;
  withoutSearch?: boolean;
}>();

const reportOptions = ref<ReportOptions>(null!);

const emit = defineEmits<{
  (e: 'data-received', data: object[]): void;
}>();

const query = computed(() => {
  if (!reportOptions.value) {
    return props.graphqlQuery;
  }
  const result = clone(props.graphqlQuery);
  const operationDef = result.definitions
    .find(d => d.kind === 'OperationDefinition') as OperationDefinitionNode;
  const resultField = operationDef.selectionSet.selections
    .find(s => s.kind === 'Field' && (s.alias ?? s.name).value === 'result') as FieldNode;
  const dataField = resultField.selectionSet!.selections
    .find(s => s.kind === 'Field' && (s.alias ?? s.name).value === 'data') as FieldNode;
  const fieldsSelectionSet = dataField.selectionSet!;

  const allFields = new Set(props.fields
    .flatMap(f => f.requestFields ?? [f.field]));

  const fieldsToRequestBySelection = reportOptions.value.columns
    .map(c => props.fields.find(f => f.name === c)!)
    .flatMap(f => f.requestFields ?? [f.field]);

  const fieldsToRequestByMandatoryFields = props.fields
    .filter(f => f.mandatory)
    .flatMap(f => f.requestFields ?? [f.field]);

  const fieldsToRequest = new Set([
    ...fieldsToRequestBySelection,
    ...fieldsToRequestByMandatoryFields,
  ]);

  const selectionShouldBeKept = (s: SelectionNode) => {
    if (s.kind === 'InlineFragment') {
      s.selectionSet.selections = s.selectionSet.selections.filter(selectionShouldBeKept);
      return s.selectionSet.selections.length > 0;
    }

    if (s.kind !== 'Field') {
      return true;
    }
    const name = (s.alias ?? s.name).value;
    // Не трогаем неизвестные поля
    if (!allFields.has(name)) {
      return true;
    }
    return fieldsToRequest.has(name);
  };

  // Оставляем только поля, выбранные в отчете
  fieldsSelectionSet.selections = fieldsSelectionSet.selections.filter(selectionShouldBeKept);

  return parse(print(result));
});

const { data, error, fetching, executeQuery } = useQuery<{ result: ReportResult<TItem> }>({
  query,
  variables: reportOptions,
  pause:     computed(() => !reportOptions.value),
});

const errorMessage = ref('');

watch(error, error => {
  errorMessage.value = error ? getPrimaryErrorFromGraphQLError(error) : '';
});

watch(data, data => {
  if (data?.result) {
    result.value = {
      data: props.transformItem
        ? data.result.data.map(props.transformItem)
        : data.result.data,
      total: data.result.total,
      summary: data.result.summary,
    };

    emit('data-received', result.value.data);
  }
});
const result = ref<ReportResult<TItem>>({
  data:  [],
  total: 0,
});

function refresh(options?: ReportOptions): void {
  if (!options || equals(options, reportOptions.value)) {
    // При ручном обновлении опции не меняются,
    // поэтому запускам обновление вручную.
    executeQuery();
  } else {
    // В противном случае изменение будет обнаружено
    // и обновление произойдет автоматически.
    reportOptions.value = options;
  }
}

const table = ref();

defineExpose({
  refresh,
  resetSearch: () => table.value.resetSearch(),
});

</script>
