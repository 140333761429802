<template>
  <SupplyDetailsMobile
    v-if="preferences.terminalMode"
    :id="id"
    :title="t('Supply')"
    @close="router.push({ name: ROUTES.SUPPLIES_LIST })"
  >
    <template #not-found-banner>
      <CustomerReturnNotFoundBanner />
    </template>
  </SupplyDetailsMobile>
  <SupplyDetailsDesktop
    v-else
    :id="id"
  >
    <template #title="{ supply }">
      {{ t('Supply') }}
      {{
        t('{id} dated {date}', {
          id: supply.externalId,
          date: formatDate(supply.createdAt)
        })
      }}
    </template>
    <template #fields="{ supply }: { supply: SupplierDelivery }">
      <div class="col-sm-3">
        <QInput
          readonly
          :label="t('Supplier')"
          :model-value="supply.counterparty?.name"
          stack-label
          :placeholder="t('Not specified')"
        />
      </div>
      <div class="col-sm-3">
        <QInput
          readonly
          :label="t('Planned Arrival Date')"
          :model-value="formatDate(supply.plannedArrivalDate)"
          stack-label
          :placeholder="t('Not specified')"
        />
      </div>
      <div
        v-if="supply.supplierReturns.length > 0"
        class="col-sm-3"
      >
        <QField
          :label="t('Supplier Returns')"
          stack-label
        >
          <LinksList
            :max-to-show="3"
            :items="supply.supplierReturns"
          >
            <template #item="{item}">
              <QTooltip>{{ formatDate(item.createdAt, FORMATS.DATE) }}</QTooltip>
              <RouterLink :to="{ name: ROUTES.SUPPLIER_RETURNS_EDIT, params: { id: item.id } }">
                {{ item.externalId }}
              </RouterLink>
              <QChip
                :color="forShipmentState(item)"
                text-color="black"
                dense
                class="q-my-none q-mr-none"
              >
                {{ t('shipmentState.' + item.state) }}
              </QChip>
            </template>
          </LinksList>
        </QField>
      </div>
    </template>
    <template #not-found-banner>
      <CustomerReturnNotFoundBanner />
    </template>
  </SupplyDetailsDesktop>
</template>

<script setup lang="ts">

import NotFoundBanner from '@/components/NotFoundBanner.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import useLocalPreferences from '@/composables/useLocalPreferences';
import type { Scalars } from '@/graphql/types';
import ROUTES from '@/router/routeNames';
import SupplyDetailsDesktop from '@/views/Arrival/Supply/SupplyDetailsDesktop.vue';
import SupplyDetailsMobile from '@/views/Arrival/Supply/SupplyDetailsMobile.vue';
import { type Component, h } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { forShipmentState } from '@/helpers/badgeColors';
import LinksList from '@/components/LinksList.vue';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const preferences = useLocalPreferences();

useBreadcrumbs(t('Supplies'));

const router = useRouter();

defineProps<{
  id: Scalars['ID'];
}>();

const CustomerReturnNotFoundBanner: Component = () => h(NotFoundBanner, {
  notFoundMessage: t('Supply not found'),
  backRoute:       { name: ROUTES.SUPPLIES_LIST },
  goBackMessage:   t('Go to Supplies List'),
});

</script>

<i18n lang="yaml">

ru:
  Supply not found: Поступление не найдено
  Go to Supplies List: Перейти к списку поступлений
  Planned Arrival Date: Планируемая дата поступления

en:
  Supply not found: Supply not found
  Go to Supplies List: Go to Supplies List
  Planned Arrival Date: Planned Arrival Date

</i18n>
