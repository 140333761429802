<template>
  <GraphQLQueryScanField
    :query="scanQuery"
    :hint="t('Specify product to add')"
    :label="t('Barcode or SKU')"
    :not-found-message="t('Nothing found')"
    :loading="loadingBatch"
    show-submit-button
    v-bind="$attrs"
    @scan="productPackToAdd = $event"
  />
  <QDialog
    :model-value="!!editingBatchData"
    @update:model-value="editingBatchData = null"
  >
    <QCard style="min-width: 300px;">
      <CardTitle>
        {{ t('Enter Batch Data') }}
      </CardTitle>

      <EditBatchData
        :batch="editingBatchData"
        :product="productPackToAdd!.product"
        strict-validation
        @save="handleBatchSave"
        @back="productPackToAdd = null"
      />
    </QCard>
  </QDialog>
</template>

<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import { gql, useQuery } from '@urql/vue';
import type {
  AccountingModel,
  Inventory,
  InventoryBatchData,
  InventoryBatchDataInput,
  ProductPack,
  QueryBatchDataForInventoryProductArgs,
  Scalars,
} from '@/graphql/types';
import GraphQLQueryScanField from '@/components/Mobile/GraphQLQueryScanField.vue';
import { computed, nextTick, ref, watch } from 'vue';
import ProductForBatch from '@/graphql/fragments/ProductForBatch';
import EditBatchData from '@/components/EditBatchData.vue';
import { omit } from 'ramda';
import CardTitle from '@/components/CardTitle.vue';

const { t } = useI18n();

const props = defineProps<{
    inventory: Inventory;
}>();

const emit = defineEmits<{
  (e: 'add-product-pack', storable: ProductPack, batch: InventoryBatchDataInput): void;
}>();

const scanQuery = gql`
  ${ProductForBatch}

  query GetStorableForAddingToInventory($barcode: String!) {
    inventoryStorableByBarcode(barcode: $barcode) {
      id
      measurementUnit { id name shortName }
      minMeasurementUnit { id shortName}
      quantityInMinMeasurementUnits
      product { id sku name ...ProductForBatch }
    }
  }
`;

const productPackToAdd = ref<ProductPack | null>(null);
const editingBatchData = ref<InventoryBatchDataInput | null>(null);

const {
  executeQuery: loadBatch,
  fetching: loadingBatch,
} = useQuery<{ batch: InventoryBatchData }, QueryBatchDataForInventoryProductArgs>({
  query: gql`
    query BatchDataForInventoryProductOnInventoryCard($storageId: ID!, $productPackId: ID!) {
      batch: batchDataForInventoryProduct(storageId: $storageId, productPackId: $productPackId) {
        number
        productionDate
        expirationDate
      }
    }
  `,
  variables: computed(() => ({
    storageId: props.inventory.storage!.id,
    productPackId: productPackToAdd.value?.id as Scalars['ID'],
  })),
  pause: true,
});

watch(productPackToAdd, async pack => {
  if (!pack) {
    editingBatchData.value = null;
    return;
  }

  await nextTick();

  const loadBatchResult = await loadBatch();

  if (!loadBatchResult.data.value) {
    return;
  }

  const batchData = omit(['__typename'], loadBatchResult.data.value.batch);

  if (isBatchDataFilled(pack.product.accountingModel, batchData)) {
    emit('add-product-pack', pack, batchData!);
    productPackToAdd.value = null;
  } else {
    editingBatchData.value = batchData;
  }
});

function isBatchDataFilled(accModel: AccountingModel, batch: InventoryBatchDataInput) {
  if (!accModel.byBatch) {
    return true;
  }

  return (!accModel.batchExpirationDateRequired || batch.expirationDate)
        && (!accModel.batchNumberRequired || batch.number)
        && (!accModel.batchProductionDateRequired || batch.productionDate);
}

function handleBatchSave(batch: InventoryBatchDataInput) {
  emit('add-product-pack', productPackToAdd.value!, batch);
  productPackToAdd.value = null;
}

</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>

<i18n lang="yaml">
ru:
  Barcode or SKU: Штрихкод или артикул
  Specify product to add: Укажите товар который надо добавить
  Enter Batch Data: Заполните данные партии

en:
  Barcode or SKU: Barcode or SKU
  Specify product to add: Specify product to add
  Enter Batch Data: Enter Batch Data
</i18n>
