<template>
  <template
    v-for="(item, i) in items.slice(0, maxToShow)"
    :key="item.id"
  >
    <span>
      <slot
        name="item"
        :item="item"
      />
    </span>
    <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
    <span v-if="i < items.length - 1">,&nbsp;</span>
  </template>
  <template v-if="items.length > maxToShow">
    {{ t('and {n} more', items.length - maxToShow) }}
  </template>
</template>

<script setup lang="ts" generic="TItem extends { id: string }">

import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps<{
  items: TItem[];
  maxToShow: number;
}>();

defineSlots<{
  item(props: {item: TItem}): unknown;
}>();

</script>
