<template>
  <div class="row root no-wrap scroll-x hide-when-empty">
    <slot :item="itemComponent" />
  </div>
</template>

<script setup lang="ts">

import type { Component } from 'vue';
import { h } from 'vue';

const itemComponent: Component = (props, { slots }) => h('div', {
  class: 'cell q-pa-md',
}, [
  h('div', { class: 'text-grey text-caption text-no-wrap' }, props.caption),
  slots.default!(),
]);

</script>

<style scoped lang="scss">

@import "quasar/src/css/variables";

.root {
  border-top: 1px solid $grey-5;
  border-bottom: 1px solid $grey-5;
}

:slotted(.cell) {
  &:not(:last-of-type) {
    border-right: 1px solid $grey-5;
  }

  &:last-of-type {
    flex-grow: 1;
  }
}

</style>
