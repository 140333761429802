<template>
  <MaximizedDialog
    :model-value="isOpen"
    fixed-header
    :title="product.name"
    @close="emit('update:is-open', false)"
  >
    <PrimaryErrorBanner />
    <SwiperCarousel
      v-if="!fetching && photos.length > 0"
      v-model:slide="currentSlide"
      :items="photos"
      pagination
      class="full-height"
      wrapper-class="full-height"
    >
      <template #item="{ item: p }">
        <div class="full-height">
          <QImg
            :src="p.url"
            class="full-height"
            :fit="imageFit"
          />
        </div>
      </template>
    </SwiperCarousel>
  </MaximizedDialog>
</template>

<script setup lang="ts">
import MaximizedDialog from '@/components/MaximizedDialog.vue';
import SwiperCarousel from '@/components/Mobile/SwiperCarousel.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import type { Product, Scalars } from '@/graphql/types';
import { gql, useQuery } from '@urql/vue';
import type { QImgProps } from 'quasar';
import { computed, ref, watch } from 'vue';

const { fillErrorsFromGraphQLError, PrimaryErrorBanner, clearErrors } = useErrorHandling();

const props = withDefaults(defineProps<{
  product: Product;
  isOpen: boolean;
  imageFit?: QImgProps['fit'];
  initialPhotoId?: Scalars['ID'];
}>(), {
  imageFit: 'cover',
});

const emit = defineEmits<{
  'update:is-open': [isOpen: boolean];
}>();

const { data, error, fetching } = useQuery<{ product: Product }>({
  query: gql`
    query ProductForPhotosCarousel($id: ID!) {
      product(id: $id) { id photos { id url } }
    }
  `,
  variables: computed(() => ({ id: props.product.id })),
  pause: computed(() => !props.isOpen),
});
watch(error, fillErrorsFromGraphQLError);
watch(fetching, fetching => {
  if (fetching) {
    clearErrors();
  }
});

const photos = computed(() => data.value?.product.photos ?? []);

const currentSlide = ref(0);

watch(photos, photos => {
  if (!props.isOpen || !props.initialPhotoId) {
    return;
  }
  const index = photos.findIndex(p => p.id === props.initialPhotoId);
  if (index >= 0) {
    currentSlide.value = index;
  }
});

</script>
