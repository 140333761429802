<template>
  <QPage padding>
    <div class="row q-col-gutter-md">
      <div class="col-12 col-md-6 col-xl-4">
        <NewToken @created="refreshTokens" />
      </div>

      <div class="col-12 col-md-6 col-xl-4">
        <TokensList
          :tokens="data?.tokens ?? []"
          :loading="fetching"
          @change="refreshTokens"
        />
      </div>
    </div>
  </QPage>
</template>

<script setup lang="ts">

import useBreadcrumbs from '@/composables/useBreadcrumbs';
import NewToken from '@/views/API/ApiTokenManager/NewToken.vue';
import TokensList from '@/views/API/ApiTokenManager/TokensList.vue';
import { gql, useQuery } from '@urql/vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

useBreadcrumbs(t('API Tokens'));

const { data, fetching, executeQuery: refreshTokens } = useQuery({
  query: gql`
    query GetAccessTokensList {
      tokens: accessTokens(withAbilities: [API]) {
        id
        name
        lastUsedAt
        createdAt
        readableToken
      }
    }
  `,
});

</script>

<i18n lang="yaml">
ru:
  API Tokens: API токены
en:
  API Tokens: API Tokens
</i18n>
