<template>
  <BaseAlert
    v-if="getPrimaryError()"
    type="error"
  >
    {{ getPrimaryError() }}
  </BaseAlert>
  <QList separator>
    <QItem dense>
      <QItemSection>
        <QItemLabel
          header
          class="q-pl-none"
        >
          {{ t('Select Printer') }}
        </QItemLabel>
      </QItemSection>
      <QItemSection side>
        <QBtn
          :loading="progress.discovering"
          flat
          round
          icon="mdi-refresh"
          class="float-right"
          @click="discover"
        />
      </QItemSection>
    </QItem>
    <TransitionGroup
      appear
      enter-active-class="animated fadeInDown"
      leave-active-class="animated fadeOutUp"
    >
      <QItem
        v-for="p in discoveredPrinters"
        :key="`${p.agentName}-${p.systemName}`"
        :active="printerSelected(p)"
        clickable
        @click="emit('update:modelValue', p)"
      >
        <QItemSection
          avatar
          style="min-width: 40px;"
        >
          <QIcon
            v-if="printerSelected(p)"
            name="mdi-check"
          />
        </QItemSection>
        <QItemSection>
          <QItemLabel>
            {{ p.displayName }}
          </QItemLabel>
          <QItemLabel
            v-if="p.systemName !== p.displayName"
            caption
          >
            {{ p.systemName }}
          </QItemLabel>
        </QItemSection>
        <QItemSection
          side
          top
        >
          <QItemLabel caption>
            {{ p.agentName }}
          </QItemLabel>
        </QItemSection>
      </QItem>
    </TransitionGroup>
  </QList>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import usePrintService from '@/composables/usePrintService';
import { onBeforeMount, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { pipe, toObservable } from 'wonka';

type PrintAgentPrinter = {
  agentName: string;
  systemName: string;
  displayName: string;
};

type PrinterProp = Pick<PrintAgentPrinter, 'agentName' | 'systemName'>;

const { t } = useI18n();

const {
  getPrimaryError,
  setPrimaryError,
  clearErrors,
} = useErrorHandling();

const progress = reactive({
  discovering: false,
  saving:      false,
});

const printService = usePrintService();

const props = defineProps<{
  modelValue: PrinterProp;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', printer: PrinterProp): void;
}>();

const discoveredPrinters = ref<PrintAgentPrinter[]>([]);

function printerSelected(p: PrintAgentPrinter) {
  return p.agentName === props.modelValue.agentName
    && p.systemName === props.modelValue.systemName;
}

onBeforeMount(discover);

async function discover(): Promise<void> {
  clearErrors();

  progress.discovering = true;
  discoveredPrinters.value = [];

  await new Promise<void>(complete => {
    pipe(
      printService.discoverPrinters(1000),
      toObservable,
    ).subscribe({
      next(printers) {
        discoveredPrinters.value = [...discoveredPrinters.value, ...printers];
      },
      error(e) {
        setPrimaryError(e.message);
      },
      complete,
    });
  });

  progress.discovering = false;
}

</script>

<i18n lang="yaml">
ru:
  Select Printer: Выберите принтер
en:
  Select Printer: Select Printer
</i18n>
