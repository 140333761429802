<template>
  <GraphQLQueryScanField
    ref="scanField"
    :query="query"
    :hint="inventoryProduct ? inventoryProduct.sku : t('Scan and take Product')"
    :placeholder="t('Product')"
    :not-found-message="t('Product not found')"
    v-bind="$attrs"
    no-omni-input-scan
    @scan="emit('scan', $event)"
  />
  <template v-if="inventoryProduct">
    <QAvatar
      rounded
      size="20px"
    >
      <ProductPhoto :product="inventoryProduct" />
    </QAvatar>
    <span class="text-subtitle2">{{ inventoryProduct.name }}</span>
  </template>
</template>

<script setup lang="ts">

import GraphQLQueryScanField from '@/components/Mobile/GraphQLQueryScanField.vue';
import ProductPhoto from '@/components/ProductPhoto.vue';
import ProductPackForInventory from '@/graphql/fragments/ProductPackForInventory';
import type { Product, ProductPack } from '@/graphql/types';
import { gql } from '@urql/vue';
import { type Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps<{
  inventoryProduct: Product | null;
}>();

const emit = defineEmits<{
  (e: 'scan', storable: ProductPack): void;
}>();

const query = gql`
  ${ProductPackForInventory}

  query GetStorableOrStorageForInventory($barcode: String!) {
    productPackByBarcode(barcode: $barcode) {
      productPack {
        ...ProductPackForInventory
      }
      amount
    }
  }
`;

const scanField: Ref<InstanceType<typeof GraphQLQueryScanField>> = ref(null!);

defineExpose({
  scan: (value: string) => scanField.value?.scan(value),
});

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>

<i18n lang="yaml">
ru:
  Scan and take Product: Отсканируйте и возьмите нужный товар
  Storable not found: Единица хранения не найдена

en:
  Scan and take Product: Scan and take Product
  Storable not found: Storable not found
</i18n>
