<template>
  <ShipmentsList
    :extra-fields="tableFields as TableColumn<Shipment>[]"
    :extra-filters="availableFilters"
    :fixed-filters="fixedFilters"
    storage-prefix="shipmentsList.report"
    mobile-storage-prefix="shipmentsList.mobileReport"
  >
    <template #import-export="{ options, onSuccess }">
      <CustomerOrdersExcelImportExport
        :options="options"
        @success="onSuccess"
      />
    </template>
    <template #validation-message="{ ids }">
      {{ t('Orders {ids} cannot be deleted', { ids: ids.join(', ') }, ids.length) }}
    </template>
    <template #confirmation-message="{ notDeletedItems }">
      {{ t('Delete {n} Orders?', { id: notDeletedItems[0].externalId ?? notDeletedItems[0].id }, notDeletedItems.length) }}
    </template>
  </ShipmentsList>
</template>

<script setup lang="ts">

import useBreadcrumbs from '@/composables/useBreadcrumbs';
import type { ReportFilterInput, CustomerOrder, Shipment } from '@/graphql/types';
import { gql, useClientHandle } from '@urql/vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import ShipmentsList from '@/views/Shipping/Shipments/ShipmentsList.vue';
import type { ReportFilter, TableColumn } from '@/types/reports';
import type { Customer, QueryCustomersArgs } from '@/graphql/types';
import * as reports from '@/helpers/reports';
import CustomerOrdersExcelImportExport from '@/views/Shipping/Shipments/CustomerOrdersExcelImportExport.vue';

const { t } = useI18n();

const { client: urql } = useClientHandle();

useBreadcrumbs(t('Orders'));

const tableFields: TableColumn<CustomerOrder>[] = [
  {
    label:  t('Customer'),
    name:   'customer',
    field:  'counterparty',
    format: (customer?: Customer) => customer?.name ?? '',
    align:  'left',
  },
  {
    label:    t('Order Number'),
    name:     'externalId',
    field:    'externalId',
    align:    'left',
    sortable: true,
  },
];

const availableFilters: ReportFilter[] = [
  {
    field:                 'customer',
    label:                 t('Customer'),
    hideLabelWhenSelected: true,
    operators:             [
      reports.createList('in', searchString => urql.query(
        gql`
          query GetCustomers($searchString: String) {
            customers(searchString: $searchString) { id name }
          }
        `,
          { searchString } as QueryCustomersArgs,
      ).then(({ data }) => data!.customers.map((s: Customer) => ({
        value: s.id,
        label: s.name,
      })))),
    ],
  },
];

const fixedFilters = computed<ReportFilterInput[]>(() => [
  {
    field:    'type',
    operator: '=',
    value:    JSON.stringify('CustomerOrder' as CustomerOrder['__typename']),
  },
]);

</script>
<i18n lang="yaml">
ru:
  Orders {ids} cannot be deleted: >
    Нельзя удалить заказы {ids}
    | Нельзя удалить заказ {ids}
    | Нельзя удалить заказы {ids}
    | Нельзя удалить заказы {ids},

  Delete {n} Orders?: >
    Удалить {n} заказов?
    | Удалить заказ {id}?
    | Удалить {n} заказа?
    | Удалить {n} заказов?

en:
  Orders {ids} cannot be deleted: >
    Order {ids} cannot be deleted
    | Orders {ids} cannot be deleted

  Delete {n} Orders?: >
    Delete Order {id}?
    | Delete {n} Orders?
</i18n>
