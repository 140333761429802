<template>
  <div class="column q-gutter-sm">
    <div v-if="getPrimaryError()">
      <BaseAlert type="error">
        {{ getPrimaryError() }}
      </BaseAlert>
    </div>
    <div>
      <QInput
        v-model="editingUnit.shortName"
        :label="t('Short Name')"
        :disable="hasProgress()"
        :rules="[unitNameRule]"
        autofocus
      />
    </div>
    <div>
      <QInput
        v-model="editingUnit.name"
        :label="t('Full Name')"
        :disable="hasProgress()"
        :rules="[unitNameRule]"
      />
    </div>
    <div>
      <QCheckbox
        v-model="editingUnit.isFractional"
        :label="t('Fractional')"
        :disable="editingUnit.isStandard"
      />
    </div>
    <div class="row">
      <QBtn
        color="success"
        icon="mdi-check"
        :loading="hasProgress()"
        :disable="!editingUnit.shortName || !editingUnit.name"
        @click="saveUnit()"
      >
        {{ t('Save') }}
      </QBtn>

      <QSpace />

      <QBtn
        color="error"
        icon="mdi-close"
        :disabled="hasProgress()"
        @click="emit('cancel')"
      >
        {{ t('Cancel') }}
      </QBtn>
    </div>
  </div>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useProgressHandling from '@/composables/useProgressHandling';
import useValidationRules from '@/composables/useValidationRules';
import type { MeasurementUnit, Mutation, MutationSaveMeasurementUnitArgs } from '@/graphql/types';
import { useI18n } from 'vue-i18n';
import { gql, useClientHandle } from '@urql/vue';
import { ref } from 'vue';

const { t } = useI18n();

const { fillErrorsFromGraphQLError, getPrimaryError } = useErrorHandling();

const { progressStarted, hasProgress } = useProgressHandling();

const { client: urql } = useClientHandle();

const { regexRule } = useValidationRules();

const unitNameRule = regexRule(/^[\p{L}\d_ /]+$/u);

const props = defineProps<{
  unit: MeasurementUnit;
}>();

const emit = defineEmits<{
  (e: 'save', unit: MeasurementUnit): void;
  (e: 'cancel'): void;
}>();

const editingUnit = ref(props.unit);

async function saveUnit(): Promise<void> {
  const done = progressStarted();

  const { error, data } = await urql.mutation<
    Pick<Mutation, 'saveMeasurementUnit'>,
    MutationSaveMeasurementUnitArgs
  >(
    gql`
      mutation SaveMeasurementUnit(
        $id: ID,
        $name: String!,
        $shortName: String!,
        $isFractional: Boolean!,
      ) {
        saveMeasurementUnit(id: $id, name: $name, shortName: $shortName, isFractional: $isFractional) {
          id name shortName isFractional
        }
      }
    `,
    editingUnit.value,
  );

  if (error) {
    fillErrorsFromGraphQLError(error);
  } else {
    emit('save', Object.assign(editingUnit.value, data!.saveMeasurementUnit));
  }

  done();
}

</script>

<i18n lang="yaml">
ru:
  Short Name: Сокращенное наименование
  Class: Тип упаковки
  Fractional: Дробное количество

en:
  Short Name: Short Name
  Class: Class
  Fractional: Fractional
</i18n>
