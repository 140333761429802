<template>
  <QBtn
    v-if="canChangeCell"
    icon="mdi-check"
    @click="handleClick"
  >
    {{ t('Make Current') }}
  </QBtn>
</template>

<script setup lang="ts">

import { computed } from 'vue';
import useSelectionStore from '@/stores/selection';
import type { Cell } from '@/graphql/types';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const store = useSelectionStore();

const props = defineProps<{
  cell: Cell;
}>();

const emit = defineEmits<{
  (e: 'change'): void;
}>();

const canChangeCell = computed(() => !store.shouldScanContainer
  && props.cell.id !== store.currentStorage?.id);

function handleClick() {
  store.currentStorage = props.cell;
  emit('change');
}

</script>
