<template>
  <FullHeightPage>
    <GraphQLQueryTable
      :graphql-query="query"
      :transform-item="transformOperation"
      :fields="tableFields"
      :available-filters="availableFilters"
      storage-prefix="operationsList.report"
      keep-page-in-url
      without-search
      sticky-header
      class="col"
    >
      <template #body-cell-id="column">
        <BodyCellLink
          :column="column"
          :to="operationCardRoute(column.row)"
          :disable="!operationCardRoute(column.row)"
        />
      </template>

      <template #body-cell-state="column">
        <QTd :props="column">
          <QChip
            :color="badgeColors.forOperationState(column.row)"
            text-color="black"
          >
            {{ column.value }}
          </QChip>
        </QTd>
      </template>

      <template #body-cell-__typename="column">
        <QTd :props="column">
          <QChip
            :color="badgeColors.forOperationType(column.row)"
            text-color="black"
          >
            {{ t('operationType.' + column.value) }}
          </QChip>
        </QTd>
      </template>

      <template #body-cell-difference="column">
        <QTd :props="column">
          <InventoryDifference
            v-if="isInventory(column.row)"
            :inventory="column.row"
          />
        </QTd>
      </template>
    </GraphQLQueryTable>
  </FullHeightPage>
</template>

<script setup lang="ts">

import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import FullHeightPage from '@/components/FullHeightPage.vue';
import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import InventoryDifference from '@/components/InventoryDifference.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import MovementForInventory from '@/graphql/fragments/MovementsForInventory';
import type { Inventory, OperationUnion, Storage } from '@/graphql/types';
import * as badgeColors from '@/helpers/badgeColors';
import operationCardRoute from '@/helpers/operationCardRoute';
import { createDatesRangeOperator, createPrimitive } from '@/helpers/reports';
import userFullName from '@/helpers/userFullName';
import type { TypenameAware } from '@/types';
import type { ReportFilter, TableColumn } from '@/types/reports';
import { gql } from '@urql/vue';
import { uniqBy } from 'ramda';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

useBreadcrumbs(t('Operations'));

const tableFields: TableColumn<TypenameAware<OperationUnion>>[] = [
  {
    label:     t('ID'),
    name:      'id',
    field:     'id',
    mandatory: true,
    align:     'left',
    sortable:  true,
  },
  {
    label:    t('Created at'),
    name:     'createdAt',
    field:    'createdAt',
    format:   d => formatDate(d, FORMATS.DATETIME),
    align:    'left',
    sortable: true,
  },
  {
    label:  t('User'),
    name:   'user',
    field:  'user',
    format: userFullName,
    align:  'left',
  },
  {
    label: t('Type'),
    name:  '__typename',
    field: '__typename',
    align: 'left',
  },
  {
    label:  t('State'),
    name:   'state',
    field:  'state',
    align:  'left',
    format: (state, op) =>  ({
      Arrival:     t(`arrivalState.${state}`),
      Inventory:   t(`inventoryState.${state}`),
      Selection:   t(`selectionState.${state}`),
      Realization: t(`realizationState.${state}`),
      Transfer:    t(`transferState.${state}`),
    }[op.__typename!]),
  },
  {
    label: t('Difference'),
    name:  'difference',
    field: 'movements',
  },
  {
    label: t('Storage'),
    name: 'storage',
    field: (operation) => {
      switch (operation.__typename) {
        case 'Arrival':
          return [(operation as unknown as { arrivalStorage: Storage }).arrivalStorage];
        case 'Inventory':
          return [(operation as unknown as { inventoryStorage: Storage }).inventoryStorage];
        case 'Transfer':
          return uniqBy(s => s.id, operation.movements.flatMap(m => [m.storageFrom!, m.storageTo!]));
        case 'Selection':
          return operation.selectionOrders.map(so => so.container);
        case 'Realization':
          return uniqBy(s => s.id, operation.items.map(i => i.container));
        default:
          return [];
      }
    },
    requestFields: [
      'arrivalStorage',
      'inventoryStorage',
      'movements',
      'selectionOrders',
      'container',
    ] as unknown[] as (keyof OperationUnion)[],
    format: (storages: Storage[]) => storages.map(s => s.name).join(', '),
    align: 'left',
  }
];

const availableFilters: ReportFilter[] = [
  {
    field:     'createdAt',
    label:     t('Created at'),
    operators: [
      createDatesRangeOperator(),
    ],
  },
  {
    label:     t('Storage'),
    field:     'storageName',
    operators: [
      createPrimitive('=', 'string'),
    ],
  },
];

const query = gql`
   query GetOperations($page: Int, $perPage: Int!, $query: String, $filter: [ReportFilterInput!], $sort: [ReportSortInput!]!) {
     result: operationsReport(page: $page, perPage: $perPage, query: $query, filter: $filter, sort: $sort) {
       data {
         __typename
         id
         createdAt
         user { id firstname lastname }
         ... on Arrival {
           # У других операций свойство тоже называется state,
           # но с другим типом, GraphQL не допускает таких пересечений.
           # https://github.com/graphql/graphql-js/issues/53
           Arrival_State: state
           arrivalStorage: storage { id name }
         }
         ... on Inventory {
           Inventory_State: state
           movements { ...MovementForInventory }
           inventoryStorage: storage { id name }
         }
         ... on Realization {
           Realization_State: state
           items { id container { id name } }
         }
         ... on Selection {
           Selection_State: state
           selectionOrders {
             id
             container { id name }
           }
         }
         ... on Transfer {
           Transfer_State: state
           movements {
             id
             storageFrom { id name }
             storageTo { id name }
           }
         }
       }
       total
     }
   }
  ${MovementForInventory}
`;

function isInventory(operation: OperationUnion): operation is Inventory {
  return operation.__typename === 'Inventory';
}

function transformOperation(op: OperationUnion): OperationUnion {
  return {
    ...op,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state: op[op.__typename + '_State' as keyof typeof op] as any,
  };
}

</script>

<i18n lang="yaml">
ru:
  Difference: Расхождение
en:
  Difference: Difference
</i18n>
