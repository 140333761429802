<template>
  <div v-if="mobile">
    <QList>
      <QItem
        :active="modelValue?.value === true"
        clickable
        @click="handleClick(true)"
      >
        <QItemSection>
          {{ trueLabel }}
        </QItemSection>
      </QItem>
      <QItem
        :active="modelValue?.value === false"
        clickable
        @click="handleClick(false)"
      >
        <QItemSection>
          {{ falseLabel }}
        </QItemSection>
      </QItem>
    </QList>
  </div>
  <BooleanSelect
    v-else
    v-bind="$attrs"
    :true-text="trueLabel"
    :false-text="falseLabel"
    :model-value="modelValue ? modelValue.value : null"
    :label="t('Select Value')"
    dense
    style="width: 150px;"
    @update:model-value="onInput($event)"
  />
</template>

<script setup lang="ts">

import BooleanSelect from '@/components/BooleanSelect.vue';
import type { FilterOperatorBoolean, FilterValue } from '@/types/reports';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  modelValue: FilterValue<boolean> | null;
  mobile: boolean;
  operator: FilterOperatorBoolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: FilterValue<boolean> | null): void;
  (e: 'save-filter'): void;
}>();

function onInput(value: boolean) {
  emit('update:modelValue', {
    label: value ? trueLabel.value : falseLabel.value,
    value,
  });
}

const trueLabel = computed(() => props.operator.trueLabel ?? t('Yes'));
const falseLabel = computed(() => props.operator.falseLabel ?? t('No'));

function handleClick(value: boolean) {
  onInput(value);

  emit('save-filter');
}

</script>

<i18n lang="yaml">
ru:
  Select Value: Выберите значение
en:
  Select Value: Select Value
</i18n>
