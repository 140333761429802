<template>
  <QCard>
    <CardTitle>
      {{ t('Update Password') }}
      <template #subtitle>
        {{ t('Ensure your account is using a long, random password to stay secure') }}
      </template>
    </CardTitle>

    <QSeparator />

    <BaseAlert
      v-if="getPrimaryError()"
      type="error"
    >
      {{ getPrimaryError() }}
    </BaseAlert>

    <QForm>
      <QCardSection>
        <QInput
          v-model="currentPassword"
          type="password"
          :label="t('Current Password')"
          v-bind="qErrorsFor('currentPassword')"
          autocomplete="current-password"
        />
        <QInput
          v-model="newPassword"
          type="password"
          :label="t('New Password')"
          v-bind="qErrorsFor('newPassword')"
          autocomplete="new-password"
        />
        <QInput
          v-model="newPasswordRepeat"
          type="password"
          :label="t('Confirm Password')"
          autocomplete="new-password"
        />
      </QCardSection>

      <QSeparator />

      <BaseAlert
        v-if="passwordUpdated"
        type="success"
      >
        {{ t('Password updated') }}
      </BaseAlert>

      <QCardActions>
        <QSpace />

        <QBtn
          color="primary"
          type="submit"
          :loading="hasProgress()"
          icon="mdi-content-save"
          @click.prevent="updatePassword()"
        >
          {{ t('Save') }}
        </QBtn>
      </QCardActions>
    </QForm>
  </QCard>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import CardTitle from '@/components/CardTitle.vue';
import useBackend from '@/composables/useBackend';
import useErrorHandling from '@/composables/useErrorHandling';
import useProgressHandling from '@/composables/useProgressHandling';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { fillErrorsFromHttpError, clearErrors, getPrimaryError, qErrorsFor } = useErrorHandling();

const { progressStarted, hasProgress } = useProgressHandling();

const backend = useBackend();

const currentPassword = ref('');

const newPassword = ref('');

const newPasswordRepeat = ref('');

const passwordUpdated = ref(false);

async function updatePassword(): Promise<void> {
  passwordUpdated.value = false;
  clearErrors();
  const finished = progressStarted();

  try {
    await backend.updatePassword(
      currentPassword.value,
      newPassword.value,
      newPasswordRepeat.value,
    );
    currentPassword.value = '';
    newPassword.value = '';
    newPasswordRepeat.value = '';
    passwordUpdated.value = true;
  } catch (e) {
    fillErrorsFromHttpError(e);
  } finally {
    finished();
  }
}

</script>

<i18n lang="yaml">
ru:
  Update Password: Изменить пароль
  Ensure your account is using a long, random password to stay secure: >
    Убедитесь, что используете длинный случайный пароль, чтобы оставаться в безопасности
  Current Password: Текущий пароль
  New Password: Новый пароль
  Confirm Password: Повтор пароля
  Password updated: Пароль успешно изменен

en:
  Update Password: Update Password
  Ensure your account is using a long, random password to stay secure: >
    Ensure your account is using a long, random password to stay secure
  Current Password: Current Password
  New Password: New Password
  Confirm Password: Confirm Password
  Password updated: Password updated
</i18n>
