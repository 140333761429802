<template>
  <QList>
    <QItem
      v-for="r in releases"
      :key="r.version"
    >
      <QItemSection>
        {{ t('Version {v}', { v: r.version }) }}
      </QItemSection>
      <QItemSection
        v-if="r.downloadUrlMsi"
        side
      >
        <QBtn
          :href="r.downloadUrlMsi"
          flat
          round
          icon="mdi-microsoft-windows"
          :title="t('Download version {v} for {platform}', {
            v: r.version,
            platform: 'Windows',
          })"
        />
      </QItemSection>
      <QItemSection
        v-if="r.downloadUrlDeb"
        side
      >
        <QBtn
          :href="r.downloadUrlDeb"
          flat
          round
          icon="mdi-penguin"
          :title="t('Download version {v} for {platform}', {
            v: r.version,
            platform: 'Linux',
          })"
        />
      </QItemSection>
    </QItem>
  </QList>
</template>

<script setup lang="ts">

import type { PrintAgentRelease } from '@/graphql/types';
import { useI18n } from 'vue-i18n';

defineProps<{
  releases: PrintAgentRelease[];
}>();

const { t } = useI18n();

</script>

<i18n lang="yaml">
ru:
  Version {v}: Версия {v}
  Download version {v} for {platform}: Скачать версию {v} для {platform}
en:
  Version {v}: Version {v}
  Download version {v} for {platform}: Download version {v} for {platform}
</i18n>
