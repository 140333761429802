<template>
  <FullHeightPage>
    <DashboardTabs storage-key="transferDashboard.tab">
      <template #pending>
        <TransferDashboardList
          storage-prefix="transferDashboard.inProgressTab.listOptions"
          :states="[TransferStateEnum.CREATED, TransferStateEnum.IN_PROGRESS]"
          class="full-height scroll-y"
        />
      </template>
      <template #completed>
        <TransferDashboardList
          storage-prefix="transferDashboard.completedTab.listOptions"
          :states="[TransferStateEnum.COMPLETED]"
          class="full-height scroll-y"
        />
      </template>
    </DashboardTabs>

    <ButtonsRow v-slot="{ buttonProps }">
      <QBtn
        v-bind="buttonProps"
        icon="mdi-plus-circle"
        @click="doTransfer()"
      >
        {{ t('Create') }}
      </QBtn>
    </ButtonsRow>
  </FullHeightPage>
</template>

<script setup lang="ts">

import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import { useI18n } from 'vue-i18n';
import useWakeLockWhenMounted from '@/composables/useWakeLockWhenMounted';
import { useRouter } from 'vue-router';
import type { Transfer } from '@/graphql/types';
import { TransferStateEnum } from '@/graphql/types';
import useTransferProcessStore from '@/stores/transferProcess';
import FullHeightPage from '@/components/FullHeightPage.vue';
import DashboardTabs from '@/components/DashboardTabs.vue';
import TransferDashboardList from '@/views/Mobile/Transfer/TransferDashboardList.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

useBreadcrumbs(t('Transfer'));

useWakeLockWhenMounted();

const router = useRouter();

const store = useTransferProcessStore();

function doTransfer(item?: Transfer): void {
  store.transfer = null;
  store.storageFrom = null;
  store.storageTo = null;

  router.push({ name: ROUTES.TRANSFER_PROCESS, params: { transferId: item?.id } });
}

</script>

<i18n lang="yaml">
ru:
  Transfer: Перемещение
  No Transfers: Перемещения отсутствуют

en:
  Transfer: Transfer
  No Transfers: No Transfers
</i18n>
