<template>
  <div class="row justify-center q-mt-md">
    <LoginCard
      class="col"
      :login-only="loginOnly"
      @login="loggedIn()"
    />
  </div>
</template>

<script setup lang="ts">

import LoginCard from '@/views/Auth/LoginCard.vue';
import { useRouter } from 'vue-router';

const router = useRouter();

async function loggedIn(): Promise<void> {
  await router.push('/');
}

const loginOnly = Boolean(import.meta.env.VITE_REGISTRATION_DISABLED);

</script>
