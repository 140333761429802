<template>
  <div
    ref="rootEl"
    class="scroll-x hide-scrollbar"
    @touchend="handleTouchEnd"
    @touchcancel="handleTouchEnd"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">

import { useDebounceFn } from '@vueuse/core';
import { ref } from 'vue';

const props = defineProps<{
  delay?: number;
}>();

const rootEl = ref<HTMLDivElement>();

const handleTouchEnd = useDebounceFn(() => {
  rootEl.value?.scroll({ left: 0, behavior: 'smooth' });
}, props.delay);

</script>
