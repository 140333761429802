<template>
  <QItem
    v-bind="$attrs"
    class="list-item-default-height"
  >
    <QItemSection class="col-grow">
      <QItemLabel>
        {{ t('# {text}', {text: transferTask.id}) }}
      </QItemLabel>
      <QItemLabel>
        {{ formatDate(transferTask.createdAt, FORMATS.DATETIME) }}
      </QItemLabel>
      <QItemLabel>
        {{ t('{n} products', R.uniqBy(i => i.productPack.id, transferTask.items).length) }}
        ({{ totalTransferredAmount }} / {{ totalPlannedAmount }})
      </QItemLabel>
    </QItemSection>
    <QItemSection
      side
      class="dense-labels justify-between"
    >
      <QItemLabel>
        <QChip
          :color="stateColor"
          text-color="black"
          dense
          class="q-ma-none"
        >
          {{ t(`transferState.${transferTask.state}`) }}
        </QChip>
      </QItemLabel>
    </QItemSection>
  </QItem>
</template>

<script setup lang="ts">

import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type { TransferTask } from '@/graphql/types';
import * as badgeColors from '@/helpers/badgeColors';
import * as R from 'ramda';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const props = defineProps<{
  transferTask: TransferTask;
}>();

const stateColor = computed(() => badgeColors.forTransferTask(props.transferTask));

const totalTransferredAmount = computed(() =>
  props.transferTask?.items.map(i => i.transferredAmount).reduce((sum, amount) => sum + amount, 0));

const totalPlannedAmount = computed(() =>
  props.transferTask?.items.map(i => i.plannedAmount).reduce((sum, amount) => sum + amount, 0));

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/counts.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/inventory.yaml"></i18n>
