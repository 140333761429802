<template>
  <BaseAlert
    v-if="getPrimaryError()"
    type="error"
  >
    {{ getPrimaryError() }}
  </BaseAlert>

  <QDialog
    :model-value="editingCells.length > 0"
    @update:model-value="editingCells = []"
  >
    <QCard
      style="min-width: 300px;"
    >
      <CardTitle>{{ t('Change') }}</CardTitle>

      <QSeparator />

      <QCardSection>
        <QSelect
          :model-value="selectedArea"
          :options="cellsAreas"
          :label="t('Cells Area')"
          option-label="name"
          @update:model-value="changeCellsArea($event)"
        />
      </QCardSection>

      <QSeparator />

      <QCardActions>
        <QSpace />

        <QBtn
          color="success"
          icon="mdi-check"
          @click="editingCells = []; cellsTableRef?.clearSelectedItems()"
        >
          {{ t('Done') }}
        </QBtn>
      </QCardActions>
    </QCard>
  </QDialog>

  <BaseTable
    ref="cellsTableRef"
    :fields="headers"
    :rows="sortedCells"
    row-key="name"
    :hide-bottom="sortedCells.length <= 10"
  >
    <template #batch-actions="{ rows, clear }">
      <SimpleTableBatchActions :rows-count="rows.length">
        <QBtn
          class="bg-white text-black"
          @click="editingCells = rows"
        >
          {{ t('Change') }}
        </QBtn>
        <PrintDialog
          v-slot="{ open }"
          v-model:barcode-type="cellBarcodeType"
          template-name="cell-barcode"
          use-count
          :title="t('Selected for print {size}', {size: rows.length})"
          :instances="printInstances"
          @count-changed="printCount=$event"
          @print="clear();"
        >
          <QBtn
            class="bg-white text-black q-ml-sm"
            icon="mdi-printer"
            @click="saveAndPrint(rows, open)"
          >
            {{ t('Print') }}
          </QBtn>
        </PrintDialog>
        <QBtn
          class="bg-white text-black q-ml-sm"
          @click="emit('remove-cells', rows); clear()"
        >
          {{ t('Delete') }}
        </QBtn>
      </SimpleTableBatchActions>
    </template>

    <template #body-cell-barcode="column">
      <QTd :props="column">
        <span v-if="column.value">{{ column.value }}</span>
        <span
          v-else
          class="text-grey"
        >
          {{ t('No') }}
          <QIcon
            :title="t('Barcode will be generated on save')"
            name="mdi-help-circle"
          />
        </span>
      </QTd>
    </template>

    <template #after-search-string>
      <NewCellsDialog
        :cells="rack.cells"
        @add-cells="addCells($event)"
      >
        <template #activator="{ onOpen }">
          <QBtn
            color="primary"
            icon="mdi-plus"
            :loading="cellsAreasLoading"
            @click="onOpen"
          >
            {{ t('Add Cells') }}
          </QBtn>
        </template>
      </NewCellsDialog>
    </template>
  </BaseTable>
</template>

<script setup lang="ts">

import BaseAlert from '@/App.vue';
import BaseTable from '@/components/BaseTable.vue';
import CardTitle from '@/components/CardTitle.vue';
import PrintDialog from '@/components/PrintDialog.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type { PrintTemplateInstance } from '@/composables/usePrintService';
import type { Cell, CellsArea, Rack } from '@/graphql/types';
import { CellsAreaKindEnum, PrintBarcodeTypeEnum } from '@/graphql/types';
import type { ParsedCellName } from '@/helpers/cellNameHelpers';
import { parseCellName, stringifyParsedCellName } from '@/helpers/cellNameHelpers';
import NewCellsDialog from '@/views/Racks/RackEdit/NewCellsDialog.vue';
import SimpleTableBatchActions from '@/views/SimpleTableBatchActions.vue';
import useDocumentsPrintingState from '@/views/useDocumentsPrintingState';
import { gql, useQuery } from '@urql/vue';
import naturalCompare from 'natural-compare-lite';
import type { QTableColumn } from 'quasar';
import * as R from 'ramda';
import { defaultTo, filter, last, map, pipe, sort, sortBy, uniqBy } from 'ramda';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { dateFormatter } = useLocalizedFormatters();

const { getPrimaryError } = useErrorHandling();

const props = defineProps<{
  rack: Rack;
}>();

const emit = defineEmits<{
  (e: 'add-cells', cells: Cell[]): void;
  (e: 'remove-cells', cells: Cell[]): void;
  (e: 'save-with-callback', cb: () => void): void;
}>();

const state = useDocumentsPrintingState();

const cellBarcodeType = ref(state.value.lastCellBarcodeType ?? PrintBarcodeTypeEnum.BARCODE);

const sortedCells = computed(() => sort(
  (a, b) => naturalCompare(b.name, a.name),
  props.rack.cells,
));

const editingCells = ref<Cell[]>([]);

const headers: QTableColumn<Cell>[] = [
  {
    label: t('Number'),
    name:  'id',
    field: 'id',
  },
  {
    label:  t('Created at'),
    name:   'createdAt',
    field:  'createdAt',
    format: dateFormatter(FORMATS.DATETIME),
    align:  'left',
  },
  {
    label:    t('Level'),
    name:     'level',
    field:    c => parseCellName(c.name)!.level,
  },
  {
    label:    t('Position'),
    name:     'number',
    field:    c => parseCellName(c.name)!.number,
  },
  {
    label:    t('Barcode'),
    name:     'barcode',
    field:    'barcode',
    align:    'left',
  },
  {
    label:    t('Name'),
    name:     'name',
    field:    'name',
    align:    'left',
  },
  {
    label: t('Cells Area'),
    name:  'area.name',
    field: c => c.cellsArea.name,
    align: 'left',
  },
  {
    label:  t('Empty'),
    name:   'isEmpty',
    field:  'isEmpty',
    format: v => t(v ? 'Yes' : 'No'),
    align:  'left',
  },
];

watch(cellBarcodeType, (): void => {
  state.value.lastCellBarcodeType = cellBarcodeType.value;
});

const cellsTableRef = ref<InstanceType<typeof BaseTable> | null>(null);

const selectedArea = ref<CellsArea | null>(null);

watch(editingCells, (cells, oldCells) => {
  if (oldCells.length === 0 && cells.length > 0) {
    const uniqAreas = uniqBy(a => a.id, cells.map(c => c.cellsArea));
    // Если у всех редактируемых ячеек одна зона, выбираем по умолчанию ее.
    selectedArea.value = uniqAreas.length === 1
      ? uniqAreas[0]
      : null;
  }
});

function changeCellsArea(newArea: CellsArea): void {
  selectedArea.value = newArea;
  for (const cell of editingCells.value) {
    cell.cellsArea = newArea;
  }
}

const {
  data:     cellsAreasResult,
  fetching: cellsAreasLoading,
} = useQuery<{ cellsAreas: CellsArea[] }>({
  query:         gql`query GetCellsAreas { cellsAreas { id name kind } }`,
  requestPolicy: 'cache-and-network',
});
const cellsAreas = computed<CellsArea[]>(() => cellsAreasResult.value?.cellsAreas ?? []);

const defaultCellsArea = computed(() => cellsAreas.value.find(
  ca => ca.kind === CellsAreaKindEnum.SELECTION,
));

function addCells({ count, level }: { count: number; level: number }): void {
  const cells = createCells(level, count);

  emit('add-cells', cells);
}

function createCells(level: number, count: number): Cell[] {
  const lastCellName = pipe(
    filter<Cell>(c => parseCellName(c.name)!.level === level),
    map(c => parseCellName(c.name)!),
    sortBy(c => c.number!),
    last,
    defaultTo({
      prefix: props.rack.prefix,
      row:    props.rack.row,
      rack:   props.rack.name,
      level,
      number: 0,
    } as ParsedCellName),
  )(props.rack.cells) as ParsedCellName;

  return R.times(i => ({
    name: stringifyParsedCellName({
      ...lastCellName,
      number: i + (lastCellName.number ?? 0) + 1,
    }),
    barcode:   '',
    cellsArea: defaultCellsArea.value,
    isEmpty:   true,
  } as Cell), count);
}

const printInstances = ref<PrintTemplateInstance[]>([]);
const printCount     = ref(1);

function saveAndPrint(cells: Cell[], print: () => void) {
  const cellsToPrint = cells.map(c => c.name);
  emit('save-with-callback', () => {
    printInstances.value = cellsToPrint.map(name => ({
      cellId:      props.rack.cells.find(c => c.name === name)!.id,
      barcodeType: cellBarcodeType.value,
    }))
      .map(p => {
        return { count: printCount.value, params: p, };
      });
    print();
  });
}

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/printing.yaml"></i18n>

<i18n lang="yaml">
ru:
  Add Cells: Добавить ячейки
  Change: Изменить
  Barcode will be generated on save: Штрихкод будет сгенерирован при сохранении

en:
  Add Cells: Add Cells
  Change: Change
  Barcode will be generated on save: Barcode will be generated on save
</i18n>
