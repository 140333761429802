<template>
  <HorizontalPropsList v-slot="{ item }">
    <component
      :is="item"
      :caption="storage.__typename === 'Cell' ? t('Cell') : t('Container')"
    >
      {{ storage.name }}
    </component>
    <component
      :is="item"
      :caption="t('Type')"
    >
      {{ entireStorage ? t('Entire Storage') : t('Some Products') }}
    </component>
    <component
      :is="item"
      v-if="inventory"
      :caption="t('Created At')"
    >
      {{ formatDate(inventory.createdAt, FORMATS.DATETIME) }}
    </component>
  </HorizontalPropsList>
</template>

<script setup lang="ts">

import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type { Cell, Container, Inventory } from '@/graphql/types';
import HorizontalPropsList from '@/views/Mobile/HorizontalPropsList.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

defineProps<{
  inventory?: Inventory;
  storage: Cell | Container;
  entireStorage: boolean;
}>();

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/inventory.yaml"></i18n>

<i18n lang="yaml">
ru:
  Created At: Дата создания
en:
  Created At: Created At
</i18n>
