<template>
  <QToolbar
    class="bg-blue text-white"
    style="height: 60px;"
  >
    <div class="q-mr-md">
      {{ t('Selected {n}', {n: rowsCount}) }}:
    </div>
    <slot />
  </QToolbar>
</template>

<script setup lang="ts">

import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps<{
  rowsCount: number;
}>();

</script>

<i18n lang="yaml">

ru:
  Selected {n}: Выбрано {n}

en:
  Selected {n}: Selected {n}

</i18n>
