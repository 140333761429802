<template>
  <QBtnGroup>
    <QBtn
      :href="link"
      target="_blank"
      :disable="!currentToken?.readableToken"
      icon="mdi-link-variant"
    >
      {{ t('Link') }}
    </QBtn>
    <ConfirmsAction
      should-prompt
      :confirm-text="t('Create New Link')"
      @confirmed="generateNewLink"
    >
      <template #activator="{ prompt }">
        <QBtn
          :loading="generating"
          :title="t('Create New Link')"
          dense
          icon="mdi-refresh"
          @click="prompt"
        />
      </template>

      <BaseAlert type="warning">
        {{ t('Old link will stop working!') }}
      </BaseAlert>
    </ConfirmsAction>
  </QBtnGroup>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import ConfirmsAction from '@/components/ConfirmsAction.vue';
import { gql, useMutation, useQuery } from '@urql/vue';
import { useQuasar } from 'quasar';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import type { PersonalAccessToken } from '@/graphql/types';

const { t } = useI18n();

const q = useQuasar();

const generating = ref(false);

const generateTokenMutation = useMutation<{ token: PersonalAccessToken }>(gql`
  mutation GenerateNewCSVExportTokenFromStocksLink {
    token: regenerateCSVExportToken {
      id
      readableToken
    }
  }
`);

const { data: tokenData } = useQuery<{ token: PersonalAccessToken }>({
  query:     gql`
    query GetAccessTokenForCsvExport {
      token: accessTokenForCsvExport {id readableToken}
    }
  `,
  variables: [],
});

watch(tokenData, data => {
  currentToken.value = data?.token;
});

const currentToken = ref<PersonalAccessToken | null>();

const link = computed(() => currentToken.value
  ? window.location.origin + '/api/stocks?api_token=' + currentToken.value.readableToken
  : null);

async function generateNewLink() {
  generating.value = true;

  const { data, error } = await generateTokenMutation.executeMutation({});

  generating.value = false;

  if (error) {
    q.notify({ message: error.message, type: 'negative' });
    return;
  }

  currentToken.value = data?.token;
}

</script>

<i18n lang="yaml">
ru:
  Link: Ссылка
  Old link will stop working!: Старая ссылка перестанет работать!
  Create New Link: Создать новую ссылку

en:
  Link: Link
  Old link will stop working!: Old link will stop working!
  Create New Link: Create New Link
</i18n>
