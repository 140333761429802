<template>
  <div
    ref="root"
    class="column no-wrap relative-position"
  >
    <div
      ref="wrapper"
      class="wrapper"
    >
      <div class="content">
        <slot
          v-for="({ item, index }) in renderingItems"
          :key="index"
          name="item"
          v-bind="{ item, index }"
        />
      </div>
    </div>
    <div v-if="$slots['indicators']">
      <slot
        name="indicators"
        v-bind="{index: modelValue, total: items.length }"
      />
    </div>
    <div
      v-else-if="showIndicators || $slots['indicators-right']"
      v-touch-swipe.horizontal="handleIndicatorsSwipe"
      class="row indicators-wrapper justify-end"
      :class="{ 'absolute-bottom': indicatorsOverlay }"
    >
      <template v-if="showIndicators">
        <div class="col-1" />
        <div class="text-center col-10">
          <CarouselIndicators
            :count="items.length"
            :active="modelValue"
            :limit="maxIndicators"
            :indicator-class="indicatorClass"
            @indicator-click="emit('update:modelValue', $event)"
          />
        </div>
        <div class="col-1 row justify-end">
          <slot name="indicators-right" />
        </div>
      </template>
      <div
        v-else
        class="row"
      >
        <slot name="indicators-right" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="TItem">

import type { BScrollInstance } from '@better-scroll/core';
import BScroll from '@better-scroll/core';
import type { PageIndex } from '@better-scroll/slide/dist/types/SlidePages';
import { useResizeObserver } from '@vueuse/core';
import type { TouchSwipeParams } from 'quasar';
import { isNil } from 'ramda';
import { computed, nextTick, onBeforeUnmount, ref, watch } from 'vue';
import CarouselIndicators from '@/components/Mobile/CarouselIndicators.vue';

// Количество элементов для рендера слева и справа от текущего.
// Остальные элементы не будут рендериться для оптимизации.
// С этим связаны манипуляции с индексами при вызове goToPage и реакции на событие slidePageChanged.
const SIBLINGS_TO_RENDER = 1;

const props = withDefaults(defineProps<{
  modelValue: number;
  items: TItem[];
  showIndicators?: boolean;
  indicatorsOverlay?: boolean;
  maxIndicators?: number;
  indicatorClass?: (index: number) => string;
  dontResetOnItemsChange?: boolean;
}>(), {
  maxIndicators:          15,
  dontResetOnItemsChange: false,
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: number): void;
}>();

const root = ref<HTMLElement>();

const wrapper = ref<HTMLElement>();

function calculatePageForSlider(page: number) {
  return Math.min(page, SIBLINGS_TO_RENDER);
}

function calculatePageFromSlider(sliderPage: number) {
  return sliderPage + Math.max(0, props.modelValue - SIBLINGS_TO_RENDER);
}

watch(() => props.modelValue, page => {
  bs?.goToPage(calculatePageForSlider(page), 0, 0);
});

let bs: BScrollInstance | null = null;

watch(wrapper, wrapper => {
  bs?.destroy();

  bs = new BScroll(wrapper!, {
    scrollX: true,
    scrollY: false,
    slide: {
      startPageXIndex: calculatePageForSlider(props.modelValue),
      loop: false,
      autoplay: false,
    },
    // "When using `slide`, this value needs to be set to `false`"
    // https://better-scroll.github.io/docs/en-US/plugins/slide.html#usage:~:text=the%20same%20time-,momentum,-When%20using%20slide
    momentum: false,
    // "The `bounce` value needs to be set to `false`,
    // otherwise it will flicker when the `loop` is `true`"
    // https://better-scroll.github.io/docs/en-US/plugins/slide.html#usage:~:text=during%20fast%20sliding.-,bounce,-The%20bounce%20value
    // В нашем случае `loop: false`, поэтому `bounce` не будет проблем.
    bounce: true,
    // "...to preserve native vertical scroll
    // but being able to add a horizontal BetterScroll (maybe a carousel)."
    // https://better-scroll.github.io/docs/en-US/guide/base-scroll-options.html#eventpassthrough
    eventPassthrough: 'vertical',
  });

  bs.on('slidePageChanged', ({ pageX }: PageIndex) => {
    emit('update:modelValue', calculatePageFromSlider(pageX));
  });
});

onBeforeUnmount(() => {
  bs?.destroy();
});

watch(() => props.items.length, count => {
  // При удалении последнего слайда
  // смена на предыдущий происходит автоматически, но с рывком,
  // поэтому делаем переключение явно для плавной анимации.
  if (!isNil(count) && count <= props.modelValue) {
    nextTick(() => {
      emit('update:modelValue', Math.max(0, count - 1));
    });
  }
});

useResizeObserver(root, () => {
  bs?.refresh();
});

watch(() => props.items, () => {
  bs?.refresh();
  if (!props.dontResetOnItemsChange) {
    bs?.goToPage(props.modelValue, 0);
  }
}, { flush: 'post' });

function handleIndicatorsSwipe({ direction }: TouchSwipeParams) {
  if (direction === 'left') {
    bs?.next();
  } else if (direction === 'right') {
    bs?.prev();
  }
}

const renderingItems = computed(() => props.items
  .map((item, index) => ({ item, index }))
  .filter(({ index }) => Math.abs(index - props.modelValue) <= SIBLINGS_TO_RENDER));

watch(renderingItems, () => {
  bs?.refresh();
}, { flush: 'post' });

</script>

<style scoped lang="scss">

.wrapper {
  overflow-x: hidden;
  height: 100%;
}

:deep(.content) {
  height: 100%;

  .slide-move,
  .slide-enter-active,
  .slide-leave-active {
    transition: transform $animate-duration ease, opacity $animate-duration ease;
  }

  .slide-enter-from,
  .slide-leave-to {
    opacity: 0;
    transform: translate(-50%, 0);
  }

  .slide-leave-active {
    position: absolute;
  }

  > * {
    vertical-align: top;
  }
}

</style>
