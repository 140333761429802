<template>
  <SecondStepComponent
    v-if="secondStep"
    @login="afterLogin()"
    @back="secondStep = false"
  />
  <FirstStep
    v-else
    :login-only="loginOnly"
    @next="secondStep = true"
    @login="afterLogin()"
  />
</template>

<script setup lang="ts">

import useStore from '@/stores/root';
import FirstStep from '@/views/Auth/LoginCard/FirstStep.vue';
import SecondStepComponent from '@/views/Auth/LoginCard/SecondStep.vue';
import { useQuasar } from 'quasar';
import { ref } from 'vue';

const store = useStore();

defineProps<{
  loginOnly?: boolean;
}>();

const emit = defineEmits<{
  (e: 'login'): void;
}>();

const secondStep = ref(false);

const q = useQuasar();

async function afterLogin(): Promise<void> {
  q.loading.show();
  await store.updateUser();
  q.loading.hide();
  emit('login');
}

</script>
