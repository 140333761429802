<template>
  <QPage padding>
    <div class="row q-col-gutter-md">
      <div class="col-12 col-md-6">
        <GlobalAccounts
          :origin="GlobalAccountOriginEnum.WMS"
          :title="t('WMS Warehouses')"
        />
      </div>
      <div class="col-12 col-md-6">
        <GlobalAccounts
          :origin="GlobalAccountOriginEnum.MP"
          :title="t('MP Users')"
        />
      </div>
    </div>
  </QPage>
</template>

<script setup lang="ts">

import useBreadcrumbs from '@/composables/useBreadcrumbs';
import { GlobalAccountOriginEnum } from '@/graphql/types';
import GlobalAccounts from '@/views/Admin/GlobalAccountsCard.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

useBreadcrumbs([t('Administration'), t('Accounts')]);

</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/navigation.yaml"></i18n>

<i18n lang="yaml">
ru:
  WMS Warehouses: Склады WMS
  MP Users: Пользователи МП
en:
  WMS Warehouses: WMS Warehouses
  MP Users: MP Users
</i18n>
