<template>
  <slot :start-confirming="startConfirmingPassword" />
  <QDialog
    :model-value="confirmingPassword"
    @update:model-value="$event || cancelConfirmation()"
  >
    <QCard>
      <CardTitle>
        <slot name="title">
          {{ t('Confirm Password') }}
        </slot>
        <template #subtitle>
          <slot name="subtitle">
            {{ t('For your security') }}
          </slot>
        </template>
      </CardTitle>

      <QForm>
        <QCardSection>
          <QInput
            v-model="password"
            type="password"
            :label="t('Password')"
            v-bind="qErrorsFor('password')"
            autofocus
          />
        </QCardSection>

        <BaseAlert
          v-if="getPrimaryError()"
          type="error"
        >
          {{ t(getPrimaryError()) }}
        </BaseAlert>

        <QSeparator />

        <QCardActions align="right">
          <QBtn @click="cancelConfirmation()">
            {{ t('Cancel') }}
          </QBtn>
          <QBtn
            type="submit"
            color="primary"
            :loading="hasProgress()"
            icon="mdi-check"
            @click.prevent="confirmPassword()"
          >
            {{ t('Confirm') }}
          </QBtn>
        </QCardActions>
      </QForm>
    </QCard>
  </QDialog>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import CardTitle from '@/components/CardTitle.vue';
import useBackend from '@/composables/useBackend';
import useErrorHandling from '@/composables/useErrorHandling';
import useProgressHandling from '@/composables/useProgressHandling';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { fillErrorsFromHttpError, clearErrors, getPrimaryError, qErrorsFor } = useErrorHandling();
const { progressStarted, hasProgress } = useProgressHandling();

const backend = useBackend();

const emit = defineEmits<{
  (e: 'confirmed'): void;
  (e: 'canceled'): void;
}>();

const confirmingPassword = ref(false);

const password = ref('');

async function startConfirmingPassword(): Promise<void> {
  if (await backend.isPasswordConfirmed()) {
    emit('confirmed');
    return;
  }
  clearErrors();
  confirmingPassword.value = true;
}

function cancelConfirmation(): void {
  emit('canceled');
  confirmingPassword.value = false;
}

async function confirmPassword(): Promise<void> {
  const done = progressStarted();

  try {
    await backend.confirmPassword(password.value);
    password.value = '';
    confirmingPassword.value = false;
    emit('confirmed');
  } catch (e) {
    fillErrorsFromHttpError(e);
  } finally {
    done();
  }
}
</script>

<i18n lang="yaml">
en:
  Confirm Password: Confirm Password
  For your security: For your security, please confirm your password to continue
ru:
  Confirm Password: Подтвердите пароль
  For your security: Для вашей безопасности, пожалуйста, подтвердите пароль, чтобы продолжить
</i18n>
