<template>
  <BaseScanField
    ref="scanField"
    :loading="isLoading"
    :search-fn="fetchEntityWithQuery"
    @not-found="emit('not-found', $event)"
  />
</template>

<script setup lang="ts" generic="TEntity">

import { useClientHandle } from '@urql/vue';
import type { DocumentNode } from 'graphql';
import { computed, type Ref, ref } from 'vue';
import BaseScanField from '@/components/Mobile/BaseScanField.vue';
import type { RequestPolicy } from '@urql/core/dist/urql-core-chunk';
import useErrorHandling from '@/composables/useErrorHandling';

const { getPrimaryErrorFromGraphQLError } = useErrorHandling();

const props = withDefaults(defineProps<{
  query: DocumentNode;
  variables?: object | ((barcode: string | null) => object);
  loading?: boolean;
  requestPolicy?: RequestPolicy;
  optimisticResult?: (barcode: string) => TEntity | null;
}>(), {
  variables: () => ({}),
});

const emit = defineEmits<{
  (e: 'not-found', barcode: string): void;
  (e: 'found', value: TEntity): void;
}>();

const { client: urql } = useClientHandle();

const fetching = ref(false);

async function fetchEntityWithQuery(barcode: string): Promise<TEntity | null> {
  const entity = props.optimisticResult?.(barcode);

  if (entity) {
    emit('found', entity);
    return entity;
  }

  const { data, error } = await urql.query(props.query, {
    barcode,
    ...(typeof props.variables === 'function' ? props.variables(barcode) : props.variables),
  }, { requestPolicy: props.requestPolicy });

  if (error) {
    throw new Error(getPrimaryErrorFromGraphQLError(error));
  }

  emit('found', data);

  return Object.values(data)[0] as TEntity | null;
}

const isLoading = computed(() => Boolean(fetching.value || props.loading));

const scanField: Ref<InstanceType<typeof BaseScanField>> = ref(null!);

defineExpose({
  scan: (value: string) => scanField.value?.scan(value),
});

</script>
