<template>
  <QDialog
    v-model="isOpen"
    position="bottom"
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <QCard>
      <PrimaryErrorBanner />
      <BlurredInput :filter="currentFieldIsFractional ? /^[\d,.]$/ : /^\d$/" />
      <QStepper
        v-model="step"
        animated
        header-nav
        alternative-labels
        done-color="success"
      >
        <QStep
          name="length"
          :title="t('Length')"
          :caption="length ? (length + ' ' + t('units.cm')) : '&nbsp;'"
          :done="!!length"
          :error="!length"
        >
          <StepInput :label="t('Length')">
            {{ input.value }} {{ t('units.cm') }}
          </StepInput>
        </QStep>
        <QStep
          name="width"
          :title="t('Width')"
          :caption="width ? (width + ' ' + t('units.cm')) : '&nbsp;'"
          :done="!!width"
          :error="!width"
        >
          <StepInput :label="t('Width')">
            {{ input.value }} {{ t('units.cm') }}
          </StepInput>
        </QStep>
        <QStep
          name="height"
          :title="t('Height')"
          :caption="height ? (height + ' ' + t('units.cm')) : '&nbsp;'"
          :done="!!height"
          :error="!height"
        >
          <StepInput :label="t('Height')">
            {{ input.value }} {{ t('units.cm') }}
          </StepInput>
        </QStep>
        <QStep
          name="weight"
          :title="t('Weight')"
          :caption="weight ? (weight + ' ' + t('units.kg')) : '&nbsp;'"
          :done="!!weight"
          :error="!weight"
        >
          <StepInput :label="t('Weight')">
            {{ input.value }} {{ t('units.kg') }}
          </StepInput>
        </QStep>
      </QStepper>
      <ButtonsRow v-slot="{ buttonProps }">
        <QBtn
          v-bind="buttonProps"
          icon="mdi-close-circle-outline"
          @click="isOpen = false"
        >
          {{ t('Cancel') }}
        </QBtn>
        <QBtn
          v-bind="buttonProps"
          icon="mdi-keyboard-tab"
          @click="nextStep()"
        >
          {{ t('Next') }}
        </QBtn>
        <QBtn
          v-bind="buttonProps"
          icon="mdi-content-save"
          :loading="saving"
          @click="save"
        >
          {{ t('Save') }}
        </QBtn>
        <QBtn
          v-if="preferences.usePhysicalKeyboard"
          v-bind="buttonProps"
          icon="mdi-decimal-comma"
          @click="input.emulateInput('.')"
        >
          {{ t('Comma') }}
        </QBtn>
      </ButtonsRow>

      <div>
        <VirtualKeyboard
          local
          :show-comma-key="currentFieldIsFractional"
        />
      </div>
    </QCard>
  </QDialog>
  <slot
    name="activator"
    @click="isOpen = true"
  />
</template>

<script setup lang="ts">

import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useLocalPreferences from '@/composables/useLocalPreferences';
import useOmniInput from '@/composables/useOmniInput';
import type { MutationUpdateProductPackDimensionsArgs, ProductPack } from '@/graphql/types';
import { gql, useMutation } from '@urql/vue';
import { useCycleList } from '@vueuse/core';
import { QField } from 'quasar';
import { replace } from 'ramda';
import { type Component, computed, h, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

// noinspection LocalVariableNamingConventionJS
const { fillErrorsFromGraphQLError, PrimaryErrorBanner } = useErrorHandling();

const props = defineProps<{
  productPack: ProductPack;
}>();

const emit = defineEmits<{
  (e: 'saved', payload: Pick<ProductPack, 'dimensions' | 'weight'>): void;
}>();

const isOpen = ref(false);

const preferences = useLocalPreferences();

const { state: step, index: stepIndex, next: nextStep } = useCycleList([
  'length',
  'width',
  'height',
  'weight',
]);

const length = ref('');
const width = ref('');
const height = ref('');
const weight = ref('');

// noinspection FunctionWithMoreThanThreeNegationsJS
watch(isOpen, open => {
  if (!open) {
    return;
  }

  length.value = String(props.productPack.dimensions?.length ?? '');
  width.value = String(props.productPack.dimensions?.width ?? '');
  height.value = String(props.productPack.dimensions?.height ?? '');
  weight.value = props.productPack.weight ? String(props.productPack.weight / 1000) : '';

  // Активным делаем первое пустое поле
  const index = [length, width, height, weight].findIndex(ref => !ref.value);
  stepIndex.value = index >= 0 ? index : 0;
});

const input = useOmniInput({
  skip: computed(() => !isOpen.value),
  priority: 100,
  replace: replace(/,/g, '.'),
});
const { BlurredInput, VirtualKeyboard } = input;

const currentField = computed({
  get() {
    return [length, width, height, weight][stepIndex.value].value;
  },
  set(value) {
    [length, width, height, weight][stepIndex.value].value = value;
  }
});

const currentFieldIsFractional = computed(() => stepIndex.value === 3 /* Вес */);

watch(input.value, val => {
  const numericValue = Number.parseFloat(val);
  currentField.value = Number.isNaN(numericValue) ? '' : String(numericValue);
});

watch(stepIndex, () => {
  input.reset(currentField.value);
}, { immediate: true });

const {
  executeMutation: doSave,
  fetching: saving,
} = useMutation<{ pack: ProductPack }, MutationUpdateProductPackDimensionsArgs>(
  gql`
    mutation UpdateProductPackDimensions($id: ID!, $dimensions: DimensionsInput, $weight: Int) {
      pack: updateProductPackDimensions(id: $id, dimensions: $dimensions, weight: $weight) {
        dimensions { length width height }
        weight
      }
    }
  `,
);

async function save(): Promise<void> {
  const dimensions = length.value && width.value && height.value ? {
    length: Number.parseInt(length.value),
    width:  Number.parseInt(width.value),
    height: Number.parseInt(height.value),
  } : null;

  const w = weight.value ? Math.round(Number.parseFloat(weight.value) * 1000) : null;

  const { data, error } = await doSave({
    id:     props.productPack.id,
    dimensions,
    weight: w,
  });

  if (error) {
    fillErrorsFromGraphQLError(error);
  } else {
    isOpen.value = false;
    emit('saved', data!.pack);
  }
}

// noinspection LocalVariableNamingConventionJS
const StepInput: Component = (props, { attrs, slots }) => h(QField, {
  readonly: true,
  stackLabel: true,
  ...attrs,
}, {
  control: () => h('div', { class: 'text-h4' }, slots.default!()),
});

</script>

<style scoped lang="scss">

:deep(.q-stepper__tab) {
  padding-left: 0;
  padding-right: 0;
}

:deep(.q-stepper__caption) {
  font-size: map-get($h6, 'size');
  line-height: map-get($h6, 'line-height');
}

</style>

<i18n lang="yaml">
ru:
  Comma: Запятая
  Next: Далее
en:
  Comma: Comma
  Next: Next
</i18n>
