<template>
  <QCard style="max-width: 600px;">
    <CardTitle class="justify-between">
      {{ $attrs.title }}
    </CardTitle>

    <QSeparator />

    <QForm>
      <QCardSection>
        <slot />
      </QCardSection>

      <BaseAlert
        v-if="$slots.success"
        type="success"
      >
        <slot name="success" />
      </BaseAlert>

      <BaseAlert
        v-if="$slots.error"
        type="error"
      >
        <slot name="error" />
      </BaseAlert>

      <QSeparator />

      <QCardActions :vertical="q.screen.xs">
        <slot name="actions" />
      </QCardActions>
    </QForm>
  </QCard>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import CardTitle from '@/components/CardTitle.vue';
import { useQuasar } from 'quasar';

const q = useQuasar();

</script>
