<template>
  <SwiperCarousel
    v-if="store.movements.length > 0"
    :slide="slide"
    :items="store.movements"
    @update:slide="emit('update:slide', $event)"
  >
    <template #item="{ item, index }">
      <TransferProcessCarouselSlide
        :item="item"
        :display-amount="index === slide ? (inputValue || '0') : item.amount"
        @delete="emit('submit-amount', { index, amount: 0 })"
        @product-edit-opened="productEditOpened = $event"
        @update:slide="emit('update:slide', $event)"
      />
    </template>
    <template #container-end="{ index, total }">
      <ScrollCarouselCounter
        :current="index + 1"
        :total="total"
        :suggested="total"
        @to-current="emit('update:slide', total - 1)"
      />
    </template>
  </SwiperCarousel>
  <VirtualKeyboard
    v-if="selectedItem"
    :show-comma-key="amountIsFractional"
    @update:visible="!$event && commitAmount(slide)"
  />
  <BlurredInput
    v-if="!inputDisabled"
    skip-input-if-barcode
    speak-digits
    @barcode="emit('barcode-scan', $event)"
  />
</template>

<script setup lang="ts">

import ScrollCarouselCounter from '@/components/Mobile/ScrollCarouselCounter.vue';
import SwiperCarousel from '@/components/Mobile/SwiperCarousel.vue';
import useOmniInput from '@/composables/useOmniInput';
import useTransferProcessStore from '@/stores/transferProcess';
import TransferProcessCarouselSlide from '@/views/Mobile/Transfer/TransferProcessCarouselSlide.vue';
import { computed, ref, watch } from 'vue';

const store = useTransferProcessStore();

const props = defineProps<{
  slide: number;
  inputDisabled: boolean;
}>();

const emit = defineEmits<{
  (e: 'barcode-scan', barcode: string): void;
  (e: 'update:slide', slide: number): void;
  (e: 'input-amount', amount: number): void;
  (e: 'submit-amount', payload: { index: number; amount: number }): void;
}>();

const selectedItem = computed(() => store.movements[props.slide]);

function commitAmount(index: number) {
  if (input.isPristine.value) {
    return;
  }

  emit('submit-amount', {
    index,
    amount: store.movements[index].amount,
  });
}

const inputDisabled = computed(() => props.inputDisabled || productEditOpened.value);

const productEditOpened = ref(false);

const amountIsFractional = computed(() => selectedItem.value?.storable.productPack.measurementUnit.isFractional ?? false);

const input = useOmniInput({
  replace: value => {
    // Не используем skip, т.к. нужна возможность сканирования ШК.
    if (inputDisabled.value || !selectedItem.value) {
      return '';
    }
    return amountIsFractional.value
      ? value.replace(/,/g, '.').replace(/[^\d.]/g, '')
      : value.replace(/\D/g, '');
  },
});
const {
  VirtualKeyboard,
  BlurredInput,
  value: inputValue,
} = input;

watch(selectedItem, (newItem, oldItem) => {
  if (!newItem) {
    return;
  }
  const index = oldItem === undefined ? -1 : store.movements.indexOf(oldItem);
  if (index >= 0) {
    commitAmount(index);
  }
  input.reset(String(newItem.amount));
}, { immediate: true });

watch(input.value, value => {
  if (!input.isPristine.value && !Number.isNaN(Number(value))) {
    emit('input-amount', Number(value));
  }
});

defineExpose({
  resetInput: () => input.reset(String(selectedItem.value?.amount ?? '')),
});

</script>
