<template>
  <ScrollCarousel
    v-if="product.photos.length > 0"
    v-model="photoSlide"
    :items="product.photos"
  >
    <template #item="{ item: p }: { item: ProductPhoto }">
      <QImg
        :src="p.url"
        height="100%"
      >
        <QBtn
          v-if="deletable"
          flat
          round
          icon="mdi-close-circle-outline"
          size="32px"
          dense
          color="red"
          class="absolute-top-right all-pointer-events"
          :loading="p.id === deletingPhotoId"
          @click="deletePhoto(p)"
        />
      </QImg>
    </template>
  </ScrollCarousel>

  <CapturePhotoDialog
    v-model="newPhotoDialog"
    filename-prefix="product-"
    @captured="photoCaptured($event)"
  />
</template>

<script setup lang="ts">

import ScrollCarousel from '@/components/Mobile/ScrollCarousel.vue';
import type {
  EntityPhoto,
  MutationDeleteProductPhotoArgs,
  MutationUploadProductPhotoArgs,
  Product,
  Scalars,
} from '@/graphql/types';
import CapturePhotoDialog from '@/views/Profile/CapturePhotoDialog.vue';
import { gql, useClientHandle } from '@urql/vue';
import { useQuasar } from 'quasar';
import { nextTick, ref, watch } from 'vue';

const q = useQuasar();

const { client: urql } = useClientHandle();

const props = defineProps<{
  product: Product;
  deletable?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:photos', photos: EntityPhoto[]): void;
}>();

const photoSlide = ref<number>(0);

watch(() => props.product, () => {
  photoSlide.value = 0;
}, { immediate: true });

const deletingPhotoId = ref<Scalars['ID'] | null>(null);

async function deletePhoto(photo: EntityPhoto) {
  deletingPhotoId.value = photo.id;

  await urql.mutation<unknown, MutationDeleteProductPhotoArgs>(
    gql`
      mutation DeleteProductPhotoFromMobileCard($productId: ID!, $photoId: ID!) {
        deleteProductPhoto(productId: $productId, photoId: $photoId)
      }
    `, { productId: props.product.id, photoId: photo.id });

  deletingPhotoId.value = null;

  emit('update:photos', props.product.photos.filter(p => p.id !== photo.id));
}

const newPhotoDialog = ref(false);

const uploadingPhoto = ref(false);

async function photoCaptured(photoData: File): Promise<void> {
  uploadingPhoto.value = true;

  const { data, error } = await urql.mutation<{
    photo: EntityPhoto;
  }, MutationUploadProductPhotoArgs>(
    gql`
      mutation UploadProductPhoto($productId: ID!, $photoData: Upload!) {
        photo: uploadProductPhoto(productId: $productId, photoData: $photoData) { id url }
      }
    `,
    {
      productId: props.product.id,
      photoData,
    },
  );

  uploadingPhoto.value = false;

  if (error) {
    q.notify({ message: error.message, type: 'negative' });
  } else {
    emit('update:photos', [...props.product.photos, data!.photo]);
    nextTick().then(() => {
      photoSlide.value = props.product.photos.indexOf(data!.photo);
    });
  }
}

defineExpose({
  openNewPhotoDialog: () => {
    newPhotoDialog.value = true;
  },
});

</script>
