<template>
  <div class="row">
    <div class="col-md-6 offset-md-3">
      <AuthCard :title="t('Password Reset')">
        <template v-if="hasProgress('resetting')">
          <QInput
            v-model="email"
            :label="t('Email')"
            type="email"
            required
            autocomplete="email"
            v-bind="qErrorsFor('email')"
          />

          <QInput
            v-model="password"
            :label="t('New Password')"
            type="password"
            required
            autocomplete="new-password"
            v-bind="qErrorsFor('password')"
          />

          <QInput
            v-model="passwordRepeat"
            :label="t('Confirm Password')"
            type="password"
            required
            autocomplete="new-password"
          />
        </template>

        <template
          v-if="successMessage"
          #success
        >
          {{ successMessage }}
        </template>

        <template
          v-if="getPrimaryError()"
          #error
        >
          {{ t(getPrimaryError()) }}
        </template>

        <template #actions>
          <template v-if="hasProgress('resetting')">
            <QBtn
              flat
              :to="{ name: ROUTES.AUTH_FORGOT_PASSWORD, query: { email } }"
            >
              {{ t('Email New Reset Link') }}
            </QBtn>

            <QSpace />

            <QBtn
              type="submit"
              color="primary"
              :loading="hasProgress()"
              icon="mdi-lock-reset"
              @click.prevent="reset()"
            >
              {{ t('Reset Password') }}
            </QBtn>
          </template>
          <template v-else>
            <QBtn :to="{ name: ROUTES.AUTH_LOGIN }">
              {{ t('Login') }}
            </QBtn>
          </template>
        </template>
      </AuthCard>
    </div>
  </div>
</template>

<script setup lang="ts">

import useBackend from '@/composables/useBackend';
import useErrorHandling from '@/composables/useErrorHandling';
import useProgressHandling from '@/composables/useProgressHandling';
import AuthCard from '@/views/Auth/AuthCard.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const { fillErrorsFromHttpError, clearErrors, getPrimaryError, qErrorsFor } = useErrorHandling();

const { progressStarted, hasProgress, progressFinished } = useProgressHandling<'resetting'>();

const route = useRoute();

const backend = useBackend();

const email = ref(route.query.email as string);

const password = ref('');

const passwordRepeat = ref('');

const successMessage = ref('');

progressStarted('resetting');

async function reset(): Promise<void> {
  const finished = progressStarted();
  clearErrors();

  try {
    const result = await backend.resetPassword(
      route.params.token as string,
      email.value,
      password.value,
      passwordRepeat.value,
    );
    successMessage.value = result.message;
    progressFinished('resetting');
  } catch (e) {
    fillErrorsFromHttpError(e);
  } finally {
    finished();
  }
}

</script>

<i18n lang="yaml">
ru:
  New Password: Новый пароль
  Confirm Password: Подтверждение пароля
  Email New Reset Link: Получить новую ссылку
  Reset Password: Сбросить пароль
  Password Reset: Сброс пароля

en:
  New Password: New Password
  Confirm Password: Confirm Password
  Email New Reset Link: Email New Reset Link
  Reset Password: Reset Password
  Password Reset: Password Reset
</i18n>
