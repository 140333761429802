<template>
  <FullHeightPage>
    <GraphQLQueryTable
      :graphql-query="query"
      :fields="tableFields"
      :available-filters="availableFilters"
      :row-is-deleted="entityIsDeleted"
      storage-prefix="usersList.report"
      keep-page-in-url
      :deletion-filter="deletionFilterForSoftDeletable"
      sticky-header
      class="col"
    >
      <template #after-search-string>
        <QBtn
          v-if="canCreate"
          color="primary"
          :to="{ name: ROUTES.USERS_NEW }"
          icon="mdi-account-plus"
        >
          {{ t('Add User') }}
        </QBtn>
      </template>
      <template #body-cell-firstname="column">
        <BodyCellLink
          :column="column"
          :to="{ name: ROUTES.USERS_EDIT, params: { id: String(column.row.id) } }"
          :disable="!column.row.editable"
        >
          {{ userFullName(column.row) }}
        </BodyCellLink>
      </template>

      <template #body-cell-deletedAt="column">
        <QTd
          :props="column"
          :title="formatDate(column.row.deletedAt)"
        >
          {{ column.row.deletedAt ? t('Yes') : '' }}
        </QTd>
      </template>

      <template #body-cell-roles="column">
        <QTd :props="column">
          {{ column.row.roles.title }}
        </QTd>
      </template>

      <template #no-data>
        <p>
          {{ t('No Users') }}
        </p>
        <QBtn
          color="primary"
          :to="{ name: ROUTES.USERS_NEW }"
          icon="mdi-account-plus"
        >
          {{ t('Add User') }}
        </QBtn>
      </template>
    </GraphQLQueryTable>
  </FullHeightPage>
</template>

<script setup lang="ts">

import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import FullHeightPage from '@/components/FullHeightPage.vue';
import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useCan from '@/composables/useCan';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type { Role, UserReportRow } from '@/graphql/types';
import * as reports from '@/helpers/reports';
import { deletionFilterForSoftDeletable, entityIsDeleted } from '@/helpers/reports';
import userFullName from '@/helpers/userFullName';
import ROUTES from '@/router/routeNames';
import { Ability } from '@/types/backend';
import type { ReportFilter } from '@/types/reports';
import { gql, useClientHandle } from '@urql/vue';
import type { QTableColumn } from 'quasar';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { dateFormatter, formatDate } = useLocalizedFormatters();

useBreadcrumbs(t('Users'));

const { client: urql } = useClientHandle();

const tableFields: QTableColumn<UserReportRow>[] = [
  {
    label:    t('User Full Name'),
    name:     'firstname',
    field:    'firstname',
    align:    'left',
    sortable: true,
  },
  {
    label:    t('Username'),
    name:     'username',
    field:    'username',
    align:    'left',
    sortable: true,
  },
  {
    label:    t('Created at'),
    name:     'createdAt',
    field:    'createdAt',
    format:    dateFormatter(FORMATS.DATETIME),
    align:    'left',
    sortable: true,
  },
  {
    label: t('Role'),
    name:  'roles',
    field: 'roles',
    align: 'left',
  },
];

const availableFilters: ReportFilter[] = [
  {
    field:     'roles',
    label:     t('Role'),
    operators: [
      reports.createList('=', () => urql.query(
        gql`query GetRoles { roles(editableOnly: false) { id title } }`,
        {},
      ).then(({ data }) => data!.roles.map((role: Role) => ({
        value: role.id,
        label: role.title,
      })))),
    ],
  }
];

const query = gql`
  query GetUsers(
    $page: Int,
    $perPage: Int!,
    $query: String,
    $filter: [ReportFilterInput!],
    $sort: [ReportSortInput!]!,
  ) {
    result: usersReport(
      page: $page,
      perPage: $perPage,
      query: $query,
      filter: $filter,
      sort: $sort,
    ) {
      data {
        id
        firstname
        lastname
        username
        createdAt
        deletedAt
        roles { title }
        editable
      }
      total
    }
  }
`;

const canCreate = useCan(Ability.CreateUsers);

</script>

<i18n lang="yaml">
ru:
  Add User: Добавить пользователя
  No Users: Нет пользователей
  Username: Логин
  Role: Роль
  User Full Name: Полное имя
  Users: Пользователи
en:
  Add User: Add User
  No Users: No Users
  Username: Login
  Role: Role
  User Full Name: Full Name
  Users: Users
</i18n>
