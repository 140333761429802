<template>
  <QDialog
    :model-value="account !== null"
    @update:model-value="emit('update:account', $event ? account : null)"
  >
    <QCard style="min-width: 540px;">
      <CardTitle>
        {{ t('New Payment') }}
        <template #subtitle>
          {{ account?.name }}
        </template>
      </CardTitle>

      <QSeparator />

      <QCardSection class="q-pt-none">
        <div class="row q-col-gutter-md">
          <QInput
            v-model.number="amount"
            :label="t('Payment Amount')"
            :error="errorsFor<string[]>('amount').length > 0"
            :error-message="errorsFor<string[]>('amount')[0]"
            class="col-12 col-sm-6"
          >
            <template #append>
              {{ GLOBAL_ACCOUNTS_CURRENCY }}
            </template>
          </QInput>
          <QInput
            v-model.number="daysToAdd"
            :label="t('Subscription days to add')"
            class="col-12 col-sm-6"
            :hint="newPaidUntil ? formatDate(newPaidUntil) : undefined"
          />
        </div>
      </QCardSection>

      <QSeparator />

      <PrimaryErrorBanner animated />

      <QCardActions align="between">
        <QBtn
          :label="t('Cancel')"
          @click="emit('update:account', null)"
        />
        <QBtn
          color="positive"
          icon="mdi-plus"
          :loading="fetching"
          :label="t('Make a Payment')"
          @click="commitPayment"
        />
      </QCardActions>
    </QCard>
  </QDialog>
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import { GLOBAL_ACCOUNTS_CURRENCY } from '@/constants';
import type { GlobalAccount, MutationAddGlobalAccountPaymentArgs } from '@/graphql/types';
import { gql, useMutation } from '@urql/vue';
import dayjs from 'dayjs';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { fillErrorsFromGraphQLError, errorsFor, clearErrors, PrimaryErrorBanner } = useErrorHandling();

const { formatDate } = useLocalizedFormatters();

const props = defineProps<{
  account: GlobalAccount | null;
}>();

const emit = defineEmits<{
  'update:account': [account: GlobalAccount | null];
  'success': [];
}>();

const amount = ref(0);
const daysToAdd = ref(1);

watch(() => props.account, () => {
  amount.value = 0;
  daysToAdd.value = 1;
});

const newPaidUntil = computed(() => {
  if (!props.account) {
    return null;
  }

  const now = dayjs();
  const oldPaidUntil = dayjs(props.account.subscriptionPaidUntil);
  const result = (now.isAfter(oldPaidUntil) ? now : oldPaidUntil)
    .add(daysToAdd.value, 'days');

  return result.isValid() ? result : null;
});

const { executeMutation, fetching } = useMutation<unknown, MutationAddGlobalAccountPaymentArgs>(gql`
  mutation AddGlobalAccountPayment(
    $account: GlobalAccountInput!,
    $amount: Int!,
    $paidPeriod: Int!,
  ) {
    addGlobalAccountPayment(
      account: $account,
      amount: $amount,
      paidPeriod: $paidPeriod,
    ) {
      id
    }
  }
`);

async function commitPayment() {
  clearErrors();

  const { error } = await executeMutation({
    account: {
      origin: props.account!.origin,
      id: props.account!.id,
    },
    amount: amount.value,
    paidPeriod: daysToAdd.value,
  });

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  emit('success');
  emit('update:account', null);
}

</script>

<i18n lang="yaml">
ru:
  New Payment: Новый платеж
  Make a Payment: Внести платеж
  Payment Amount: Сумма
  Subscription days to add: Добавить дней подписки
en:
  New Payment: Make a Payment
  Make a Payment: Make a Payment
  Payment Amount: Amount
  Subscription days to add: Subscription days to add
</i18n>
