<template>
  <ExcelImport
    :type="ExcelImportTypeEnum.STOCKS"
    :mutation-validate="mutationValidate"
    :mutation-import="mutationImport"
    :success-notification="n => t('Imported {n} stocks', n)"
    :label="t('Upload Stocks')"
    @success="emit('success')"
  >
    <template #import-btn-title>
      {{ t('Load Stocks') }}
    </template>

    <template #row-with-error-message="{ rowsCount }">
      {{ t('{n} stocks with errors', rowsCount) }}
    </template>

    <template #validation-success="{ rowsCount }">
      {{ t('Validation success. You can update {n} stocks.', rowsCount) }}
    </template>
  </ExcelImport>
</template>

<script setup lang="ts">

import { ExcelImportTypeEnum } from '@/graphql/types';
import type { ReportOptions } from '@/types/reports';
import ExcelImport from '@/views/ExcelImport.vue';
import { gql } from '@urql/vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps<{
  options?: ReportOptions;
}>();

const emit = defineEmits<{
  (e: 'success'): void;
}>();

const mutationImport = gql`
  mutation ImportStocks($type: ExcelImportTypeEnum!, $file: Upload) {
    importedCount: importExcelFile(type: $type, file: $file)
  }
`;

const mutationValidate = gql`
  mutation ValidateFileStocks($type: ExcelImportTypeEnum!, $file: Upload) {
    validationResult: validateExcelImportFile(type: $type, file: $file) {
      isValid
      rowsCount
      validationErrorMessage
      fileWithErrorsUrl
    }
  }
`;

</script>

<i18n lang="yaml">
ru:
  Load Stocks: Загрузить остатки
  Validation success. You can update {n} stocks.: >
    Проверка прошла успешно. Вы можете обновить {n} строк остатка
    | Проверка прошла успешно. Вы можете обновить {n} строка остатка
    | Проверка прошла успешно. Вы можете обновить {n} строки остатков
    | Проверка прошла успешно. Вы можете обновить {n} строк остатка
  "{n} stocks with errors": >
    {n} строк остатка
    | {n} строка остатка
    | {n} строки остатка
    | {n} строк остатков
  Imported {n} stocks: >
    Импортировано {n} строк остатка
    | Импортирована {n} строка остатка
    | Импортировано {n} строки остатка
    | Импортировано {n} строк остатков
  Upload Stocks: Загрузить остатки
en:
  Load Stocks: Load Stocks
  Validation success. You can update {n} stocks.: >
    Validation success. You can update {n} stock.
    | Validation success. You can update {n} stocks.
  "{n} stocks with errors": >
    {n} stock with errors
    | {n} stocks with errors
  Imported {n} stocks: >
    Imported {n} stock
    | Imported {n} stocks
  Upload Stocks: Upload Stocks
</i18n>
