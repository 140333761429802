<template>
  <QPage padding>
    <BaseAlert
      v-if="recentlyVerified"
      type="success"
    >
      {{ t('Email Verified Successfully!') }}
    </BaseAlert>

    <GlobalBarcodeSearch
      v-if="preferences.terminalMode"
      class="q-mb-md"
    />

    <RecentActions />
  </QPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import GlobalBarcodeSearch from '@/views/TheDashboard/GlobalBarcodeSearch.vue';
import RecentActions from '@/views/TheDashboard/QuickAccess.vue';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import useLocalPreferences from '@/composables/useLocalPreferences';

const { t } = useI18n();

const preferences = useLocalPreferences();

const route = useRoute();

useBreadcrumbs(t('Dashboard'));

const recentlyVerified = computed(() => !!route.query.verified);

</script>

<i18n lang="yaml">
ru:
  Email Verified Successfully!: E-mail успешно подтвержден
  Dashboard: Обзор

en:
  Email Verified Successfully!: Email Verified Successfully!
  Dashboard: Dashboard
</i18n>
