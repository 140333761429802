<template>
  <div>
    <div class="row items-center q-gutter-md">
      <template v-if="loading">
        <QSkeleton type="QBtn" />
      </template>
      <template v-else>
        <QBtn
          :label="t('Full Sync')"
          icon="mdi-sync"
          :loading="syncing"
          :disable="!warehouseIsEmpty || progress.syncing || !integrationIsConfigured"
          @click="fullSync"
        >
          <QTooltip v-if="!warehouseIsEmpty">
            {{ t('Full sync is only available for empty warehouse') }}
          </QTooltip>
        </QBtn>
        <div v-if="progress.syncing">
          <QSpinner />
          {{ t('Syncing') }}
        </div>
        <div
          v-else-if="progress.error"
          class="text-negative"
        >
          {{ progress.error }}
        </div>
        <div
          v-if="primaryError"
          class="text-negative"
        >
          {{ primaryError }}
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">

import useBackendProgress from '@/composables/useBackendProgress';
import useErrorHandling from '@/composables/useErrorHandling';
import type { MoySkladIntegration, Product } from '@/graphql/types';
import { gql, useMutation, useQuery } from '@urql/vue';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { clearErrors, fillErrorsFromGraphQLError, primaryError } = useErrorHandling();

const { t } = useI18n();

const props = defineProps<{
  integration: MoySkladIntegration;
}>();

const emit = defineEmits<{
  'sync-completed': [];
}>();

const integrationIsConfigured = computed(() => props.integration.isEnabled && props.integration.accessToken);

const { data, fetching: loading } = useQuery<{
  products: Product[];
}>({
  query: gql`
    query GetDataForMoySkladSync {
      products { id }
    }
  `,
});

const warehouseIsEmpty = computed(() => data.value?.products.length === 0);

const {
  data: progress,
  reset: resetProgress,
} = useBackendProgress('syncMoySklad', {
  syncing: false,
  error: null as string | null,
});

const { executeMutation: doSync, fetching: syncing } = useMutation<{ started: boolean }>(
  gql`
    mutation MoySkladSyncData {
      started: moySkladSyncAll
    }
  `,
);

watch(() => progress.syncing, syncing => {
  if (!syncing) {
    emit('sync-completed');
  }
});

async function fullSync() {
  resetProgress();
  clearErrors();

  const { error } = await doSync();

  if (error) {
    fillErrorsFromGraphQLError(error);
  }
}

</script>

<i18n lang="yaml">
ru:
  Full Sync: Загрузить все
  Full sync is only available for empty warehouse: Загрузка всех данных доступна только для пустого склада
  Syncing: Синхронизация

en:
  Full Sync: Full Sync
  Full sync is only available for empty warehouse: Full sync is only available for empty warehouse
  Syncing: Syncing
</i18n>
