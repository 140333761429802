<template>
  <ProductListItem :product="product">
    <template #top-right>
      <QItemLabel caption>
        {{ formattedDiff }}
        {{ productPack.measurementUnit.shortName }}
      </QItemLabel>
    </template>
  </ProductListItem>
</template>

<script setup lang="ts">

import ProductListItem from '@/components/ProductListItem.vue';
import type { ProductPack } from '@/graphql/types';
import { computed } from 'vue';

const props = defineProps<{
  productPack: ProductPack;
  diff: number;
}>();

const product = computed(() => props.productPack.product);

const formattedDiff = computed(() => props.diff > 0 ? `+${props.diff}` : props.diff);

</script>
