<template>
  <GraphQLReportList
    :graphql-query="query"
    :fixed-filters="fixedFilters"
    :no-data-label="t('No arrivals')"
    :storage-prefix="storagePrefix"
  >
    <template #item="{ item }">
      <ArrivalDashboardListItem
        :arrival="item"
        @continue-arrival="emit('continue-arrival', item)"
      />
    </template>
  </GraphQLReportList>
</template>

<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import type { Arrival, ArrivalStateEnum, ReportFilterInput } from '@/graphql/types';
import { gql } from '@urql/vue';
import GraphQLReportList from '@/components/Mobile/GraphQLReportList.vue';
import { computed } from 'vue';
import ArrivalDashboardListItem from '@/views/Mobile/Arrival/ArrivalDashboardListItem.vue';

const { t } = useI18n();

const props = defineProps<{
  states: ArrivalStateEnum[];
  storagePrefix: string;
}>();

const emit = defineEmits<{
  (e: 'continue-arrival', arrival: Arrival): void;
}>();

const query = gql`
  query ArrivalsForDashboard(
    $first: Int!,
    $after: Cursor,
    $query: String,
    $filter: [ReportFilterInput!],
  ) {
    result: arrivalsList(
      first: $first,
      after: $after,
      query: $query,
      filter: $filter,
    ) {
      edges {
        cursor
        node {
          id
          createdAt
          state
          storage { id name }
          supply { id externalId }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const fixedFilters = computed<ReportFilterInput[]>(() => [{
  field:    'state',
  operator: 'in',
  value:    JSON.stringify(props.states),
}]);

</script>

<i18n lang="yaml">
ru:
  No arrivals: Нет операций

en:
  No arrivals: No arrivals

</i18n>
