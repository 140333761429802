<template>
  <QItem>
    <QItemSection
      v-if="itemEditable"
      avatar
    >
      <QBtn
        flat
        round
        icon="mdi-pencil"
        :title="t('Edit')"
        :disable="!isItemEditable"
        @click="emit('edit')"
      />
    </QItemSection>
    <QItemSection>
      <QItemLabel>
        {{ itemTitle(item) }}
      </QItemLabel>
      <QItemLabel
        v-if="itemSubtitle"
        caption
      >
        {{ itemSubtitle(item) }}
      </QItemLabel>
    </QItemSection>
    <slot
      name="actions"
      :item="item"
    />
    <QItemSection
      v-if="itemDeletable"
      side
    >
      <QBtn
        flat
        round
        icon="mdi-delete"
        color="error"
        :title="t('Delete')"
        :disable="!isItemDeletable"
        :loading="hasProgress('deleting')"
        @click="doDeleteItem(item)"
      />
    </QItemSection>
  </QItem>
</template>

<script setup lang="ts" generic="TItem">

import useProgressHandling from '@/composables/useProgressHandling';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

const { t } = useI18n();

const { progressStarted, hasProgress } = useProgressHandling<'deleting'>();

const props = defineProps<{
  item: TItem;
  itemTitle: (item: TItem) => string;
  itemSubtitle?: (item: TItem) => string;
  itemEditable?: ((item: TItem) => boolean) | boolean;
  itemDeletable?: ((item: TItem) => boolean) | boolean;
  deleteItem?: (item: TItem) => PromiseLike<unknown>;
}>();

const emit = defineEmits<{
  (e: 'delete', item: TItem): void;
  (e: 'error', error: Error): void;
  (e: 'edit'): void;
}>();

async function doDeleteItem(item: TItem): Promise<void> {
  const done = progressStarted('deleting');
  try {
    if (props.deleteItem) {
      await props.deleteItem(item);
    }
    emit('delete', item);
  } catch (e) {
    emit('error', e as Error);
  } finally {
    done();
  }
}

const isItemEditable = computed((): boolean =>
  typeof props.itemEditable === 'function'
    ? props.itemEditable(props.item)
    : !!props.itemEditable);

const isItemDeletable = computed((): boolean =>
  typeof props.itemDeletable === 'function'
    ? props.itemDeletable(props.item)
    : !!props.itemDeletable);

</script>

<style scoped lang="scss">

:deep(.q-btn.disabled) {
  opacity: 1 !important;
  color: $grey-4 !important;
}

</style>
