<template>
  <FullHeightPage>
    <GraphQLQueryTable
      :graphql-query="query"
      :fields="tableFields"
      :available-filters="availableFilters"
      storage-prefix="productsMovementsList.report"
      keep-page-in-url
      sticky-header
      class="col"
    >
      <template #body-cell-operationId="column">
        <BodyCellLink
          :column="column"
          :to="rowOperationCardRoute(column.row)"
          :disable="!rowOperationCardRoute(column.row)"
          variant="link"
        />
      </template>
      <template #body-cell-productSku="column">
        <BodyCellLink
          :column="column"
          :to="{ name: ROUTES.PRODUCTS_EDIT, params: { id: column.row.productId } }"
          variant="link"
        />
      </template>
      <template #body-cell-cellFromName="column">
        <QTd v-if="isNil(column.value)">
          —
        </QTd>
        <BodyCellLink
          v-else
          :column="column"
          :to="column.row.cellFromType === 'Cell'
            ? { name: ROUTES.CELLS_EDIT, params: { id: column.row.cellFromId } }
            : { name: ROUTES.CONTAINERS_EDIT, params: { id: column.row.cellFromId } }"
          variant="link"
        />
      </template>
      <template #body-cell-cellToName="column">
        <QTd v-if="isNil(column.value)">
          —
        </QTd>
        <BodyCellLink
          v-else
          :column="column"
          :to="column.row.cellToType === 'Cell'
            ? { name: ROUTES.CELLS_EDIT, params: { id: column.row.cellToId } }
            : { name: ROUTES.CONTAINERS_EDIT, params: { id: column.row.cellToId } }"
          variant="link"
        />
      </template>
      <template #body-cell-details="column">
        <QTd v-if="column.loading">
          <QSkeleton />
        </QTd>
        <BodyCellLink
          v-else-if="column.row.operationType === 'Arrival' && column.row.supplyId"
          :column="column"
          :to="{ name: ROUTES.SUPPLIES_EDIT, params: { id: column.row.supplyId } }"
          variant="link"
          :title="arrivalDetailsTitle(column.row)"
        >
          {{ arrivalDetailsText(column.row) }}
        </BodyCellLink>
        <QTd v-else />
      </template>
    </GraphQLQueryTable>
  </FullHeightPage>
</template>

<script setup lang="ts">

import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import FullHeightPage from '@/components/FullHeightPage.vue';
import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type {
  Arrival,
  Inventory,
  OperationUnion,
  ProductMovementReportRow,
  Realization,
  Selection,
  Transfer,
} from '@/graphql/types';
import operationCardRoute from '@/helpers/operationCardRoute';
import {
  createDatesRangeOperator,
  createList,
  createPrimitive,
  dashIfNil,
} from '@/helpers/reports';
import type { ReportFilter, TableColumn } from '@/types/reports';
import { gql } from '@urql/vue';
import type { QSelectOption } from 'quasar';
import { isNil } from 'ramda';
import { useI18n } from 'vue-i18n';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

useBreadcrumbs(t('Products Movements'));

const tableFields: TableColumn<ProductMovementReportRow>[] = [
  {
    label:         t('Operation'),
    name:          'operationId',
    field:         'operationId',
    requestFields: ['operationId', 'operationType'],
    align:         'left',
  },
  {
    label:    t('Created at'),
    name:     'createdAt',
    field:    'createdAt',
    format:   value => formatDate(value, FORMATS.DATETIME),
    align:    'left',
    sortable: true,
  },
  {
    label:  t('Operation Type'),
    name:   'operationType',
    field:  'operationType',
    format: value => value === 'QualityChange' ? t('Quality Change') : t('operationType.' + value),
    align:  'left',
  },
  {
    label: t('Product SKU'),
    name:  'productSku',
    field: 'productSku',
    align: 'left',
  },
  {
    label: t('Product Name'),
    name:  'productName',
    field: 'productName',
    align: 'left',
  },
  {
    label: t('Stock before'),
    name:  'warehouseStockBefore',
    field: 'warehouseStockBefore',
  },
  {
    label: t('Stock after'),
    name:  'warehouseStockAfter',
    field: 'warehouseStockAfter',
  },
  {
    label:      t('Stock Diff'),
    labelTitle: t('Stock Difference'),
    name:       'warehouseStockDiff',
    field:      'warehouseStockDiff',
  },
  {
    label:      t('Source Cell'),
    labelTitle: t('Source Cell or Container'),
    name:       'cellFromName',
    field:      'cellFromName',
    align:      'left',
  },
  {
    label:      t('Destination Cell'),
    labelTitle: t('Destination Cell or Container'),
    name:       'cellToName',
    field:      'cellToName',
    align:      'left',
  },
  {
    label:      t('Source Stock, Before'),
    labelTitle: t('Source Cell Stock, Before'),
    name:       'cellFromStockBefore',
    field:      'cellFromStockBefore',
    format:     dashIfNil,
  },
  {
    label:      t('Source Stock, After'),
    labelTitle: t('Source Cell Stock, After'),
    name:       'cellFromStockAfter',
    field:      'cellFromStockAfter',
    format:     dashIfNil,
  },
  {
    label:      t('Destination Stock, Before'),
    labelTitle: t('Destination Cell Stock, Before'),
    name:       'cellToStockBefore',
    field:      'cellToStockBefore',
    format:     dashIfNil,
  },
  {
    label:      t('Destination Stock, After'),
    labelTitle: t('Destination Cell Stock, After'),
    name:       'cellToStockAfter',
    field:      'cellToStockAfter',
    format:     dashIfNil,
  },
  {
    label:      t('Cell Diff'),
    labelTitle: t('Cell Stock Difference'),
    name:       'cellStockDiff',
    field:      'cellStockDiff',
  },
  {
    label: t('User'),
    name:  'userFullName',
    field: 'userFullName',
    align: 'left',
  },
  {
    label:         t('Details'),
    name:          'details',
    field:         () => '',
    requestFields: [
      'supplyId',
      'supplyExternalId',
      'supplierName',
    ],
    align:         'left',
  },
];

const operationTypeFilterValues = () => Promise.resolve<QSelectOption[]>([
  {
    label: t('operationType.Arrival'),
    value: ('Arrival' as Arrival['__typename'])!,
  },
  {
    label: t('operationType.Inventory'),
    value: ('Inventory' as Inventory['__typename'])!,
  },
  {
    label: t('operationType.Transfer'),
    value: ('Transfer' as Transfer['__typename'])!,
  },
  {
    label: t('operationType.Selection'),
    value: ('Selection' as Selection['__typename'])!,
  },
  {
    label: t('operationType.Realization'),
    value: ('Realization' as Realization['__typename'])!,
  },
  {
    label: t('Quality Change'),
    value: 'QualityChange',
  },
]);

const availableFilters: ReportFilter[] = [
  {
    label:     t('Created at'),
    field:     'createdAt',
    operators: [
      createDatesRangeOperator(),
    ],
  },
  {
    label:     t('Product SKU'),
    field:     'productSku',
    operators: [
      createPrimitive('=', 'string'),
      createPrimitive('!=', 'string', { label: '≠' }),
      createPrimitive('in', 'multiString', {
        label: t('reportFilterOperator.in'),
      }),
      createPrimitive('not in', 'multiString', {
        label: t('reportFilterOperator.not in'),
      }),
    ],
  },
  {
    label:     t('Cell or Container'),
    field:     'storage',
    operators: [
      createPrimitive('=', 'string'),
      createPrimitive('!=', 'string', { label: '≠' }),
      createPrimitive('in', 'multiString', {
        label: t('reportFilterOperator.in'),
      }),
      createPrimitive('not in', 'multiString', {
        label: t('reportFilterOperator.not in'),
      }),
    ],
  },
  {
    label:     t('Operation Type'),
    field:     'operationType',
    operators: [
      createList('=', operationTypeFilterValues, t('reportFilterOperator.=')),
      createList('!=', operationTypeFilterValues, t('reportFilterOperator.!=')),
      createList('in', operationTypeFilterValues, t('reportFilterOperator.in')),
      createList('not in', operationTypeFilterValues, t('reportFilterOperator.not in')),
    ],
  },
  {
    label:     t('Supply'),
    field:     'supply',
    operators: [
      createPrimitive('=', 'string'),
    ],
  },
];

const query = gql`
  query GetMovementsForProductsMovementsReport(
    $page: Int,
    $perPage: Int!,
    $query: String,
    $filter: [ReportFilterInput!],
    $sort: [ReportSortInput!]!,
  ) {
    result: productsMovementsReport(
      page: $page,
      perPage: $perPage,
      query: $query,
      filter: $filter,
      sort: $sort,
    ) {
      data {
        movementId
        operationId
        createdAt
        operationType
        productId
        productSku
        productName
        warehouseStockBefore
        warehouseStockAfter
        warehouseStockDiff
        cellFromId
        cellFromType
        cellFromName
        cellToId
        cellToType
        cellToName
        cellFromStockBefore
        cellFromStockAfter
        cellToStockBefore
        cellToStockAfter
        cellStockDiff
        userFullName
        supplyId
        supplyExternalId
        supplierName
      }
      total
    }
  }
`;

function arrivalDetailsText(row: ProductMovementReportRow) {
  return row.supplierName
    ? row.supplyExternalId + ', ' + row.supplierName
    : row.supplyExternalId;
}

function arrivalDetailsTitle(row: ProductMovementReportRow) {
  return row.supplierName
    ? t('Supply') + ' ' + row.supplyExternalId + ', ' + t('Supplier') + ' ' + row.supplierName
    : t('Supply') + ' ' + row.supplyExternalId;
}

function rowOperationCardRoute(row: ProductMovementReportRow) {
  const typename = row.operationType === 'QualityChange'
    ? 'Transfer'
    : row.operationType as OperationUnion['__typename'];

  return operationCardRoute({
    id:         row.operationId!,
    __typename: typename,
  });
}

</script>

<i18n lang="yaml">
ru:
  Products Movements: Движения товара
  Operation: Операция
  Operation Type: Тип операции
  Quality Change: Изменение качества
  Stock, After: Остаток, после
  Stock Diff: Изм ост
  Stock Difference: Изменение остатка
  Stock before: Остаток, до
  Stock after: Остаток, после
  Source Cell: Ячейка 1
  Source Cell or Container: Исходная ячейка или контейнер
  Destination Cell: Ячейка 2
  Destination Cell or Container: Конечная ячейка или контейнер
  Source Stock, Before: Ост Яч 1 до
  Source Cell Stock, Before: Остаток в исходной ячейке, до
  Source Stock, After: Ост Яч 1 пос
  Source Cell Stock, After: Остаток в исходной ячейке, после
  Destination Stock, Before: Ост Яч 2 до
  Destination Cell Stock, Before: Остаток в конечной ячейке, до
  Destination Stock, After: Ост Яч 2 пос
  Destination Cell Stock, After: Остаток в конечной ячейке, после
  Cell Diff: Изм Яч
  Cell Stock Difference: Изменение в ячейке
  Details: Детали
en:
  Products Movements: Products Movements
  Operation: Operation
  Operation Type: Operation Type
  Quality Change: Quality Change
  Stock, After: Stock, After
  Stock Diff: Stock Diff
  Stock Difference: Stock Difference
  Stock before: Stock before
  Stock after: Stock after
  Source Cell: Source Cell
  Source Cell or Container: Source Cell or Container
  Destination Cell: Destination Cell
  Destination Cell or Container: Destination Cell or Container
  Source Stock, Before: Source Cell Stock, Before
  Source Cell Stock, Before: Source Cell Stock, Before
  Source Stock, After: Source Stock, After
  Source Cell Stock, After: Source Cell Stock, After
  Destination Stock, Before: Destination Cell Stock, Before
  Destination Cell Stock, Before: Destination Cell Stock, Before
  Destination Stock, After: Destination Stock, After
  Destination Cell Stock, After: Destination Cell Stock, After
  Cell Diff: Cell Diff
  Cell Stock Difference: Cell Stock Difference
  Details: Details
</i18n>
