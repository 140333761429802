<template>
  <QLayout
    v-if="isInitialized"
    :view="view"
  >
    <QHeader>
      <QToolbar
        class="q-pl-xs"
        :class="headerClass"
      >
        <div
          id="teleport-target-app-menu-button"
          class="show-only-last-child"
        >
          <QBtn
            v-if="userVerified"
            flat
            round
            icon="mdi-menu"
            @click="drawerOpen = !drawerOpen"
          />
        </div>

        <QBreadcrumbs active-color="white">
          <QBreadcrumbsEl
            v-for="item in breadcrumbs"
            :key="item"
          >
            {{ item }}
          </QBreadcrumbsEl>
        </QBreadcrumbs>

        <QSpace />

        <PaymentWarning />

        <FullscreenToggle />
        <template v-if="userVerified">
          <IntegrationsMenu v-if="canViewApps && !preferences.terminalMode" />

          <PrintQueueMenu />
        </template>
      </QToolbar>
    </QHeader>

    <NavigationDrawer
      v-model="drawerOpen"
      @logout="logout()"
    />

    <QPageContainer>
      <RouterView />
    </QPageContainer>

    <LoginModal />

    <QFooter class="app-footer">
      <div id="teleport-target-buttons-row" />
      <div id="teleport-target-virtual-keyboard" />
      <div id="teleport-target-invisible" />
      <QToolbar
        v-if="!q.screen.xs"
        class="justify-end"
      >
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        <div class="col-auto">
          WMS 🐈
        </div>
      </QToolbar>
    </QFooter>
  </QLayout>
  <QLayout
    v-else
    class="flex items-center justify-center"
  >
    <QCircularProgress
      color="primary"
      size="150px"
      font-size="0.1em"
      indeterminate
      show-value
    >
      {{ t('Loading') }}
    </QCircularProgress>
  </QLayout>
</template>

<script setup lang="ts">

import FullscreenToggle from '@/components/FullscreenToggle.vue';
import useBackend from '@/composables/useBackend';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useCan from '@/composables/useCan';
import useLocalPreferences from '@/composables/useLocalPreferences';
import PaymentWarning from '@/views/PaymentWarning.vue';
import ROUTES from '@/router/routeNames';
import useStore from '@/stores/root';
import { Ability } from '@/types/backend';
import LoginModal from '@/views/Auth/LoginModal.vue';
import IntegrationsMenu from '@/views/IntegrationsMenu.vue';
import NavigationDrawer from '@/views/NavigationDrawer.vue';
import PrintQueueMenu from '@/views/Printing/PrintQueueMenu.vue';
import { useStorage, useTitle } from '@vueuse/core';
import { getCssVar, useQuasar } from 'quasar';
import { isNil } from 'ramda';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const { t } = useI18n();

const router = useRouter();

const docTitle = useTitle();

const q = useQuasar();

const backend = useBackend();

const store = useStore();

const isInitialized = computed(() => store.isInitialized);

const userVerified = computed(() => store.isVerified);

const drawerOpen = ref(false);

// https://quasar.dev/layout/layout#understanding-the-view-prop
const view = computed(() => [
  'Lhh',
  'Lpr',
  `L${q.screen.xs ? 'F' : 'f'}f`,
].join(' '));

const ls = useStorage('app', {
  darkMode: false,
});

const canViewApps = useCan(Ability.ViewApps);

async function logout(): Promise<void> {
  q.loading.show();
  await backend.logout();
  await store.updateUser();
  q.loading.hide();

  // noinspection ES6MissingAwait
  router.push({ name: ROUTES.AUTH_LOGIN });
}

q.dark.set(ls.value.darkMode);
store.init();

q.addressbarColor.set(getCssVar('primary')!);

const { breadcrumbs } = useBreadcrumbs();

watch(breadcrumbs, updateDocTitle);

function updateDocTitle() {
  docTitle.value = [
    import.meta.env.VITE_APP_NAME,
    ...breadcrumbs.value,
  ].reverse().join(' - ');
}

router.beforeEach(updateDocTitle);

const preferences = useLocalPreferences();

onBeforeMount(() => {
  if (isNil(preferences.terminalMode)) {
    preferences.terminalMode = q.screen.xs;
  }
});

const headerClass = computed(() => import.meta.env.VITE_APP_HEADER_CLASS ?? '');

</script>

<style lang="scss">

@import "quasar/src/css/core/visibility";
@import "quasar/src/css/variables";
@import "@/css/mixins";

@include dark-mode() using($isDark) {
  .q-item--active {
    background: if($isDark, $grey-9, $grey-4);
  }
}

.q-table th {
  font-weight: map-get($text-weights, 'bold') !important;
}

// https://quasar.dev/style/color-palette#adding-your-own-colors

.text-error {
  color: $red !important;
}

.bg-error {
  background: $red !important;
}

.text-success {
  color: $green !important;
}

.bg-success {
  background: $green !important;
}

.list-item-default-height {
  height: 75px;
}

.q-list--separator > .q-item-type + .q-item-type.list-item-default-height {
  height: 75px + 1px;
}

.q-item__section.dense-labels .q-item__label + .q-item__label {
  margin-top: 0;
}

.q-carousel__navigation-icon--active .q-icon {
  color: $primary;
}

body {
  overscroll-behavior: contain;
  touch-action: manipulation;
}

body.body--light .app-footer {
  background-color: $grey-2;
  color: black;
}

body.body--dark .app-footer {
  background-color: $dark;
}

body.body--dark .app-link {
  color: $primary;

  &:visited {
    color: $purple;
  }
}

.overflow-fade {
  overflow: hidden;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent 0%, transparent 75%, white);
  }
}

.q-field.text-right .q-field__native {
  text-align: right;
}

.q-field.field-ellipsis .q-field__native {
  text-overflow: ellipsis !important;
}

.q-select.ellipsis .q-field__native > span {
  @extend .ellipsis
}

.hide-when-empty:empty {
  display: none;
}

.sentry-error-embed-wrapper {
  z-index: 9999 !important;
}

.show-only-last-child > :not(:last-of-type) {
  display: none;
}

.text-underline-dotted {
  text-decoration: underline dotted;
}

.q-chip.multiline-chip {
  height: auto;
  min-height: 2em;

  & > .q-chip__content {
    white-space: normal;
    line-height: 1em;
  }
}

</style>
