<template>
  <QCard>
    <QLinearProgress
      :indeterminate="fetching"
      instant-feedback
    />
    <CardTitle>
      {{ title }}

      <QSpace />

      <QBtn
        v-if="itemDraft && !editingItem"
        flat
        round
        icon="mdi-plus"
        color="green"
        :title="t('Add')"
        @click="addItem()"
      />
    </CardTitle>

    <QSeparator />

    <BaseAlert
      v-if="getPrimaryError()"
      type="error"
      dismissible
      @input="clearErrors()"
    >
      {{ getPrimaryError() }}
    </BaseAlert>

    <QCardSection v-if="editingItem">
      <slot
        name="edit"
        v-bind="{
          item: editingItem,
          on: { save: onItemSaved, cancel: onEditCancel },
        }"
      />
    </QCardSection>

    <template v-else>
      <QCardSection
        v-if="itemFilter"
        class="q-py-none"
      >
        <QInput
          v-model="searchString"
          dense
          clearable
          :label="t('Search')"
        >
          <template #prepend>
            <QIcon name="mdi-magnify" />
          </template>
        </QInput>
      </QCardSection>

      <QList :dense="listDense">
        <template v-if="items.length > 0">
          <EditableListCardItem
            v-for="item in currentPageItems"
            :key="itemKey(item)"
            :item="item"
            :delete-item="deleteItem"
            :item-title="itemTitle"
            :item-subtitle="itemSubtitle"
            :item-editable="itemEditable"
            :item-deletable="itemDeletable"
            @edit="editItem(item)"
            @delete="onItemDeleted($event)"
            @error="fillErrorsFromGraphQLError"
          >
            <template
              v-if="$slots['item-actions']"
              #actions="scope"
            >
              <slot
                name="item-actions"
                v-bind="scope"
              />
            </template>
          </EditableListCardItem>
        </template>
        <QItem v-else-if="!fetching && !editingItem && itemDraft">
          <QItemSection>
            <QBtn
              color="success"
              icon="mdi-plus"
              @click="addItem()"
            >
              {{ t('Add') }}
            </QBtn>
          </QItemSection>
        </QItem>
      </QList>
      <QCardSection class="row justify-between">
        <div class="row items-center">
          <QPagination
            v-if="filteredItems.length > paginationPerPage"
            v-model="paginationPage"
            :max="Math.ceil(filteredItems.length / paginationPerPage)"
            :max-pages="3"
            direction-links
            :ellipses="false"
            :boundary-numbers="false"
          />
        </div>
        <div>
          {{ t('Records per page') }}:
          <QSelect
            v-model="paginationPerPage"
            class="inline"
            borderless
            dense
            options-dense
            :options="[5, 10, 20]"
          />
        </div>
      </QCardSection>
    </template>
  </QCard>
</template>

<script setup lang="ts" generic="TItem">

import BaseAlert from '@/components/BaseAlert.vue';
import CardTitle from '@/components/CardTitle.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import spliceByValue from '@/helpers/spliceByValue';
import { useQuery } from '@urql/vue';
import { useI18n } from 'vue-i18n';
import type { DocumentNode } from 'graphql';
import { computed, type Ref, ref, watch } from 'vue';
import EditableListCardItem from './EditableListCardItem.vue';

const { t } = useI18n();

const { clearErrors, fillErrorsFromGraphQLError, getPrimaryError } = useErrorHandling();

const props = defineProps<{
  title: string;
  itemsQuery: DocumentNode | TItem[];
  itemsQueryVars?: unknown;
  itemTitle: (item: TItem) => string;
  itemSubtitle?: (item: TItem) => string;
  itemEditable?: ((item: TItem) => boolean) | boolean;
  itemDeletable?: ((item: TItem) => boolean) | boolean;
  deleteItem?: (item: TItem) => PromiseLike<unknown>;
  itemDraft?: () => TItem;
  itemKey: (item: TItem) => string;
  itemFilter?: (item: TItem, searchString: string) => boolean;
  listDense?: boolean;
}>();

const { data, executeQuery, fetching } = useQuery({
  query: props.itemsQuery as DocumentNode,
  pause: Array.isArray(props.itemsQuery),
  variables: computed(() => props.itemsQueryVars),
});
watch(data, data => {
  fetchedItems.value = Object.values(data)[0] as TItem[];
});
const fetchedItems = ref([]) as Ref<TItem[]>;

const items = computed((): TItem[] => {
  if (Array.isArray(props.itemsQuery)) {
    return props.itemsQuery;
  }

  return fetchedItems.value;
});

const searchString = ref('');

const filteredItems = computed(() => items.value
  .filter(item => props.itemFilter ? props.itemFilter(item, searchString.value ?? '') : true));

const paginationPage = ref(1);
const paginationPerPage = ref(5);

watch(paginationPerPage, () => {
  paginationPage.value =  1;
});

const currentPageItems = computed(() => filteredItems.value.slice(
  (paginationPage.value - 1) * paginationPerPage.value,
  paginationPage.value * paginationPerPage.value,
));

watch(searchString, () => {
  paginationPage.value = 1;
});

watch(currentPageItems, clearErrors);

const editingItem = ref(null) as Ref<TItem | null>;

function editItem(item: TItem): void {
  clearErrors();
  editingItem.value = item;
}

function addItem(): void {
  editingItem.value = props.itemDraft!();
}

async function onItemSaved(freshItem: TItem) {
  editingItem.value = null;

  await executeQuery();

  const index = fetchedItems.value.findIndex(item => props.itemKey(item) === props.itemKey(freshItem));
  paginationPage.value = Math.floor(index / paginationPerPage.value) + 1;
}

function onEditCancel(): void {
  editingItem.value = null;
}

function onItemDeleted(item: TItem): void {
  clearErrors();
  spliceByValue(items.value, item);
  executeQuery();
}

defineExpose({
  refresh: executeQuery,
});

</script>
