<template>
  <template v-if="storage.id && canCreateInventory && !storageIsContainerAndContainsOrders">
    <QBtn
      v-if="pendingInventory"
      :to="{ name: ROUTES.INVENTORIES_EDIT, params: { id: pendingInventory.id } }"
    >
      {{ t('Inventory') }} {{ t('# {text}', { text: pendingInventory.id }) }}
    </QBtn>
    <QBtn
      v-else
      :loading="enqueueingInventory || fetchingPendingInventory"
      @click="enqueueInventory"
    >
      {{ t('Create Inventory') }}
    </QBtn>
  </template>
</template>

<script setup lang="ts">

import { computed, ref, watch } from 'vue';
import type {
  Inventory,
  MutationEnqueueInventoryArgs,
  QueryPendingStorageInventoryArgs,
  Storage,
} from '@/graphql/types';
import { gql, useMutation, useQuery } from '@urql/vue';
import useCan from '@/composables/useCan';
import { Ability } from '@/types/backend';
import { useI18n } from 'vue-i18n';
import isContainer from '@/helpers/isContainer';
import { ContainerKindEnum } from '@/graphql/types';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const props = defineProps<{
  storage: Storage;
}>();

const emit = defineEmits<{
  (e: 'error', error: Error): void;
}>();

const canCreateInventory = useCan(Ability.CreateInventories);

const storageIsContainerAndContainsOrders = computed(() =>
  isContainer(props.storage) && props.storage.kind === ContainerKindEnum.ORDERS,
);

const pendingInventory = ref<Inventory | null>(null);

const {
  data: pendingInventoryData,
  fetching: fetchingPendingInventory,
} = useQuery<{ inventory: Inventory | null }, QueryPendingStorageInventoryArgs>({
  query: gql`
    query GetPendingStorageInventory($storageId: ID!) {
      inventory: pendingStorageInventory(storageId: $storageId) { id }
    }
  `,
  variables: computed(() => ({ storageId: props.storage.id })),
  pause: computed(() => !props.storage.id),
});

watch(pendingInventoryData, data => {
  if (data) {
    pendingInventory.value = data!.inventory;
  }
});

const {
  executeMutation: doEnqueueInventory,
  fetching: enqueueingInventory,
} = useMutation<{ inventory: Inventory }, MutationEnqueueInventoryArgs>(
  gql`
    mutation EnqueueInventory($storageId: ID!) {
      inventory: enqueueInventory(storageId: $storageId) { id }
    }
  `,
);

async function enqueueInventory(): Promise<void> {
  const { data, error } = await doEnqueueInventory(
    { storageId: props.storage.id },
  );

  if (error) {
    emit('error', error);
  } else {
    pendingInventory.value = data!.inventory;
  }
}

</script>
