<template>
  <ProductEditMobile v-if="preferences.terminalMode" />
  <ProductEditDesktop v-else />
</template>

<script setup lang="ts">

import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useLocalPreferences from '@/composables/useLocalPreferences';
import ProductEditDesktop from '@/views/Products/ProductEditDesktop.vue';
import ProductEditMobile from '@/views/Products/ProductEditMobile.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const preferences = useLocalPreferences();

useBreadcrumbs([t('Products'), t('Edit')]);

</script>
