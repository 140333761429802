<template>
  <MaximizedDialog
    model-value
    :title="t('Product')"
    @close="emit('cancel')"
  >
    <ProductEditCard
      :id="id"
      :buttons-panel="buttonsPanel"
    />
    <template #bottom>
      <div ref="buttonsPanel" />
    </template>
  </MaximizedDialog>
</template>

<script setup lang="ts">

import MaximizedDialog from '@/components/MaximizedDialog.vue';
import type { Scalars } from '@/graphql/types';
import ProductEditCard from '@/views/Products/ProductEditCard.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps<{
  id: Scalars['ID'];
}>();

const emit = defineEmits<{
  (e: 'cancel'): void;
}>();

const buttonsPanel = ref();

</script>
