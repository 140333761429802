<template>
  <QPage padding>
    <div class="row q-col-gutter-lg">
      <div class="col-12 col-md-6 offset-md-3">
        <QCard>
          <BaseAlert
            v-if="getPrimaryError()"
            type="error"
          >
            {{ getPrimaryError() }}
          </BaseAlert>
          <QCardSection
            v-if="fetching"
            class="text-center"
          >
            <QCircularProgress
              indeterminate
              size="150px"
              font-size="0.1em"
              color="primary"
              show-value
            >
              {{ t('Loading') }}
            </QCircularProgress>
          </QCardSection>
          <template v-else-if="user">
            <CardTitle>
              <span :class="{ 'text-grey': !fullName }">
                {{ fullName || t('New user') }}
                <QBadge
                  v-if="!isNew && user.deletedAt"
                  rounded
                  color="error"
                  :label="t('Deleted')"
                  :title="t('Deleted {at}', { at: formatDate(user.deletedAt) })"
                />
              </span>

              <QSpace />

              <template v-if="canDelete">
                <QBtn
                  v-if="user.deletedAt"
                  flat
                  round
                  icon="mdi-delete-restore"
                  color="success"
                  :title="t('Restore')"
                  :loading="hasProgress('deleting')"
                  @click="restoreUser()"
                />

                <QBtn
                  v-else
                  flat
                  round
                  icon="mdi-delete"
                  color="error"
                  :title="t('Delete')"
                  :loading="hasProgress('deleting')"
                  @click="deleteUser()"
                />
              </template>
            </CardTitle>
            <QCardSection v-if="canChangeRole">
              <p>
                {{ t('Role') }}
              </p>
              <QCircularProgress
                v-if="rolesLoading"
                indeterminate
                size="sm"
              />
              <QBtnToggle
                v-model="role"
                mandatory
                :options="roles.map(r => ({ value: r.id, label: r.title}))"
              />
            </QCardSection>

            <QSeparator />

            <QCardSection>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <QInput
                    v-model="user.firstname"
                    :label="t('Firstname')"
                    v-bind="qErrorsFor('user.firstname')"
                  />
                </div>
                <div class="col-4">
                  <QInput
                    v-model="user.lastname"
                    :label="t('Lastname')"
                    v-bind="qErrorsFor('user.lastname')"
                  />
                </div>
                <div
                  v-if="canEditEmail"
                  class="col-4"
                >
                  <QInput
                    v-model="user.email"
                    :label="t('Email')"
                    v-bind="qErrorsFor('user.email')"
                  />
                </div>
                <div class="col-4">
                  <QInput
                    v-model="user.username"
                    autocomplete="off"
                    :label="t('Username')"
                    :readonly="!!user.id"
                    class="no-bottom-line"
                    :loading="generatingUsername"
                    v-bind="qErrorsFor('user.username')"
                  />
                </div>
                <div class="col-4">
                  <QInput
                    v-model="password"
                    autocomplete="new-password"
                    :label="t('Password')"
                    :type="'password'"
                    v-bind="qErrorsFor('user.password')"
                  />
                </div>
              </div>
            </QCardSection>

            <QSeparator />

            <QCardActions>
              <QBtn
                exact
                :to="{ name: ROUTES.USERS_LIST }"
                icon="mdi-arrow-left"
              >
                {{ t('Back') }}
              </QBtn>

              <template v-if="user.editable">
                <QSpace />

                <QBtn
                  color="success"
                  icon="mdi-content-save"
                  :loading="hasProgress('applying')"
                  @click="save()"
                >
                  {{ t('Apply') }}
                </QBtn>
                <QBtn
                  color="primary"
                  icon="mdi-content-save"
                  :loading="hasProgress('saving')"
                  @click="saveAndGoToList()"
                >
                  {{ t('Save') }}
                </QBtn>
              </template>
            </QCardActions>
          </template>
          <BaseAlert
            v-else
            type="info"
            icon="mdi-run-fast"
          >
            <p>
              {{ t('User not found') }}
            </p>
            <QBtn
              exact
              color="primary"
              icon="mdi-account-group"
              :to="{ name: ROUTES.USERS_LIST }"
            >
              {{ t('Go to Users List') }}
            </QBtn>
          </BaseAlert>
        </QCard>
      </div>
    </div>
  </QPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import CardTitle from '@/components/CardTitle.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useCan from '@/composables/useCan';
import useErrorHandling from '@/composables/useErrorHandling';
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import useProgressHandling from '@/composables/useProgressHandling';
import type {
  MutationDeleteUserArgs,
  MutationRestoreUserArgs,
  QueryGenerateUsernameArgs,
  Role,
  User,
  UserInput,
} from '@/graphql/types';
import useStore from '@/stores/root';
import { useDebounce } from '@vueuse/core';
import { Ability } from '@/types/backend';
import { useI18n } from 'vue-i18n';
import { gql, useClientHandle, useQuery } from '@urql/vue';
import { computed, nextTick, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const { client: urql } = useClientHandle();

const { fillErrorsFromGraphQLError, clearErrors, getPrimaryError, qErrorsFor } = useErrorHandling();

const { progressStarted, hasProgress } = useProgressHandling<'saving' | 'applying' | 'deleting'>();

const router = useRouter();

const props = defineProps<{
  id?: number;
}>();

useBreadcrumbs([
  t('Users'),
  props.id ? t('Edit') : t('New'),
]);

const UserFragment = gql`
  fragment UserForCard on User {
    id
    firstname
    lastname
    email
    username
    deletedAt
    roles { id title }
    editable
  }
`;

const { error, data, fetching, executeQuery } = useQuery<{ user: User }>({
  query:     gql`
    ${UserFragment}

    query GetUser($id: Int!) { user(id: $id) { ...UserForCard } }
  `,
  variables: computed(() => ({
    id: props.id,
  })),
  pause:     true,
});

watch(error, fillErrorsFromGraphQLError);

watch(data, data => {
  user.value = data!.user;
});

const user = ref({
  id:        null,
  firstname: '',
  lastname:  '',
  email:     '',
  username:  '',
  roles:     [],
  editable:  true,
} as Partial<User> as User);

const { data: rolesResult, fetching: rolesLoading } = useQuery<{ roles: Role[] }>({
  query: gql`query GetRoles { roles(editableOnly: true) { id title } }`,
});

watch(rolesResult, data => {
  roles.value = data!.roles;
  if (!role.value) {
    role.value = roles.value[0].id;
  }
});

const roles = ref<Role[]>([]);

const password = ref('');

watch(() => props.id, (newId, oldId) => {
  if (!newId || newId === oldId) {
    return;
  }

  nextTick(loadUser);
}, { immediate: true });

const role = computed({
  get(): string {
    return user.value.roles[0]?.id;
  },

  set(roleId: string) {
    const role = roles.value.find(r => r.id === roleId)!;
    user.value.roles = [role];
  },
});

async function loadUser(): Promise<void> {
  await executeQuery();
}

async function saveAndGoToList(): Promise<void> {
  const done = progressStarted('saving');

  if (await doSave()) {
    // noinspection ES6MissingAwait
    router.push({ name: ROUTES.USERS_LIST });
  }

  done();
}

async function save(): Promise<void> {
  const done = progressStarted('applying');

  await doSave();

  done();
}

async function doSave(): Promise<boolean> {
  clearErrors();

  const { error, data } = await urql.mutation<{ saveUser: User }, { user: UserInput }>(
    gql`
      ${UserFragment}

      mutation SaveUser($user: UserInput!) {
        saveUser(user: $user) { ...UserForCard }
      }
    `,
    {
      user: {
        id:        user.value.id,
        firstname: user.value.firstname,
        lastname:  user.value.lastname,
        username:  user.value.username,
        email:     user.value.email,
        password:  password.value,
        roles:     user.value.roles.map(r => r.id),
      },
    },
  );

  if (error) {
    fillErrorsFromGraphQLError(error);
    return false;
  }

  const newId = Number(data!.saveUser.id);

  if (newId && props.id !== newId) {
    // noinspection ES6MissingAwait
    router.push({
      name:   ROUTES.USERS_EDIT,
      params: { id: String(newId) },
    });
  }
  return true;
}

const fullName = computed((): string => {
  return user.value ? `${user.value.firstname} ${user.value.lastname}`.trim() : '';
});

const isNew = computed((): boolean => {
  return !user.value.id;
});

const store = useStore();

const canChangeRole = computed(() => user.value.id !== store.user!.id);

const canEdit = useCan(computed(() => ('edit.userWithRole.' + role.value) as Ability));

const canDelete = computed((): boolean => {
  return !!user.value.id && canEdit.value;
});

const canEditEmail = useCan(Ability.EditOwnEmail, u => u.id === user.value.id);

async function deleteUser(): Promise<void> {
  const done = progressStarted('deleting');

  const { error } = await urql.mutation<unknown, MutationDeleteUserArgs>(
    gql`mutation DeleteUser($id: ID!) { deleteUser(id: $id) { id } }`,
    { id: user.value.id! }
  );

  done();

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  } else {
    await loadUser();
  }
}

async function restoreUser(): Promise<void> {
  const done = progressStarted('deleting');

  const { error } = await urql.mutation<unknown, MutationRestoreUserArgs>(
    gql`mutation RestoreUser($id: ID!) { restoreUser(id: $id) { id } }`,
    { id: user.value.id! },
  );

  done();

  if (error) {
    fillErrorsFromGraphQLError(error);
  } else {
    await loadUser();
  }
}

const genUsernameArgs = useDebounce(computed(() => ({
  firstname: user.value.firstname,
  lastname:  user.value.lastname,
})), 200);

const {
  data: genUsernameData,
  fetching: generatingUsername,
} = useQuery<{ username: string }, QueryGenerateUsernameArgs>({
  query: gql`
    query GenerateUsername($firstname: String, $lastname: String) {
      username: generateUsername(firstname: $firstname, lastname: $lastname)
    }
  `,
  variables: genUsernameArgs,
  pause: computed(() => !!user.value.id
    || !genUsernameArgs.value.firstname && !genUsernameArgs.value.lastname),
});

watch(genUsernameData, data => {
  if (data) {
    user.value.username = data.username;
  }
});

</script>

<i18n lang="yaml">
ru:
  Role: Роль
  New user: Новый пользователь
  Username: Логин
  User not found: Пользователь не найден
  Go to Users List: Перейти к списку пользователей
  Users: Пользователи

en:
  Role: Role
  New user: New user
  Username: Username
  User not found: User not found
  Go to Users List: Go to Users List
  Users: Users
</i18n>
