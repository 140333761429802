<template>
  <QDialog
    v-model="showDialog"
    :persistent="deleting"
  >
    <QCard>
      <CardTitle>
        {{ t('Wipe account') }}
      </CardTitle>

      <QCardSection>
        {{ t('Warning! Following entities will be deleted permanently') }}
        <ul>
          <li>{{ t('Operations') }}</li>
          <li>{{ t('Stocks') }}</li>
          <li>{{ t('Supplies') }}</li>
          <li>{{ t('Orders') }}</li>
          <li>{{ t('Customer Returns') }}</li>
          <li>{{ t('Supplier Returns') }}</li>
          <li>{{ t('Cells Areas') }}</li>
          <li>{{ t('Products') }}</li>
          <li>{{ t('Cells') }}</li>
          <li>{{ t('Racks') }}</li>
          <li>{{ t('Containers') }}</li>
        </ul>
      </QCardSection>

      <QCardSection>
        <QInput
          v-model="password"
          :label="t('Enter your password to continue')"
          v-bind="qErrorsFor('password')"
        />
      </QCardSection>

      <template v-if="deleting">
        <div class="text-center">
          {{ progress.message }}
        </div>
        <QLinearProgress
          size="25px"
          :value="progress.percentage"
          :animation-speed="200"
        >
          <div class="absolute-full flex flex-center">
            <QBadge
              color="white"
              text-color="primary"
            >
              {{ (progress.percentage * 100).toFixed(0) + '%' }}
            </QBadge>
          </div>
        </QLinearProgress>
      </template>

      <BaseAlert
        v-if="getPrimaryError()"
        type="error"
      >
        {{ getPrimaryError() }}
      </BaseAlert>

      <QCardActions>
        <QBtn
          color="error"
          :disabled="!password"
          :loading="deleting"
          @click="eraseWarehouseData"
        >
          {{ t('Wipe account permanently') }}
        </QBtn>

        <QBtn
          :disabled="deleting"
          @click="showDialog = false"
        >
          {{ t('Cancel') }}
        </QBtn>
      </QCardActions>
    </QCard>
  </QDialog>

  <QBtn
    :label="t('Wipe account')"
    color="error"
    icon="mdi-store-remove"
    @click="showDialog = true"
  />
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import CardTitle from '@/components/CardTitle.vue';
import useBackendProgress from '@/composables/useBackendProgress';
import useErrorHandling from '@/composables/useErrorHandling';
import type { MutationEraseWarehouseDataArgs } from '@/graphql/types';
import useSelectionState, {
  createEmptySelectionState,
} from '@/views/Mobile/Selection/useSelectionState';
import { gql, useClientHandle } from '@urql/vue';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuasar } from 'quasar';
import useStore from '@/stores/root';

const { t } = useI18n();

const { client: urql } = useClientHandle();

const deleting = ref(false);

const {
  fillErrorsFromGraphQLError,
  getPrimaryError,
  qErrorsFor,
  clearErrors,
} = useErrorHandling();

const showDialog = ref(false);

const password = ref('');

const {
  data: progress,
  reset: resetProgress,
} = useBackendProgress('erasingWarehouseData', {
  percentage: 0,
  message: '',
  error: '',
  done: false,
});

const q = useQuasar();

async function eraseWarehouseData(): Promise<void> {
  resetProgress();
  clearErrors();
  deleting.value = true;
  const { error } = await urql.mutation<unknown, MutationEraseWarehouseDataArgs>(
    gql`
      mutation EraseWarehouseData($password: String!) {
        eraseWarehouseData(password: $password)
      }
    `,
    { password: password.value },
  );

  if (error) {
    fillErrorsFromGraphQLError(error);
    deleting.value = false;
  }
}

watch(() => progress.error, error => {
  if (error) {
    fillErrorsFromGraphQLError(new Error(error));
    deleting.value = false;
  }
});

watch(() => progress.done, done => {
  if (done && showDialog.value) {
    clearLocalStorage();
    useStore().updateUser();
    deleting.value = false;
    showDialog.value = false;

    q.notify({
      message:  t('Account Wiped Successfully'),
      type:     'positive',
      position: 'top',
    });
  }
});

watch(showDialog, show => {
  if (!show) {
    clearErrors();
  }
});

function clearLocalStorage(): void {
  useSelectionState().value = createEmptySelectionState();
}
</script>

<i18n lang="yaml">
ru:
  Warning! Following entities will be deleted permanently: >
    Внимание! Следующие объекты будут удалены безвозвратно
  Enter your password to continue: Введите свой пароль чтобы продолжить
  Wipe account permanently: Безвозвратно очистить аккаунт
  Wipe account: Очистить аккаунт
  Account Wiped Successfully: Аккаунт успешно очищен
en:
  Warning! Following entities will be deleted permanently: >
    Attention! Following entities will be deleted permanently
  Enter your password to continue: Enter your password to continue
  Wipe account permanently: Wipe account permanently
  Wipe account: Wipe account
  Account Wiped Successfully: Account Wiped Successfully
</i18n>
