<template>
  <QPage>
    <GraphQLQueryTable
      :graphql-query="query"
      :fields="tableFields"
      hide-column-settings
    />
  </QPage>
</template>

<script setup lang="ts">

import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import { GLOBAL_ACCOUNTS_CURRENCY } from '@/constants';
import type { GlobalAccountPayment } from '@/graphql/types';
import type { TableColumn } from '@/types/reports';
import { gql } from '@urql/vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { dateFormatter } = useLocalizedFormatters();

useBreadcrumbs([t('Administration'), t('Payments')]);

const query = gql`
  query GetAccountsPayments(
    $page: Int,
    $perPage: Int!,
    $query: String,
    $filter: [ReportFilterInput!],
    $sort: [ReportSortInput!]!,
  ) {
    result: globalAccountsPaymentsReport(
      page: $page,
      perPage: $perPage,
      query: $query,
      filter: $filter,
      sort: $sort,
    ) {
      data {
        __typename
        id
        createdAt
        amount
        account {
          id
          name
        }
        paidPeriod
      }
      total
    }
  }
`;

const tableFields: TableColumn<GlobalAccountPayment>[] = [
  {
    label: t('Account'),
    name:  'accountName',
    field: payment => payment.account.name,
  },
  {
    label:  t('Created at'),
    name:   'createdAt',
    field:  'createdAt',
    format: dateFormatter(FORMATS.DATETIME),
  },
  {
    label:  t('Amount'),
    name:   'amount',
    field:  'amount',
    format: amount => `${amount} ${GLOBAL_ACCOUNTS_CURRENCY}`,
  },
  {
    label:  t('Paid Period'),
    name:   'paidPeriod',
    field:  'paidPeriod',
    format: period => period + ' ' + t('days'),
  },
];

</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/navigation.yaml"></i18n>

<i18n lang="yaml">
ru:
  Account: Аккаунт
  Paid Period: Оплачено
en:
  Account: Account
  Paid Period: Paid Period
</i18n>
