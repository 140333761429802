<template>
  <GraphQLQueryTable
    ref="report"
    :graphql-query="query"
    :fields="tableFields"
    :available-filters="availableFilters"
    :fixed-filters="fixedFilters"
    :row-key="getRowKey"
    sticky-header
    class="col"
  >
    <template #body-cell-productBatchName="column">
      <BodyCellLink
        :column="column"
        :to="{ name: ROUTES.BATCHES_EDIT, params: { id: String(column.row.productBatchId) } }"
        no-wrap
      >
        {{ column.value }}
      </BodyCellLink>
    </template>

    <template #body-cell-productSku="column">
      <BodyCellLink
        :column="column"
        :to="{ name: ROUTES.PRODUCTS_EDIT, params: { id: column.row.productId } }"
        variant="link"
      />
    </template>

    <template #body-cell-cellName="column">
      <BodyCellLink
        v-if="column.row.cellId"
        :column="column"
        :to="{ name: ROUTES.CELLS_EDIT, params: { id: column.row.cellId } }"
        variant="link"
      />
      <QTd
        v-else
        :props="column"
      />
    </template>

    <template #body-cell-containerName="column">
      <BodyCellLink
        v-if="column.row.containerId"
        :column="column"
        :to="{ name: ROUTES.CONTAINERS_EDIT, params: { id: column.row.containerId } }"
        variant="link"
      />
      <QTd
        v-else
        :props="column"
      />
    </template>

    <template #body-cell-locks="column">
      <QTd>
        <QSkeleton v-if="column.loading" />
        <template v-else>
          <template
            v-for="lock in JSON.parse(column.row.locks)"
            :key="lock"
          >
            {{ lock.amount }} ({{ t('operationType.' + lock.operationType) }}
            <RouterLink
              :to="operationCardRoute({ __typename: lock.operationType, id: lock.operationId })"
            >
              {{ lock.operationId }}
              <QIcon
                name="mdi-open-in-new"
                color="grey"
                class="q-ml-xs"
              />
            </RouterLink>)
            <br>
          </template>
        </template>
      </QTd>
    </template>

    <template #after-search-string="{ options, refresh }">
      <slot
        name="after-search-string"
        v-bind="{ options, refresh }"
      />
    </template>

    <template #batch-actions="scope">
      <SimpleTableBatchActions :rows-count="scope.rows.length">
        <slot
          name="batch-actions"
          v-bind="scope"
        />

        <CreateLossButton
          :stocks="scope.rows.map((row: StockReportRow) => ({
            storageUnitId: row.storageUnitId!,
            storageId: row.storageId!,
          }))"
          @done="scope.refresh"
        />
      </SimpleTableBatchActions>
    </template>

    <template #top-row="scope">
      <slot
        name="top-row"
        v-bind="scope"
      />
    </template>
  </GraphQLQueryTable>
</template>

<script setup lang="ts">

import type BaseTable from '@/components/BaseTable.vue';
import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import getCellsAreasForReportFilter from '@/graphql/shorthands/getCellsAreasForReportFilter';
import {
  CellsAreaKindEnum,
  ContainerKindEnum,
  type Maybe,
  type Product,
  type ReportFilterInput,
  type Scalars,
  type StockReportRow,
  type Storage,
} from '@/graphql/types';
import isContainer from '@/helpers/isContainer';
import itemAmount from '@/helpers/itemAmount';
import operationCardRoute from '@/helpers/operationCardRoute';
import * as reports from '@/helpers/reports';
import { createBoolean, createPrimitive } from '@/helpers/reports';
import ROUTES from '@/router/routeNames';
import type { ReportFilter, TableColumn } from '@/types/reports';
import SimpleTableBatchActions from '@/views/SimpleTableBatchActions.vue';
import CreateLossButton from '@/views/Storage/CreateLossButton.vue';
import { gql } from '@urql/vue';
import { uniq } from 'ramda';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import getSuppliersForSelect from '@/graphql/shorthands/getSuppliersForSelect';

const { t } = useI18n();

const props = defineProps<{
  storage?: Storage;
  product?: Product;
}>();

const report = ref<InstanceType<typeof BaseTable>>(null);

// noinspection NestedConditionalExpressionJS
const tableFields: TableColumn<StockReportRow>[] = [
  ...(props.storage ? [] : [
    {
      label:    t('Cell ID'),
      name:     'cellId',
      field:    'cellId',
      align:    'left',
      sortable: true,
    },
    {
      label:         t('Cell Name'),
      name:          'cellName',
      field:         'cellName',
      requestFields: ['cellId', 'cellName'],
      align:         'left',
      sortable:      true,
    },
    {
      label: t('Container'),
      name:  'containerName',
      field: 'containerName',
      align: 'left',
    },
    {
      label:  t('Container Type'),
      name:   'containerKind',
      field:  'containerKind',
      format: kind => kind ? t(`containerKind.${kind}`) : '',
      align:  'left',
    },
  ] as TableColumn<StockReportRow>[]),
  ...(props.product ? [] : [
    {
      label:    t('SKU'),
      name:     'productSku',
      field:    'productSku',
      align:    'left',
      sortable: true,
    },
    {
      label: t('Name'),
      name:  'productName',
      field: 'productName',
      align: 'left',
    },
  ] as TableColumn<StockReportRow>[]),
  {
    label:         t('Amount'),
    name:          'amount',
    field:         itemAmount,
    summaryField:  props.product
      // Если отчет фильтруется по товару, базовая единица у всех строк будет одинаковой -
      // - можно ее вывести в итоговой строке.
      ? (row => itemAmount({ ...row, productPack: props.product!.mostBasicProductPack }))
      // Иначе выводим количество без единиц.
      : 'amount',
    requestFields: ['productPack', 'amount'],
    sortable:      true,
  },
  {
    label: t('Batch'),
    name:  'productBatchName',
    field: 'productBatchName',
    requestFields: ['productBatchId', 'productBatchName'],
    align: 'left',
  },
  {
    label: t('Lock'),
    name:  'locks',
    field: 'locks',
    align: 'left',
  },
  {
    label: t('Orders'),
    name:  'shipments',
    field: 'productPackId',
    format: (productPackId?: Maybe<Scalars['ID']>) => getShipmentsFromContainer(productPackId),
    align: 'left',
  },
  {
    label: t('Supplier'),
    name:  'supplierName',
    field: 'supplierName',
    align: 'left',
  },
];

const availableFilters: ReportFilter[] = [
  {
    field:     'productSku',
    label:     t('Product SKU'),
    operators: [
      createPrimitive('=', 'string'),
      createPrimitive('in', 'multiString', {
        label: t('reportFilterOperator.in'),
      }),
    ],
  },
  {
    field:     'expirationDate',
    label:     t('Expiration Date'),
    operators: [
      createPrimitive('>', 'date'),
      createPrimitive('<', 'date'),
      createBoolean('is not null', t('Filled')),
    ],
  },
  {
    field:     'productInventoryDate',
    label:     t('Product Inventory Date'),
    operators: [
      createPrimitive('>', 'date'),
      createPrimitive('<', 'date'),
      createBoolean('is null', t('Empty')),
      createBoolean('is not null', t('Not empty')),
    ],
  },
  ...(props.storage ? [] : [
    {
      field:     'cellInventoryDate',
      label:     t('Cell Inventory Date'),
      operators: [
        createPrimitive('>', 'date'),
        createPrimitive('<', 'date'),
        createBoolean('is null'),
        createBoolean('is not null'),
      ],
    },
    {
      field:     'cellsArea',
      label:     t('Cells Area'),
      operators: [
        reports.createList('=', getCellsAreasForReportFilter),
      ],
    },
    {
      field:     'cellsAreaKind',
      label:     t('Cells Area Kind'),
      operators: [
        reports.createList('in', reports.enumValues(
          CellsAreaKindEnum, kind =>  t('cellsAreaKind.' + kind),
        ), t('reportFilterOperator.in')),
      ],
    },
    {
      field:     'cellName',
      label:     t('Cell Name'),
      operators: [
        reports.createPrimitive('=', 'string'),
      ],
    },
    {
      field:     'containerName',
      label:     t('Container Name'),
      operators: [
        reports.createPrimitive('=', 'string'),
      ],
    },
    {
      field:     'storageType',
      label:     t('Storage Type'),
      operators: [
        ...(['=', '!='] as const).map(op =>
          reports.createList(op, () => Promise.resolve([
            {
              value: null,
              label: t('Cell'),
            },
            {
              value: ContainerKindEnum.PRODUCTS,
              label: t('Container with Products'),
            },
            {
              value: ContainerKindEnum.ORDERS,
              label: t('Container with Orders'),
            },
          ]), t(`reportFilterOperator.${op}`))),
      ],
    },
    {
      field:     'supplierName',
      label:     t('Supplier'),
      operators: [
        reports.createList('=', getSuppliersForSelect),
      ],
    },
  ]),
];

const fixedFilters = computed<ReportFilterInput[]>(() => [
  ...(props.storage ? [{
    field:    'cellId',
    operator: '=',
    value:    JSON.stringify(props.storage.id),
  }] : []),
  ...(props.product ? [{
    field:    'productSku',
    operator: '=',
    value:    JSON.stringify(props.product.sku),
  }] : []),
]);

function getShipmentsFromContainer(productPackId: Maybe<Scalars['ID']> | undefined): string {
  if (!productPackId || !props.storage || !isContainer(props.storage)) {
    return '';
  }

  return uniq(
    props.storage.activeSelections.flatMap(s => s.selectionOrders)
      .filter((so => so.container.id === props.storage!.id))
      .filter(so => so.items.some(i => i.storageUnit.productPack.id === productPackId))
      .map(so => so.shipment.externalId) || [],
  ).join(', ');
}

const query = gql`
  query GetStocks(
    $page: Int,
    $perPage: Int!,
    $query: String,
    $filter: [ReportFilterInput!],
    $sort: [ReportSortInput!]!,
  ) {
    result: stocksReport(
      page: $page,
      perPage: $perPage,
      query: $query,
      filter: $filter,
      sort: $sort,
    ) {
      data {
        cellId
        cellName
        containerId
        containerName
        containerKind
        productId
        productSku
        productName
        storageId
        storageUnitId
        productPackId
        productPack {
          id
          measurementUnit { id shortName }
          minMeasurementUnit { id shortName }
          smallerProductPack { id }
          quantityInMinMeasurementUnits
        }
        productBatchId
        productBatchName
        amount
        locks
        supplierName
      }
      total
      summary {
        amount
      }
    }
  }
`;

function getRowKey(row: StockReportRow): string {
  return `${row.storageId}-${row.storageUnitId}`;
}

</script>

<i18n lang="yaml">
ru:
  Cell ID: Код ячейки
  Container Name: Контейнер
  Cell Name: Наименование ячейки
  Expiration Date: Срок годности
  Cell Inventory Date: Дата инв.ячейки
  Product Inventory Date: Дата инв.товара
  Container Type: Тип контейнера
  Storage Type: Тип места хранения
  Container with Products: Контейнер с товарами
  Container with Orders: Контейнер с заказами
  Cells Area Kind: Тип зоны хранения

en:
  Cell ID: Cell ID
  Container Name: Container Name
  Cell Name: Cell Name
  Expiration Date: Expiration Date
  Cell Inventory Date: Cell Inventory Date
  Product Inventory Date: Product Inventory Date
  Container Type: Container Type
  Storage Type: Storage Type
  Container with Products: Container with Products
  Container with Orders: Container with Orders
  Cells Area Kind: Cells Area Kind
</i18n>
