<template>
  <div>{{ items[ 0 ] }}</div>
  <QSlideTransition :duration="100">
    <div v-if="expanded">
      <div
        v-for="i in items.slice(1)"
        :key="i"
      >
        {{ i }}
      </div>
    </div>
  </QSlideTransition>
  <div
    class="text-underline-dotted text-no-wrap"
    :class="{ invisible: items.length <= 1 }"
    @click="emit('update:expanded', !expanded)"
  >
    {{
      expanded
        ? t('hide')
        : t('{n} more', { n: items.length - 1 })
    }}
  </div>
</template>

<script setup lang="ts">

import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps<{
  items: string[];
  expanded: boolean;
}>();

const emit = defineEmits<{
  'update:expanded': [expanded: boolean];
}>();

</script>

<i18n lang="yaml">
ru:
  "{n} more": "еще {n}"
  hide: скрыть

en:
  "{n} more": "{n} more"
  hide: hide
</i18n>
