<template>
  <QDialog
    v-model="isOpen"
    :persistent="fetching"
  >
    <QCard>
      <PrimaryErrorBanner />
      <QCardSection>
        <QInput
          v-model="containerInput"
          :label="t('Container')"
          :hint="t('Enter Container barcode')"
        />
      </QCardSection>
      <QCardActions>
        <QBtn
          :label="t('Cancel')"
          :disable="fetching"
          @click="isOpen = false"
        />
        <QBtn
          :label="t('Save')"
          color="primary"
          icon="mdi-content-save"
          :loading="fetching"
          :disable="!containerInput"
          @click="changeContainer"
        />
      </QCardActions>
    </QCard>
  </QDialog>
  <slot @open="isOpen = true" />
</template>

<script setup lang="ts">

import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { gql, useMutation } from '@urql/vue';
import type { Container, MutationChangeSelectionContainerArgs, Selection } from '@/graphql/types';
import useErrorHandling from '@/composables/useErrorHandling';
import { useQuasar } from 'quasar';

const { t } = useI18n();

// noinspection LocalVariableNamingConventionJS
const { fillErrorsFromGraphQLError, PrimaryErrorBanner } = useErrorHandling();

const q = useQuasar();

const props = defineProps<{
  selection: Selection;
}>();

const isOpen = ref(false);

const containerInput = ref('');

const {
  executeMutation: doChangeContainer,
  fetching,
} = useMutation<{ container: Container }, MutationChangeSelectionContainerArgs>(gql`
  mutation ChangeContainer($selectionId: ID!, $container: String!) {
    container: changeSelectionContainer(selectionId: $selectionId, container: $container) {
      id name
    }
  }
`);

async function changeContainer() {
  const { data, error } = await doChangeContainer({
    selectionId: props.selection.id,
    container: containerInput.value,
  });

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  q.notify({
    message: t('Container changed to {container}', { container: data!.container.name }),
    type:    'positive',
  });

  containerInput.value = '';
  isOpen.value = false;
}

</script>

<i18n lang="yaml">
ru:
  Enter Container barcode: Введите штрихкод контейнера
  Container changed to {container}: Контейнер изменен на {container}

en:
  Enter Container barcode: Enter Container barcode
  Container changed to {container}: Container changed to {container}
</i18n>
