<template>
  <QTabs v-model="listTab">
    <QTab name="pending">
      {{ t('Pending') }}
    </QTab>
    <QTab name="completed">
      {{ t('Completed') }}
    </QTab>
  </QTabs>
  <QTabPanels
    v-model="listTab"
    class="full-height"
  >
    <QTabPanel
      name="pending"
      class="q-pa-none column"
    >
      <slot name="pending" />
    </QTabPanel>
    <QTabPanel
      name="completed"
      class="q-pa-none column"
    >
      <slot name="completed" />
    </QTabPanel>
  </QTabPanels>
</template>
<script setup lang="ts">

import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useLocalStorage } from '@vueuse/core';

const { t } = useI18n();

const props = defineProps<{
  storageKey?: string;
}>();

const listTab = props.storageKey
  ? useLocalStorage(props.storageKey, 'pending')
  : ref('pending');

</script>
