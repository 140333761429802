<template>
  <QCardSection>
    <div class="row q-col-gutter-md">
      <div
        v-if="product.accountingModel.batchNumberRequired"
        class="col-sm-4"
      >
        <QInput
          v-model="v.number.$model"
          :label="t('Number')"
          :placeholder="t('Not specified')"
          :error="v.number.$error"
          :error-message="v.number.$errors[0]?.$message"
        />
      </div>
      <div
        v-if="product.accountingModel.batchProductionDateRequired"
        class="col-sm-4"
      >
        <DatePicker
          v-model="v.productionDate.$model"
          :label="t('Production Date')"
          :placeholder="t('Not specified')"
          :error="v.productionDate.$error"
          :error-message="v.productionDate.$errors[0]?.$message"
        />
      </div>
      <div
        v-if="product.accountingModel.batchExpirationDateRequired"
        class="col-sm-4"
      >
        <DatePicker
          v-model="v.expirationDate.$model"
          :label="t('Expiration Date')"
          :placeholder="t('Not specified')"
          :error="v.expirationDate.$error"
          :error-message="v.expirationDate.$errors[0]?.$message"
        />
      </div>
    </div>
  </QCardSection>

  <QSeparator />

  <QCardActions>
    <QBtn
      icon="mdi-arrow-left"
      @click="emit('back')"
    >
      {{ t('Back') }}
    </QBtn>

    <QSpace />

    <QBtn
      color="success"
      icon="mdi-content-save"
      :loading="saving"
      :disable="strictValidation && v.$invalid"
      @click="emit('save', editingBatch)"
    >
      {{ t('Save') }}
    </QBtn>
  </QCardActions>
</template>

<script setup lang="ts">

import DatePicker from '@/components/DatePicker.vue';
import type { InventoryBatchDataInput, Product, } from '@/graphql/types';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import useBatchDataForm from '@/composables/useBatchDataForm';

const { t } = useI18n();

const props = defineProps<{
  batch: InventoryBatchDataInput;
  product: Product;
  strictValidation?: boolean;
  saving?: boolean;
}>();

const emit = defineEmits<{
  (e: 'back'): void;
  (e: 'save', batch: InventoryBatchDataInput): void;
}>();

const { vuelidate: v, data: editingBatch } = useBatchDataForm(
  computed(() => props.batch),
  computed(() => props.product),
  computed(() => props.product.accountingModel),
);

</script>

<i18n lang="yaml">
ru:
  Production Date: Дата производства
  Expiration Date: Дата истечения срока годности
  Batch not found: Партия не найдена
en:
  Production Date: Production Date
  Expiration Date: Expiration Date
  Batch not found: Batch not found
</i18n>
