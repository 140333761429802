<template>
  <QItem
    class="no-scroll list-item-default-height"
  >
    <QItemSection
      v-if="!hideEmptyPhoto || product.photos.length > 0"
      avatar
    >
      <slot
        v-if="'photo' in slots"
        name="photo"
      />
      <QAvatar v-else>
        <ProductPhoto
          :product="product"
          @click="emit('photo-click')"
        >
          <template v-if="'no-photo' in slots">
            <slot name="no-photo" />
          </template>
        </ProductPhoto>
      </QAvatar>
    </QItemSection>
    <QItemSection
      top
      class="dense-labels no-wrap"
    >
      <QItemLabel
        caption
        class="row no-wrap items-center"
      >
        <slot name="top-left">
          <span class="text-no-wrap ellipsis">
            {{ product.sku }}
          </span>
        </slot>

        <template v-if="'top-right' in slots">
          <QSpace />

          <slot name="top-right" />
        </template>
      </QItemLabel>
      <QItemLabel v-clamp="2">
        <slot name="middle">
          {{ product.name }}
        </slot>
      </QItemLabel>

      <slot name="bottom" />
    </QItemSection>
  </QItem>
</template>

<script setup lang="ts">

import ProductPhoto from '@/components/ProductPhoto.vue';
import type { Product } from '@/graphql/types';
import { useSlots } from 'vue';
import vClamp from '@/directives/clamp';

withDefaults(defineProps<{
  product: Product;
  hideEmptyPhoto?: boolean;
}>(), {
  hideEmptyPhoto: false,
});

const emit = defineEmits<{
  (e: 'photo-click'): void;
}>();

const slots = useSlots();

</script>
