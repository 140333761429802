<template>
  <QBtn
    flat
    round
    icon="mdi-apps"
    :title="t('Apps')"
  >
    <QMenu
      auto-close
      style="min-width: 280px"
    >
      <QList>
        <QItem v-if="fetching && !moyskladIntegrationData">
          <QItemSection avatar>
            <QSkeleton
              type="QAvatar"
              size="40px"
            />
          </QItemSection>
          <QItemSection>
            <QItemLabel>
              <QSkeleton type="text" />
            </QItemLabel>
            <QItemLabel caption>
              <QSkeleton type="text" />
            </QItemLabel>
          </QItemSection>
          <QItemSection side>
            <QIcon
              size="32px"
              name="mdi-toggle-switch-off-outline"
              color="grey-4"
            />
          </QItemSection>
          <QItemSection side>
            <QIcon
              size="32px"
              name="mdi-sync"
              color="grey-4"
            />
          </QItemSection>
        </QItem>
        <QItem
          v-if="moyskladIntegrationData"
          :to="canConfigure ? { name: ROUTES.INTEGRATION_EDIT_MOYSKLAD } : undefined"
          :style="{ opacity: moyskladIntegrationData?.isEnabled ? undefined : '30%' }"
        >
          <QItemSection avatar>
            <QImg :src="moyskladLogo" />
          </QItemSection>
          <QItemSection>
            <QItemLabel>
              {{ t('MoySklad') }}
            </QItemLabel>
            <QItemLabel
              v-if="canConfigure"
              caption
            >
              {{ t('Configure') }}
            </QItemLabel>
          </QItemSection>
          <QItemSection avatar>
            <QIcon
              size="32px"
              :name="moyskladIntegrationData?.isEnabled ? 'mdi-toggle-switch-outline' : 'mdi-toggle-switch-off-outline'"
              color="primary"
            />
          </QItemSection>
          <QItemSection
            v-if="moyskladIntegrationData?.firstSync"
            avatar
          >
            <QAvatar>
              <QBtn
                v-if="(primaryError || syncProgress.error) && !syncProgress.syncing"
                flat
                round
                dense
                icon="mdi-sync-alert"
                color="negative"
                :disable="!integrationIsConfigured"
                @click.prevent.stop="startSync"
              >
                <QTooltip>
                  {{ t('Sync error') }}: {{ primaryError || syncProgress.error }}
                  <br>
                  {{ t('Last Sync') }}: {{ moyskladIntegrationData?.lastSync ? formatRelativeDateTime(moyskladIntegrationData.lastSync) : t('Never') }}
                </QTooltip>
              </QBtn>
              <QBtn
                v-else
                flat
                round
                icon="mdi-sync"
                color="positive"
                :loading="initializing || startingSync || fetching"
                :disable="syncProgress.syncing || !integrationIsConfigured"
                :class="{ 'syncing-icon': syncProgress.syncing }"
                @click.prevent.stop="startSync"
              >
                <QTooltip v-if="syncProgress.syncing">
                  {{ t('Syncing') }}
                </QTooltip>
                <QTooltip v-else>
                  {{ t('Last Sync') }}: {{ moyskladIntegrationData?.lastSync ? formatRelativeDateTime(moyskladIntegrationData.lastSync) : t('Never') }}
                </QTooltip>
              </QBtn>
            </QAvatar>
          </QItemSection>
        </QItem>
      </QList>
    </QMenu>
  </QBtn>
</template>

<script setup lang="ts">

import moyskladLogo from '@/assets/moysklad.svg';
import useBackendProgress from '@/composables/useBackendProgress';
import useCan from '@/composables/useCan';
import useErrorHandling from '@/composables/useErrorHandling';
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import type { Warehouse } from '@/graphql/types';
import ROUTES from '@/router/routeNames';
import { Ability } from '@/types/backend';
import { gql, useMutation, useQuery } from '@urql/vue';
import { useEventBus } from '@vueuse/core';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { formatRelativeDateTime } = useLocalizedFormatters();

const { fillErrorsFromGraphQLError, clearErrors, primaryError } = useErrorHandling();

const canConfigure = useCan(Ability.EditIntegrationSettings);

const { data, fetching, executeQuery } = useQuery<{ warehouse: Warehouse }>({
  query: gql`
    query GetWarehouseForIntegrationsData {
      warehouse {
        id
        moyskladIntegration {
          isEnabled
          accessToken
          storeId
          organizationId
          firstSync
          lastSync
        }
      }
    }
  `
});

const moyskladIntegrationData = computed(() => data.value?.warehouse.moyskladIntegration ?? null);

const { on: onRefreshIntegrations } = useEventBus('refresh-integrations');

onRefreshIntegrations(() => executeQuery());

const {
  data: syncProgress,
  initializing,
} = useBackendProgress('syncMoySklad', {
  syncing: false,
  error: null as string | null,
});

watch(() => syncProgress.syncing, syncing => {
  if (!syncing) {
    executeQuery();
  }
});

async function startSync() {
  clearErrors();
  await executeMutation();
}

const {
  executeMutation,
  fetching: startingSync,
  error,
} = useMutation(
  gql`
    mutation MoySkladSync {
      moySkladSync
    }
  `,
);

watch(error, fillErrorsFromGraphQLError);

const integrationIsConfigured = computed(() => moyskladIntegrationData.value?.isEnabled && moyskladIntegrationData.value.accessToken);

</script>

<i18n lang="yaml">
ru:
  Apps: Приложения
  No Apps: Нет подключенных приложений
  Configure: Настроить
  MoySklad: МойСклад
  Sync error: Ошибка синхронизации
  Syncing: Синхронизация
  Last Sync: Последняя синхронизация
  Never: Никогда
en:
  Apps: Apps
  No Apps: No Apps
  Configure: Configure
  MoySklad: MoySklad
  Sync error: Sync error
  Syncing: Syncing
  Last Sync: Last Sync
  Never: Never
</i18n>

<style lang="scss" scoped>

.syncing-icon {
  animation: slow-spin 4s linear infinite;
}

@keyframes slow-spin {
  100% {
    transform: rotate(-360deg);
  }
}

</style>
