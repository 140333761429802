<template>
  <FullHeightPage>
    <GraphQLReportList
      :graphql-query="query"
      :available-filters="availableFilters"
      :fixed-filters="fixedFilters"
      with-search
      :storage-prefix="storagePrefix"
      class="col"
      :no-data-label="t('No Orders')"
      :no-more-data-label="t('No More Orders')"
      blurred-search
    >
      <template #item="{ item }: { item: Shipment }">
        <ShipmentListItem
          :order="item"
          :to="shipmentCardRoute(item)"
        />
      </template>
    </GraphQLReportList>
  </FullHeightPage>
</template>

<script setup lang="ts">

import FullHeightPage from '@/components/FullHeightPage.vue';
import getCarriersForSelect from '@/graphql/shorthands/getCarriersForSelect';
import shipmentCardRoute from '@/helpers/shipmentCardRoute';
import { useI18n } from 'vue-i18n';
import { gql } from '@urql/vue';
import type { ReportFilter } from '@/types/reports';
import * as reports from '@/helpers/reports';
import {
  createDatesRangeOperator,
  createList,
  createPrimitive,
  enumValues,
} from '@/helpers/reports';
import type { ReportFilterInput, Shipment } from '@/graphql/types';
import { ShipmentStateEnum } from '@/graphql/types';
import GraphQLReportList from '@/components/Mobile/GraphQLReportList.vue';
import ShipmentListItem from '@/components/ShipmentListItem.vue';

const { t } = useI18n();

const props = withDefaults(defineProps<{
  extraFilters?: ReportFilter[];
  fixedFilters: ReportFilterInput[];
  storagePrefix: string;
}>(), {
  extraFilters: () => [],
});


const query = gql`
  query GetShipmentsMobile(
    $first: Int!,
    $after: Cursor,
    $query: String,
    $filter: [ReportFilterInput!],
  ) {
    result: shipmentsList(first: $first, after: $after, query: $query, filter: $filter) {
      edges {
        cursor
        node {
          id
          externalId
          state
          createdAt
          plannedShipmentDate
          counterparty { id name }
          carrier { id name }
          items {
            id
            amount
            productPack {
              id
              product { id }
              weight
              dimensions { length height width }
            }
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const availableFilters: ReportFilter[] = [
  {
    field:                 'createdAt',
    label:                 t('Created at'),
    hideLabelWhenSelected: true,
    operators:             [
      createDatesRangeOperator(),
    ],
  },
  ...props.extraFilters,
  {
    field:                 'state',
    label:                 t('State'),
    hideLabelWhenSelected: true,
    operators:             [
      reports.createList('in', enumValues(
        ShipmentStateEnum,
        v => t(`shipmentState.${v}`),
      )),
    ],
  },
  {
    field:                 'product',
    label:                 t('SKU'),
    hideLabelWhenSelected: true,
    operators:             [
      createPrimitive('=', 'string'),
      createPrimitive('in', 'multiString', {
        label: t('reportFilterOperator.in'),
      }),
    ],
  },
  {
    field:                 'carrier',
    label:                 t('Carrier Name'),
    hideLabelWhenSelected: true,
    operators:             [
      createList('in', getCarriersForSelect),
    ],
  },
];
</script>

<i18n lang="yaml">
ru:
  No Orders: Заказы отсутствуют
  No More Orders: Заказов больше нет

en:
  No Orders: No Orders
  No More Orders: No More Orders

</i18n>
