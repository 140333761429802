<template>
  <QList separator>
    <QItem v-if="fetchingStocks">
      <QItemSection>
        <QItemLabel>
          <QSkeleton type="text" />
        </QItemLabel>
        <QItemLabel caption>
          <QSkeleton
            type="text"
            width="50%"
          />
        </QItemLabel>
        <QItemLabel caption>
          <QSkeleton
            type="text"
            width="70%"
          />
        </QItemLabel>
      </QItemSection>
    </QItem>
    <QItem
      v-for="stock in stocks"
      v-else
      :key="stockItemKey(stock)"
      class="list-item-default-height"
    >
      <QItemSection class="dense-labels">
        <QItemLabel>{{ stock.storage.name }}</QItemLabel>
        <StockAmountLabel :stock="stock" />
        <QItemLabel v-if="stock.storageUnit.batch && !product.accountingModel.isDefault">
          {{ stock.storageUnit.batch.name }}
        </QItemLabel>
      </QItemSection>
      <QItemSection
        v-if="stock.storage.cellsArea"
        side
        top
      >
        <QItemLabel>
          <QChip
            dense
            class="q-ml-none"
            :class="forCellAreaKind(stock.storage.cellsArea.kind)"
          >
            {{ stock.storage.cellsArea.name }}
          </QChip>
        </QItemLabel>
      </QItemSection>
    </QItem>
  </QList>
</template>

<script setup lang="ts">

import type {
  Product,
  QueryProductStocksArgs,
  Stock,
} from '@/graphql/types';
import { forCellAreaKind } from '@/helpers/badgeColors';
import { gql, useQuery } from '@urql/vue';
import { QItemLabel } from 'quasar';
import type { Component } from 'vue';
import { computed, h, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  product: Product;
}>();

const emit = defineEmits<{
  (e: 'update:totalStock', stock: number): void;
}>();

const {
  data: stocksData,
  fetching: fetchingStocks,
} = useQuery<{ stocks: Stock[] }, QueryProductStocksArgs>({
  query:     gql`
    query GetProductStocksMobile($productId: ID!) {
      stocks: productStocks(productId: $productId) {
        storage {
          id
          name
          cellsArea { id name kind }
        }
        storageUnit {
          id
          batch { id name }
          productPack {
            id
            measurementUnit {
              id
              shortName
            }
            minMeasurementUnit {
              id
              shortName
            }
            quantityInMinMeasurementUnits
          }
        }
        amount
        lockedAmount
      }
    }
  `,
  variables: computed(() => ({
    productId: props.product.id,
  })),
  pause:     computed(() => props.product.deletedAt !== null),
});
const stocks = computed<Stock[]>(() => stocksData.value?.stocks ?? []);

watch(stocks, stocks => {
  emit('update:totalStock', stocks.reduce((acc, s) =>
    acc + s.amount * s.storageUnit.productPack.quantityInMinMeasurementUnits, 0));
});

function stockItemKey(s: Stock) {
  return s.storage.id + s.storageUnit.id;
}

const { t } = useI18n();

// noinspection LocalVariableNamingConventionJS
const StockAmountLabel: Component = ({ stock }: { stock: Stock }) => {
  const pack = stock.storageUnit.productPack;
  const {
    minMeasurementUnit: minUnit,
    measurementUnit: unit,
    quantityInMinMeasurementUnits: quantityInMinUnits,
  } = pack;

  return h(QItemLabel, () => [
    stock.amount, ' ', unit.shortName,
    ...(minUnit.shortName === unit.shortName
      ? []
      : [' (', stock.amount * quantityInMinUnits, ' ', minUnit.shortName, ')']),
    ...(stock.lockedAmount
      ? [' (', t('lock {amount}', { amount: stock.lockedAmount }) , ')']
      : []),
  ]);
};

</script>

<i18n lang="yaml">
ru:
  lock {amount}: блок {amount}
en:
  lock {amount}: lock {amount}
</i18n>
