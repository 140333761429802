<template>
  <QSelect
    v-model="editableLocale"
    dense
    :options="i18n.global.availableLocales"
    :option-label="t"
  />
</template>

<script setup lang="ts">

import useGlobalCookies from '@/composables/useGlobalCookies';
import i18n from '@/plugins/i18n';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

const { t, locale } = useI18n();

const cookies = useGlobalCookies();

const editableLocale = computed({
  get: (): string => {
    return locale.value;
  },
  set: (newLocale: string) => {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 100);
    cookies.set('locale', newLocale, { expires, path: '/' });

    // Не переключаем локаль "на лету" с помощью `i18n.locale = newLocale`,
    // так как некоторые строки останутся в старой локали:
    //   - Даты,
    //   - Строки, полученные с сервера.
    window.location.reload();
  }
});

</script>

<i18n lang="yaml">
en:
  en: English
  ru: Русский
ru:
  en: English
  ru: Русский
</i18n>
