<template>
  <ArrivalDetailsMobile v-if="preferences.terminalMode" />
  <ArrivalEdit v-else />
</template>

<script setup lang="ts">

import useBreadcrumbs from '@/composables/useBreadcrumbs';
import { useI18n } from 'vue-i18n';
import ArrivalEdit from '@/views/Arrival/ArrivalEdit.vue';
import ArrivalDetailsMobile from '@/views/Arrival/ArrivalDetailsMobile.vue';
import { useAttrs } from 'vue';
import useLocalPreferences from '@/composables/useLocalPreferences';

const { t } = useI18n();

const preferences = useLocalPreferences();

useBreadcrumbs(t('Arrival #{id}', { id: useAttrs().id }));

</script>

<i18n lang="yaml">
ru:
  "Arrival #{id}": Приёмка №{id}

en:
  "Arrival #{id}": "Arrival #{id}"

</i18n>
