<template>
  <span :class="batchColorClass">
    {{ batchName }}
    <QIcon
      size="14px"
      name="mdi-pencil"
    />
  </span>
</template>

<script setup lang="ts">

import type { AccountingModel } from '@/graphql/types';
import { computed } from 'vue';

const props = defineProps<{
  batchName: string;
  accountingModel: AccountingModel;
}>();

const batchColorClass = computed(() => {
  if (props.accountingModel.byShelfLife) {
    return 'bg-green-2 text-black';
  }

  if (props.accountingModel.byBatch) {
    return 'bg-green-2 text-black';
  }

  return '';
});

</script>
