<template>
  <slot
    name="activator"
    :prompt="start"
  />
  <QDialog v-model="confirming">
    <QCard>
      <CardTitle>
        <slot name="title">
          {{ t('Confirm Action') }}
        </slot>
      </CardTitle>

      <QSeparator />

      <slot />

      <QSeparator />

      <QCardActions>
        <QSpace />

        <QBtn @click="cancel()">
          {{ cancelLabel }}
        </QBtn>
        <QBtn
          color="primary"
          icon="mdi-check"
          :disable="disabledConfirm"
          @click="confirm()"
        >
          {{ confirmLabel }}
        </QBtn>
      </QCardActions>
    </QCard>
  </QDialog>
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = withDefaults(defineProps<{
  confirmText?: string;
  cancelText?: string;
  shouldPrompt?: boolean;
  disabledConfirm?: boolean;
  showImmediately?: boolean;
}>(), {
  shouldPrompt: true,
});

const confirmLabel = computed(() => props.confirmText ?? t('Confirm'));
const cancelLabel = computed(() => props.cancelText ?? t('Cancel'));

const emit = defineEmits<{
  (e: 'confirmed'): void;
  (e: 'canceled'): void;
}>();

const confirming = ref(false);

function start(): void {
  if (props.shouldPrompt) {
    confirming.value = true;
  } else {
    confirm();
  }
}

onMounted(function mounted(): void {
  if (props.showImmediately) {
    start();
  }
});

watch(confirming, function(): void {
  if (!confirming.value) {
    cancel();
  }
});

function cancel(): void {
  emit('canceled');
  confirming.value = false;
}

function confirm(): void {
  emit('confirmed');
  confirming.value = false;
}

defineSlots<{
  activator(props: { prompt: typeof start }): unknown;
  title(): unknown;
  default(): unknown;
}>();

</script>

<i18n lang="yaml">
ru:
  Confirm Action: Подтвердите действие
en:
  Confirm Action: Confirm Action
</i18n>
