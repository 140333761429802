<template>
  <slot
    v-if="!url && ('no-photo' in slots)"
    name="no-photo"
  />
  <QImg
    v-else-if="showPhoto"
    :src="url || placeholderUrl"
  />
</template>

<script setup lang="ts">

import placeholderUrl from '@/assets/photo-placeholder.png';
import type { Product } from '@/graphql/types';
import { computed, useSlots } from 'vue';

const props = defineProps<{
  product: Product;
  hideWhenNoPhoto?: boolean;
}>();

const slots = useSlots();

const url = computed(() => props.product.photos[0]?.url);

const showPhoto = computed(() => url.value || !props.hideWhenNoPhoto);

</script>
