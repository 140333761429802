<template>
  <QBtn
    flat
    round
    icon="mdi-view-column"
    :title="t('Columns Settings')"
    @click="dialogOpen = true"
  />
  <QDialog
    v-model="dialogOpen"
  >
    <QCard
      style="max-width: 1200px;"
      class="column no-wrap"
    >
      <div class="row">
        <div class="col">
          <CardTitle>
            {{ t('Columns Settings') }}
          </CardTitle>
        </div>

        <QSeparator vertical />

        <div class="col-6 col-sm-4">
          <CardTitle>
            {{ t('Selected Columns') }}
            <template #subtitle>
              {{ t('Drag to Reorder') }}
            </template>
          </CardTitle>
        </div>
      </div>

      <QSeparator />

      <div class="col row no-wrap">
        <div class="col">
          <QCardSection>
            <div class="row">
              <div
                v-for="header in headers"
                :key="header.name"
                class="col-12 col-sm-6 col-md-4"
              >
                <QCheckbox
                  v-model="currentHeaders"
                  :val="header.name"
                  :label="header.label"
                />
              </div>
            </div>
          </QCardSection>
        </div>

        <QSeparator vertical />

        <div class="col-6 col-sm-4 scroll-y">
          <QList>
            <Draggable
              v-model="currentHeaders"
              :item-key="h => h"
              animation="200"
              handle=".handle"
              @start="dragging = true"
              @end="dragging = false"
            >
              <template #item="{ element: header }">
                <QItem class="">
                  <QItemSection
                    side
                    class="handle"
                  >
                    <QIcon name="mdi-drag-horizontal-variant" />
                  </QItemSection>
                  <QItemSection>
                    {{ fieldTitle(header) }}
                  </QItemSection>
                  <QItemSection side>
                    <QBtn
                      flat
                      round
                      icon="mdi-close-circle"
                      @click="hideHeader(header)"
                    />
                  </QItemSection>
                </QItem>
              </template>
            </Draggable>
          </QList>
        </div>
      </div>

      <QSeparator />

      <QCardActions>
        <QBtn
          :disabled="currentHeadersAreEmpty"
          color="primary"
          @click="apply()"
        >
          {{ t('Apply') }}
        </QBtn>
        <QBtn
          flat
          @click="cancel()"
        >
          {{ t('Cancel') }}
        </QBtn>
      </QCardActions>
    </QCard>
  </QDialog>
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import spliceByValue from '@/helpers/spliceByValue';
import type { DependentFieldsMap, ReportFieldName } from '@/types/reports';
import type { QTableColumn } from 'quasar';
import * as R from 'ramda';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Draggable from 'vuedraggable';

const { t } = useI18n();

type TReportItem = object;

const props = defineProps<{
  headers: QTableColumn<TReportItem>[];
  selectedHeaders: ReportFieldName<TReportItem>[];
  dependentHeaders?: DependentFieldsMap<TReportItem>;
}>();

const emit = defineEmits<{
  (e: 'update:selected-headers', headers: ReportFieldName<TReportItem>[]): void;
}>();

const dragging = ref(false);

const dialogOpen = ref(false);

const currentHeaders = ref<ReportFieldName<TReportItem>[]>([]);

const currentHeadersAreEmpty = computed(() => {
  return currentHeaders.value.length === 0;
});

function fieldTitle(header: string): string {
  return props.headers.find(h => h.name === header)?.label || header;
}

function hideHeader(headerToHide: ReportFieldName<TReportItem>): void {
  spliceByValue(currentHeaders.value, headerToHide);
}

watch(currentHeaders, function currentHeadersChanged(newHeaders, oldHeaders): void {
  if (!props.dependentHeaders) {
    return;
  }

  const addedHeaders = R.difference(newHeaders, oldHeaders);
  const removedHeaders = R.difference(oldHeaders, newHeaders);

  for (const added of addedHeaders) {
    const dep = props.dependentHeaders![added] as ReportFieldName<TReportItem> | undefined;
    if (dep && !currentHeaders.value.includes(dep)) {
      currentHeaders.value.push(dep);
    }
  }

  for (const removed of removedHeaders) {
    for (const current of currentHeaders.value) {
      if (props.dependentHeaders![current] === removed) {
        hideHeader(current);
      }
    }
  }
}, { deep: true });

watch(dialogOpen, function dialogOpenChanged(nowOpen): void {
  if (nowOpen) {
    currentHeaders.value = [...props.selectedHeaders];
  }
});

function apply(): void {
  dialogOpen.value = false;
  emit('update:selected-headers', currentHeaders.value);
}

function cancel(): void {
  dialogOpen.value = false;
}

</script>

<style scoped lang="scss">

.handle {
  cursor: grab;
}

</style>

<i18n lang="yaml">
ru:
  Columns Settings: Изменение столбцов
  Selected Columns: Мои столбцы
  Drag to Reorder: Перетащите, чтобы изменить порядок
en:
  Columns Settings: Columns Settings
  Selected Columns: Selected Columns
  Drag to Reorder: Drag to Reorder
</i18n>
