<template>
  <GraphQLQueryScanField
    :query="query"
    :placeholder="t('Search')"
    :hint="t('Products, Cells, Containers')"
    :not-found-message="t('Nothing found')"
    @scan="handleScan"
  />
</template>

<script setup lang="ts">

import GraphQLQueryScanField from '@/components/Mobile/GraphQLQueryScanField.vue';
import type { Cell, Container, Product } from '@/graphql/types';
import { gql } from '@urql/vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const router = useRouter();

const query = gql`
  query GlobalSearch($barcode: String!) {
    globalBarcodeSearch(barcode: $barcode) {
      ...on Cell { id }
      ...on Container { id }
      ...on Product { id }
    }
  }
`;

function handleScan(entity: Cell | Container | Product) {
  switch (entity.__typename) {
    case 'Cell':
      return router.push({ name: ROUTES.CELLS_EDIT, params: { id: entity.id } });
    case 'Container':
      return router.push({ name: ROUTES.CONTAINERS_EDIT, params: { id: entity.id } });
    case 'Product':
      return router.push({ name: ROUTES.PRODUCTS_EDIT, params: { id: entity.id } });
  }
}

</script>

<i18n lang="yaml">
ru:
  Products, Cells, Containers: Товары, ячейки, контейнеры
  Nothing found: Ничего не найдено
en:
  Products, Cells, Containers: Products, Cells, Containers
  Nothing found: Nothing found
</i18n>
