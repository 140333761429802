<template>
  <slot
    name="activator"
    @click="isCreateNewContainer = true"
  />
  <MaximizedDialog
    :model-value="isCreateNewContainer"
    :title="t('Container Creating')"
    transition-show="slide-up"
    transition-hide="slide-down"
    @close="isCreateNewContainer = false"
  >
    <QCardSection>
      <QInput
        ref="barcodeInput"
        v-model="barcode"
        :label="t('Barcode')"
        :placeholder="t('Barcode')"
        :rules="[notEmptyRule]"
        :loading="barcodeGenerating"
        @focus="$event.target.select()"
      />

      <QInput
        ref="nameInput"
        v-model="name"
        :label="t('Name')"
        :placeholder="t('Name')"
        :loading="nameGenerating"
        :rules="[notEmptyRule]"
      />

      <EditWeight
        v-model="capacity"
        :label="t('Capacity, kg of Volume Weight')"
        :placeholder="t('Capacity')"
        :suffix="t('units.kg')"
        :rules="[positiveOrNullRule, lteRule(1000000)]"
        type="number"
        min="0"
        step="1"
        clearable
        v-bind="qErrorsFor('capacity')"
      />

      <PrintersSelect
        hide-hardware-settings-link
        @select="selectedPrinter = $event"
      />

      <QSelect
        v-if="showPaperLayoutSelection"
        v-model="selectedPrinterPaperLayout"
        :display-value="paperLayoutAsString(selectedPrinterPaperLayout)"
        :options="paperLayouts"
        :label="t('Paper Format')"
        emit-value
      />
    </QCardSection>

    <template #bottom>
      <ButtonsRow v-slot="{ buttonProps }">
        <QBtn
          v-bind="buttonProps"
          icon="mdi-content-save"
          :loading="hasProgress('creating container') || hasProgress('printing')"
          :disable="barcodeGenerating || nameGenerating"
          @click="createContainer()"
        >
          {{ t('Save') }}
        </QBtn>
      </ButtonsRow>
    </template>
  </MaximizedDialog>
</template>

<script setup lang="ts">

import EditWeight from '@/components/EditWeight.vue';
import MaximizedDialog from '@/components/MaximizedDialog.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import PrintersSelect from '@/components/PrintersSelect.vue';
import useDocumentsPrinting from '@/composables/useDocumentsPrinting';
import useProgressHandling from '@/composables/useProgressHandling';
import useValidationRules from '@/composables/useValidationRules';
import { PrintBarcodeTypeEnum, PrinterPaperFormatEnum } from '@/graphql/types';
import type { Container, MutationCreateContainerArgs } from '@/graphql/types';
import useDocumentsPrintingState from '@/views/useDocumentsPrintingState';
import { gql, useClientHandle, useQuery } from '@urql/vue';
import { onStartTyping, useFocus, useLocalStorage } from '@vueuse/core';
import type { QInput as QInputType } from 'quasar';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import useErrorHandling from '@/composables/useErrorHandling';

const { t } = useI18n();

withDefaults(defineProps<{
  hideCancel?: boolean;
}>(), {
  hideCancel: false,
});

const ls = useLocalStorage('createContainer', {
  capacity: null as number | null,
});

const { client: urql } = useClientHandle();

const { progressStarted, hasProgress } = useProgressHandling<'creating container' | 'printing'>();

const { fillErrorsFromGraphQLError, qErrorsFor } = useErrorHandling();

const {
  paperLayoutAsString,
  paperLayouts,
  selectedPrinter,
  printDisabled,
  printNow,
  enqueuePrint,
  selectedPrinterPaperLayout,
  showPaperLayoutSelection,
  loadPrinters,
} = useDocumentsPrinting();

onMounted(loadPrinters);

const { positiveOrNullRule, notEmptyRule, lteRule } = useValidationRules();

const emit = defineEmits<{
  (e: 'create', container: Container): void;
  (e: 'close'): void;
}>();

const barcodeInput = ref<InstanceType<typeof QInputType>>();

const { focused } = useFocus(barcodeInput);

onStartTyping(() => {
  if (!focused.value) {
    focused.value = true;
  }
});

const isCreateNewContainer = ref(false);

const capacity = ref<number | null>(null);

const isClosed = computed(() => !isCreateNewContainer.value);

const {
  data:     barcodeResult,
  fetching: barcodeGenerating,
} = useQuery<{ barcode: string }>({
  query: gql`
    query GenerateStorageBarcode {
      barcode: generateStorageStringBarcode
    }
  `,
  pause: isClosed,
});
watch(barcodeResult, data => {
  barcode.value = data!.barcode;
});
const barcode = ref('');

const {
  data:     nameResult,
  fetching: nameGenerating,
} = useQuery<{ name: string }>({
  query: gql`
    query GenerateContainerName {
      name: generateContainerName
    }
  `,
  pause: isClosed,
});
watch(nameResult, data => {
  name.value = data!.name;
});
const name = ref('');

watch(isCreateNewContainer, () => {
  barcode.value = '';
  name.value = '';
});

(function created(): void {
  capacity.value = ls.value.capacity;
})();

async function createContainer(): Promise<void> {
  const done = progressStarted('creating container');

  ls.value.capacity = capacity.value;

  const { data, error } = await urql.mutation<{
    container: Container;
  }, MutationCreateContainerArgs>(
    gql`
      mutation CreateContainer($barcode: String!, $name: String!, $capacity: Int) {
        container: createContainer(barcode: $barcode, name: $name, capacity: $capacity) {
          id
          name
          barcode
          isEmpty
          activeSelections { id }
        }
      }
    `,
    {
      barcode:  barcode.value,
      name:     name.value,
      capacity: capacity.value,
    },
  );

  done();

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  emit('create', data!.container);
  if (!printDisabled.value) {
    // eslint-disable-next-line unicorn/prefer-ternary
    if (selectedPrinter.value?.__typename === 'PrintAgentPrinter'
        && selectedPrinter.value!.paperFormat !== PrinterPaperFormatEnum.A4) {
      await print(data!.container);
    } else {
      await enqueue(data!.container);
    }
  }
  emit('close');
  isCreateNewContainer.value = false;
}

const state = useDocumentsPrintingState();

const barcodeType = ref(state.value.lastContainerBarcodeType ?? PrintBarcodeTypeEnum.BARCODE);

async function print(container: Container): Promise<void> {
  const done = progressStarted('printing');
  await printNow('container-barcode', [{
    count: 1,
    params: {
      containerId: container.id,
      barcodeType: barcodeType.value,
    }
  }], selectedPrinterPaperLayout.value!);
  done();
}

async function enqueue(container: Container): Promise<void> {
  const done = progressStarted('printing');
  await enqueuePrint('container-barcode', [{
    count: 1,
    params: {
      containerId: container.id,
      barcodeType: barcodeType.value,
    }
  }], selectedPrinterPaperLayout.value!);
  done();
}
</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/printing.yaml"></i18n>

<i18n lang="yaml">
ru:
  Container Creating: Создание контейнера
en:
  Container Creating: Container Creating
</i18n>
