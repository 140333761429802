<template>
  <QCard>
    <CardTitle>
      {{ t('Profile Information') }}
      <template #subtitle>
        {{ t('Update your profile information') }}
      </template>
    </CardTitle>

    <QSeparator />

    <QCardSection>
      <div
        class="row"
        :class="{'q-gutter-md': q.screen.gt.sm }"
      >
        <div class="col-12 col-md column items-center">
          <div>
            <QAvatar size="72px">
              <QImg :src="photoPreview || user.profilePhotoUrl" />
            </QAvatar>
          </div>
          <div class="self-stretch">
            <QFile
              v-model="file"
              :label="t('Select A New Photo')"
              v-bind="qErrorsFor('photo')"
            >
              <template #before>
                <QIcon name="mdi-attachment" />
              </template>
              <template #after>
                <QBtn
                  flat
                  round
                  icon="mdi-camera"
                  @click="showingCameraDialog = true"
                />
              </template>
            </QFile>
            <CapturePhotoDialog
              v-model="showingCameraDialog"
              filename-prefix="user-"
              @captured="photoCaptured($event)"
            />
          </div>
          <div v-if="user.profilePhotoUrl">
            <QBtn
              :loading="hasProgress('removing-photo')"
              icon="mdi-delete-forever"
              @click="deletePhoto()"
            >
              {{ t('Remove Photo') }}
            </QBtn>
          </div>
        </div>

        <QSeparator vertical />

        <div class="col-12 col-md">
          <QInput
            v-model="firstname"
            :label="t('Firstname')"
            v-bind="qErrorsFor('firstname')"
            autocomplete="given-name"
          />
          <QInput
            v-model="lastname"
            :label="t('Lastname')"
            v-bind="qErrorsFor('lastname')"
            autocomplete="family-name"
          />
          <QInput
            v-if="canEditEmail"
            v-model="email"
            :label="t('Email')"
            v-bind="qErrorsFor('email')"
            autocomplete="email"
          />
        </div>
      </div>
    </QCardSection>

    <BaseAlert
      v-if="getPrimaryError()"
      type="error"
    >
      {{ getPrimaryError() }}
    </BaseAlert>

    <QSeparator />

    <QCardActions>
      <QSpace />

      <QBtn
        color="primary"
        :loading="hasProgress()"
        icon="mdi-content-save"
        @click="updateProfileInformation()"
      >
        {{ t('Save') }}
      </QBtn>
    </QCardActions>
  </QCard>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import CardTitle from '@/components/CardTitle.vue';
import useBackend from '@/composables/useBackend';
import useCan from '@/composables/useCan';
import useErrorHandling from '@/composables/useErrorHandling';
import useProgressHandling from '@/composables/useProgressHandling';
import useStore from '@/stores/root';
import { Ability } from '@/types/backend';
import CapturePhotoDialog from '@/views/Profile/CapturePhotoDialog.vue';
import { useBase64 } from '@vueuse/core';
import { useQuasar } from 'quasar';
import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const q = useQuasar();

const store = useStore();

const user = computed(() => store.user!);

const { fillErrorsFromHttpError, clearErrors, getPrimaryError, qErrorsFor } = useErrorHandling();
const { progressStarted, hasProgress } = useProgressHandling<'removing-photo'>();

const backend = useBackend();

const firstname = ref('');

const lastname = ref('');

const email = ref('');

const canEditEmail = useCan(Ability.EditOwnEmail);

const file = ref<File | null>(null);

const photoData = ref<File | null>(null);

const { base64: photoPreview } = useBase64(photoData as Ref<File>);

const showingCameraDialog = ref(false);

(function created(): void {
  firstname.value = user.value.firstname;
  lastname.value = user.value.lastname;
  email.value = user.value.email ?? '';
})();

async function updateProfileInformation(): Promise<void> {
  clearErrors();

  const finished = progressStarted();
  try {
    await backend.updateProfileInfo(
      firstname.value,
      lastname.value,
      email.value,
      photoData.value,
    );
    await store.updateUser();
  } catch (e) {
    fillErrorsFromHttpError(e);
  } finally {
    finished();
  }
}

watch(file, async function photoSelected(file): Promise<void> {
  photoData.value = file;
});

function photoCaptured(arg: File): void {
  photoData.value = arg;
}

async function deletePhoto(): Promise<void> {
  const finished = progressStarted('removing-photo');

  await backend.deleteProfilePhoto();

  photoData.value = null;

  finished();

  await store.updateUser();
}
</script>

<i18n lang="yaml">
en:
  Profile Information: Profile Information
  Update your profile information: Update your account's profile information
  Photo: Photo
  Select A New Photo: Select A New Photo
  Remove Photo: Remove Photo
ru:
  Profile Information: Профиль
  Update your profile information: Обновите свой профиль
  Photo: Фото
  Select A New Photo: Выбрать новое фото
  Remove Photo: Удалить фото
</i18n>
