<template>
  <TransferDetailsMobile v-if="preferences.terminalMode" />
  <TransferEdit v-else />
</template>

<script setup lang="ts">

import useBreadcrumbs from '@/composables/useBreadcrumbs';
import { useI18n } from 'vue-i18n';
import TransferDetailsMobile from '@/views/Mobile/Transfer/TransferDetailsMobile.vue';
import TransferEdit from '@/views/Storage/TransferEdit.vue';
import useLocalPreferences from '@/composables/useLocalPreferences';

const { t } = useI18n();

const preferences = useLocalPreferences();

useBreadcrumbs([t('Transfers'), t('Edit')]);

</script>
