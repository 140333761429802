<template>
  <MaximizedDialog
    model-value
    transition-show="slide-up"
    transition-hide="slide-down"
    :title="breadcrumbs.join(' / ')"
    @close="navigateBack({name: ROUTES.ARRIVALS_LIST})"
  >
    <BigLoading v-if="fetching" />
    <div v-else>
      <HorizontalPropsList v-slot="{ item }">
        <component
          :is="item"
          :caption="t('Number')"
        >
          {{ id }}
        </component>
        <component
          :is="item"
          :caption="t('Cell')"
        >
          {{ arrival.storage.name }}
        </component>
        <component
          :is="item"
          v-if="supply"
          :caption="isCustomerReturn ? t('Customer Return') : t('Supply')"
        >
          {{ supply.externalId }}
        </component>
        <component
          :is="item"
          v-if="supply?.plannedArrivalDate"
          :caption="t('Arrival Date')"
        >
          {{ formatDate(supply.plannedArrivalDate) }}
        </component>
        <component
          :is="item"
          v-if="supply?.counterparty"
          :caption="isCustomerReturn ? t('Customer') : t('Supplier')"
        >
          {{ supply.counterparty.name }}
        </component>
        <component
          :is="item"
          v-if="supply"
          :caption="t('Products')"
        >
          {{ productsCount }}
        </component>
      </HorizontalPropsList>

      <FilterableList
        :items="arrivalListItems"
        with-search
        :item-meets-search-string="movementMeetsSearchString"
        :debounce="0"
      >
        <template #item="{ item }">
          <ProductListItem
            :product="item.storable.productPack.product"
            :to="{ name: ROUTES.PRODUCTS_EDIT, params: { id: item.storable.productPack.product.id } }"
          >
            <template #top-right>
              <span>
                {{ item.amount }}
                {{ item.storable.productPack.minMeasurementUnit.shortName }}
              </span>
            </template>
          </ProductListItem>
        </template>
      </FilterableList>
    </div>
  </MaximizedDialog>
</template>

<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import type {
  Arrival,
  CustomerReturn,
  QueryArrivalArgs,
  Scalars,
  SupplierDelivery,
} from '@/graphql/types';
import HorizontalPropsList from '@/views/Mobile/HorizontalPropsList.vue';
import { computed } from 'vue';
import { gql, useQuery } from '@urql/vue';
import BigLoading from '@/components/BigLoading.vue';
import { collectBy, uniqBy } from 'ramda';
import ProductListItem from '@/components/ProductListItem.vue';
import FilterableList from '@/components/FilterableList.vue';
import MaximizedDialog from '@/components/MaximizedDialog.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import navigateBack from '@/helpers/navigateBack';
import movementMeetsSearchString from '@/helpers/movementMeetsSearchString';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const { breadcrumbs } = useBreadcrumbs();

const { formatDate } = useLocalizedFormatters();

const props = defineProps<{
  id: Scalars['ID'];
}>();

const { data, fetching } = useQuery<{ arrival: Arrival }, QueryArrivalArgs>({
  query: gql`
    query GetArrivalForMobileDetails($id: ID!) {
      arrival(id: $id) {
        id
        storage { id name }
        supply {
          id
          externalId
          plannedArrivalDate
          counterparty { __typename id name }
          items { id productPack { id product { id sku } } }
        }
        movements {
          id
          amount
          storable {
            id
            batch { id }
            productPack {
              id
              quantityInMinMeasurementUnits
              minMeasurementUnit { id shortName }
              product {
                id
                sku
                name
                photos {
                  id
                  url(size: SMALL)
                }
              }
            }
          }
        }
      }
    }
  `,
  variables: computed(() => ({ id: props.id }))
});

const arrival = computed(() => data.value?.arrival ?? null);

const supply = computed(() => arrival.value?.supply ?? null);

const productsCount = computed(() =>
  uniqBy(i => i.productPack.product.sku, supply.value!.items).length,
);

const arrivalListItems = computed(() => collectBy(
  movement => movement.storable.batch.id,
  arrival.value?.movements ?? [],
).map(ms => ({
  ...ms[0],
  amount: ms.reduce((acc, m) => acc + m.amount * m.storable.productPack.quantityInMinMeasurementUnits, 0),
})));

const isCustomerReturn = computed(() => (supply.value as CustomerReturn | SupplierDelivery)?.__typename === 'CustomerReturn');

</script>

<i18n lang="yaml">
ru:
  "Arrival #{id}": Приёмка №{id}

en:
  "Arrival #{id}": "Arrival #{id}"

</i18n>
