<template>
  <GraphQLReportList
    :graphql-query="queuedSelectionsQuery"
    :available-filters="availableFilters"
    :fixed-filters="fixedFilters"
    with-search
    :no-data-label="t('No Selections')"
    :storage-prefix="storagePrefix"
    class="col"
  >
    <template #item="{ item }">
      <QItem
        class="list-item-default-height"
        clickable
        @click="router.push({ name: ROUTES.SELECTIONS_EDIT, params: {id: item.id }})"
      >
        <QItemSection class="dense-labels no-wrap">
          <QItemLabel>
            {{ t('# {text}', { text: item.id }) }}
            {{ item.areaName }}
          </QItemLabel>
          <QItemLabel class="row justify-between">
            <span>
              {{ formatDate(item.createdAt, FORMATS.DATETIME) }}
            </span>
            <span>
              {{ item.carrier?.name }}
            </span>
          </QItemLabel>
          <QItemLabel class="row justify-between">
            <span>
              {{ t('{n} products', item.productsCount) }}
              /
              {{ getShipmentsLabel(item) }}
            </span>
            <span>
              <QChip
                dense
                class="q-ma-none"
                :color="isCustomerOrder(item) ? 'blue-3' : 'red-3'"
                text-color="black"
              >
                {{ isCustomerOrder(item) ? t('Order') : t('Return') }}
              </QChip>
              <QChip
                dense
                class="q-ma-none"
                :color="stateColor(item)"
                text-color="black"
              >
                {{ t(`selectionState.${ item.state }`) }}
              </QChip>
            </span>
          </QItemLabel>
        </QItemSection>
      </QItem>
    </template>
  </GraphQLReportList>
</template>

<script setup lang="ts">

import getCarriersForSelect from '@/graphql/shorthands/getCarriersForSelect';
import { gql } from '@urql/vue';
import type { ReportFilterInput, SelectionReportRow, SelectionStateEnum } from '@/graphql/types';
import { useI18n } from 'vue-i18n';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import GraphQLReportList from '@/components/Mobile/GraphQLReportList.vue';
import type { ReportFilter } from '@/types/reports';
import { createList, createPrimitive } from '@/helpers/reports';
import { useRouter } from 'vue-router';
import * as badgeColors from '@/helpers/badgeColors';
import { computed } from 'vue';
import { uniq } from 'ramda';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const router = useRouter();

const props = defineProps<{
  states: (SelectionStateEnum)[];
  storagePrefix: string;
}>();

function stateColor(row: SelectionReportRow): string {
  return badgeColors.forSelectionState(row.state);
}

function getShipmentsLabel(row: SelectionReportRow): string {
  const shipments = uniq(row.items.map(i => i.shipment.externalId));

  return shipments.length > 1
    ? t('{n} orders', shipments.length)
    : t('# {text}', { text: shipments[0] });
}

function isCustomerOrder(row: SelectionReportRow): boolean {
  return row.items[0].shipment.__typename === 'CustomerOrder';
}

const queuedSelectionsQuery = gql`
  query GetQueuedSelectionsList(
    $first: Int!,
    $after: Cursor,
    $query: String,
    $filter: [ReportFilterInput!],
  ) {
    result: selectionsList(
      first: $first,
      after: $after,
      query: $query,
      filter: $filter,
    ) {
      edges {
        cursor
        node {
          id
          state
          createdAt
          areaName
          carrier { id name }
          productsCount
          items {
            id
            shipment {
              __typename
              id
              externalId
            }
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const availableFilters: ReportFilter[] = [
  {
    field:                 'carrier',
    label:                 t('Carrier Name'),
    hideLabelWhenSelected: true,
    operators:             [
      createList('in', getCarriersForSelect),
    ],
  },
  {
    field:     'customerOrderId',
    label:     t('Order Number'),
    operators: [
      createPrimitive('=', 'string'),
    ],
  },
  {
    field:     'supplierReturnId',
    label:     t('Return Number'),
    operators: [
      createPrimitive('=', 'string'),
    ],
  },
];

const fixedFilters = computed<ReportFilterInput[]>(() => [{
  field:    'state',
  operator: 'in',
  value:    JSON.stringify(props.states),
}]);

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/counts.yaml"></i18n>

<i18n lang="yaml">

ru:
  Area: Зона
  No Selections: Нет Отборов

en:
  Area: Area
  No Selections: No Selections

</i18n>
