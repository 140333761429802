ru:
  From {name}: Из {name}
  Selection Completed: Отбор завершён
  Specify Weight and Dimensions: Укажите ВГХ
  To {name}: В {name}
  "{amount} out of {total}": "{amount} из {total}"

en:
  From {name}: From {name}
  Selection Completed: Selection Completed
  Specify Weight and Dimensions: Specify Weight and Dimensions
  To {name}: To {name}
  "{amount} out of {total}": "{amount} out of {total}"
