<template>
  <QSelect
    v-bind="$attrs"
    :model-value="modelValue"
    :options="data?.result ?? []"
    :loading="fetching"
    :error="!!error"
    :error-message="error?.message"
    hide-dropdown-icon
    use-input
    hide-selected
    fill-input
    stack-label
    :placeholder="modelValue ? undefined : t('Not specified')"
    @input-value="emit('update:modelValue', $event)"
    @filter="handleFilter"
    @clear="emit('update:modelValue', null)"
  >
    <template
      v-for="slot in Object.keys($slots)"
      #[slot]="bind"
    >
      <slot
        :name="slot"
        v-bind="bind ?? {}"
      />
    </template>
  </QSelect>
</template>

<script setup lang="ts">

import { useQuery } from '@urql/vue';
import { until } from '@vueuse/core';
import type { DocumentNode } from 'graphql';
import type { QSelectProps } from 'quasar';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  modelValue: string | null;
  query: DocumentNode;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', entity: string | null): void;
}>();

const searchString = ref('');

async function handleFilter(...[q, done]: Parameters<NonNullable<QSelectProps['onFilter']>>) {
  searchString.value = q;
  await until(fetching).toBe(false);
  done(() => null);
}

const { data, fetching, error } = useQuery<{ result: string[] }>({
  query: props.query,
  variables: computed(() => ({
    searchString: searchString.value,
  })),
});
</script>
