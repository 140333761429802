<template>
  <div class="row justify-center q-mt-md">
    <AuthCard
      class="col"
      :title="t('Registration')"
    >
      <QInput
        v-model="firstname"
        :label="t('Firstname')"
        type="text"
        v-bind="qErrorsFor('firstname')"
        required
        autofocus
        autocomplete="firstname"
      />

      <QInput
        v-model="lastname"
        :label="t('Lastname')"
        type="text"
        v-bind="qErrorsFor('lastname')"
        required
        autocomplete="lastname"
      />

      <QInput
        v-model="email"
        :label="t('Email')"
        type="email"
        required
        autocomplete="email"
        v-bind="qErrorsFor('email')"
      />

      <QInput
        v-model="password"
        :label="t('Password')"
        type="password"
        v-bind="qErrorsFor('password')"
        required
        autocomplete="new-password"
      />

      <QInput
        v-model="passwordRepeat"
        :label="t('Confirm Password')"
        type="password"
        required
        autocomplete="new-password"
      />

      <QCheckbox
        v-model="remember"
        :label="t('Remember me')"
      />

      <template
        v-if="primaryError"
        #error
      >
        {{ t(primaryError) }}
      </template>

      <template #actions>
        <QBtn
          flat
          :to="{ name: ROUTES.AUTH_LOGIN }"
        >
          {{ t('Already registered?') }}
        </QBtn>

        <QSpace />

        <QBtn
          type="submit"
          color="primary"
          :loading="isRegistering"
          icon="mdi-account-plus"
          @click.prevent="register()"
        >
          {{ t('Register') }}
        </QBtn>
      </template>
    </AuthCard>
  </div>
</template>

<script setup lang="ts">

import useBackend from '@/composables/useBackend';
import useErrorHandling from '@/composables/useErrorHandling';
import useStore from '@/stores/root';
import AuthCard from '@/views/Auth/AuthCard.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const { fillErrorsFromHttpError, qErrorsFor, primaryError } = useErrorHandling();

const backend = useBackend();

const store = useStore();

const router = useRouter();

const firstname = ref('');

const lastname = ref('');

const email = ref('');

const password = ref('');

const passwordRepeat = ref('');

const remember = ref(true);

const isRegistering = ref(false);

async function register(): Promise<void> {
  isRegistering.value = true;

  try {
    await backend.register(
      firstname.value,
      lastname.value,
      email.value,
      password.value,
      passwordRepeat.value,
      remember.value,
    );
    await store.updateUser();
    // noinspection ES6MissingAwait
    router.push({ name: ROUTES.AUTH_VERIFY_EMAIL });
  } catch (e) {
    await fillErrorsFromHttpError(e);
  } finally {
    isRegistering.value = false;
  }
}

</script>

<i18n lang="yaml">
ru:
  Confirm Password: Подтверждение пароля
  Remember me: Запомнить меня
  Already registered?: Уже зарегистрированы?
  Register: Зарегистрироваться
  Registration: Регистрация

en:
  Confirm Password: Confirm Password
  Remember me: Remember me
  Already registered?: Already registered?
  Register: Register
  Registration: Registration
</i18n>
