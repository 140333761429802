<template>
  <slot
    v-if="persistent || releases.length > 0"
    name="activator"
    :loading="fetching"
    :preferred-url="preferredUrl"
    :releases="releases"
  />
</template>

<script setup lang="ts">

import type { PrintAgentRelease } from '@/graphql/types';
import { gql, useQuery } from '@urql/vue';
import { useQuasar } from 'quasar';
import { computed } from 'vue';

defineProps<{
  persistent?: boolean;
}>();

const q = useQuasar();

const { data, fetching } = useQuery<{ releases: PrintAgentRelease[] }>({
  query: gql`
    query GetPrintAgentReleases {
      releases: printAgentReleases {
        version
        downloadUrlDeb
        downloadUrlMsi
      }
    }
  `,
});

const releases = computed(() => data.value?.releases ?? []);

const preferredUrl = computed(() => releases.value[0] ? releasePlatformUrl(releases.value[0]) : null);

function releasePlatformUrl(r: PrintAgentRelease): string {
  return (q.platform.is.linux
    ? (r.downloadUrlDeb ?? r.downloadUrlMsi)
    : (r.downloadUrlMsi ?? r.downloadUrlDeb))!;
}

</script>
