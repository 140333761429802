<template>
  <div class="row justify-center q-mt-md">
    <AuthCard :title="t('Verify Email')">
      <p>
        {{ t('Thanks for signing up, {email}!', { email: store.user!.email }) }}
      </p>

      <template
        v-if="newMailSent"
        #success
      >
        {{ t('A new verification link has been sent') }}
      </template>

      <template #actions>
        <QBtn
          color="primary"
          :loading="hasProgress('resend')"
          icon="mdi-email-send"
          @click="resendMail()"
        >
          {{ t('Resend Verification Email') }}
        </QBtn>

        <QSpace />

        <QBtn
          color="primary"
          :loading="hasProgress('logout')"
          icon="mdi-logout"
          @click="logout()"
        >
          {{ t('Logout') }}
        </QBtn>
      </template>
    </AuthCard>
  </div>
</template>

<script setup lang="ts">

import useBackend from '@/composables/useBackend';
import useProgressHandling from '@/composables/useProgressHandling';
import useStore from '@/stores/root';
import AuthCard from '@/views/Auth/AuthCard.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const backend = useBackend();

const router = useRouter();

const { progressStarted, hasProgress } = useProgressHandling<'resend' | 'logout'>();

const store = useStore();

const newMailSent = ref(false);

async function resendMail(): Promise<void> {
  const finished = progressStarted('resend');

  newMailSent.value = false;

  try {
    await backend.resendVerificationEmail();
    newMailSent.value = true;
  } finally {
    finished();
  }
}

async function logout(): Promise<void> {
  const finished = progressStarted('logout');

  await backend.logout();
  await store.updateUser();

  finished();

  await router.push({ name: ROUTES.AUTH_LOGIN });
}

</script>

<i18n lang="yaml">
en:
  Thanks for signing up, {email}!: >
    Thanks for signing up! You're logged in as {email}. Before getting started,
    could you verify your email address by clicking on the link we just emailed to you?
    If you didn't receive the email, we will gladly send you another
  A new verification link has been sent: >
    A new verification link has been sent to the email address you provided during registration
  Resend Verification Email: Resend Verification Email
  Logout: Logout
  Verify Email: Verify Email
ru:
  Thanks for signing up, {email}!: >
    Спасибо за регистрацию! Вы вошли в аккаунт {email}.
    Чтобы начать работу подтвердите свой E-mail, перейдя по ссылке из письма, которое мы отправили.
    Если вы не получились письмо, мы можем отправить его снова
  A new verification link has been sent: >
    Новая ссылка для подтверждения аккаунта была отправлена на E-mail, указанный при регистрации
  Resend Verification Email: Отправить письмо повторно
  Logout: Выйти
  Verify Email: Подтверждение E-mail
</i18n>
