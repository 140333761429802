<template>
  <QChip
    :color="badgeColors.forOperationState(operation)"
    text-color="black"
  >
    {{ title }}
  </QChip>
</template>

<script setup lang="ts">

import type { OperationUnion } from '@/graphql/types';
import * as badgeColors from '@/helpers/badgeColors';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  operation: OperationUnion;
}>();

const { t } = useI18n();

const title = computed(() => ({
  Arrival:     t(`arrivalState.${props.operation.state}`),
  Inventory:   t(`inventoryState.${props.operation.state}`),
  Selection:   t(`selectionState.${props.operation.state}`),
  Realization: t(`realizationState.${props.operation.state}`),
  Transfer:    t(`transferState.${props.operation.state}`),
}[props.operation.__typename!]));

</script>
