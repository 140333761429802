<template>
  <FullHeightPage>
    <GraphQLQueryTable
      :graphql-query="query"
      :fields="tableFields"
      :available-filters="availableFilters"
      storage-prefix="transferTasksList.report"
      keep-page-in-url
      sticky-header
      class="col"
    >
      <template #after-search-string="{ options, refresh }">
        <TransferCreateTaskDialog
          @created="refresh"
        >
          <template #activator="{ onClick }">
            <QBtn
              color="primary"
              icon="mdi-plus"
              :label="t('Task')"
              @click="onClick"
            />
          </template>
        </TransferCreateTaskDialog>
        <TransfersExcelImport
          :options="options"
          @success="refresh"
        />
      </template>

      <template #body-cell-id="column">
        <BodyCellLink
          :column="column"
          :to="{ name: ROUTES.TRANSFER_TASKS_EDIT, params: { id: column.value } }"
        />
      </template>

      <template #body-cell-state="column">
        <QTd :props="column">
          <QChip
            :color="badgeColors.forTransferTask(column.row)"
            text-color="black"
          >
            {{ column.value }}
          </QChip>
        </QTd>
      </template>
    </GraphQLQueryTable>
  </FullHeightPage>
</template>

<script setup lang="ts">

import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import FullHeightPage from '@/components/FullHeightPage.vue';
import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import { type TransferTask, TransferTaskKindEnum } from '@/graphql/types';
import {
  TransferStateEnum
} from '@/graphql/types';
import * as badgeColors from '@/helpers/badgeColors';
import * as reports from '@/helpers/reports';
import { createDatesRangeOperator, dashIfNil } from '@/helpers/reports';
import userFullName from '@/helpers/userFullName';
import ROUTES from '@/router/routeNames';
import type {  TypenameAware } from '@/types';
import type { ReportFilter, TableColumn } from '@/types/reports';
import TransferCreateTaskDialog from '@/views/Mobile/TransferTask/TransferCreateTaskDialog.vue';
import { gql  } from '@urql/vue';
import { useI18n } from 'vue-i18n';
import TransfersExcelImport from '@/views/Mobile/Transfer/TransfersExcelImport.vue';

const { t } = useI18n();

useBreadcrumbs(t('Transfer Tasks'));

const { formatDate } = useLocalizedFormatters();

const tableFields: TableColumn<TypenameAware<TransferTask>>[] = [
  {
    label:     t('ID'),
    name:      'id',
    field:     'id',
    mandatory: true,
    align:     'left',
    sortable:  true,
  },
  {
    label:    t('Created at'),
    name:     'createdAt',
    field:    'createdAt',
    format:   d => formatDate(d, FORMATS.DATETIME),
    align:    'left',
    sortable: true,
  },
  {
    label: t('User'),
    name:  'user',
    field:  'user',
    format: userFullName,
    align: 'left',
  },
  {
    label:  t('State'),
    name:   'state',
    field:  'state',
    align:  'left',
    format: state => t(`transferState.${state}`),
  },
  {
    label:  t('Transfer Task Kind'),
    name:   'kind',
    field:  'kind',
    format: kind => kind ? t(`transferTaskKind.${kind}`) : '',
    align:  'left',
  },
  {
    label:  t('Packs'),
    name:   'packsAmount',
    field:  'packsAmount',
    format: dashIfNil,
  },
];

const availableFilters: ReportFilter[] = [
  {
    field:     'createdAt',
    label:     t('Created at'),
    operators: [
      createDatesRangeOperator(),
    ],
  },
  {
    field:     'state',
    label:     t('State'),
    operators: (['=', '!=', 'in', 'not in'] as const).map(op =>
      reports.createList(op, () => Promise.resolve([
        TransferStateEnum.CREATED,
        TransferStateEnum.IN_PROGRESS,
        TransferStateEnum.COMPLETED,
      ].map(v => ({
        value: v,
        label: t(`transferState.${v}`),
      }))), t(`reportFilterOperator.${op}`)),
    ),
  },
  {
    field:                 'kind',
    label:                 t('Transfer Task Kind'),
    hideLabelWhenSelected: true,
    operators:             [
      reports.createList('=', () => Promise.resolve([
        null,
        TransferTaskKindEnum.MOVE_TO_SPECIFIED_STORAGE,
        TransferTaskKindEnum.CONSOLIDATION_INTO_SINGLE_STORAGE,
      ].map(v => ({
        value: v,
        label: v ? t(`transferTaskKind.${v}`) : t('No Task Type'),
      })))),
    ],
  },
];

const query = gql`
  query GetTransferTasks($page: Int, $perPage: Int!, $query: String, $filter: [ReportFilterInput!], $sort: [ReportSortInput!]!) {
    result: transferTasksReport(page: $page, perPage: $perPage, query: $query, filter: $filter, sort: $sort) {
      data {
        id
        kind
        createdAt
        user { firstname lastname }
        state
        packsAmount
      }
      total
    }
  }
`;

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/transfer.yaml"></i18n>

<i18n lang="yaml">
ru:
  Packs: Упаковки
  No Task Type: Без типа

en:
  Packs: Packs
  No Task Type: No Task Type

</i18n>
