<template>
  <PrintDialog
    v-model:barcode-type="barcodeType"
    template-name="product-pack-barcode"
    :title="t('Selected for print {size}', {size: products.length})"
    :instances="printInstances"
    :no-instances-message="t('No barcodes for selected Pack Type and Barcode Type')"
    @print="emit('done')"
    @count-changed="printCount = $event"
  >
    <template #default="{ open }">
      <QBtn
        class="bg-white text-black q-ml-sm"
        icon="mdi-printer"
        @click="open()"
      >
        {{ t('Print') }}
      </QBtn>
    </template>
    <template #selection>
      <QSelect
        v-model="selectedPackOption"
        :label="t('Select Product Pack Type')"
        :options="packOptions"
        option-value="id"
        option-label="name"
        :display-value="selectedPackOption?.name"
        class="ellipsis"
        :loading="measurementUnitsLoading"
      />
      <QSelect
        v-model="barcodeGroup"
        :label="t('Select Barcode Type')"
        :options="barcodeGroups"
        :option-label="barcodeGroupOptionLabel"
        :display-value="barcodeGroupOptionLabel(barcodeGroup)"
        class="ellipsis"
      />
      <div v-if="weightBarcodeSelected">
        <QInput
          v-if="weightBarcodeSelected"
          v-model.number="weight"
          :label="t('Quantity')"
          :error="v.weight.$error"
          :error-message="v.weight.$errors[0]?.$message"
          :placeholder="t('Enter from keyboard')"
        />
      </div>
      <div v-else>
        <QSelect
          v-model="selectedLabelCountOption"
          :options="labelCountOptions"
          :label="t('Label count')"
        />
        <QInput
          v-if="selectedLabelCountOption === t('Specific amount')"
          v-model.number="printCount"
          type="number"
          step="1"
          min="1"
          :rules="[notEmptyRule, positiveIntegerOrNullRule]"
          :label="t('Count')"
          autofocus
        />
      </div>
    </template>
  </PrintDialog>
</template>

<script setup lang="ts">

import PrintDialog from '@/components/PrintDialog.vue';
import type { PrintTemplateInstance } from '@/composables/usePrintService';
import useValidationRules from '@/composables/useValidationRules';
import type {
  MeasurementUnit,
  Product,
  ShipmentItem,
  SupplyItem,
  SupplyOrShipmentUnion,
} from '@/graphql/types';
import { PrintBarcodeTypeEnum } from '@/graphql/types';
import useDocumentsPrintingState from '@/views/useDocumentsPrintingState';
import { gql, useQuery } from '@urql/vue';
import { uniq } from 'ramda';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import useStore from '@/stores/root';
import useVuelidate from '@vuelidate/core';
import { between, numeric, required } from '@/setup/validation';
import { Ean13Utils } from 'ean13-lib';

const { t } = useI18n();

const state = useDocumentsPrintingState();
const { notEmptyRule, positiveIntegerOrNullRule } = useValidationRules();

const props = defineProps<{
  products: Product[];
  document?: SupplyOrShipmentUnion;
}>();

const emit = defineEmits<{
  (e: 'done'): void;
}>();

const printCount = ref<number>(1);

const selectedLabelCountOption = ref<string>(t('Specific amount'));

const byDocumentTitle = computed(() => props.document?.__typename ? {
  'CustomerReturn':   t('By customer return'),
  'SupplierDelivery': t('By supply'),
  'CustomerOrder':    t('By order'),
  'SupplierReturn':   t('By supplier return'),
}[props.document.__typename] : '');

const labelCountOptions = computed(() => [
  t('Specific amount'),
  ...(props.document ? [byDocumentTitle.value] : []),
]);

const barcodeType = ref(state.value.lastProductPackBarcodeType ?? PrintBarcodeTypeEnum.BARCODE);

watch(barcodeType, (): void => {
  state.value.lastProductPackBarcodeType = barcodeType.value;
});

const weight = ref<number|undefined|null>(1);

const weightBarcodeSelected = computed(() =>
  barcodeGroup.value === t('Weight Type'),
);

const v = useVuelidate({
  weight:     {
    required,
    numeric,
    between: between(0, 100),
  },
}, { weight }, { $autoDirty: true });


const {
  data: measurementUnitsData,
  fetching: measurementUnitsLoading,
} = useQuery<{ units: MeasurementUnit[] }>({
  query: gql`
    query MeasurementUnitsForProductPrintDialog {
      units: measurementUnits { id name shortName isFractional class }
    }
  `,
});

const packOptions = computed(() =>
// Отображаем только варианты для которых есть ШК
  measurementUnitsData.value?.units.filter(
    u => props.products.flatMap((p => p.productPacks.filter(pp => pp.measurementUnit.id === u.id)))
      .some(
        pp => pp.barcodes.length > 0 || pp.measurementUnit.isFractional,
      ),
  ) ?? [],
);

const selectedPackOption = ref<MeasurementUnit | null>(null);

watch(selectedPackOption, unit => {
  state.value.lastProductPackUnitId = unit?.id ?? null;
});

watch(packOptions, options => {
  if (packOptions.value.length === 0) {
    selectedPackOption.value = null;

    return;
  }

  selectedPackOption.value = state.value.lastProductPackUnitId === undefined
    ? null
    : options.find(o => state.value.lastProductPackUnitId === (o?.id ?? null)) ?? null;

  if (!selectedPackOption.value && props.products.length === 1) {
    selectedPackOption.value = options.some(o => o.id === props.products[0].mostBasicProductPack.measurementUnit.id)
      ? props.products[0].mostBasicProductPack.measurementUnit
      : options[0] ?? null;
  }
});

const barcodeGroup = ref<string | null>(null);

watch(barcodeGroup, () => {
  state.value.lastProductPackBarcodeGroup = barcodeGroup.value;
});

const barcodeGroups = computed(() => {
  const groups = [
    ...uniq(props.products
      .flatMap(p => p.productPacks
        .filter(pp => selectedPackOption.value?.isFractional ? pp.measurementUnit.isFractional : true)
        .flatMap(pp => {
          const prefix = useStore().warehouse!.weightBarcodesPrefix;

          if (selectedPackOption.value?.isFractional && /^\d{5}$/.test(pp.product.sku) && prefix) {
            return [t('Weight Type')];
          }

          return pp.barcodes.map(b => b.group).filter(Boolean);
        }),
      ),
    ),
  ];

  if (!selectedPackOption.value?.isFractional) {
    groups.unshift(null);
  }

  return groups;
});

watch(barcodeGroups, groups => {
  if (state.value.lastProductPackBarcodeGroup
      && groups.includes(state.value.lastProductPackBarcodeGroup)
  ) {
    barcodeGroup.value = state.value.lastProductPackBarcodeGroup;
  }

  if (!groups.includes(barcodeGroup.value)) {
    barcodeGroup.value = groups.length > 0 ? groups[0]! : null;
  }
}, { immediate: true });

const barcodeGroupOptionLabel = (option: string | null) => option ?? t('Regular');

const printInstances = computed((): PrintTemplateInstance[] => props.products
  .flatMap(selectedPackOption.value
    ? p => p.productPacks.filter(pp => pp.measurementUnit.id === selectedPackOption.value!.id)
    : p => p.mostBasicProductPack,
  )
  .filter(pp => weightBarcodeSelected.value ? pp.measurementUnit.isFractional : pp.barcodes.some(b => b.group === barcodeGroup.value))
  .map(pp => {
    let barcodeToPrint;
    if (weightBarcodeSelected.value) {
      const prefix = useStore().warehouse!.weightBarcodesPrefix;
      const weightStr = v.value.weight.$invalid
        ? '00000'
        : (weight.value as number).toFixed(3).replace('.', '').padStart(5, '0');

      const weightBarcode = `${prefix}${pp.product.sku}${weightStr}`;

      barcodeToPrint = weightBarcode + Ean13Utils.calculateCheckDigit(weightBarcode);
    } else {

      barcodeToPrint = barcodeGroup.value
        ? pp.barcodes.find(b => b.group === barcodeGroup.value)!.barcode
        : (pp.lastScannedBarcode?.barcode ?? pp.barcodes.find(b => !b.group)!.barcode);
    }

    return {
      count: packPrintAmount(pp.id),
      params: {
        packId:      pp.id,
        barcode:     barcodeToPrint,
        barcodeType: barcodeType.value
      }
    };
  }));

function packPrintAmount(packId: string): number {
  if (weightBarcodeSelected.value) {
    return 1;
  }

  if (selectedLabelCountOption.value === t('Specific amount')) {
    return printCount.value;
  }

  return (props.document?.items ?? [] as (SupplyItem | ShipmentItem)[])
    .filter(i => i.productPack.id === packId)
    .reduce((sum, item) => sum + item.amount, 0);
}

</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/printing.yaml"></i18n>

<i18n lang="yaml">
ru:
  Select Product Pack Type: Выберите тип упаковки
  Select Barcode Type: Выберите тип штрихкода
  Regular: Основной
  No barcodes for selected Pack Type and Barcode Type: >
    Для выбранного типа штрихкода и типа упаковки штрихкоды отсутствуют
en:
  Select Product Pack Type: Select Product Pack Type
  Select Barcode Type: Select Barcode Type
  Regular: Regular
  No barcodes for selected Pack Type and Barcode Type: >
    No barcodes for selected Pack Type and Barcode Type
</i18n>
