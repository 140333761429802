<template>
  <EditableListCard
    ref="cardRef"
    :title="title"
    :items-query="query"
    :items-query-vars="variables"
    :item-title="(acc: GlobalAccount) => acc.name"
    :item-key="acc => acc.id"
    :item-filter="(acc, q) => acc.name.toLocaleLowerCase().includes(q)"
    list-dense
  >
    <template #item-actions="{ item }">
      <QItemSection>
        <QItemLabel class="text-right">
          <QChip :color="subscriptionExpired(item) ? 'warning' : undefined">
            {{ t('Payed Until') }}
            {{ formatDate(item.subscriptionPaidUntil, FORMATS.DATE) }}
          </QChip>
        </QItemLabel>
      </QItemSection>
      <QItemSection side>
        <QBtn
          flat
          round
          icon="mdi-cash-plus"
          :title="t('Make a Payment')"
          @click="newPaymentAccount = item"
        />
      </QItemSection>
    </template>
  </EditableListCard>
  <GlobalAccountsNewPaymentDialog
    v-model:account="newPaymentAccount"
    @success="cardRef.refresh()"
  />
</template>

<script setup lang="ts">

import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type { GlobalAccount, GlobalAccountOriginEnum } from '@/graphql/types';
import GlobalAccountsNewPaymentDialog from '@/views/Admin/GlobalAccountsNewPaymentDialog.vue';
import EditableListCard from '@/views/WarehouseSettings/EditableListCard.vue';
import { gql } from '@urql/vue';
import dayjs from 'dayjs';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const props = defineProps<{
  origin: GlobalAccountOriginEnum;
  title: string;
}>();

const query = gql`
  query GetGlobalAccounts($origin: GlobalAccountOriginEnum!) {
    accounts: globalAccounts(origin: $origin) {
      id
      origin
      name
      subscriptionPaidUntil
    }
  }
`;
const variables = computed(() => ({ origin: props.origin }));

const newPaymentAccount = ref<GlobalAccount | null>(null);

function subscriptionExpired(acc: GlobalAccount) {
  return dayjs(acc.subscriptionPaidUntil).isBefore(dayjs());
}

const cardRef = ref<InstanceType<typeof EditableListCard>>();

</script>

<i18n lang="yaml">
ru:
  Payed Until: Оплачен до
  Make a Payment: Внести платеж
en:
  Payed Until: Payed Until
  Make a Payment: Make a Payment
</i18n>
