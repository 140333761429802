<template>
  <QCard>
    <BaseAlert
      v-if="getPrimaryError()"
      type="error"
    >
      {{ getPrimaryError() }}
    </BaseAlert>
    <CardTitle>
      {{
        t('Inventory {id}, User: {user}', {
          id: inventoryRef.id,
          user: `${ inventoryRef.user.lastname } ${ inventoryRef.user.firstname }`,
        })
      }}
      <QChip
        :color="stateBadgeColor"
        text-color="black"
      >
        {{ t(`inventoryState.${ inventoryRef.state }`) }}
      </QChip>
    </CardTitle>

    <QCardSection>
      <div class="row q-col-gutter-md">
        <div class="col-sm-4 col-md-2 col-xl-1">
          <NonEditableField :label="t('Created at')">
            {{ formatDate(inventoryRef.createdAt) }}
          </NonEditableField>
        </div>
        <div class="col-sm-4 col-md-2 col-xl-1">
          <NonEditableField :label="t('Cell')">
            {{ inventoryRef.storage.name }}
          </NonEditableField>
        </div>
        <div class="col-sm-4 col-md-2 col-xl-1">
          <NonEditableField :label="t('Type')">
            {{ inventoryRef.isEntireStorage ? t('Entire Storage') : t('Some Products') }}
          </NonEditableField>
        </div>
        <div
          v-if="inventoryRef.comment"
          class="col-sm-12 col-md-6 col-xl-10"
        >
          <QInput
            readonly
            :label="t('Comment')"
            :model-value="inventoryRef.comment"
          />
        </div>
      </div>
    </QCardSection>

    <FilterableTable
      v-if="isInventoryCompleted"
      :rows="inventory.movements"
      :fields="itemsTableHeadersMovement"
      :available-filters="availableFilters"
      :item-meets-search-string="movementMeetsSearchString"
      show-line-numbers
    >
      <template #body-cell-productSku="column">
        <BodyCellLink
          :column="column"
          :to="{ name: ROUTES.PRODUCTS_EDIT, params: { id: column.row.storable.productPack.product.id } }"
          variant="link"
        />
      </template>
    </FilterableTable>
    <FilterableTable
      v-else
      :rows="inventoryRef.items"
      :fields="itemsTableHeadersItems"
      :available-filters="availableFilters"
      :item-meets-search-string="inventoryItemMeetsSearchString"
      show-line-numbers
    >
      <template #body-cell-productSku="column">
        <BodyCellLink
          :column="column"
          :to="{ name: ROUTES.PRODUCTS_EDIT, params: { id: column.row.storable.product.id } }"
          variant="link"
        />
      </template>
      <template #body-cell-difference="column">
        <QTd :props="column">
          <QInput
            v-model.number="column.row.diff"
            type="number"
            dense
            input-style="text-align: right"
          />
        </QTd>
      </template>
      <template #body-cell-after="column">
        <QTd :props="column">
          <QInput
            :loading="stocksInStorageLoading"
            :disable="stocksInStorageLoading"
            :model-value="stocksInStorageLoading ? '' : (getStock(column.row.storable) + column.row.diff)"
            type="number"
            min="0"
            dense
            input-style="text-align: right"
            :rules="[gteRule(0)]"
            @update:model-value="column.row.diff = Number($event) - getStock(column.row.storable)"
          />
        </QTd>
      </template>

      <template #bottom-left>
        <InventoryEditNewStorable
          :inventory="inventoryRef"
          @add-product-pack="addProductPack"
        />
      </template>
    </FilterableTable>

    <QSeparator />

    <QCardActions>
      <QBtn
        exact
        icon="mdi-arrow-left"
        @click="navigateBack({ name: ROUTES.INVENTORY_DASHBOARD })"
      >
        {{ t('Back') }}
      </QBtn>
      <QBtn
        v-if="!isInventoryCompleted"
        color="success"
        :loading="progress.completing"
        :disable="progress.saving || progress.deleting || isInvalid"
        @click="complete()"
      >
        {{ t('Confirm amount') }}
      </QBtn>
      <QBtn
        v-if="inventory.state === InventoryStateEnum.NEEDS_RECOUNT"
        color="primary"
        :loading="progress.saving"
        :disable="progress.completing || progress.deleting"
        @click="saveInventory"
      >
        {{ t('Save') }}
      </QBtn>
      <ConfirmsAction
        v-if="!isInventoryCompleted"
        should-prompt
        :confirm-text="t('Delete')"
        @confirmed="deleteInventory"
      >
        <template #title>
          {{ t('Delete inventory?') }}
        </template>
        <template #activator="{ prompt }">
          <QBtn
            color="red"
            :loading="progress.deleting"
            :disable="progress.completing || progress.saving"
            @click="prompt"
          >
            {{ t('Delete Inventory') }}
          </QBtn>
        </template>
      </ConfirmsAction>
    </QCardActions>
  </QCard>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import CardTitle from '@/components/CardTitle.vue';
import ConfirmsAction from '@/components/ConfirmsAction.vue';
import FilterableTable from '@/components/FilterableTable.vue';
import NonEditableField from '@/components/NonEditableField.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useErrorHandling from '@/composables/useErrorHandling';
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import useValidationRules from '@/composables/useValidationRules';
import InventoryForProcess from '@/graphql/fragments/InventoryForProcess';
import GetStocksInStorage from '@/graphql/queries/GetStocksInStorage';
import type {
  Inventory,
  InventoryBatchDataInput,
  InventoryItem,
  Movement,
  MutationCompleteInventoryArgs,
  MutationDeleteInventoryArgs,
  ProductPack,
  Scalars,
  Stock,
} from '@/graphql/types';
import { InventoryStateEnum } from '@/graphql/types';
import * as badgeColors from '@/helpers/badgeColors';
import movementMeetsSearchString from '@/helpers/movementMeetsSearchString';
import navigateBack from '@/helpers/navigateBack';
import { createPrimitive } from '@/helpers/reports';
import ROUTES from '@/router/routeNames';
import type { ReportFilter } from '@/types/reports';
import InventoryEditNewStorable from '@/views/Storage/InventoryEditNewStorable.vue';
import { gql, useClientHandle, useQuery } from '@urql/vue';
import type { QTableColumn } from 'quasar';
import { omit } from 'ramda';
import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

useBreadcrumbs([ t('Operations'), t('Edit') ]);

const props = defineProps<{
  inventory: Inventory;
}>();

const { client: urql } = useClientHandle();

const { fillErrorsFromGraphQLError, getPrimaryError, clearErrors } = useErrorHandling();

const { gteRule } = useValidationRules();

const progress = reactive({
  saving: false,
  completing: false,
  deleting: false,
});

const inventoryRef = ref(props.inventory);

const stateBadgeColor = computed((): string => {
  return badgeColors.forOperationState(props.inventory);
});

const isInventoryCompleted = computed(() => props.inventory.state === InventoryStateEnum.COMPLETED);

function formatPackUnit(pack: ProductPack): string {
  const isBasic = pack.measurementUnit.id === pack.minMeasurementUnit.id;
  return pack.measurementUnit.name + (isBasic ? '' : ` (${pack.quantityInMinMeasurementUnits} ${pack.minMeasurementUnit.shortName})`);
}

// noinspection NestedConditionalExpressionJS
const itemsTableHeadersMovement: QTableColumn<Movement>[] = [
  {
    label: t('SKU'),
    name:  'productSku',
    field: m => m.storable.productPack.product.sku,
    align: 'left',
  },
  {
    label:  t('Name'),
    name:   'productName',
    field:  m => m.storable.productPack.product.name,
    align:  'left',
  },
  {
    label: t('Difference'),
    name:  'difference',
    field: item => (item.storageTo ? item.amount : -item.amount),
    style: 'min-width: 100px;',
  },
  {
    label: t('Before'),
    name:  'before',
    field: i => i.storageTo == null ? i.amountBeforeFrom : i.amountBeforeTo,
    style: 'min-width: 100px;',
  },
  {
    label: t('After'),
    name:  'after',
    field: calculateAfter,
    style: 'min-width: 100px;',
  },
  {
    label:  t('Base Unit'),
    name:   'measurementUnit',
    field:  item => item.storable.productPack,
    format: formatPackUnit,
  },
];

const itemsTableHeadersItems: QTableColumn<InventoryItem>[] = [
  {
    label: t('SKU'),
    name:  'productSku',
    field: s => s.storable.product.sku,
    align: 'left',
  },
  {
    label: t('Name'),
    name:  'productName',
    field: s => s.storable.product.name,
    align: 'left',
  },
  {
    label: t('Difference'),
    name:  'difference',
    field: 'diff',
    style: 'min-width: 100px;',
  },
  {
    label: t('Before'),
    name:  'before',
    field: item => stocksInStorageLoading.value ? '' : getStock(item.storable),
    style: 'min-width: 100px;',
  },
  {
    label: t('After'),
    name:  'after',
    // Поле не важно, используется слот
    field: () => '',
    style: 'min-width: 100px;',
  },
  {
    label:  t('Base Unit'),
    name:   'measurementUnit',
    field:  item => item.storable,
    format: formatPackUnit,
  },
];


function calculateAfter(item: Movement): number {
  // Если `storageTo` не заполнено, значит было списание из `storageFrom`.
  // Иначе был приход в `storageTo`.
  return item.storageTo == null
    ? item.amountBeforeFrom! - item.amount
    : item.amountBeforeTo! + item.amount;
}

async function saveInventory() {
  progress.saving = true;

  const { data, error } = await urql.mutation<{ inventory: Inventory }, MutationCompleteInventoryArgs>(
    gql`
      mutation SaveInventory(
        $inventoryId: ID!,
        $inventoryItems: [InventoryItemInput!]!,
      ) {
        inventory: saveInventory(
          inventoryId: $inventoryId,
          inventoryItems: $inventoryItems,
        ) { ...InventoryForProcess }
      }
      ${ InventoryForProcess }
    `,
    {
      inventoryId:          inventoryRef.value.id,
      inventoryItems:       inventoryRef.value.items.map(item => ({
        id:            item.id,
        diff:          item.diff,
        productPackId: item.storable.id,
        batchData:     omit(['__typename'], item.batchData),
      })),
    },
  );

  progress.saving = false;

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  if (data) {
    inventoryRef.value = data.inventory;
  }

  navigateBack({ name: ROUTES.INVENTORY_DASHBOARD });
}

async function complete(): Promise<void> {
  clearErrors();
  progress.completing = true;

  const { data, error } = await urql.mutation<{ inventory: Inventory }, MutationCompleteInventoryArgs>(
    gql`
      mutation CompleteInventory(
        $inventoryId: ID!,
        $inventoryItems: [InventoryItemInput!]!,
      ) {
        inventory: completeInventory(
          inventoryId: $inventoryId,
          inventoryItems: $inventoryItems,
        ) { ...InventoryForProcess }
      }
      ${ InventoryForProcess }
    `,
    {
      inventoryId:          inventoryRef.value.id,
      inventoryItems:       inventoryRef.value.items.map(item => ({
        id:            item.id,
        diff:          item.diff,
        productPackId: item.storable.id,
        batchData:     omit(['__typename'], item.batchData),
      })),
    },
  );

  progress.completing = false;

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  inventoryRef.value = data!.inventory;
}

async function deleteInventory(): Promise<void> {
  progress.deleting = true;

  const { error } = await urql.mutation<{ id: Scalars['ID'] }, MutationDeleteInventoryArgs>(
    gql`
      mutation DeleteInventory($inventoryId: ID!) {
        id: deleteInventory(inventoryId: $inventoryId)
      }
    `,
    { inventoryId: inventoryRef.value.id },
  );

  progress.deleting = false;

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  navigateBack({ name: ROUTES.INVENTORY_DASHBOARD });
}

function getStock(productPack: ProductPack): number {
  const stock = stocksInStorage.value
    .find(f => f.storageUnit.productPack.id === productPack.id);

  return stock ? stock.amount : 0;
}

const isInvalid = computed(() => inventoryRef.value.items.some(item => getStock(item.storable) + item.diff < 0));

const {
  data:     stocksInStorageResult,
  error:    stocksInStorageError,
  fetching: stocksInStorageLoading,
} = useQuery<{ stocksInStorage: Stock[] }>({
  query:     GetStocksInStorage,
  variables: computed(() => ({
    storageId: inventoryRef.value?.storage!.id,
  })),
  pause:     computed(() => !inventoryRef.value),
});
watch(stocksInStorageError, fillErrorsFromGraphQLError);
const stocksInStorage = computed<Stock[]>(() => stocksInStorageResult.value?.stocksInStorage ?? []);

function addProductPack(storable: ProductPack, batchData: InventoryBatchDataInput) {
  if (inventoryRef.value.items.some(item => item.storable.id === storable.id)) {
    return;
  }
  inventoryRef.value.items.push({
    __typename: 'InventoryItem',
    id:         null!,
    storable,
    batchData,
    diff:       0,
  });
}

const availableFilters: ReportFilter[] = [
  {
    label:     t('Difference'),
    field:     'difference',
    operators: [
      createPrimitive('>', 'string'),
      createPrimitive('=', 'string'),
      createPrimitive('<', 'string'),
      createPrimitive('!=', 'string', { label: '≠' }),
    ],
  },
];

function inventoryItemMeetsSearchString(item: InventoryItem, searchString: string) {
  const search = searchString.toLocaleLowerCase();
  const { barcodes, product } = item.storable;

  return [product.sku, product.name].some(v => v.toLocaleLowerCase().includes(search))
    || (barcodes?.some(b => b.barcode.toLocaleLowerCase() === search) ?? false);
}

</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/inventory.yaml"></i18n>

<i18n lang="yaml">
ru:
  "Inventory {id}, User: {user}": "Инвентаризация {id}, Сотрудник: {user}"
  Confirm amount: Подтвердить количество
  Difference: Изменение
  Before: До
  After: После
  Comment: Комментарий
  Base Unit: Единица упаковки
  Delete Inventory: Удалить инвентаризацию
  Delete inventory?: Удалить инвентаризацию?

en:
  "Inventory {id}, User: {user}": "Inventory {id}, User: {user}"
  Confirm amount: Confirm amount
  Difference: Difference
  Before: Before
  After: After
  Comment: Comment
  Base Unit: Base Unit
  Delete Inventory: Delete Inventory
  Delete inventory?: Delete inventory?
</i18n>
