<template>
  <div
    class="scroll-wrapper"
    :style="scrollWrapperStyle"
  >
    <div
      class="indicators"
      :style="indicatorsStyle"
    >
      <div
        v-for="n in count"
        :key="n"
        class="indicator"
        :class="indicatorClass?.(n - 1)"
        :style="{...indicatorStyle, marginLeft: n === 1 ? '0' : `${spacing}px`}"
        @click="emit('indicator-click', n - 1)"
      />
      <div
        class="indicator active"
        :style="{...indicatorStyle, ...activeIndicatorStyle}"
      />
    </div>
  </div>
</template>

<script setup lang="ts">

import { computed, type CSSProperties } from 'vue';
import { clamp } from 'ramda';

const props = withDefaults(defineProps<{
  count: number;
  active: number;
  limit: number;
  indicatorSize?: number;
  spacing?: number;
  indicatorClass?: (index: number) => string;
}>(), {
  indicatorSize: 10,
  spacing: 4,
});

const emit = defineEmits<{
  (e: 'indicator-click', index: number): void;
}>();

const scrollWrapperStyle = computed<CSSProperties>(() => {
  const maxWidth = props.limit * props.indicatorSize + (props.limit - 1) * props.spacing;

  return {
    maxWidth: `${maxWidth}px`,
  };
});

const indicatorsStyle = computed<CSSProperties>(() => {
  // Расчет смещения влево, чтобы текущий элемент по возможности был по центру.

  // Ограничиваем смещение, чтобы всегда отображалось не менее limit элементов.
  const constrainOffset = clamp(
    // Минимально возможное смещение влево - 0.
    // Отрицательное смещение приведет к появлению пустоты слева.
    0,
    // Максимально возможное смещение влево - разница между количеством элементов и лимитом.
    // Большее смещение приведет к появлению пустоты справа.
    Math.max(0, props.count - props.limit),
  );

  // Позиция текущего элемента относительно "центра".
  // Сдвигаем элементы влево на это значение с учетом ограничений.
  const offset = constrainOffset(props.active - Math.floor(props.limit / 2));
  const offsetPx = -offset * (props.indicatorSize + props.spacing);

  return {
    left: `${offsetPx}px`,
  };
});

const indicatorStyle = computed<CSSProperties>(() => ({
  width:  `${props.indicatorSize}px`,
  height: `${props.indicatorSize}px`,
}));

const activeIndicatorStyle = computed<CSSProperties>(() => {
  const left = props.active * (props.indicatorSize + props.spacing);
  return {
    left: `${left}px`,
  };
});

</script>

<style scoped lang="scss">

.scroll-wrapper {
  display: inline-block;
  overflow-x: hidden;
  line-height: 0;

  > .indicators {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    overflow-x: hidden;
    transition: left ($animate-duration / 2) ease;

    > .indicator {
      display: inline-block;

      border-radius: 50%;
      background-color: $grey-5;

      &.active {
        background-color: $primary;
        position: absolute;
        transition: left ($animate-duration / 2) ease;
      }
    }
  }
}

</style>
