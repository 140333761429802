<template>
  <div class="row">
    <div
      v-for="(photo, index) in photos"
      :key="photo.url"
      class="q-pa-md"
    >
      <QAvatar
        rounded
        class="cursor-pointer"
        @click="emit('photo-click', photo)"
      >
        <QImg
          :src="photo.url"
          :class="{ 'light-dimmed': isDeleted(index) }"
          ratio="1"
        />
      </QAvatar>
      <QAvatar rounded>
        <QBtn
          v-if="isDeleted(index)"
          flat
          round
          icon="mdi-restore"
          color="success"
          :title="t('Restore Photo')"
          @click="restorePhoto(index)"
        />
        <QBtn
          v-else
          flat
          round
          icon="mdi-delete"
          color="error"
          :title="t('Delete Photo')"
          @click="deletePhoto(index)"
        />
      </QAvatar>
    </div>
  </div>

  <BaseAlert
    v-if="externalErrors.length > 0"
    type="error"
  >
    {{ externalErrors }}
  </BaseAlert>

  <div>
    <QBtn
      color="success"
      icon="mdi-plus"
      @click="showUploadDialog = true"
    >
      {{ t('Upload new Photo') }}
    </QBtn>
    <QDialog v-model="showUploadDialog">
      <QCard style="width: 600px;">
        <CardTitle>{{ t('New Photo Upload') }}</CardTitle>

        <QSeparator />

        <QCardSection>
          <QFile
            v-model="newPhotoBlob"
            :label="t('Select Photo')"
            :accept="ALLOWED_FILE_TYPES.join(',')"
            :max-file-size="MAX_FILE_SIZE"
            :error="!!photoPickError"
            :error-message="photoPickError"
            @rejected="handleInvalidFile"
          >
            <template #before>
              <QIcon name="mdi-attachment" />
            </template>
            <template #file="{ file }">
              {{ file.name }} ({{ fileSize(file.size) }})
            </template>
          </QFile>
        </QCardSection>

        <QImg
          v-if="newPhotoUrl"
          ratio="1"
          fit="contain"
          :src="newPhotoUrl"
        />

        <QSeparator />

        <QCardActions>
          <QBtn @click="close()">
            {{ t('Cancel') }}
          </QBtn>

          <QSpace />

          <QBtn
            color="primary"
            :disable="!newPhotoUrl"
            @click="upload()"
          >
            {{ t('Done') }}
          </QBtn>
        </QCardActions>
      </QCard>
    </QDialog>
  </div>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import CardTitle from '@/components/CardTitle.vue';
import fileSize from '@/helpers/fileSize';
import type { PhotoForm } from '@/types';
import { useBase64 } from '@vueuse/core';
import mime from 'mime';
import type { QRejectedEntry } from 'quasar/dist/types/api/qfile';
import * as R from 'ramda';
import { computed, type Ref, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  photos: PhotoForm[];
  errors: Record<number, {
    [K in keyof Pick<PhotoForm, 'data'>]: string[];
  }>;
}>();

const emit = defineEmits<{
  (e: 'photo-click', photo: PhotoForm): void;
  (e: 'update:photos', photos: PhotoForm[]): void;
}>();

const newPhotoBlob = ref<File | null>(null);

const { base64: newPhotoUrl } = useBase64(newPhotoBlob as Ref<File>);

const showUploadDialog = ref(false);

function upload(): void {
  emit('update:photos', [...props.photos, {
    id:     null,
    data:   newPhotoBlob.value!,
    url:    newPhotoUrl.value!,
    delete: false,
  }]);

  close();
}

function close(): void {
  newPhotoBlob.value = null;
  showUploadDialog.value = false;
}

function deletePhoto(index: number): void {
  if (props.photos[index].id) {
    emit('update:photos', R.adjust(index, p => ({
      ...p,
      delete: true,
    }), props.photos));
  } else {
    emit('update:photos', R.remove(index, 1, props.photos));
  }
}

function restorePhoto(index: number): void {
  emit('update:photos', R.adjust(index, p => ({
    ...p,
    delete: false,
  }), props.photos));
}

function isDeleted(index: number): boolean {
  return props.photos[index].delete;
}

const externalErrors = computed(() => Object.values(props.errors).flatMap(e => e.data).join(', '));

// Дублируется на сервере (\App\Models\ProductPhoto::ALLOWED_MIME_TYPES)
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/webp'];
// Дублируется на сервере (\App\Models\ProductPhoto::MAX_FILE_SIZE)
const MAX_FILE_SIZE = 300 * 1024;

const photoPickError = ref('');

watch(newPhotoBlob, () => {
  photoPickError.value = '';
});

function handleInvalidFile(entries: QRejectedEntry[]) {
  photoPickError.value = entries.map(e => t(`validations.file.${e.failedPropValidation}`, {
    types: ALLOWED_FILE_TYPES.map(m => mime.getExtension(m)).join(', '),
    size:  fileSize(MAX_FILE_SIZE),
  })).join(', ');
}

</script>

<i18n lang="yaml">
ru:
  Delete Photo: Удалить фотографию
  Restore Photo: Отменить удаление
  Upload new Photo: Загрузить фотографию
  New Photo Upload: Загрузка новой фотографии
  Select Photo: Выберите фотографию

en:
  Delete Photo: Delete Photo
  Restore Photo: Restore Photo
  Upload new Photo: Upload new Photo
  New Photo Upload: New Photo Upload
  Select Photo: Select Photo
</i18n>
