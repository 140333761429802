<template>
  <PrimaryErrorBanner />
  <div class="column justify-center no-wrap">
    <ArrivalScanProductList
      ref="listRef"
      :error-messages="errorMessages"
      @barcode="scanField.scan($event)"
      @amount-changed="handleAmountChanged"
      @save-amount="saveStockAmount"
      @storage-unit-saved="handleStorageUnitSaved"
    />
    <ArrivalScanProductField
      ref="scanField"
      @scan="scanProductPack"
    />
  </div>
  <Teleport to="#teleport-target-buttons-row">
    <ButtonsRow v-slot="{ buttonProps }">
      <KeyboardToggleButton v-bind="buttonProps" />
      <ConfirmsAction
        :should-prompt="!!currentArrival"
        :confirm-text="t('Yes')"
        :cancel-text="t('No')"
        @confirmed="cancel"
      >
        <template #title>
          {{ t('Cancel Arrival?') }}
        </template>
        <template #activator="{ prompt }">
          <QBtn
            v-bind="buttonProps"
            icon="mdi-close-circle-outline"
            :loading="progress.deleting"
            @click="prompt"
          >
            {{ t('Cancel') }}
          </QBtn>
        </template>
      </ConfirmsAction>
      <QBtn
        v-if="currentArrival"
        v-bind="buttonProps"
        icon="mdi-check-outline"
        :loading="progress.completing || validatingBatch.size > 0"
        :disable="cannotComplete"
        @click="complete()"
      >
        {{ t('Complete') }}
      </QBtn>
      <FixErrorsPrompt
        v-model:slide="slideWithErrorToFix"
        :error="batchErrors[store.movements[slideWithErrorToFix!]?.id]?.[0]"
        @fix="listRef?.slideTo($event)"
      />
      <QBtn
        v-if="store.supply"
        v-bind="buttonProps"
        icon="mdi-format-list-checks"
        @click="emit('show-supply')"
      >
        {{ t('Task') }}
      </QBtn>
    </ButtonsRow>
  </Teleport>
</template>

<script setup lang="ts">

import ConfirmsAction from '@/components/ConfirmsAction.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import FixErrorsPrompt from '@/components/Mobile/FixErrorsPrompt.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useOmniInput from '@/composables/useOmniInput';
import useSpeaker from '@/composables/useSpeaker';
import type {
  Movement,
  ProductPack,
  ProductPackWithAmount,
  QueryValidateArrivalMovementArgs,
  Scalars,
  StorageUnit,
} from '@/graphql/types';
import { AskForWeightAndDimensionsOptionEnum } from '@/graphql/types';
import productPackIsMissingWeightOrDimensions
  from '@/helpers/productPackIsMissingWeightOrDimensions';
import { speechOnProductPackScan } from '@/helpers/speechOnScan';
import useProductArrivalStore from '@/stores/productArrival';
import ArrivalScanProductField from '@/views/Mobile/Arrival/ArrivalScanProductField.vue';
import ArrivalScanProductList from '@/views/Mobile/Arrival/ArrivalScanProductList.vue';
import { gql, useClientHandle } from '@urql/vue';
import { useDebounceFn, useEventListener } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { concat, difference, fromPairs, isEmpty, isNil, keys, mergeWith, uniqBy } from 'ramda';
import { computed, onBeforeMount, onBeforeUnmount, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

// noinspection LocalVariableNamingConventionJS
const {
  primaryError,
  fillErrorsFromGraphQLError,
  errorsFor,
  PrimaryErrorBanner,
} = useErrorHandling();

const emit = defineEmits<{
  (e: 'exit'): void;
  (e: 'show-supply'): void;
}>();

const store = useProductArrivalStore();

const speaker = useSpeaker();

const progress = reactive({
  completing: false,
  deleting:   false,
});

const { supply, currentArrival } = storeToRefs(store);

// Индекс слайда, на котором меняли количество.
// Чтобы не делать запросы прямо при вводе количества,
// сохранение выполняется не сразу.
const lastEditedAmountIndex = ref<number | null>(null);

function handleAmountChanged(index: number) {
  lastEditedAmountIndex.value = index;
  debouncedSaveLastEditedAmount();
}

const excessAmountErrors = computed(() => {
  if (!supply.value) {
    return {};
  }

  const products = uniqBy(p => p.id, store.movements.map(m => m.storable.productPack.product));

  const amountsByProduct = fromPairs(products.map(product => [
    product.id,
    {
      arrivedAmount:           store.getArrivedAmount(product),
      amountInAnotherArrivals: store.getOtherArrivalsAmount(product),
      expectedAmount:          store.getExpectedAmount(product),
    },
  ]));

  // Движения отсортированы по дате, новые в начале.
  return fromPairs(store.movements
    .map(m => [m.id, validateMovement(m)] as const)
    .filter(([, errors]) => errors.length > 0));

  // noinspection NestedFunctionJS
  function validateMovement(m: Movement): string[] {
    const amounts = amountsByProduct[m.storable.productPack.product.id];
    if (!amounts) {
      return [];
    }

    const arrivedAmount = amounts.arrivedAmount + amounts.amountInAnotherArrivals;

    if (arrivedAmount > amounts.expectedAmount) {
      return [t('Excess amount')];
    }

    return [];
  }
});

watch(excessAmountErrors, (errors, oldErrors) => {
  const newErrors = keys(errors)
    .flatMap(id => difference(errors[id], oldErrors[id] ?? {}));

  if (newErrors.length > 0) {
    speaker.speak(newErrors[0]);
  }
}, { deep: true });

const batchErrors = reactive<Record<Scalars['ID'], string[]>>({});

const errorMessages = computed(() => mergeWith(concat, excessAmountErrors.value, batchErrors));

const { client: urql } = useClientHandle();

const validatingBatch = reactive(new Set<Scalars['ID']>());

async function validateMovementBatch(movement: Movement) {
  validatingBatch.add(movement.id);

  const errors = [];

  if (store.isBatchDataRequired(movement)) {
    errors.push(t('Specify batch'));
  }

  const { data } = await urql.query<{ result: string | null }, QueryValidateArrivalMovementArgs>(
    gql`
      query ValidateArrivalMovementMutation($movementId: ID!) {
        result: validateArrivalMovement(movementId: $movementId)
      }
    `,
    { movementId: movement.id },
  );

  if (data?.result) {
    errors.push(data.result);
  }

  if (errors.length > 0) {
    batchErrors[movement.id] = errors;
  } else {
    delete batchErrors[movement.id];
  }

  validatingBatch.delete(movement.id);
}

function handleStorageUnitSaved({ index, storageUnit }: { index: number; storageUnit: StorageUnit }): void {
  const movement = store.movements[index];
  if (!movement) {
    return;
  }

  movement.storable = storageUnit;
  validateMovementBatch(movement);
}

const cannotComplete = computed(() =>
  store.noMovements
  || validatingBatch.size > 0,
);

async function saveLastEditedAmount() {
  if (isNil(lastEditedAmountIndex.value)) {
    return;
  }

  return saveStockAmount({
    index:  lastEditedAmountIndex.value,
    amount: store.movements[lastEditedAmountIndex.value].amount,
  });
}

onBeforeUnmount(saveLastEditedAmount);
// Запрос к серверу тут же отменяется, но до сервера он доходит, сохранение выполняется.
useEventListener('beforeunload', saveLastEditedAmount);

const debouncedSaveLastEditedAmount = useDebounceFn(saveLastEditedAmount, 5000);

async function saveStockAmount({ index, amount }: {
  index: number;
  amount: number;
}): Promise<void> {
  const savingLastEditedAmount = index === lastEditedAmountIndex.value;
  if (savingLastEditedAmount) {
    lastEditedAmountIndex.value = null;
  }

  const movement = store.movements[index];

  if (amount === 0) {
    const movementId = movement.id;
    const error = await store.deleteMovement(movement);
    if (error) {
      fillErrorsFromGraphQLError(error);
      speaker.speak(primaryError.value);
      if (savingLastEditedAmount) {
        lastEditedAmountIndex.value = index;
      }
    } else {
      delete batchErrors[movementId];
    }
  } else {
    await store.updateMovementAmount(movement, amount);
  }
}

onBeforeMount(async () => {
  await store.createArrivalIfNeeded();
  store.movements.map(validateMovementBatch);
});

async function scanProductPack(packWithAmount: ProductPackWithAmount): Promise<void> {
  const pack = packWithAmount.productPack;
  const alreadyScanned = store.movements.findLast(m => m.storable.productPack.id === pack.id);
  const lastScannedIndex = store.movements.length - 1;

  // Если отсканирован товар с последнего слайда, то надо увеличить для него количество на 1.
  if (alreadyScanned && alreadyScanned === store.movements[lastScannedIndex]) {
    if (lastScannedIndex === lastEditedAmountIndex.value) {
      // Если слайд, который ожидает сохранения, совпал с отсканированным,
      // его отдельное сохранение не требуется.
      lastEditedAmountIndex.value = null;
    }
    await saveLastEditedAmount();

    await store.updateMovementAmount(
      alreadyScanned,
      alreadyScanned.amount + packWithAmount.amount,
    );
    listRef.value?.movementScanned(alreadyScanned);
  } else {
    // Иначе создаем новое движение (и новый слайд).

    speaker.speak(speechOnProductPackScan(pack));
    if (productPackIsMissingWeightOrDimensions(pack) && shouldNotifyAboutWeightAndDimensions(pack)) {
      speaker.speak(t('Specify Weight and Dimensions'));
    }

    await saveLastEditedAmount();

    const { data, error } = await store.addArrivedProductPack(pack, packWithAmount.amount);

    if (error) {
      fillErrorsFromGraphQLError(error);
      speaker.speak(errorsFor<string[]>('movements')[0]);
      return;
    } else {
      if (store.isBatchDataRequired(data!.movement)) {
        speaker.speak(t('Specify batch'));
      }
      listRef.value?.movementScanned(data!.movement);
      // noinspection ES6MissingAwait
      validateMovementBatch(data!.movement);
    }
  }

  const arrivedAmount = store.getArrivedAmount(pack.product);
  const expectedAmount = store.getExpectedAmount(pack.product)
    - store.getOtherArrivalsAmount(pack.product);

  if (expectedAmount > 0) {
    speaker.speak(t('{amount} out of {total}', {
      amount: arrivedAmount,
      total:  expectedAmount,
    }));
  } else {
    speaker.speak(`${arrivedAmount}`);
  }
}

function shouldNotifyAboutWeightAndDimensions(pack: ProductPack): boolean {
  return [
    AskForWeightAndDimensionsOptionEnum.ALWAYS,
    AskForWeightAndDimensionsOptionEnum.ARRIVAL_ONLY,
  ].includes(pack.product.accountingModel.askForWeightAndDimensions);
}

const listRef = ref<InstanceType<typeof ArrivalScanProductList>>();

const slideWithErrorToFix = ref<number | null>(null);

async function complete(): Promise<void> {
  if (!currentArrival.value) {
    primaryError.value = t('Cannot complete. Products are not scanned');
    speaker.speak(primaryError.value);
    return;
  }

  if (!isEmpty(batchErrors)) {
    const movementId = Object.keys(batchErrors)[0];
    slideWithErrorToFix.value = store.movements.findIndex(m => m.id === movementId);
    speaker.speak(t('Fix the errors'));
    return;
  }

  speaker.speak(t('Completed'));

  progress.completing = true;

  await saveLastEditedAmount();

  const { error } = await store.completeArrival();

  progress.completing = false;

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  emit('exit');
}

async function cancel(): Promise<void> {
  if (currentArrival.value) {
    const deleted = await deleteArrival();
    if (!deleted) {
      return;
    }
  }

  emit('exit');
}

async function deleteArrival() {
  progress.deleting = true;

  const error = await store.deleteArrival();

  progress.deleting = false;

  if (error) {
    fillErrorsFromGraphQLError(error);
    speaker.speak(primaryError.value);
    return false;
  }

  return true;
}

const scanField = ref<InstanceType<typeof ArrivalScanProductField>>(null!);

const { KeyboardToggleButton }  = useOmniInput({ skip: true });

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/speaking.yaml"></i18n>

<i18n lang="yaml">
ru:
  Specify batch: Укажите партию
  Excess amount: Лишнее
  Cannot complete. Products are not scanned: Невозможно завершить. Товары не отсканированы
  Cancel Arrival?: Отменить приёмку?
  Fix the errors: Исправьте ошибки

en:
  Specify batch: Specify batch
  Excess amount: Excess amount
  Cannot complete. Products are not scanned: Cannot complete. Products are not scanned
  Cancel Arrival?: Cancel Arrival?
  Fix the errors: Fix the errors

</i18n>
