<template>
  <QExpansionItem
    v-if="visibleItems.length > 0"
    :content-inset-level="0.25"
    group="drawer"
  >
    <template #header>
      <QItemSection avatar>
        <QAvatar :icon="icon" />
      </QItemSection>
      <QItemSection>{{ title }}</QItemSection>
    </template>

    <NavigationItem
      v-for="item in visibleItems"
      :key="item.route"
      :item="item"
    />
  </QExpansionItem>
</template>

<script setup lang="ts">

import useNavigationItems from '@/composables/useNavigationItems';
import NavigationItem from '@/views/Navigation/NavigationItem.vue';
import { computed } from 'vue';

const props = defineProps<{
  title: string;
  icon: string;
  children: string[];
}>();

const { getItemByRoute, isItemAvailable } = useNavigationItems();

const visibleItems = computed(() => props.children.map(getItemByRoute).filter(isItemAvailable));

</script>
