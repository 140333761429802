<template>
  <FullHeightPage>
    <GraphQLQueryTable
      :graphql-query="query"
      :fields="tableFields"
      storage-prefix="cellsAreasList.report"
      keep-page-in-url
      without-search
      sticky-header
      class="col"
    >
      <template #after-search-string>
        <QBtn
          v-if="canCreate"
          color="primary"
          :to="{ name: ROUTES.CELLS_AREAS_NEW }"
          icon="mdi-account-plus"
        >
          {{ t('Add Cells Area') }}
        </QBtn>
      </template>

      <template #body-cell-id="column">
        <BodyCellLink
          :column="column"
          :to="{ name: ROUTES.CELLS_AREAS_EDIT, params: { id: String(column.value) } }"
          :disable="!column.row.isMutable"
        />
      </template>

      <template #body-cell-kind="column">
        <QTd :props="column">
          <QChip
            color="grey-4"
            text-color="black"
          >
            {{ t('cellsAreaKind.' + column.value) }}
          </QChip>
        </QTd>
      </template>
    </GraphQLQueryTable>
  </FullHeightPage>
</template>

<script setup lang="ts">

import FullHeightPage from '@/components/FullHeightPage.vue';
import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useCan from '@/composables/useCan';
import type { CellsArea } from '@/graphql/types';
import type { TableColumn } from '@/types/reports';
import { useI18n } from 'vue-i18n';
import { Ability } from '@/types/backend';
import { gql } from '@urql/vue';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

useBreadcrumbs(t('Cells Areas'));

const tableFields: TableColumn<CellsArea>[] = [
  {
    label:     t('ID'),
    name:      'id',
    field:     'id',
    mandatory: true,
    align:     'left',
    sortable:  true,
  },
  {
    label:    t('Name'),
    name:     'name',
    field:    'name',
    align:    'left',
    sortable: true,
  },
  {
    label: t('Kind'),
    name:  'kind',
    field: 'kind',
    align: 'left',
  },
  {
    label: t('Separate Selection'),
    name:  'nameForSeparateSelection',
    field: 'nameForSeparateSelection',
    align: 'left',
  },
  {
    label: t('Cells count'),
    name:  'cellsCount',
    field: 'cellsCount',
  },
  {
    label: t('Product packs count'),
    name:  'productPacksCount',
    field: 'productPacksCount',
  },
];

const query = gql`
  query GetCellsAreasReport($page: Int, $perPage: Int!, $query: String, $filter: [ReportFilterInput!], $sort: [ReportSortInput!]!) {
    result: cellsAreasReport(page: $page, perPage: $perPage, query: $query, filter: $filter, sort: $sort) {
      data {
        id
        name
        kind
        nameForSeparateSelection
        cellsCount
        productPacksCount
        isMutable
      }
      total
    }
  }
`;

const canCreate = useCan(Ability.ViewCellsAreas);

</script>

<i18n lang="yaml">
ru:
  Add Cells Area: Добавить зону
  Kind: Тип
  Separate Selection: Отдельный отбор
  Cells count: Количество ячеек
  Product packs count: Количество уникальных товаров
en:
  Add Cells Area: Add Cells Area
  Kind: Kind
  Separate Selection: Separate Selection
  Cells count: Cells count
  Product packs count: Product packs count
</i18n>
