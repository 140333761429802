<template>
  <QDialog
    :model-value="productPack !== null"
    :persistent="progress.saving || progress.generatingBarcode || progress.deleting"
    @update:model-value="emit('close')"
  >
    <QCard v-if="editingPack">
      <BaseAlert
        v-if="primaryError"
        type="error"
      >
        {{ primaryError }}
      </BaseAlert>
      <CardTitle>
        {{ t('Product pack') }}
      </CardTitle>

      <QSeparator />

      <QCardSection>
        <div class="row q-col-gutter-md">
          <div class="col">
            <QSelect
              v-model="editingPack.measurementUnit"
              :options="measurementUnits"
              :loading="measurementUnitsLoading"
              :disable="measurementUnitsLoading"
              option-label="name"
              :label="t('Measurement Unit')"
              :error="v.measurementUnit.$error"
              :error-message="v.measurementUnit.$errors[0]?.$message"
            />
          </div>
          <div class="col">
            <QSelect
              v-model="editingPack.smallerProductPack"
              :options="productPacks"
              :option-label="pp => pp.measurementUnit.name"
              :label="t('Consists Of')"
              :disable="packIsUsedInMovements"
              :error="v.smallerProductPack.$error"
              :error-message="v.smallerProductPack.$errors[0]?.$message"
            />
          </div>
        </div>
        <div class="row q-col-gutter-md">
          <div class="col">
            <QInput
              v-model.number="editingPack.quantity"
              type="number"
              min="1"
              :disable="packIsUsedInMovements"
              :rules="[positiveFloatRule]"
              v-bind="qErrorsFor('quantity')"
              :label="t('Quantity')"
            />
          </div>
          <div class="col">
            <EditWeight
              v-model="editingPack.weight"
              v-bind="vuelidateToQuasarProps(v.weight)"
              :label="t('Weight (kg)')"
            />
          </div>
          <div class="col">
            <QInput
              v-model="packVolume"
              :label="t('Volume (cm³)')"
              type="number"
              min="1"
              :rules="[positiveOrNullRule]"
            />
          </div>
        </div>
        <div class="row q-col-gutter-md">
          <EditDimensions v-model="editingPack.dimensions">
            <template #default="{ dimensions }">
              <div class="col">
                <QInput
                  v-model.number="dimensions.length"
                  :label="t('Length (cm)')"
                  type="number"
                  min="1"
                  :rules="[positiveIntegerOrNullRule]"
                  v-bind="qErrorsFor('dimensions.length')"
                />
              </div>
              <div class="col">
                <QInput
                  v-model.number="dimensions.width"
                  :label="t('Width (cm)')"
                  type="number"
                  min="1"
                  :rules="[positiveIntegerOrNullRule]"
                  v-bind="qErrorsFor('dimensions.width')"
                />
              </div>
              <div class="col">
                <QInput
                  v-model.number="dimensions.height"
                  :label="t('Height (cm)')"
                  type="number"
                  min="1"
                  :rules="[positiveIntegerOrNullRule]"
                  v-bind="qErrorsFor('dimensions.height')"
                />
              </div>
            </template>
          </EditDimensions>
        </div>
        <BaseAlert
          v-if="v.dimensions.$error"
          type="error"
        >
          {{ v.dimensions.$errors[0].$message }}
        </BaseAlert>
        <p>{{ t('Barcodes') }}</p>
        <div>
          <QChip
            v-for="barcode in editingPack.barcodes"
            :key="barcode.barcode"
            :color="barcodeIsLast(barcode) ? 'primary' : 'grey-4'"
            :text-color="barcodeIsLast(barcode) ? 'white' : 'black'"
            removable
            @remove="deleteBarcode(barcode)"
          >
            {{ barcode.group ? `${barcode.group}:` : '' }}{{ barcode.barcode }}
          </QChip>
          <QBtn
            flat
            round
            icon="mdi-plus"
            color="success"
            :title="t('Add Barcode')"
          >
            <QMenu v-model="showNewBarcodeDialog">
              <QCard>
                <QCardSection>
                  <div class="row no-wrap">
                    <QInput
                      v-model="newBarcodeGroup"
                      :label="t('Type')"
                      :disable="progress.saving"
                      style="width: 64px;"
                    />
                    <QInput
                      v-model="newBarcode"
                      :label="t('Enter manually')"
                      autofocus
                      :error="existsBarcode"
                      :error-message="existsBarcode ? t('Barcode exists') : ''"
                      :loading="progress.saving"
                      :rules="[minLength(BARCODE_MIN_LENGTH)]"
                      lazy-rules
                      no-error-icon
                      @keypress.enter="saveNewBarcode()"
                    >
                      <template
                        v-if="otherPacksWithBarcode.length === 0"
                        #after
                      >
                        <QBtn
                          flat
                          round
                          icon="mdi-check"
                          color="positive"
                          :loading="progress.checking"
                          :disable="newBarcode.length < BARCODE_MIN_LENGTH"
                          @click="saveNewBarcode()"
                        />
                      </template>
                    </QInput>
                  </div>

                  <div v-if="otherPacksWithBarcode.length === 0">
                    <DividerWithText :text="t('or')" />
                    <QBtn
                      class="full-width"
                      icon="mdi-dice-3"
                      :loading="progress.generatingBarcode"
                      @click="generateNewBarcode()"
                    >
                      {{ t('Generate Automatically') }}
                    </QBtn>
                  </div>
                </QCardSection>
                <div v-if="otherPacksWithBarcode.length > 0">
                  <BaseAlert
                    type="warning"
                    style="width: 300px;"
                  >
                    {{ t('Barcode belongs to another product, do you want to add it?') }}
                  </BaseAlert>
                </div>
                <QCardActions
                  v-if="otherPacksWithBarcode.length > 0"
                  class="row justify-evenly"
                >
                  <QBtn
                    class="col-5 q-col-gutter"
                    color="primary"
                    icon="mdi-plus"
                    @click="completeSaving"
                  >
                    {{ t('Yes') }}
                  </QBtn>
                  <QBtn
                    class="col-5 q-col-gutter"
                    color="red"
                    icon="mdi-cancel"
                    @click="otherPacksWithBarcode = []"
                  >
                    {{ t('No') }}
                  </QBtn>
                </QCardActions>
              </QCard>
            </QMenu>
          </QBtn>
        </div>
      </QCardSection>

      <BaseAlert
        v-if="barcodesErrors.length > 0"
        type="error"
      >
        {{ barcodesErrors.join(', ') }}
      </BaseAlert>

      <QSeparator />

      <QCardActions>
        <ConfirmsAction
          :confirm-text="t('Delete')"
          @confirmed="deleteProductPack(editingPack)"
        >
          <template #activator="{ prompt }">
            <QBtn
              color="red"
              icon="mdi-delete"
              :label="t('Delete')"
              :loading="progress.deleting"
              @click="prompt"
            />
          </template>
          <template #title>
            {{ t('Delete product pack?') }}
          </template>
        </ConfirmsAction>

        <QSpace />

        <QBtn
          color="success"
          icon="mdi-check"
          :disable="v.$invalid"
          :loading="progress.saving"
          @click="saveProductPack(editingPack)"
        >
          {{ t('Save') }}
        </QBtn>
        <QBtn
          icon="mdi-cancel"
          @click="emit('close')"
        >
          {{ t('Cancel') }}
        </QBtn>
      </QCardActions>
    </QCard>
  </QDialog>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import CardTitle from '@/components/CardTitle.vue';
import ConfirmsAction from '@/components/ConfirmsAction.vue';
import DividerWithText from '@/components/DividerWithText.vue';
import EditDimensions from '@/components/EditDimensions.vue';
import EditWeight from '@/components/EditWeight.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useValidationRules from '@/composables/useValidationRules';
import { BARCODE_MIN_LENGTH } from '@/constants';
import ProductPackForDesktopEdit from '@/graphql/fragments/ProductPackForDesktopEdit';
import type {
  Barcode,
  BarcodeInput,
  Dimensions,
  MeasurementUnit,
  MutationDeleteProductPackArgs,
  MutationGenerateProductPackBarcodeArgs,
  MutationSaveProductPackArgs,
  ProductPack,
  ProductPackOrStorage,
  Query, QueryProductPackIsUsedInMovementsArgs,
} from '@/graphql/types';
import pickSmallerPackAndUnitForNewPack from '@/helpers/pickSmallerPackAndUnitForNewPack';
import spliceByValue from '@/helpers/spliceByValue';
import { required, requiredIf } from '@/setup/validation';
import { gql, useClientHandle, useQuery } from '@urql/vue';
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { clone, last } from 'ramda';
import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { MeasurementUnitClassEnum } from '@/graphql/types';

const { t } = useI18n();

const { clearErrors, primaryError, fillErrorsFromGraphQLError, errorsFor, qErrorsFor, vuelidateToQuasarProps } = useErrorHandling();
const progress = reactive({
  checking:          false,
  generatingBarcode: false,
  saving:            false,
  deleting:          false,
});
const {
  isNonNegativeIntegerOrEmpty,
  minLength,
  positiveFloatRule,
  positiveIntegerOrNullRule,
  positiveOrNullRule,
} = useValidationRules();

const { client: urql } = useClientHandle();

const props = defineProps<{
  productPack: ProductPack | null;
  productPacks: ProductPack[];
}>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const editingPack = ref<ProductPack>(null!);

const v = useVuelidate<ProductPack>({
  measurementUnit:    { required },
  smallerProductPack: {
    required: requiredIf(() => editingPack.value?.measurementUnit?.class !== MeasurementUnitClassEnum.PIECES),
    measurementUnitDiffers: helpers.withMessage(
      t('Should not consist of Product Pack with same Measurement Unit'),
      (smallerPack: ProductPack, pack: ProductPack) => !smallerPack || smallerPack.measurementUnit.class !== pack.measurementUnit?.class,
    ),
  },
  dimensions:         [helpers.withMessage(t('Not all dimensions are filled'), validDimensions)],
  weight:             [
    helpers.withMessage(t('validations.Expected weight with an accuracy of one gram'), isNonNegativeIntegerOrEmpty),
    helpers.withMessage(errorsFor<string[]>('weight')[0] || '', () => errorsFor('weight').length === 0),
  ],
}, editingPack, { $autoDirty: true });

const {
  data: measurementUnitsResult,
  fetching: measurementUnitsLoading,
} = useQuery<{ measurementUnits: MeasurementUnit[] }>({
  query: gql`query GetMeasurementUnits { measurementUnits { id name shortName class } }`,
});
const measurementUnits = computed<MeasurementUnit[]>(() => measurementUnitsResult.value?.measurementUnits ?? []);

watch([editingPack, measurementUnits], ([pack, units]) => {
  if (pack && !pack.measurementUnit && !pack.smallerProductPack && units.length > 0) {
    Object.assign(editingPack.value, pickSmallerPackAndUnitForNewPack(props.productPacks, units));
  }
});

const newBarcodeGroup = ref('');
const newBarcode = ref('');

const showNewBarcodeDialog = ref(false);

const packVolume = computed((): number | null => {
  const dimensions = editingPack.value.dimensions;

  if (dimensions) {
    return dimensions.length * dimensions.width * dimensions.height;
  }

  return null;
});

function barcodeIsLast(barcode: Barcode): boolean {
  return editingPack.value.lastScannedBarcode?.barcode === barcode.barcode;
}

const existsBarcode = ref(false);

watch(newBarcode, function newBarcodeChanged(): void {
  existsBarcode.value         = false;
  otherPacksWithBarcode.value = [];
});

const otherPacksWithBarcode = ref<ProductPackOrStorage[]>([]);

async function saveNewBarcode(): Promise<void> {
  if (!newBarcode.value) {
    return;
  }

  if (barcodeExists(newBarcode.value)) {
    existsBarcode.value = true;
    return;
  }

  progress.checking = true;

  const entities = await fetchEntitiesByBarcode(newBarcode.value);

  progress.checking = false;

  if (entities.some(e => e.__typename !== 'ProductPack')) {
    existsBarcode.value = true;
    return;
  }

  otherPacksWithBarcode.value = entities.filter(e => e.__typename === 'ProductPack'
      && e.product.id !== props.productPack.product.id);

  if (otherPacksWithBarcode.value.length > 0) {
    return;
  }
  completeSaving();
}

function completeSaving() {
  addNewBarcodeToProductPack({ barcode: newBarcode.value, group: newBarcodeGroup.value, isGenerated: false });
  showNewBarcodeDialog.value = false;
}

watch(showNewBarcodeDialog, function newBarcodeDialogToggled(isShownNow, wasShown): void {
  if (wasShown && !isShownNow) {
    newBarcode.value = '';
    newBarcodeGroup.value = '';
    otherPacksWithBarcode.value = [];
  }
});

function barcodeExists(input: string): boolean {
  return props.productPacks.some(pack => pack.barcodes.some(b => b.barcode === input));
}

async function fetchEntitiesByBarcode(barcode: string): Promise<ProductPackOrStorage[]> {
  const { data } = await urql.query<Pick<Query, 'entitiesByBarcode'>>(
    gql`
      query ProductPackDialogGetEntitiesByBarcode($barcode: String!) {
        entitiesByBarcode(barcode: $barcode) {
          ... on Storage { __typename id }
          ... on ProductPack {
            __typename
            id
            product {
              id
            }
          }
        }
      }
    `,
    { barcode },
  );

  return data?.entitiesByBarcode || [];
}

async function generateNewBarcode(): Promise<void> {
  progress.generatingBarcode = true;

  try {
    const barcode = editingPack.value.id
      ? await generateBarcodeForPack(editingPack.value)
      : await generateBarcodeForNewpack();
    addNewBarcodeToProductPack(barcode);
    showNewBarcodeDialog.value = false;
  } catch (error) {
    fillErrorsFromGraphQLError(error);
  } finally {
    progress.generatingBarcode = false;
  }
}

async function generateBarcodeForPack(pack: ProductPack): Promise<Barcode> {
  const { error, data } = await urql.mutation<{ pack: ProductPack }, MutationGenerateProductPackBarcodeArgs>(
    gql`
      mutation GenerateProductPackBarcode($id: ID!) {
        pack: generateProductPackBarcode(id: $id) { barcodes { barcode group isGenerated } }
      }
    `,
    { id: pack.id },
  );

  if (error) {
    throw error;
  } else {
    return last(data!.pack.barcodes)!;
  }
}

async function generateBarcodeForNewpack() {
  const { error, data } = await urql.mutation<{ barcode: string }>(
    gql`
      mutation GenerateBarcodeForNewProductPack { barcode: generateUniqueBarcode }
    `,
    {},
  );

  if (error) {
    throw error;
  } else {
    return { barcode: data!.barcode, group: null, isGenerated: true };
  }
}

const packIsUsedInMovements = computed(() => props.productPack?.id ? data.value?.productPackIsUsedInMovements : false);

const { data } = useQuery<{ productPackIsUsedInMovements: boolean }, QueryProductPackIsUsedInMovementsArgs>({
  query:     gql`
     query GetProductPackIsUsedInMovements($productPackId: ID!) {
         productPackIsUsedInMovements(productPackId:  $productPackId)
    }
  `,
  variables: computed(() => ({
    productPackId: props.productPack?.id,
  })),
  pause:     computed(() => !props.productPack || !props.productPack?.id)

});

watch(() => props.productPack, function productPackChanged(pack): void {
  editingPack.value = clone(pack);
  clearErrors();
});

function deleteBarcode(barcode: Barcode): void {
  spliceByValue(editingPack.value.barcodes, barcode);

  if (editingPack.value.lastScannedBarcode?.barcode === barcode.barcode) {
    editingPack.value.lastScannedBarcode = editingPack.value.barcodes.find(b => !b.group) ?? null;
  }
}

async function saveProductPack(pack: ProductPack): Promise<void> {
  clearErrors();
  progress.saving = true;
  const { error } = await urql.mutation<{ productPack: ProductPack }, MutationSaveProductPackArgs>(
    gql`
      ${ProductPackForDesktopEdit}
      mutation SaveProductPack($productId: ID!, $productPack: ProductPackInput!) {
        productPack: saveProductPack(productId: $productId, productPack: $productPack) {
          ...ProductPackForDesktopEdit
        }
      }
    `,
    {
      productId:   editingPack.value.product.id,
      productPack: {
        id:                   pack.id,
        unitId:               pack.measurementUnit.id,
        smallerProductPackId: pack.smallerProductPack?.id || null,
        quantity:             pack.quantity,
        weight:               pack.weight || null,
        dimensions:           mapDimensionsToSaveObject(pack.dimensions as Dimensions),
        barcodes:             pack.barcodes.map(barcodeToBarcodeInput),
        lastScannedBarcode:   pack.lastScannedBarcode
          ? barcodeToBarcodeInput(pack.lastScannedBarcode)
          : null,
      },
    },
  );

  progress.saving = false;

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  emit('close');
}

async function deleteProductPack(pack: ProductPack) {
  progress.deleting = true;

  const { error } = await urql.mutation<unknown, MutationDeleteProductPackArgs>(gql`
    mutation DeleteProductPack($id: ID!) {
      deleteProductPack(id: $id)
    }
  `, { id: pack.id });

  progress.deleting = false;

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }
  emit('close');
}

const barcodeToBarcodeInput = (barcode: Barcode): BarcodeInput => ({
  barcode:     barcode.barcode,
  group:       barcode.group,
  isGenerated: barcode.isGenerated,
});

function isAllDimensionsSet(dimensions: Dimensions): boolean {
  // eslint-disable-next-line unicorn/explicit-length-check
  return !!(dimensions.length && dimensions.width && dimensions.height);
}

function notEmptyDimensions(dimensions: Dimensions): boolean {
  // eslint-disable-next-line unicorn/explicit-length-check
  return !!(dimensions.length || dimensions.width || dimensions.height);
}

function mapDimensionsToSaveObject(dimensions: Dimensions | null): Dimensions | null {
  if (!dimensions) {
    return null;
  }

  return {
    length: dimensions.length,
    width:  dimensions.width,
    height: dimensions.height,
  } as Dimensions;
}

function validDimensions(dimensions: Dimensions | null): boolean {
  if (!dimensions) {
    return true;
  }

  if (isAllDimensionsSet(dimensions)) {
    return true;
  }

  return !notEmptyDimensions(dimensions);
}

function addNewBarcodeToProductPack(barcode: Barcode): void {
  editingPack.value.barcodes.push(barcode);

  if (!editingPack.value.lastScannedBarcode) {
    editingPack.value.lastScannedBarcode = barcode;
  }
}

const barcodesErrors = computed(() => Object.values(errorsFor('productPack.barcodes')).map(Object.values).flat(2));

</script>

<i18n lang="yaml">
ru:
  Weight (kg): Вес (кг)
  Add Barcode: Добавить штрихкод
  Enter manually: Ввести вручную
  Barcode exists: Штрихкод уже существует
  Generate Automatically: Создать автоматически
  Length (cm): Длина (см)
  Not all dimensions are filled: Не все габариты заполнены
  Width (cm): Ширина (см)
  Height (cm): Высота (см)
  Volume (cm³): Объем (см³)
  Standard: Обычная
  Delete product pack?: Удалить упаковку?
  Should not consist of Product Pack with same Measurement Unit: >
    Должна состоять из упаковок с другой единицей упаковки

en:
  Weight (kg): Weight (kg)
  Add Barcode: Add Barcode
  Enter manually: Enter manually
  Barcode exists: Barcode exists
  Generate Automatically: Generate Automatically
  Length (cm): Length (cm)
  Not all dimensions are filled: Not all dimensions are filled
  Width (cm): Width (cm)
  Height (cm): Height (cm)
  Volume (cm³): Volume (cm³)
  Standard: Standard
  Delete product pack?: Delete product pack?
  Should not consist of Product Pack with same Measurement Unit: >
    Should not consist of Product Pack with same Measurement Unit
</i18n>
