ru:
  By carrier orders: Из СД заказов
  By order: Из заказа
  By customer return: Из возврата покупателя
  By supplier return: Из возврата поставщика
  By supply: Из поступления
  Enter from keyboard: Введите с клавиатуры
  Label count: Число этикеток
  Paper Format: Формат бумаги
  Print: Печать
  Selected for print {size}: Выбрано для печати {size}
  Specific amount: Указанное количество
  Printing: Печать
  Print settings: Настройка печати
  Weight Type: Весовой

en:
  By carrier orders: By carrier orders
  By order: By order
  By customer return: By customer return
  By supplier return: By supplier return
  By supply: By supply
  Enter from keyboard: Enter from keyboard
  Label count: Label count
  Selected for print {size}: Selected for print {size}
  Specific amount: Specific amount
  Print settings: Print settings
  Weight Type: Weight Type
