<template>
  <PrimaryErrorBanner />
  <BigLoading v-if="fetching" />
  <div v-else-if="product">
    <QToolbar class="q-px-md">
      <QToolbarTitle style="white-space: normal;">
        {{ product.name }}
      </QToolbarTitle>
    </QToolbar>

    <HorizontalPropsList v-slot="{ item }">
      <component
        :is="item"
        :caption="t('SKU')"
      >
        {{ product.sku }}
      </component>
      <component
        :is="item"
        :caption="t('Stock')"
      >
        <QSkeleton
          v-if="totalStock === null"
          type="text"
        />
        <template v-else>
          {{ totalStock }} {{ product.mostBasicProductPack.measurementUnit.shortName }}
        </template>
      </component>
      <component
        :is="item"
        :caption="t('Supplier')"
      >
        {{ product.primarySupplier?.name }}
      </component>
      <component
        :is="item"
        :caption="t('Accounting model')"
      >
        {{ product.accountingModel.name }}
      </component>
      <component
        :is="item"
        :caption="t('Category')"
      >
        {{ product.category?.name }}
      </component>
    </HorizontalPropsList>

    <ProductPhotosMobile
      ref="productPhotosRef"
      :product="product"
      deletable
      @update:photos="product.photos = $event"
    />

    <CardTitle>
      {{ t('Stocks') }}
    </CardTitle>

    <QSeparator />

    <ProductStocksMobile
      v-model:totalStock="totalStock"
      :product="product"
    />

    <QSeparator />

    <CardTitle>
      {{ t('Product Packs') }}
    </CardTitle>

    <EditProductPacks
      :product="product"
      :buttons-panel="buttonsPanel ?? '#teleport-target-buttons-row'"
      @goto-product="emit('goto-product', $event)"
    >
      <template #left-buttons="{ buttonProps }">
        <QBtn
          v-bind="buttonProps"
          icon="mdi-camera-plus"
          class="q-px-xs"
          @click="productPhotosRef.openNewPhotoDialog()"
        >
          {{ t('Add photo') }}
        </QBtn>
      </template>
    </EditProductPacks>
    <BlurredInput @barcode="handleBarcodeScan" />
  </div>
  <BaseAlert
    v-else
    type="info"
  >
    {{ t('Product not found') }}
  </BaseAlert>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import BigLoading from '@/components/BigLoading.vue';
import CardTitle from '@/components/CardTitle.vue';
import EditProductPacks from '@/components/Mobile/EditProductPacks.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useSpeaker from '@/composables/useSpeaker';
import type { Product, Scalars , ProductPackWithAmount } from '@/graphql/types';
import productPackIsMissingWeightOrDimensions
  from '@/helpers/productPackIsMissingWeightOrDimensions';
import HorizontalPropsList from '@/views/Mobile/HorizontalPropsList.vue';
import ProductPhotosMobile from '@/views/Products/ProductEdit/ProductPhotosMobile.vue';
import ProductStocksMobile from '@/views/Products/ProductEdit/ProductStocksMobile.vue';
import { gql, useClientHandle, useQuery } from '@urql/vue';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import type { ProductPack } from '@/graphql/types';
import { AskForWeightAndDimensionsOptionEnum } from '@/graphql/types';
import useOmniInput from '@/composables/useOmniInput';

// noinspection LocalVariableNamingConventionJS
const { fillErrorsFromGraphQLError, PrimaryErrorBanner } = useErrorHandling();

const { t } = useI18n();

const props = defineProps<{
  id: Scalars['ID'];
  buttonsPanel?: HTMLElement | string;
  gotoProductOnScan?: boolean;
}>();

const emit = defineEmits<{
  (e: 'goto-product', id: string): void;
}>();

const { BlurredInput } = useOmniInput({
  skip: computed(() => !props.gotoProductOnScan)
});

const { client: urql } = useClientHandle();

async function handleBarcodeScan(value: string) {
  const { data } = await urql.query<{ packWithAmount: ProductPackWithAmount | null }>(gql`
    query ProductPackByBarcodeToOpenFromMobileCard($barcode: String!) {
       packWithAmount: productPackByBarcode(barcode: $barcode) { productPack {
        id product { id }
      }}
    }
  `, { barcode: value });

  if (data!.packWithAmount?.productPack.product) {
    emit('goto-product', data!.packWithAmount?.productPack.product.id);
  }
}

const productPhotosRef = ref();

const totalStock = ref<number | null>(null);

const { speak } = useSpeaker();

const { data, error, fetching } = useQuery<{ product: Product }>({
  query: gql`
    query GetProductForMobileEdit($id: ID!) { product(id: $id) {
      id
      sku
      name
      mostBasicProductPack {
        id
        measurementUnit { id shortName }
      }
      category { id name }
      primarySupplier { id name }
      accountingModel {
        id
        name
        isDefault
      }
      deletedAt
      photos { id url }
      productPacks {
        id
        weight
        dimensions { length width height }
        product {
          id
          accountingModel {
            id
            askForWeightAndDimensions
          }
        }
      }
    } }
  `,
  variables: computed(() => ({ id: props.id })),
});
watch(error, fillErrorsFromGraphQLError);

const product = computed(() => data.value?.product ?? null);

function shouldNotifyAboutWeightAndDimensions(pack: ProductPack): boolean {
  return pack.product.accountingModel.askForWeightAndDimensions === AskForWeightAndDimensionsOptionEnum.ALWAYS;
}

watch(product, product => {
  if (product?.productPacks.some(
    pack => productPackIsMissingWeightOrDimensions(pack) && shouldNotifyAboutWeightAndDimensions(pack),
  )) {
    speak(t('Specify Weight and Dimensions'));
  }
});

</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/speaking.yaml"></i18n>

<i18n lang="yaml">
ru:
  Product not found: Товар не найден
  Category: Группа товаров
  Stock: Остаток
  Product Packs: Упаковки
  Add photo: Добавить фото

en:
  Product not found: Product not found
  Category: Category
  Stock: Stock
  Product Packs: Product Packs
  Add photo: Add photo
</i18n>
