ru:
  Cell not found: Ячейка не найдена
  Container not found: Контейнер не найден
  Container is occupied. Start {operation} in empty Container: >
    Контейнер занят. Начните {operation} в пустой контейнер
  Excess product, only {needed} needed: Лишний товар, надо {needed}
  Nothing found: Ничего не найдено
  Not enough product, available {stock}: Недостаточно товара, доступно {stock}
  Product not found: Товар не найден
  Product or Container: Товар или контейнер
  Product or Storage: Товар или место хранения
  Product, Container or Cell: Товар, контейнер или ячейка
  Scan and take needed Product: Отсканируйте и возьмите нужный товар
  Scan Cell or Container: Сканируйте ячейку или контейнер
  Scan Cell: Сканируйте ячейку
  Scan Container: Сканируйте контейнер
  Scan First Storage: Сканируйте первое место хранения
  Scan Product or Storage: Сканируйте товар или место хранения
  Scan Second Storage: Сканируйте второе место хранения
  Scan Storage: Сканируйте место хранения
  Scan the above specified Storage: Отсканируйте указанное выше место хранения
  Storage not found: Место хранения не найдено
  The storage should match specified: Место хранения должно соответствовать указанному

en:
  Cell not found: Cell not found
  Container not found: Container not found
  Container is occupied. Start {operation} in empty Container: >
    Container is occupied. Start {operation} in empty Container
  Nothing found: Nothing found
  Excess product, only {needed} needed: Excess product, only {needed} needed
  Not enough product, available {stock}: Not enough product, available {stock}
  Product not found: Product not found
  Product or Container: Product or Container
  Product or Storage: Product or Storage
  Product, Container or Cell: Product, Container or Cell
  Scan and take needed Product: Scan and take needed Product
  Scan Cell or Container: Scan Cell or Container
  Scan Cell: Scan Cell
  Scan Container: Scan Container
  Scan First Storage: Scan First Storage
  Scan Product or Storage: Scan Product or Storage
  Scan Second Storage: Scan Second Storage
  Scan Storage: Scan Storage
  Scan the above specified Storage: Scan the above specified Storage
  Storage not found: Storage not found
  The storage should match specified: The storage should match specified
