<template>
  <!--
  width: 100% нужен временно, пока слайд имеет класс inline-block
  и может использоваться как в ScrollCarousel, так и в SwiperCarousel.
  После полного перевода на SwiperCarousel
  убрать width: 100% здесь и inline-block у ProductSlide
  -->
  <ProductSlide
    :product-pack="transferItem.storageUnit.productPack"
    :actual-amount="takenAmount"
    :expected-amount="transferItem.plannedAmount * transferItem.storageUnit.productPack.quantityInMinMeasurementUnits"
    :amount-badge-color="productSlideAmountBadgeColor"
    :total-amount="totalTakenAmount"
    :show-total-amount="totalTakenAmount !== takenAmount"
    title-clickable
    style="width: 100%"
    :deletable="!!slide.movement"
    @delete="emit('delete')"
    @title-click="emit('product-click', transferItem.storageUnit.productPack.product)"
    @amount-click="handleAmountClick"
  >
    <template
      v-if="transferItem.targetProductPack"
      #before-amount
    >
      <QChip
        dense
        color="purple-4"
        text-color="white"
        square
        clickable
        icon="mdi-arrow-right"
      >
        {{ expectedAmountInTargetUnits }}
        {{ transferItem.targetProductPack.measurementUnit.shortName }}
      </QChip>
    </template>
    <template
      v-if="indicesWithProduct.length > 1"
      #under-amount
    >
      <CarouselIndicators
        :count="indicesWithProduct.length"
        :active="indicesWithProduct.indexOf(index)"
        :limit="5"
        :indicator-size="7"
      />
    </template>
  </ProductSlide>
</template>

<script setup lang="ts">

import CarouselIndicators from '@/components/Mobile/CarouselIndicators.vue';
import ProductSlide from '@/components/Mobile/ProductSlide.vue';
import type { Product } from '@/graphql/types';
import getPacksRatio from '@/helpers/getPacksRatio';
import useTransferProcessStore from '@/stores/transferProcess';
import type { TransferSlide } from '@/types/transfer';
import { isNotNil, uniqBy } from 'ramda';
import { computed } from 'vue';

const store = useTransferProcessStore();

const props = defineProps<{
  slide: TransferSlide;
  index: number;
}>();

const emit = defineEmits<{
  (e: 'delete'): void;
  (e: 'product-click', product: Product): void;
  (e: 'update:slide', slide: number): void;
}>();

const transferItem = computed(() => props.slide.transferItem);

const productSlideAmountBadgeColor = computed((): string | undefined => {
  if (totalTakenAmount.value === transferItem.value.plannedAmount) {
    return 'green-3';
  }

  if (totalTakenAmount.value > 0) {
    return 'yellow-3';
  }

  return undefined;
});

const itemsWithProduct = computed(() => store.slides
  .map((item, index) => [item, index] as const)
  .filter(([item]) => item.transferItem.storageUnit.productPack.product.id
    === transferItem.value.storageUnit.productPack.product.id));

const indicesWithProduct = computed(() => itemsWithProduct.value.map(([, index]) => index));

function handleAmountClick() {
  const index = (indicesWithProduct.value.indexOf(props.index) + 1) % indicesWithProduct.value.length;

  emit('update:slide', (indicesWithProduct.value)[index]);
}

const expectedAmountInTargetUnits = computed(() => {
  if (!transferItem.value.targetProductPack) {
    return null;
  }
  const ratio = getPacksRatio(transferItem.value.storageUnit.productPack, transferItem.value.targetProductPack);

  if (!ratio) {
    return null;
  }

  const [sourceQuantity, targetQuantity] = ratio;

  return transferItem.value.plannedAmount * targetQuantity / sourceQuantity;
});

const takenAmount = computed(() => {
  const amount = props.slide.movement?.amount ?? transferItem.value.transferredAmount;
  return Number((amount * transferItem.value.storageUnit.productPack.quantityInMinMeasurementUnits)
    .toPrecision(3));
});

const totalTakenAmount = computed(() => Number(uniqBy(m => m.id, itemsWithProduct.value
  .map(([item]) => item.movement)
  .filter(isNotNil))
  .reduce((acc, m) => acc + m.amount * m.storable.productPack.quantityInMinMeasurementUnits, 0)
  .toPrecision(3)));

</script>

