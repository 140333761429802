<template>
  <CardTitle>
    {{ t('Product Packs') }}
  </CardTitle>
  <ProductPackDialog
    :product-pack="editingProductPack"
    :product-packs="productPacks"
    :parent-errors="errorsFor(String(editingProductPackIndex))"
    @refresh="emit('refresh')"
    @close="editingProductPack = null"
  />
  <BaseTable
    :fields="headers"
    :rows="productPacksSorted"
    storage-prefix="productEdit.productPacksList"
    hide-bottom
  >
    <template #body-cell-barcodes="column: { row: ProductPack, value: Barcode[] }">
      <QTd :props="column">
        <QChip
          v-if="column.value.some(b => !b.group)"
          :color="column.row.lastScannedBarcode ? 'primary' : undefined"
          :text-color="column.row.lastScannedBarcode ? 'white' : undefined"
          class="cursor-pointer"
        >
          {{ formatBarcode(column.row.lastScannedBarcode || column.value.find(b => !b.group)!) }}
          <QMenu>
            <QList>
              <QItem
                v-for="pb in column.value"
                :key="pb.barcode"
              >
                <QItemLabel>
                  <QChip
                    :color="barcodeIsLast(pb, column.row) ? 'primary' : 'grey-4'"
                    text-color="black"
                  >
                    {{ formatBarcode(pb) }}
                  </QChip>
                </QItemLabel>
              </QItem>
            </QList>
          </QMenu>
        </QChip>
        <span v-else>—</span>
      </QTd>
    </template>

    <template #body-cell-dimensions="column">
      <QTd :props="column">
        <span v-if="column.value && isDimensionsNotEmpty(column.value)">
          {{ column.value.length }} × {{ column.value.width }} × {{ column.value.height }}
        </span>
        <span v-else>—</span>
      </QTd>
    </template>

    <template #body-cell-actions="column">
      <QTd :props="column">
        <QBtn
          flat
          round
          icon="mdi-pencil"
          @click="editProductPack(column.row)"
        />
      </QTd>
    </template>

    <template #after-search-string>
      <QBtn
        :label="t('Create Product Pack')"
        color="green"
        icon="mdi-plus"
        @click="editProductPack(makeNewProductPack())"
      />
    </template>
  </BaseTable>
</template>

<script setup lang="ts">

import BaseTable from '@/components/BaseTable.vue';
import CardTitle from '@/components/CardTitle.vue';
import type { FieldsErrors } from '@/composables/useErrorHandling';
import useErrorHandling from '@/composables/useErrorHandling';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import {
  type Barcode,
  type Dimensions,
  MeasurementUnitClassEnum,
  type Product,
  type ProductPack,
} from '@/graphql/types';
import ProductPackDialog from '@/views/Products/ProductEdit/ProductPackDialog.vue';
import type { QTableColumn } from 'quasar';
import * as R from 'ramda';
import { gte, when } from 'ramda';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { dateFormatter, formatWeight } = useLocalizedFormatters();

const { errorsFor } = useErrorHandling();

const props = defineProps<{
  product: Product;
  productPacks: ProductPack[];
  parentErrors: FieldsErrors;
}>();

const emit = defineEmits<{
  (e: 'refresh'): void;
  (e: 'update:product-packs', packs: ProductPack[]): void;
}>();

const editingProductPack = ref<ProductPack | null>(null);

function isDimensionsNotEmpty(dimensions: Dimensions): boolean {
  // eslint-disable-next-line unicorn/explicit-length-check
  return !!(dimensions.length || dimensions.width || dimensions.height);
}

function barcodeIsLast(barcode: Barcode, item: ProductPack): boolean {
  return item.lastScannedBarcode === barcode;
}

function editProductPack(pack: ProductPack): void {
  editingProductPack.value = pack;
}

const headers: QTableColumn<ProductPack>[] = [
  {
    sortable: true,
    label:    t('Name'),
    name:     'measurementUnit.name',
    field:    pack => pack.measurementUnit.name
      + (pack.smallerProductPack
        ? ` (${pack.quantity} ${pack.smallerProductPack?.measurementUnit.shortName})`
        : ''),
    align: 'left',
  },
  {
    label: t('Barcodes'),
    name:  'barcodes',
    field: 'barcodes',
    align: 'left',
  },
  {
    label: t('Weight'),
    name:  'weight',
    field: pack => formatWeight(pack.weight),
  },
  {
    label: t('Dimensions (LxWxH)'),
    name:  'dimensions',
    field: 'dimensions',
    align: 'left',
  },
  {
    sortable: true,
    label:    t('Measured At'),
    name:     'measuredAt',
    field:    'measuredAt',
    format:    dateFormatter(FORMATS.DATETIME),
    align:    'left',
  },
];

const productPacksSorted = computed((): ProductPack[] =>
  // "Штуки" в начале (у них отсутствует smallerProductPack - возвращаем -1).
  // Остальные сортируем по порядку возрастания единиц (indexOf вернет 0 или больше).
  R.sortBy(pack => (pack.smallerProductPack ? [
    MeasurementUnitClassEnum.PIECES,
    MeasurementUnitClassEnum.BOXES,
    MeasurementUnitClassEnum.PALLETS,
  ].indexOf(pack.measurementUnit.class) : -1), props.productPacks));

const nullIfNegative = when(gte(0), () => null);

const editingProductPackIndex = computed(() => editingProductPack.value
  ? nullIfNegative(props.productPacks.indexOf(editingProductPack.value))
  : null
);

function formatBarcode(barcode: Barcode) {
  return `${barcode.group ? `${barcode.group}:` : ''}${barcode.barcode}`;
}

function makeNewProductPack(): ProductPack {
  return {
    measurementUnit: null!,
    smallerProductPack: null,
    product: props.product,
    quantity: 1,
    weight: 0,
    dimensions: null,
    barcodes: [],
    lastScannedBarcode: null,
    measuredAt: null,
  } as Partial<ProductPack> as ProductPack;
}

</script>

<i18n lang="yaml">
ru:
  Product Packs: Упаковки
  Create Product Pack: Создать упаковку
  Dimensions (LxWxH): Габариты (ДxШxВ)
  Measured At: Дата измерения
  Not all fields are filled: Не все поля заполнены

en:
  Product Packs: Product Packs
  Create Product Pack: Create Product Pack
  Dimensions (LxWxH): Dimensions (LxWxH)
  Measured At: Measured At
  Not all fields are filled: Not all fields are filled
</i18n>
