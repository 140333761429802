<template>
  <ShipmentEditMobile
    v-if="preferences.terminalMode"
    :id="id"
    @close="navigateBack({ name: ROUTES.CUSTOMER_ORDERS_LIST })"
  />
  <ShipmentEditDesktop
    v-else
    :id="id"
    @load="handleShipmentLoaded"
  >
    <template #title="{ shipment }: { shipment: CustomerOrder }">
      {{ t('Shipment Order {id}', { id: shipment.externalId }) }}
    </template>
    <template #fields="{ shipment }: { shipment: CustomerOrder }">
      <div class="col-12 col-sm-6 col-md-3 col-lg-2">
        <GraphQLAutocomplete
          v-model="carrierName"
          :query="carriersAutocompleteQuery"
          :label="t('Carrier Name')"
          option-label="name"
          clearable
        />
      </div>
      <div class="col-12 col-sm-6 col-md-3 col-lg-1">
        <DatePicker
          v-model="shipment.plannedShipmentDate"
          :label="t('Planned Shipment Date')"
          :readonly="store.moySkladIntegrationEnabled"
          stack-label
          :placeholder="t('Not specified')"
        />
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-2">
        <GraphQLAutocomplete
          v-model="customerName"
          :query="customersAutocompleteQuery"
          :label="t('Customer')"
          option-label="name"
          clearable
          :readonly="store.moySkladIntegrationEnabled"
        />
      </div>
    </template>
    <template #not-found-banner>
      <CustomerOrderNotFoundBanner />
    </template>
    <template #bottom>
      <PrimaryErrorBanner />
    </template>
    <template #buttons="{shipment, onRefresh}">
      <ShipmentCompleteDialog
        v-if="shipment.state !== ShipmentStateEnum.COMPLETED"
        :shipment="shipment"
        :title="t('Complete Order?')"
        :label="t('Complete Order')"
        @completed="onRefresh"
      />
      <QBtn
        color="success"
        icon="mdi-content-save"
        :loading="saving"
        @click="save(shipment)"
      >
        {{ t('Apply') }}
      </QBtn>
      <QBtn
        color="primary"
        icon="mdi-content-save"
        :loading="saving"
        @click="saveAndGoToList(shipment)"
      >
        {{ t('Save') }}
      </QBtn>
    </template>
  </ShipmentEditDesktop>
</template>

<script setup lang="ts">

import GraphQLAutocomplete from '@/components/Mobile/GraphQLAutocomplete.vue';
import ShipmentEditMobile from '@/views/Shipping/Shipments/ShipmentEditMobile.vue';
import ShipmentEditDesktop from '@/views/Shipping/Shipments/ShipmentEditDesktop.vue';
import useLocalPreferences from '@/composables/useLocalPreferences';
import { type CustomerOrder, type Scalars, ShipmentStateEnum } from '@/graphql/types';
import { useI18n } from 'vue-i18n';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useErrorHandling from '@/composables/useErrorHandling';
import { gql, useMutation } from '@urql/vue';
import { type Component, h, ref, watch } from 'vue';
import DatePicker from '@/components/DatePicker.vue';
import NotFoundBanner from '@/components/NotFoundBanner.vue';
import ROUTES from '@/router/routeNames';
import useStore from '@/stores/root';
import navigateBack from '@/helpers/navigateBack';
import ShipmentCompleteDialog from '@/views/Shipping/Shipments/ShipmentCompleteDialog.vue';

const preferences = useLocalPreferences();

const store = useStore();

const { fillErrorsFromGraphQLError, clearErrors, PrimaryErrorBanner } = useErrorHandling();

const { t } = useI18n();

useBreadcrumbs([t('Order')]);

defineProps<{
  id: Scalars['ID'];
}>();

const CustomerOrderNotFoundBanner: Component = () => h(NotFoundBanner, {
  notFoundMessage: t('Order not found'),
  backRoute:       { name: ROUTES.CUSTOMER_ORDERS_LIST },
  goBackMessage:   t('Go to Orders List'),
});

const customerName = ref('');
const carrierName = ref('');

function handleShipmentLoaded(shipment: CustomerOrder) {
  customerName.value = shipment.counterparty?.name ?? '';
  carrierName.value = shipment.carrier?.name ?? '';
}

const {
  fetching: saving,
  error: saveError,
  executeMutation: doSave,
} = useMutation(gql`
  mutation SaveCustomerOrder(
    $id: ID!,
    $plannedShipmentDate: DateTime,
    $customerName: String,
    $carrierName: String,
  ) {
    saveCustomerOrder(
      id: $id,
      plannedShipmentDate: $plannedShipmentDate,
      customerName: $customerName,
      carrierName: $carrierName,
    ) { id }
  }
`);

async function save(shipment: CustomerOrder) {
  clearErrors();
  return doSave({
    id: shipment.id,
    plannedShipmentDate: shipment.plannedShipmentDate,
    customerName: customerName.value,
    carrierName: carrierName.value,
  });
}

async function saveAndGoToList(shipment: CustomerOrder) {
  const { error } = await save(shipment);

  if (!error) {
    navigateBack({ name: ROUTES.CUSTOMER_ORDERS_LIST });
  }
}

const customersAutocompleteQuery = gql`
  query GetCustomersForSelect($searchString: String) {
    result: customers(searchString: $searchString) { id name }
  }
`;

const carriersAutocompleteQuery = gql`
  query GetCarriersForSelect($searchString: String) {
    result: carriers(searchString: $searchString) { id name }
  }
`;

watch(saveError, fillErrorsFromGraphQLError);

</script>

<i18n lang="yaml">
ru:
  Order not found: Заказ не найден
  Go to Orders List: Перейти к списку заказов
  Shipment Order {id}: Заказ на отгрузку {id}

  Complete Order?: Завершить Заказ?
  Complete Order: Завершить Заказ

en:
  Order not found: Order not found
  Go to Orders List: Go to Orders List
  "Shipment Order {id}": Shipment Order {id}

  Complete Order?: Complete Shipment Order?
  Complete Order: Complete Shipment Order

</i18n>
