<template>
  <InventoriesList v-if="!preferences.terminalMode" />
  <InventoryDashboard v-else />
</template>

<script setup lang="ts">

import useBreadcrumbs from '@/composables/useBreadcrumbs';
import { useI18n } from 'vue-i18n';
import InventoriesList from '@/views/Mobile/Inventory/InventoriesList.vue';
import InventoryDashboard from '@/views/Mobile/Inventory/InventoryDashboard.vue';
import useLocalPreferences from '@/composables/useLocalPreferences';

const { t } = useI18n();

useBreadcrumbs(t('Inventory'));

const preferences = useLocalPreferences();

</script>
