<template>
  <QPage padding>
    <div class="row">
      <div class="col-12 col-md-8 col-lg-6">
        <QCard>
          <BaseAlert
            v-if="getPrimaryError()"
            type="error"
          >
            {{ getPrimaryError() }}
          </BaseAlert>
          <QCardSection
            v-if="fetching"
            class="text-center"
          >
            <QCircularProgress
              indeterminate
              size="150px"
              font-size="0.1em"
              color="primary"
              show-value
            >
              {{ t('Loading') }}
            </QCircularProgress>
          </QCardSection>
          <template v-else-if="cellsArea">
            <CardTitle>
              {{ t('Cells Area {name}', {name: cellsArea.name}) }}
            </CardTitle>

            <QCardSection class="row q-col-gutter-sm">
              <div class="col-12">
                <QInput
                  v-model="cellsArea.name"
                  :label="t('Name')"
                  :rules="[notEmptyRule]"
                  v-bind="qErrorsFor('cellsArea.name')"
                />
              </div>
              <div class="col-12">
                <QSelect
                  v-model="cellsArea.kind"
                  :label="t('Kind')"
                  :options="kinds"
                  :option-label="kindLabel"
                />
              </div>
              <div class="col-12">
                <QCheckbox
                  :label="t('Separate Selection')"
                  :model-value="cellsArea.nameForSeparateSelection !== null"
                  @update:model-value="cellsArea.nameForSeparateSelection = $event ? '' : null"
                />
              </div>
              <div
                v-if="cellsArea.nameForSeparateSelection !== null"
                class="col-12"
              >
                <QInput
                  v-model="cellsArea.nameForSeparateSelection"
                  :label="t('Separate Selection Name')"
                  v-bind="qErrorsFor('cellsArea.nameForSeparateSelection')"
                  style="max-width: 100%;"
                />
              </div>
            </QCardSection>

            <QSeparator />

            <QCardActions>
              <QBtn
                exact
                :to="{ name: 'Cells Areas' }"
                icon="mdi-arrow-left"
              >
                {{ t('Back') }}
              </QBtn>

              <QSpace />

              <QBtn
                color="success"
                icon="mdi-content-save"
                :loading="progress.applying"
                @click="save()"
              >
                {{ t('Apply') }}
              </QBtn>
              <QBtn
                color="primary"
                icon="mdi-content-save"
                :loading="progress.saving"
                @click="saveAndGoToList()"
              >
                {{ t('Save') }}
              </QBtn>
            </QCardActions>
          </template>
          <BaseAlert
            v-else
            type="info"
            icon="mdi-run-fast"
          >
            <p>
              {{ t('Cells Area not found') }}
            </p>
            <QBtn
              exact
              color="primary"
              icon="mdi-arrow-left"
              :to="{ name: 'Cells Areas' }"
            >
              {{ t('Go to Cells Areas List') }}
            </QBtn>
          </BaseAlert>
        </QCard>
      </div>
    </div>
  </QPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import CardTitle from '@/components/CardTitle.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useErrorHandling from '@/composables/useErrorHandling';
import useValidationRules from '@/composables/useValidationRules';
import type { CellsArea, MutationSaveCellsAreaArgs, QueryCellsAreaArgs } from '@/graphql/types';
import { CellsAreaKindEnum } from '@/graphql/types';
import { useI18n } from 'vue-i18n';
import { gql, useClientHandle, useQuery } from '@urql/vue';
import { computed, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const { notEmptyRule } = useValidationRules();

const { fillErrorsFromGraphQLError, clearErrors, getPrimaryError, qErrorsFor } = useErrorHandling();
const { client: urql } = useClientHandle();
const router = useRouter();

const props = defineProps<{
  id?: string;
}>();

useBreadcrumbs([
  t('Cells Areas'),
  props.id ? t('Edit') : t('New'),
]);

const progress = reactive({
  applying: false,
  saving:   false,
});

const kinds = Object.values(CellsAreaKindEnum);

const kindLabel = (v: CellsAreaKindEnum) => t(`cellsAreaKind.${v}`);

const {
  data: cellsAreaResult,
  error,
  fetching,
} = useQuery<{ cellsArea: CellsArea }, QueryCellsAreaArgs>({
  query:     gql`
    query GetCellsArea($id: ID!) { cellsArea(id: $id) {
      id
      name
      nameForSeparateSelection
      kind
    } }
  `,
  variables: computed(() => ({
    id: props.id!,
  })),
  pause:     computed(() => !props.id),
});
watch(error, fillErrorsFromGraphQLError);
watch(cellsAreaResult, data => {
  cellsArea.value = data!.cellsArea;
});
const cellsArea = ref<Partial<CellsArea>>({
  id:                       '',
  name:                     '',
  kind:                     CellsAreaKindEnum.SELECTION,
  nameForSeparateSelection: null,
});

async function doSave(): Promise<boolean> {
  clearErrors();

  const { error, data } = await urql.mutation<{
    saveCellsArea: CellsArea;
  }, MutationSaveCellsAreaArgs>(
    gql`
      mutation SaveCellsArea($cellsArea: CellsAreaInput!) {
        saveCellsArea(cellsArea: $cellsArea) { id }
      }
    `,
    {
      cellsArea: {
        id:                       cellsArea.value.id || null,
        name:                     cellsArea.value.name,
        nameForSeparateSelection: cellsArea.value.nameForSeparateSelection,
        kind:                     cellsArea.value.kind,
      },
    },
  );

  if (error) {
    fillErrorsFromGraphQLError(error);
    return false;
  }

  const newId = data!.saveCellsArea.id;
  if (newId && props.id !== newId) {
    await router.push({
      name:   ROUTES.CELLS_AREAS_EDIT,
      params: { id: newId },
    });
  }

  return true;
}

async function save(): Promise<void> {
  progress.applying = true;

  await doSave();

  progress.applying = false;
}

async function saveAndGoToList(): Promise<void> {
  progress.saving = true;

  if (await doSave()) {
    // noinspection ES6MissingAwait
    router.push({ name: 'Cells Areas' });
  }

  progress.saving = false;
}

</script>

<i18n lang="yaml">
ru:
  Kind: Тип
  Cells Area not found: Зона не найдена
  Go to Cells Areas List: Перейти к списку зон
  Cells Area {name}: Зона {name}
  Separate Selection: Отдельный отбор
  Separate Selection Name: Наименование отдельного отбора
en:
  Kind: Kind
  Cells Area not found: Cells Area not found
  Go to Cells Areas List: Go to Cells Areas List
  Cells Area {name}: Cells Area {name}
  Separate Selection: Separate Selection
  Separate Selection Name: Separate Selection Name
</i18n>
