<template>
  <template v-if="accountPaymentExpired">
    <QBtn
      v-if="screen.xs"
      flat
      round
      color="warning"
      icon="mdi-alert"
    >
      <QTooltip :hide-delay="1000">
        {{ expirationMessage }}
      </QTooltip>
    </QBtn>
    <QChip
      v-else
      color="warning"
      icon="mdi-alert"
    >
      {{ expirationMessage }}
    </QChip>
  </template>
</template>

<script setup lang="ts">
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import useStore from '@/stores/root';
import dayjs from 'dayjs';
import { useQuasar } from 'quasar';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { screen } = useQuasar();

const { t } = useI18n();

const store = useStore();

const { formatDate } = useLocalizedFormatters();

const accountPaymentExpired = computed(() =>
  store.user ? dayjs(store.user.warehouse.subscriptionPaidUntil).isBefore(dayjs()) : false,
);

const expirationMessage = computed(() => store.user
  ? t('Subscription expired on {date}', {
    date: formatDate(store.user.warehouse.subscriptionPaidUntil)
  })
  : '');
</script>

<i18n lang="yaml">
ru:
  Subscription expired on {date}: Срок оплаты истек {date}
en:
  Subscription expired on {date}: Subscription expired on {date}
</i18n>
