<template>
  <slot
    name="activator"
    @open="isOpen = true"
  />
  <QDialog v-model="isOpen">
    <QCard>
      <QCardSection>
        <QInput
          v-model="count"
          :label="t('Count')"
          type="number"
          min="1"
        />
        <QInput
          v-model.number="v.level.$model"
          :label="t('Level')"
          type="number"
          min="1"
          :error="v.level.$error"
          :error-message="v.level.$errors[0]?.$message"
        />
      </QCardSection>

      <QCardActions>
        <QSpace />

        <QBtn
          color="primary"
          :disable="v.$invalid"
          @click="addCells()"
        >
          {{ t('Add') }}
        </QBtn>
      </QCardActions>
    </QCard>
  </QDialog>
</template>

<script setup lang="ts">

import type { Cell } from '@/graphql/types';
import { parseCellName } from '@/helpers/cellNameHelpers';
import { integer, positive, required } from '@/setup/validation';
import useVuelidate from '@vuelidate/core';
import { last, uniq } from 'ramda';
import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const DEFAULT_CELLS_COUNT = 5;

const { t } = useI18n();

const props = defineProps<{
  cells: Cell[];
}>();

const emit = defineEmits<{
  (e: 'add-cells', payload: { count: number; level: number }): void;
}>();

const isOpen = ref(false);

const count = ref(DEFAULT_CELLS_COUNT);

const existingLevels = computed(() =>
  uniq(props.cells.map(c => parseCellName(c.name)!.level!)).sort(),
);

function calculateInitialLevel() {
  if (existingLevels.value.length === 0) {
    return 1;
  }

  if (existingLevels.value[0] === 10) {
    return last(existingLevels.value)! + 10;
  }

  return last(existingLevels.value)! + 1;
}

const v = useVuelidate({
  level: { required, integer, positive },
}, reactive({ level: 1 }));

watch(isOpen, open => {
  if (open) {
    v.value.level.$model = calculateInitialLevel();
    v.value.$touch();
  }
}, { immediate: true });

function addCells(): void {
  emit('add-cells', { count: Number(count.value), level: v.value.level.$model });
  isOpen.value = false;
}

</script>
