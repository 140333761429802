<template>
  <QCard>
    <CardTitle>{{ t('Keyboard') }}</CardTitle>
    <QCardSection>
      <QCheckbox
        v-model="usePhysicalKeyboard"
        :label="t('Use TCD keyboard')"
      />
    </QCardSection>
  </QCard>
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import useLocalPreferences from '@/composables/useLocalPreferences';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const preferences = useLocalPreferences();

const usePhysicalKeyboard = ref(preferences.usePhysicalKeyboard ?? false);

watch(usePhysicalKeyboard, value => {
  preferences.usePhysicalKeyboard = value;
});

</script>

<i18n lang="yaml">
ru:
  Use TCD keyboard: Использовать клавиатуру ТСД
en:
  Use TCD keyboard: Use TCD keyboard
</i18n>
