<template>
  <QField
    :label="t('Containers')"
    stack-label
    :error="!!primaryError"
    :error-message="primaryError"
  >
    <template #control>
      <QSkeleton
        v-if="fetching"
        width="100px"
      />
      <template v-else>
        <LinksList
          :max-to-show="3"
          :items="containers"
        >
          <template #item="{item}">
            <RouterLink :to="{ name: ROUTES.CONTAINERS_EDIT, params: { id: item.id } }">
              {{ item.name }}
            </RouterLink>
          </template>
        </LinksList>
      </template>
    </template>
  </QField>
</template>

<script setup lang="ts">

import useErrorHandling from '@/composables/useErrorHandling';
import type { Container, QueryContainersByShipmentArgs, Scalars } from '@/graphql/types';
import ROUTES from '@/router/routeNames';
import { gql, useQuery } from '@urql/vue';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import LinksList from '@/components/LinksList.vue';

const { t } = useI18n();

const { fillErrorsFromGraphQLError, primaryError } = useErrorHandling();

const props = defineProps<{
  orderId: Scalars['ID'];
  maxToShow: number;
}>();

const { data, fetching, error } = useQuery<{ containers: Container[] }, QueryContainersByShipmentArgs>({
  query:     gql`
    query GetContainersByShipmentForShipmentCardDesktop($shipmentId: ID!) {
      containers: containersByShipment(shipmentId: $shipmentId) { id name }
    }
  `,
  variables: computed(() => ({
    shipmentId: props.orderId,
  })),
});

watch(error, fillErrorsFromGraphQLError);

const containers = computed(() => data.value?.containers || []);

</script>
