<template>
  <ExcelExport
    v-if="store.can(Ability.ImportExcel)"
    :type="ExcelImportTypeEnum.CELLS"
    :mutation-export="mutationExport"
    :options="options"
  />
  <ExcelImport
    :type="ExcelImportTypeEnum.CELLS"
    :mutation-validate="mutationValidate"
    :mutation-import="mutationImport"
    :success-notification="n => t('Imported {n} cells', n)"
    @success="emit('success')"
  >
    <template #info-for-upload-file>
      {{ t('File must contain cells only for add or update (not full cells list)') }}
    </template>

    <template #import-btn-title>
      {{ t('Update Cells') }}
    </template>

    <template #row-with-error-message="{ rowsCount }">
      {{ t('{n} cells with errors', rowsCount) }}
    </template>

    <template #validation-success="{ rowsCount }">
      {{ t('Validation success. You can update {n} cells', rowsCount) }}
    </template>
  </ExcelImport>
</template>

<script setup lang="ts">

import { ExcelImportTypeEnum } from '@/graphql/types';
import { Ability } from '@/types/backend';
import type { ReportOptions } from '@/types/reports';
import ExcelExport from '@/views/ExcelExport.vue';
import ExcelImport from '@/views/ExcelImport.vue';
import { gql } from '@urql/vue';
import { useI18n } from 'vue-i18n';
import useStore from '@/stores/root';

const { t } = useI18n();

const store = useStore();

defineProps<{
  options?: ReportOptions;
}>();

const emit = defineEmits<{
  (e: 'success'): void;
}>();

const mutationImport = gql`
  mutation ImportCells($type: ExcelImportTypeEnum!, $file: Upload) {
    importedCount: importExcelFile(type: $type, file: $file)
  }
`;

const mutationValidate = gql`
  mutation ValidateCellsImportFile($type: ExcelImportTypeEnum!, $file: Upload) {
    validationResult: validateExcelImportFile(type: $type, file: $file) {
      isValid
      rowsCount
      validationErrorMessage
      fileWithErrorsUrl
    }
  }
`;

const mutationExport = gql`
  mutation GenerateCellsFile($type: ExcelImportTypeEnum!, $searchString: String, $filter: [ReportFilterInput!]) {
    exportFilePath: generateExcelExportFile(type: $type, searchString: $searchString, filter: $filter)
  }
`;

</script>

<i18n lang="yaml">
ru:
  Update Cells: Обновить ячейки
  File must contain cells only for add or update (not full cells list): >
    Файл должен содержать только ячейки для добавления или обновления
    (не весь список ячеек)
  Validation success. You can update {n} cells: >
    Проверка прошла успешно. Вы можете обновить {n} ячеек.
    | Проверка прошла успешно. Вы можете обновить {n} ячейку.
    | Проверка прошла успешно. Вы можете обновить {n} ячейки.
    | Проверка прошла успешно. Вы можете обновить {n} ячеек.
  "{n} cells with errors": >
    {n} ячеек с ошибками
    | {n} ячейка с ошибками
    | {n} ячейки с ошибками
    | {n} ячеек с ошибками
  Imported {n} cells: >
    Импортировано {n} ячеек
    | Импортирована {n} ячейка
    | Импортировано {n} ячейки
    | Импортировано {n} ячеек
en:
  Update Cells: Update Cells
  File must contain cells only for add or update (not full cells list): File must contain cells only for add or update (not full cells list)
  Validation success. You can update {n} cells: >
    Validation success. You can update {n} cells.
    | Validation success. You can update {n} cell.
  "{n} cells with errors": >
    {n} cell with errors
    | {n} cells with errors
  Imported {n} cells: >
    Imported {n} cell
    | Imported {n} cells
</i18n>
