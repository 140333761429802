<template>
  <QDialog
    :model-value="show"
    persistent
  >
    <LoginCard
      login-only
      @login="loggedIn()"
    />
  </QDialog>
</template>

<script setup lang="ts">

import { useEventBus } from '@vueuse/core';
import LoginCard from '@/views/Auth/LoginCard.vue';
import { ref } from 'vue';

const show = ref(false);

const { on: onLoginRequired } = useEventBus('login-required');
const { emit: emitLoginComplete } = useEventBus('login-complete');

onLoginRequired(() => {
  show.value = true;
});

function loggedIn(): void {
  emitLoginComplete();
  show.value = false;
}

</script>
