<template>
  <HorizontalPropsList v-slot="{ item }">
    <component
      :is="item"
      v-if="store.supply"
      :caption="isCustomerReturn ? t('Customer Return') : t('Supply')"
    >
      {{ store.supply.externalId }}
    </component>
    <component
      :is="item"
      v-if="store.storage"
      :caption="t(store.storage.__typename === 'Cell' ? 'Cell' : 'Container')"
    >
      {{ store.storage.name }}
    </component>
    <component
      :is="item"
      v-if="store.supply"
      :caption="t('Products')"
    >
      {{ R.uniqBy(i => i.productPack.product.sku, store.supply.items).length }}
    </component>
    <component
      :is="item"
      v-if="store.supply"
      :caption="isCustomerReturn ? t('Customer') : t('Supplier')"
    >
      {{ store.supply.counterparty?.name }}
    </component>
  </HorizontalPropsList>
</template>

<script setup lang="ts">

import type { CustomerReturn, SupplierDelivery } from '@/graphql/types';
import useProductArrivalStore from '@/stores/productArrival';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import HorizontalPropsList from '@/views/Mobile/HorizontalPropsList.vue';
import * as R from 'ramda';

const { t } = useI18n();

const store = useProductArrivalStore();

const isCustomerReturn = computed(() => (store.supply as CustomerReturn | SupplierDelivery).__typename === 'CustomerReturn');

</script>
