<template>
  <QDialog
    v-model="isOpen"
    :persistent="completing"
  >
    <QCard>
      <CardTitle>
        {{ t('Cancel Selection?') }}
      </CardTitle>

      <QSeparator />

      <PrimaryErrorBanner animated />

      <QCardActions>
        <QBtn
          :label="t('No')"
          :disable="completing"
          @click="isOpen = false"
        />
        <QBtn
          :label="t('Cancel Selection')"
          color="primary"
          icon="mdi-check-all"
          :loading="completing"
          @click="completeSelection"
        />
      </QCardActions>
    </QCard>
  </QDialog>
  <QBtn
    v-if="canCompleteSelection"
    :label="t('Cancel Selection')"
    icon="mdi-check-all"
    :loading="loadingSelections"
    @click="isOpen = true"
  />
</template>

<script setup lang="ts">

import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { gql, useMutation, useQuery } from '@urql/vue';
import type { Container, QuerySelectionsByContainerAndStatesArgs , Selection } from '@/graphql/types';
import { SelectionStateEnum } from '@/graphql/types';
import useErrorHandling from '@/composables/useErrorHandling';
import CardTitle from '@/components/CardTitle.vue';

const { t } = useI18n();

// noinspection LocalVariableNamingConventionJS
const { fillErrorsFromGraphQLError, clearErrors, PrimaryErrorBanner } = useErrorHandling();

const props = defineProps<{
  container: Container;
}>();

const emit = defineEmits<{
  completed: [];
}>();

const isOpen = ref(false);

watch(isOpen, clearErrors);

const {
  executeMutation: doComplete,
  fetching: completing,
} = useMutation(gql`
  mutation CompleteContainerSelections($containerId: ID!) {
    completeContainerSelections(containerId: $containerId) { id }
  }
`);

const {
  data: selectionsData,
  fetching: loadingSelections,
} = useQuery<{ selections: Selection[] }, QuerySelectionsByContainerAndStatesArgs>({
  query: gql`
    query GetSelectionsByContainerForCompletionDialog($containerId: ID!, $states: [SelectionStateEnum!]!) {
      selections: selectionsByContainerAndStates(
        containerId: $containerId,
        states: $states
      ) { id state }
    }
  `,
  variables: {
    containerId: props.container.id,
    states:      [
      SelectionStateEnum.SELECTING,
      SelectionStateEnum.SELECTED,
    ],
  },
  pause: computed(() => !props.container.id),
});

const canCompleteSelection = computed(() => selectionsData.value
  // В контейнере должны быть отборы в статусе "Отобран",
  // но не должно быть ни одного в статусе "Отбор".
  && selectionsData.value.selections.some(s => s.state === SelectionStateEnum.SELECTED)
  && selectionsData.value.selections.every(s => s.state !== SelectionStateEnum.SELECTING));

async function completeSelection() {
  clearErrors();

  const { error } = await doComplete({ containerId: props.container.id });

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  isOpen.value = false;

  emit('completed');
}

</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/selection.yaml"></i18n>
