<template>
  <QDialog
    :model-value="slide !== null"
    @update:model-value="emit('update:slide', null)"
  >
    <QCard>
      <CardTitle>
        {{ t('Fix the errors') }}:
      </CardTitle>
      <BaseAlert type="error">
        {{ error }}
      </BaseAlert>
      <QCardActions>
        <QBtn
          :label="t('Cancel')"
          @click="emit('update:slide', null)"
        />
        <QBtn
          :label="t('Fix')"
          icon="mdi-wrench"
          color="primary"
          @click="handleFix"
        />
      </QCardActions>
    </QCard>
  </QDialog>
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import BaseAlert from '@/components/BaseAlert.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  slide: number | null;
  error?: string | null;
}>();

const emit = defineEmits<{
  'fix': [slide: number];
  'update:slide': [value: number | null];
}>();

function handleFix() {
  emit('fix', props.slide!);
  emit('update:slide', null);
}

</script>

<i18n lang="yaml">
ru:
  Fix the errors: Исправьте ошибки
  Fix: Исправить

en:
  Fix the errors: Fix the errors
  Fix: Fix
</i18n>
