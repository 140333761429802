<template>
  <QPage padding>
    <QCard>
      <BaseAlert
        v-if="getPrimaryError()"
        type="error"
      >
        {{ getPrimaryError() }}
      </BaseAlert>
      <QCardSection
        v-if="initializing"
        class="text-center"
      >
        <QCircularProgress
          indeterminate
          size="150px"
          font-size="0.1em"
          color="primary"
          show-value
        >
          {{ t('Loading') }}
        </QCircularProgress>
      </QCardSection>
      <template v-else-if="container">
        <CardTitle>
          {{ container.id ? t('Container {name}', { name: container.name }) : t('New Container') }}
          <QBadge
            v-if="container.deletedAt"
            rounded
            color="error"
            :label="t('Deleted')"
            :title="t('Deleted {at}', { at: formatDate(container.deletedAt) })"
          />

          <QSpace />

          <template v-if="container.id">
            <QBtn
              v-if="container.deletedAt === null"
              flat
              round
              icon="mdi-delete"
              color="error"
              :title="t('Delete')"
              :loading="progress.deleting"
              :disable="!container.isEmpty"
              @click="deleteContainer()"
            />
            <QBtn
              v-else
              flat
              round
              icon="mdi-delete-restore"
              color="success"
              :loading="progress.restoring"
              :title="t('Restore')"
              @click="restoreContainer()"
            />
          </template>
        </CardTitle>

        <QCardSection>
          <div class="row q-col-gutter-md">
            <div class="col-sm-1">
              <QInput
                v-model="container.name"
                :label="t('Name')"
                :rules="container.id ? [notEmptyRule] : []"
                v-bind="qErrorsFor('name')"
              />
            </div>
            <div class="col-sm-1">
              <NonEditableField
                v-if="container.id"
                :label="t('Code')"
              >
                {{ container.id }}
              </NonEditableField>
            </div>
            <div class="col-sm-2">
              <QInput
                v-model="container.barcode"
                :label="t('Barcode')"
                :rules="[minLength(BARCODE_MIN_LENGTH)]"
                hide-hint
                v-bind="qErrorsFor('barcode')"
              />
            </div>
            <div
              v-if="container.id"
              class="col-sm-2"
            >
              <NonEditableField :label="t('Created at')">
                {{ formatDate(container.createdAt) }}
              </NonEditableField>
            </div>
            <div
              v-if="container.id"
              class="col-sm-2"
            >
              <NonEditableField :label="t('Storage Cell')">
                {{ container.storageCell.name }}
              </NonEditableField>
            </div>
            <div
              v-if="container.id"
              class="col-sm-1"
            >
              <NonEditableField :label="t('Type')">
                {{ t('containerKind.' + container.kind) }}
              </NonEditableField>
            </div>
            <div
              v-if="isContainerHasOrders"
              class="col-sm-2"
            >
              <NonEditableField :label="t('Orders')">
                <RouterLink
                  v-for="(shipment, i) in container!.shipments"
                  :key="shipment.id"
                  :to="shipmentCardRoute(shipment)"
                  :class="i > 0 ? 'q-ml-sm' : ''"
                  :title="shipment.__typename === 'SupplierReturn' ? t('Return') : t('Order')"
                >
                  <ReturnableDocumentLabel :document="shipment" />
                </RouterLink>
              </NonEditableField>
            </div>
          </div>
          <div class="row">
            <ContainerShipButton
              v-if="canShipContainer(container)"
              :containers="[container]"
              class="q-ml-sm"
              color="success"
              @completed="loadContainer"
            />
            <ContainerCompleteSelectionDialog
              :container="container"
              @completed="loadContainer"
            />
            <StorageInventoryButton
              :storage="container!"
              @error="fillErrorsFromGraphQLError"
            />
            <ContainerTransferOrderDialog
              :container="container"
              @success="loadContainer"
            />
          </div>
        </QCardSection>

        <StocksReport
          v-if="container.id"
          :storage="container"
          storage-prefix="containerCard.stocksList.report"
        />

        <QSeparator />

        <QCardActions>
          <QBtn
            exact
            icon="mdi-arrow-left"
            @click="navigateBack({ name: ROUTES.CONTAINERS_LIST })"
          >
            {{ t('Back') }}
          </QBtn>

          <QSpace />

          <PrintDialog
            v-if="container.id"
            v-slot="{ open }"
            v-model:barcode-type="barcodeType"
            template-name="container-barcode"
            use-count
            :title="container.name"
            :instances="[{count: printCount, params: { containerId: container.id, barcodeType }}]"
            @count-changed="printCount=$event"
          >
            <QBtn
              color="primary"
              icon="mdi-printer"
              @click="open()"
            >
              {{ t('Print') }}
            </QBtn>
          </PrintDialog>

          <QBtn
            color="success"
            icon="mdi-content-save"
            :loading="progress.applying"
            @click="save()"
          >
            {{ t('Apply') }}
          </QBtn>
          <QBtn
            color="primary"
            icon="mdi-content-save"
            :loading="progress.saving"
            @click="saveAndGoToList()"
          >
            {{ t('Save') }}
          </QBtn>
        </QCardActions>
      </template>
      <EntityNotFound
        v-else
        :not-found-text="t('Container not found')"
        :back-button-route="{ name: ROUTES.CONTAINERS_LIST }"
        :back-button-text="t('Go to Containers List')"
      />
    </QCard>
  </QPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import CardTitle from '@/components/CardTitle.vue';
import PrintDialog from '@/components/PrintDialog.vue';
import ReturnableDocumentLabel from '@/components/ReturnableDocumentLabel.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useErrorHandling from '@/composables/useErrorHandling';
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import useValidationRules from '@/composables/useValidationRules';
import type {
  Container,
  MutationDeleteContainerArgs,
  MutationRestoreContainerArgs,
  MutationSaveContainerArgs,
  QueryContainerArgs,
} from '@/graphql/types';
import { ContainerKindEnum, PrintBarcodeTypeEnum } from '@/graphql/types';
import shipmentCardRoute from '@/helpers/shipmentCardRoute';
import { gql, useClientHandle, useMutation } from '@urql/vue';
import dayjs from 'dayjs';
import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import useDocumentsPrintingState from '@/views/useDocumentsPrintingState';
import NonEditableField from '@/components/NonEditableField.vue';
import EntityNotFound from '@/components/EntityNotFound.vue';
import { BARCODE_MIN_LENGTH } from '@/constants';
import StorageInventoryButton from '@/components/StorageInventoryButton.vue';
import ContainerCompleteSelectionDialog from './ContainerCompleteSelectionDialog.vue';
import navigateBack from '@/helpers/navigateBack';
import ContainerTransferOrderDialog from '@/views/Containers/ContainerTransferOrderDialog.vue';
import StocksReport from '@/views/Storage/StocksReport.vue';
import ROUTES from '@/router/routeNames';
import canShipContainer from '@/helpers/canShipContainer';
import ContainerShipButton from '@/components/ContainerShipButton.vue';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const { notEmptyRule } = useValidationRules();

useBreadcrumbs([t('Containers'), t('Edit')]);

const { client: urql } = useClientHandle();

const state = useDocumentsPrintingState();

const barcodeType = ref(state.value.lastContainerBarcodeType ?? PrintBarcodeTypeEnum.BARCODE);

const printCount = ref(1);

const { fillErrorsFromGraphQLError, clearErrors, getPrimaryError, qErrorsFor } = useErrorHandling();

const progress = reactive({
  saving:              false,
  applying:            false,
  deleting:            false,
  restoring:           false,
  shipping:            false,
});

const { minLength } = useValidationRules();

const router = useRouter();

const props = defineProps<{
  id?: string;
}>();

const initializing = ref(false);

const container = ref<Container>();

watch(() => props.id, loadContainer, { immediate: true });

async function loadContainer(): Promise<void> {
  if (!props.id) {
    container.value = {
      __typename: 'Container',
      id: null!,
      deletedAt: null,
      barcode: '',
      createdAt: dayjs().toISOString(),
      storageCell: null!,
      capacity: null,
      kind: ContainerKindEnum.PRODUCTS,
      stocks: [],
      name: '',
      cellsArea: null!,
      activeSelections: [],
      isEmpty: true,
      shipments: [],
    };
    return;
  }

  initializing.value = true;

  const { data: containerData } = await urql.query<{ container: Container }, QueryContainerArgs>(
    gql`
      query GetContainer($id: ID!) { container(id: $id) {
        id
        name
        barcode
        capacity
        createdAt
        deletedAt
        isEmpty
        storageCell { id name }
        kind
        shipments { id externalId }
        activeSelections {
          id
          selectionOrders {
            id
            container {
              id
            }
            shipment {
              id
              externalId
            }
            items {
              id
              storageUnit {
                productPack {
                  id
                }
              }
            }
          }
        }
      } }
    `,
    { id: props.id! },
  );

  initializing.value = false;

  container.value = containerData!.container;
}

watch(barcodeType, (): void => {
  state.value.lastContainerBarcodeType = barcodeType.value;
});

const saveContainerMutation = useMutation<{ container: Container }, MutationSaveContainerArgs>(gql`
  mutation SaveContainer($id: ID, $name: String, $barcode: String) {
    container: saveContainer(id: $id, name: $name, barcode: $barcode) { id }
  }
`);

async function doSave(): Promise<boolean> {
  clearErrors();
  const { error, data } = await saveContainerMutation.executeMutation(
    {
      id:       container.value!.id,
      barcode:  container.value!.barcode || null,
      name:     container.value!.name || null,
    },
  );

  if (error) {
    fillErrorsFromGraphQLError(error);
    return false;
  }

  // eslint-disable-next-line unicorn/prefer-ternary
  if (props.id) {
    await loadContainer();
  } else {
    await router.replace({ name: ROUTES.CONTAINERS_EDIT, params: { id: data!.container.id } });
  }

  return true;
}

async function save(): Promise<void> {
  progress.applying = true;

  await doSave();

  progress.applying = false;
}

async function saveAndGoToList(): Promise<void> {
  progress.saving = true;

  if (await doSave()) {
    navigateBack({ name: ROUTES.CONTAINERS_LIST });
  }

  progress.saving = false;
}

async function deleteContainer(): Promise<void> {
  progress.deleting = true;

  await urql.mutation<unknown, MutationDeleteContainerArgs>(
    gql`mutation DeleteContainer($id: ID!) { deleteContainer(id: $id) }`,
    { id: props.id! },
  );

  progress.deleting = false;

  await navigateBack({ name: ROUTES.CONTAINERS_LIST });
}

async function restoreContainer(): Promise<void> {
  progress.restoring = true;

  await urql.mutation<unknown, MutationRestoreContainerArgs>(
    gql`mutation RestoreContainer($id: ID!) { restoreContainer(id: $id) { id } }`,
    { id: container.value!.id },
  );

  progress.restoring = false;
  await loadContainer();
}

const isContainerHasOrders = computed(() => container.value!.kind !== ContainerKindEnum.PRODUCTS);

</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>

<i18n lang="yaml">
ru:
  Amount in base measurement units: Количество в базовых единицах
  Amount in pack measurement units: Количество в единицах упаковки
  Code: Код
  Container {name}: Контейнер {name}
  Go to Containers List: Перейти к списку контейнеров
  New Container: Новый контейнер
  Pack measurement unit: Единица хранения
  Product name: Наименование товара

en:
  Amount in base measurement units: Amount in base measurement units
  Amount in pack measurement units: Amount in pack measurement units
  Code: Code
  Container {name}: Container {name}
  Go to Containers List: Go to Containers List
  New Container: New Container
  Pack measurement unit: Pack measurement unit
  Product name: Product name

</i18n>
