<template>
  <FullHeightPage>
    <DashboardTabs storage-key="transferDashboard.tab">
      <template #pending>
        <TransferTaskDashboardList
          storage-prefix="transferTaskDashboard.inProgressTab.listOptions"
          :states="[TransferStateEnum.CREATED, TransferStateEnum.IN_PROGRESS]"
          class="full-height scroll-y"
        />
      </template>
      <template #completed>
        <TransferTaskDashboardList
          storage-prefix="transferTaskDashboard.completedTab.listOptions"
          :states="[TransferStateEnum.COMPLETED]"
          class="full-height scroll-y"
        />
      </template>
    </DashboardTabs>
  </FullHeightPage>
</template>

<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import useWakeLockWhenMounted from '@/composables/useWakeLockWhenMounted';
import { TransferStateEnum } from '@/graphql/types';
import FullHeightPage from '@/components/FullHeightPage.vue';
import DashboardTabs from '@/components/DashboardTabs.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import TransferTaskDashboardList from '@/views/Mobile/TransferTask/TransferTaskDashboardList.vue';

const { t } = useI18n();

useBreadcrumbs(t('Transfer Task'));

useWakeLockWhenMounted();

</script>

<i18n lang="yaml">
ru:
  Transfer Task: Задание на перемещение
  No Transfers: Перемещения отсутствуют

en:
  Transfer Task: Transfer Task
  No Transfers: No Transfers
</i18n>
