ru:
  Dashboard: Обзор

  Products Incomes: Приём товара
  Arrival: Приёмка
  Arrivals list: Приёмки

  Products Storage: Хранение
  Transfer: Перемещение
  Restocking: Пополнение

  Shipping: Отправка
  Selections: Отборы
  Realizations: Отгрузки

  Reports: Отчеты
  Products Movements: Движение товара

  Directories: Справочники
  Users: Пользователи

  Administration: Администрирование
  Accounts: Аккаунты
  Payments: Платежи

  Warehouse Settings: Настройки склада

en:
  Dashboard: Dashboard

  Products Incomes: Products Incomes
  Arrival: Arrival
  Arrivals list: Arrivals list

  Products Storage: Storage
  Transfer: Transfer
  Restocking: Restocking

  Shipping: Shipping
  Selections: Selections
  Realizations: Realizations

  Reports: Reports
  Products Movements: Products Movements

  Directories: Directories
  Users: Users

  Administration: Administration
  Accounts: Accounts
  Payments: Payments

  Warehouse Settings: Warehouse Settings
