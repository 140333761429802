<template>
  <QField
    :label="label"
    stack-label
    :error="!!primaryError"
    :error-message="primaryError"
  >
    <template #control>
      <QSkeleton
        v-if="fetching"
        width="100px"
      />
      <template v-else>
        <LinksList
          :max-to-show="3"
          :items="items"
        >
          <template #item="{item}">
            <slot
              name="item"
              :item="item"
            />
          </template>
        </LinksList>
      </template>
    </template>
  </QField>
</template>

<script setup lang="ts">

import useErrorHandling from '@/composables/useErrorHandling';
import type { UseQueryArgs } from '@urql/vue';
import { useQuery } from '@urql/vue';
import { computed, watch } from 'vue';
import LinksList from '@/components/LinksList.vue';

type TItem = object;

const { fillErrorsFromGraphQLError, primaryError } = useErrorHandling();

const props = defineProps<{
  label: string;
  query: UseQueryArgs;
}>();

const { data, fetching, error } = useQuery<{ items: TItem[] }>(props.query);

watch(error, fillErrorsFromGraphQLError);

const items = computed(() => data.value?.items || []);

</script>
