<template>
  <div
    v-if="q.screen.xs"
    class="full-height column no-wrap"
  >
    <div class="q-px-md q-pt-md">
      <QInput
        v-if="showSearch"
        ref="searchInputEl"
        v-bind="$attrs"
        v-model="searchString"
        dense
        bottom-slots
        class="full-width"
        :label="t('Search')"
        :loading="searching"
        @keydown.enter="searchInputEl!.blur()"
      >
        <template #prepend>
          <QIcon name="mdi-magnify" />
        </template>
      </QInput>
    </div>
    <QList class="col scroll-y">
      <QItem v-if="searching">
        <QItemSection>
          <QItemLabel><QSkeleton type="text" /></QItemLabel>
        </QItemSection>
      </QItem>
      <template v-else>
        <Option
          v-for="item in items"
          :key="item.value"
          :option="item"
          :q-item-props="{
            clickable: true,
            active: isPicked(item),
            activeClass: 'bg-grey-4',
          }"
          @click="handlePick(item)"
        />
      </template>
    </QList>

    <ButtonsRow
      v-if="isMultiple && modelArray.length > 0"
      v-slot="{ buttonProps }"
    >
      <QBtn
        v-bind="buttonProps"
        icon="mdi-content-save"
        @click="emit('save-filter')"
      >
        {{ t('Apply') }}
      </QBtn>
      <QBtn
        v-bind="buttonProps"
        icon="mdi-cancel"
        @click="handleSelect([])"
      >
        {{ t('Cancel') }}
      </QBtn>
    </ButtonsRow>
  </div>

  <QSelect
    v-else
    v-bind="$attrs"
    :options="items"
    :model-value="modelValue"
    :multiple="isMultiple"
    :label="t('Select Value')"
    :loading="searching"
    use-input
    dense
    @update:model-value="handleSelect($event)"
    @filter="filterItems"
  >
    <template
      v-if="operator.renderOption"
      #option="{ itemProps, opt }"
    >
      <Option
        :q-item-props="itemProps"
        :option="opt"
      />
    </template>
  </QSelect>
</template>

<script setup lang="ts">

import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import type { Scalars } from '@/graphql/types';
import type { Filter, FilterOperatorList, FilterValue, RenderOptionProps } from '@/types/reports';
import { useDebounce, useVModel } from '@vueuse/core';
import type { QSelectOption } from 'quasar';
import { QInput, QItem, QItemLabel, QItemSection, useQuasar } from 'quasar';
import type { FunctionalComponent } from 'vue';
import { computed, h, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

type ID = Scalars['ID'];
type ListFilterValue = FilterValue<ID> | FilterValue<ID>[];

const { t } = useI18n();

const q = useQuasar();

const searching = ref(false);

const props = defineProps<{
  modelValue: ListFilterValue | null;
  operator: FilterOperatorList;
  otherFilters: Filter[];
}>();

const model = useVModel(props, 'modelValue');

const emit = defineEmits<{
  (e: 'update:modelValue', value: typeof props.modelValue): void;
  (e: 'save-filter'): void;
}>();

const items = ref<QSelectOption<ID>[]>([]);

const searchString = ref<string | null>(null);

watch(useDebounce(searchString, 500), loadItems);

const searchRequired = ref(false);

watch(() => props.operator, async () => {
  await loadItems(searchString.value);
  searchRequired.value = items.value.length > 10;
}, { immediate: true });

const isMultiple = computed(() => props.operator.type === 'multiList');

// noinspection NestedConditionalExpressionJS
const modelArray = computed(
  () => Array.isArray(model.value)
    ? model.value
    : (model.value ? [model.value] : [])
);

const showSearch = computed(() => !props.operator.hideSearch && searchRequired.value);

async function loadItems(val: string|null ): Promise<void> {
  searching.value = true;
  items.value     = [];
  items.value     = await props.operator.values(val ?? '', props.otherFilters);
  if (props.operator.nullOption) {
    items.value.unshift(props.operator.nullOption());
  }
  searching.value = false;
}

async function filterItems(val: string, done: () => void) {
  await loadItems(val);
  done();
}

function handleSelect(value: ListFilterValue): void {
  model.value = value;
}

function isPicked(value: FilterValue<ID>) {
  return modelArray.value.some(v => v.value === value.value);
}

function handlePick(value: FilterValue<ID>): void {
  if (isMultiple.value) {
    model.value = modelArray.value.some(v => v.value === value.value)
      ? modelArray.value.filter(v => v.value !== value.value)
      : [...modelArray.value, value];

  } else {
    model.value = value;
    emit('save-filter');
  }
}

// noinspection LocalVariableNamingConventionJS
const Option: FunctionalComponent<RenderOptionProps> = optionProps => {
  if (props.operator.renderOption) {
    return props.operator.renderOption(optionProps);
  }

  return h(
    QItem,
    { ...optionProps.qItemProps },
    () => h(QItemSection, () => h(QItemLabel, () => optionProps.option.label)),
  );
};
// The prop names will not be normalized to camelCase unless the `props` option is specified
Option.props = ['qItemProps', 'option'];

const searchInputEl = ref<InstanceType<typeof QInput>>();
</script>

<i18n lang="yaml">
ru:
  Select Value: Выберите значение
en:
  Select Value: Select Value
</i18n>
