<template>
  <FullHeightPage>
    <GraphQLQueryTable
      :graphql-query="query"
      :fields="tableFields"
      :available-filters="availableFilters"
      storage-prefix="selectionsList"
      keep-page-in-url
      sticky-header
      class="col"
    >
      <template #body-cell-id="column">
        <BodyCellLink
          :column="column"
          :to="{ name: ROUTES.SELECTIONS_EDIT, params: { id: column.row.id } }"
        />
      </template>
      <template #body-cell-state="column">
        <QTd :props="column">
          <QChip
            :color="stateColor(column.row)"
            text-color="black"
          >
            {{ column.value }}
          </QChip>
        </QTd>
      </template>
      <template #after-search-string="{ options }">
        <ExcelExport
          v-if="canImport"
          :type="ExcelImportTypeEnum.SELECTIONS"
          :mutation-export="mutationExport"
          :options="options"
        />
      </template>
    </GraphQLQueryTable>
  </FullHeightPage>
</template>

<script setup lang="ts">

import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import FullHeightPage from '@/components/FullHeightPage.vue';
import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useCan from '@/composables/useCan';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import {
  type SelectionItem,
  type SelectionOrder,
  type CustomerOrder,
  ExcelImportTypeEnum,
} from '@/graphql/types';
import {
  SelectionKindEnum,
  type SelectionReportRow,
  SelectionStateEnum,
} from '@/graphql/types';
import * as badgeColors from '@/helpers/badgeColors';
import * as reports from '@/helpers/reports';
import { createDatesRangeOperator, createPrimitive } from '@/helpers/reports';
import userFullName from '@/helpers/userFullName';
import ROUTES from '@/router/routeNames';
import { Ability } from '@/types/backend';
import type { ReportFilter, TableColumn } from '@/types/reports';
import ExcelExport from '@/views/ExcelExport.vue';
import { gql } from '@urql/vue';
import * as R from 'ramda';
import { uniq } from 'ramda';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const canImport = useCan(Ability.ImportExcel);

const mutationExport = gql`
  mutation GenerateSelectionsFile($type: ExcelImportTypeEnum!, $searchString: String, $filter: [ReportFilterInput!]) {
    exportFilePath: generateExcelExportFile(type: $type, searchString: $searchString, filter: $filter)
  }
`;

useBreadcrumbs(t('Selections'));

const tableFields: TableColumn<SelectionReportRow>[] = [
  {
    label:     t('ID'),
    name:      'id',
    field:     'id',
    mandatory: true,
    align:     'left',
    sortable:  true,
  },
  {
    label:    t('Created at'),
    name:     'createdAt',
    field:    'createdAt',
    format:   d => formatDate(d, FORMATS.DATETIME),
    align:    'left',
    sortable: true,
  },
  {
    label: t('User'),
    name:  'user',
    field: 'user',
    format: userFullName,
    align: 'left',
  },
  {
    label:  t('State'),
    name:   'state',
    field:  'state',
    format: (state?: SelectionStateEnum) => state ? t(`selectionState.${state}`) : '',
    align:  'left',
  },
  {
    label:  t('Type'),
    name:   'selectionKind',
    field:  'selectionKind',
    align:  'left',
    format: kind => kind ? t(`selectionKind.${kind}`) : '',
  },
  {
    label:  t('Shipment Type'),
    name:   'type',
    field:  'items',
    format: (items?: SelectionItem[]) => getShipments(items)[0].__typename === 'CustomerOrder'
      ? t('Order')
      : t('Return'),
  },
  {
    label:      t('Orders Count'),
    labelTitle: t('Orders Count Title'),
    name:       'ordersCount',
    field:      'items',
    format:     (items?: SelectionItem[]) => String(getShipments(items).map(o => o.id).length),
  },
  {
    label:      t('Products Count'),
    labelTitle: t('Products Count Title'),
    name:       'productsCount',
    field:      'items',
    format:     (items?: SelectionItem[]) => String(R.sum((items ?? []).map(
      i => i.productPack.quantityInMinMeasurementUnits * i.amount,
    ))),
  },
  {
    label:  t('Carrier Name'),
    name:   'carrier',
    field:  'items',
    format: (items?: SelectionItem[]) => uniq(getShipments(items).map(o => o.carrier?.name).filter(Boolean)).join(', '),
  },
  {
    label:  t('Orders'),
    name:   'shipments',
    field:  'items',
    format: (items?: SelectionItem[]) => truncateArrayFormat(3)(getShipments(items).map(o => o.externalId!)),
  },
  {
    label:  t('Containers'),
    name:   'containers',
    field:  'selectionOrders',
    format: (orders?: SelectionOrder[]) => truncateArrayFormat(3)(uniq((orders ?? []).map(so => so.container.name))),
    align:  'left',
  },
];

const availableFilters: ReportFilter[] = [
  {
    field:     'createdAt',
    label:     t('Created at'),
    operators: [
      createDatesRangeOperator(),
    ],
  },
  {
    field:     'selectionKind',
    label:     t('Type'),
    operators: (['=', '!=', 'in', 'not in'] as const).map(op =>
      reports.createList(op, () => Promise.resolve([
        SelectionKindEnum.ORDER,
        SelectionKindEnum.CLUSTER,
        SelectionKindEnum.GROUP,
      ].map(value => ({
        value,
        label: t(`selectionKind.${value}`),
      }))), t(`reportFilterOperator.${op}`)),
    ),
  },
  {
    field:     'state',
    label:     t('State'),
    operators: (['=', '!=', 'in', 'not in'] as const).map(op =>
      reports.createList(op, () => Promise.resolve([
        SelectionStateEnum.CREATED,
        SelectionStateEnum.SELECTING,
        SelectionStateEnum.COMPLETED,
      ].map(value => ({
        value,
        label: t(`selectionState.${value}`),
      }))), t(`reportFilterOperator.${op}`)),
    ),
  },
  {
    field:     'productSku',
    label:     t('SKU'),
    operators: [
      reports.createPrimitive('=', 'string'),
      reports.createPrimitive('in', 'multiString', {
        label: t('reportFilterOperator.in'),
      }),
    ],
  },
  {
    field:     'orderNumber',
    label:     t('Order Number'),
    operators: [
      createPrimitive('=', 'string'),
    ],
  },
];

function stateColor(row: SelectionReportRow): string {
  return badgeColors.forSelectionState(row.state);
}

function truncateArrayFormat(limit: number) {
  return (elements: string[]) => {
    if (elements.length > limit) {
      return [...elements.slice(0, limit), t('{n} more', { n: elements.length - limit })].join(', ');
    }
    return elements.join(', ');
  };
}

function getShipments(items?: SelectionItem[]): CustomerOrder[] {
  return uniq((items ?? []).map(i => i.shipment));
}


const query =   gql`
  query GetSelectionReport(
    $page: Int,
    $perPage: Int!,
    $query: String,
    $filter: [ReportFilterInput!],
    $sort: [ReportSortInput!]!,
  ) {
    result: selectionsReport(
      page: $page,
      perPage: $perPage,
      query: $query,
      filter: $filter,
      sort: $sort,
    ) {
      data {
        id
        state
        createdAt
        selectionKind
        user {
          id
          firstname
          lastname
        }
        selectionOrders {
          id
          container {
            id
            name
          }
        }
        items {
          id
          amount
          productPack {
            id
            quantityInMinMeasurementUnits
          }
          shipment {
            id
            externalId
            carrier { id name }
          }
        }
      }
      total
    }
  }
`;

</script>

<i18n lang="yaml">
ru:
  Selections: Отборы
  Shipment Type: Тип отправления
  Orders Count: Кол-во заказов
  Orders Count Title: Количество заказов
  Products Count: Товары
  Products Count Title: Количество товаров
  "{n} more": "еще {n}"
en:
  Selections: Selections
  Shipment Type: Shipment Type
  Orders Count: Orders Count
  Orders Count Title: Orders Count
  Products Count: Products
  Products Count Title: Products Count
  "{n} more": "{n} more"
</i18n>
