<template>
  <QInput
    v-if="$attrs.type === 'number'"
    v-bind="$attrs"
    v-model.number="model"
    @focus="handleFocus"
    @keydown.enter="handleEnterDown"
  />
  <QInput
    v-else
    v-bind="$attrs"
    v-model="model"
    @focus="handleFocus"
    @keydown.enter="handleEnterDown"
  />
</template>

<script setup lang="ts">

import { BARCODE_MIN_LENGTH } from '@/constants';
import { useVModel } from '@vueuse/core';
import { isNil } from 'ramda';
import { ref, watch } from 'vue';

const props = defineProps<{
  modelValue: string | number | null;
}>();

const emit = defineEmits<{
  (e: 'barcode-detected', barcode: string): void;
}>();

const model = useVModel(props);

const savedValue = ref<string | number | null>(null);

const isReplace = ref<boolean>(false);

watch(model, value => {
  if (String(value).length === 1) {
    isReplace.value = true;
  }
});

function handleFocus(): void {
  savedValue.value = model.value;
  isReplace.value = false;
}

function handleEnterDown(): void {
  const offset = isReplace.value || isNil(savedValue.value) ? 0 : String(savedValue.value).length;

  if (String(model.value).length - offset >= BARCODE_MIN_LENGTH) {
    emit(
      'barcode-detected',
      String(model.value).slice(offset),
    );
    model.value = savedValue.value!;

    isReplace.value = false;
  }
}

</script>
