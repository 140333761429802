<template>
  <QDialog
    :model-value="!!token"
    @hide="close()"
  >
    <QCard>
      <CardTitle>
        {{ t('Delete API Token') }}
        <template #subtitle>
          <i>{{ token.name }}</i>
        </template>
      </CardTitle>

      <QCardSection>
        {{ t('Are you sure?') }}
      </QCardSection>

      <PrimaryErrorBanner />

      <QSeparator />

      <QCardActions align="right">
        <QBtn @click="close()">
          {{ t('Cancel') }}
        </QBtn>
        <QBtn
          color="error"
          :loading="deletingToken"
          icon="mdi-delete-forever"
          @click="deleteToken"
        >
          {{ t('Delete') }}
        </QBtn>
      </QCardActions>
    </QCard>
  </QDialog>
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import type { MutationDeleteAccessTokenArgs, PersonalAccessToken } from '@/graphql/types';
import { gql, useMutation } from '@urql/vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { fillErrorsFromGraphQLError, PrimaryErrorBanner } = useErrorHandling();

const props = defineProps<{
  token: PersonalAccessToken | null;
}>();

const emit = defineEmits<{
  (e: 'update:token', token: PersonalAccessToken | null): void;
  (e: 'delete'): void;
}>();

function close(): void {
  emit('update:token', null);
}

const {
  executeMutation: doDeleteToken,
  fetching: deletingToken,
} = useMutation<{ id: string }, MutationDeleteAccessTokenArgs>(gql`
  mutation DeleteAccessToken($id: ID!) {
    id: deleteAccessToken(id: $id)
  }
`);

async function deleteToken(): Promise<void> {
  const { error } = await doDeleteToken({ id: props.token!.id });

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  emit('delete');
  close();
}
</script>

<i18n lang="yaml">
en:
  Delete API Token: Delete API Token
  Are you sure?: Are you sure you would like to delete this API token?
ru:
  Delete API Token: Удаление API токена
  Are you sure?: Вы уверены, что хотите удалить этот API токен?
</i18n>
