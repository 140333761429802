<template>
  <QDialog
    persistent
    max-width="650"
    :model-value="!!token"
    @update:model-value="close()"
  >
    <QCard>
      <CardTitle>{{ t('API Token') }}</CardTitle>

      <QSeparator />

      <QCardSection>
        <p class="mt-4">
          {{ t('Please copy your new API token') }}
        </p>
        <p class="mb-0">
          <code>{{ token }}</code>
          <QBtn
            flat
            round
            icon="mdi-content-copy"
            @click="copyToClipboard(token)"
          />
        </p>
      </QCardSection>

      <QSeparator />

      <QCardActions>
        <QSpace />

        <QBtn @click="close()">
          {{ t('Close') }}
        </QBtn>
      </QCardActions>
    </QCard>
  </QDialog>
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import copyToClipboard from 'copy-to-clipboard';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps<{
  token: string | null;
}>();

const emit = defineEmits<{
  (e: 'update:token', token: string | null): void;
}>();

function close(): void {
  emit('update:token', null);
}
</script>

<i18n lang="yaml">
en:
  API Token: API Token
  Please copy your new API token: >
    Please copy your new API token. For your security, it won't be shown again.
ru:
  API Token: API токен
  Please copy your new API token: >
    Скопируйте новый API токен. Для вашей безопасности он не будет показан снова
</i18n>
