<template>
  <QCard>
    <CardTitle>
      {{ t('Warehouse Settings') }}
    </CardTitle>

    <QSeparator />

    <QList>
      <QItem
        v-for="[routeName, title] in settingsList"
        :key="routeName"
      >
        <QItemSection avatar>
          <QBtn
            flat
            round
            icon="mdi-pencil"
            :to="{ name: routeName }"
          />
        </QItemSection>
        <QItemSection>
          <QItemLabel>
            {{ title }}
          </QItemLabel>
        </QItemSection>
      </QItem>
    </QList>
  </QCard>
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import ROUTES from '@/router/routeNames';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const settingsList: [string, string][] = [
  [ ROUTES.WAREHOUSE_SETTINGS_PRODUCTS, t('Products Settings') ],
];

</script>

<i18n lang="yaml">
ru:
  Warehouse Settings: Настройки склада
  Products Settings: Настройки товаров
en:
  Warehouse Settings: Warehouse Settings
  Products Settings: Products Settings
</i18n>
