<template>
  <QBtnDropdown
    v-if="store.can(Ability.ImportExcel)"
    v-model="exportDropdown"
    :label="t('Download')"
    icon="mdi-download"
    color="primary"
  >
    <QList>
      <ExcelExport
        v-for="[mutation, type, title] in [
          [mutationExport, ExcelImportTypeEnum.PRODUCTS, t('Products')] as const,
          [mutationExport, ExcelImportTypeEnum.PRODUCTS_BARCODES, t('Barcodes')] as const,
        ]"
        :key="title"
        v-slot="{ generateExportFile, generatingFile }"
        :mutation-export="mutation"
        :options="options"
        :type="type"
        @file-generated="handleExportFileGenerated"
      >
        <QItem
          clickable
          @click="generateExportFile"
        >
          <QItemSection>
            <QItemLabel>{{ title }}</QItemLabel>
          </QItemSection>
          <QItemSection
            v-if="generatingFile"
            side
          >
            <QCircularProgress indeterminate />
          </QItemSection>
        </QItem>
      </ExcelExport>
    </QList>
  </QBtnDropdown>

  <QBtnDropdown
    v-if="store.canImport"
    v-model="importDropdown"
    :label="t('Upload File')"
    icon="mdi-upload"
    color="green"
  >
    <QList>
      <ExcelImport
        :type="ExcelImportTypeEnum.PRODUCTS"
        :mutation-import="mutationImport"
        :mutation-validate="mutationValidate"
        :options="options"
        :success-notification="n => t('Imported {n} products', n)"
        @success="emit('success')"
        @close="importDropdown = false"
      >
        <template #default="{ start }">
          <QItem
            clickable
            @click="start"
          >
            <QItemSection>
              <QItemLabel>{{ t('Products') }}</QItemLabel>
            </QItemSection>
          </QItem>
        </template>

        <template #info-for-upload-file>
          {{ t('File must contain products only for add or update (not full product list)') }}
        </template>

        <template #import-btn-title>
          {{ t('Update Products') }}
        </template>

        <template #row-with-error-message="{ rowsCount }">
          {{ t('{n} products with errors', rowsCount) }}
        </template>

        <template #validation-success="{ rowsCount }">
          {{ t('Validation success. You can update {n} products.', rowsCount) }}
        </template>
      </ExcelImport>
      <ExcelImport
        :type="ExcelImportTypeEnum.PRODUCTS_BARCODES"
        :mutation-import="mutationImport"
        :mutation-validate="mutationValidate"
        :options="options"
        :success-notification="n => t('Imported {n} barcodes', n)"
        @success="emit('success')"
        @close="importDropdown = false"
      >
        <template #default="{ start }">
          <QItem
            clickable
            @click="start"
          >
            <QItemSection>
              <QItemLabel>{{ t('Barcodes') }}</QItemLabel>
            </QItemSection>
          </QItem>
        </template>

        <template #info-for-upload-file>
          {{ t('File must contain barcodes only for add or update (not full barcodes list)') }}
        </template>

        <template #import-btn-title>
          {{ t('Update Barcodes') }}
        </template>

        <template #row-with-error-message="{ rowsCount }">
          {{ t('{n} barcodes with errors', rowsCount) }}
        </template>

        <template #validation-success="{ rowsCount }">
          {{ t('Validation success. You can update {n} barcodes.', rowsCount) }}
        </template>
      </ExcelImport>
    </QList>
  </QBtnDropdown>
</template>

<script setup lang="ts">

import { ExcelImportTypeEnum } from '@/graphql/types';
import { Ability } from '@/types/backend';
import type { ReportOptions } from '@/types/reports';
import ExcelExport from '@/views/ExcelExport.vue';
import ExcelImport from '@/views/ExcelImport.vue';
import { gql } from '@urql/vue';
import { useI18n } from 'vue-i18n';
import { nextTick, ref } from 'vue';
import useStore from '@/stores/root';

const { t } = useI18n();
const store = useStore();

defineProps<{
  options?: ReportOptions;
}>();

const emit = defineEmits<{
  (e: 'success'): void;
}>();

const importDropdown = ref(false);
const exportDropdown = ref(false);

function handleExportFileGenerated() {
  // Компонент ExcelImport находится внутри выпадающего меню, при закрытии он исчезает.
  // Поэтому сразу после завершения генерации файла надо сохранить меню открытым, чтобы произошло скачивание,
  // а уже при следующем тике можно закрыть меню.
  nextTick(() => {
    exportDropdown.value = false;
  });
}

const mutationImport = gql`
  mutation ImportProducts($type: ExcelImportTypeEnum!, $file: Upload) {
    importedCount: importExcelFile(type: $type, file: $file)
  }
`;

const mutationValidate = gql`
  mutation ValidateFileProducts($type: ExcelImportTypeEnum!, $file: Upload) {
    validationResult: validateExcelImportFile(type: $type, file: $file) {
      isValid
      rowsCount
      validationErrorMessage
      fileWithErrorsUrl
    }
  }
`;

const mutationExport = gql`
  mutation GenerateProductsFile($type: ExcelImportTypeEnum!, $searchString: String, $filter: [ReportFilterInput!]) {
    exportFilePath: generateExcelExportFile(type: $type, searchString: $searchString, filter: $filter)
  }
`;

</script>

<i18n lang="yaml">
ru:
  Upload File: Загрузить файл
  Update Products: Обновить товары
  Update Barcodes: Обновить штрихкоды
  File must contain products only for add or update (not full product list): >
    Файл должен содержать только товары для добавления или обновления
    (не весь список товаров)
  File must contain barcodes only for add or update (not full barcodes list): >
    Файл должен содержать только штрихкоды для добавления или обновления
    (не весь список штрихкодов)
  Validation success. You can update {n} products.: >
    Проверка прошла успешно. Вы можете обновить {n} товаров
    | Проверка прошла успешно. Вы можете обновить {n} товар
    | Проверка прошла успешно. Вы можете обновить {n} товара
    | Проверка прошла успешно. Вы можете обновить {n} товаров
  Validation success. You can update {n} barcodes.: >
    Проверка прошла успешно. Вы можете обновить {n} штрихкодов
    | Проверка прошла успешно. Вы можете обновить {n} штрихкод
    | Проверка прошла успешно. Вы можете обновить {n} штрихкода
    | Проверка прошла успешно. Вы можете обновить {n} штрихкодов
  "{n} products with errors": >
    {n} товаров с ошибками
    | {n} товар с ошибками
    | {n} товара с ошибками
    | {n} товаров с ошибками
  "{n} barcodes with errors": >
    {n} штрихкодов с ошибками
    | {n} штрихкод с ошибками
    | {n} штрихкода с ошибками
    | {n} штрихкодов с ошибками
  Imported {n} products: >
    Импортировано {n} товаров
    | Импортирован {n} товар
    | Импортировано {n} товара
    | Импортировано {n} товаров
  Imported {n} barcodes: >
    Импортировано {n} штрихкодов
    | Импортирован {n} штрихкод
    | Импортировано {n} штрихкода
    | Импортировано {n} штрихкодов
en:
  Upload File: Upload File
  Update Products: Update Products
  Update Barcodes: Update Barcodes
  File must contain products only for add or update (not full product list): >
    File must contain products only for add or update (not full product list)
  File must contain barcodes only for add or update (not full barcodes list): >
    File must contain barcodes only for add or update (not full barcodes list)
  Validation success. You can update {n} products.: >
    Validation success. You can update {n} product.
    | Validation success. You can update {n} products
  Validation success. You can update {n} barcodes.: >
    Validation success. You can update {n} barcode.
    | Validation success. You can update {n} barcodes
  "{n} products with errors": >
    {n} product with errors
    | {n} products with errors'
  "{n} barcodes with errors": >
    {n} barcode with errors
    | {n} barcodes with errors'
  Imported {n} products: >
    Imported {n} product
    | Imported {n} products
  Imported {n} barcodes: >
    Imported {n} barcode
    | Imported {n} barcodes
</i18n>
