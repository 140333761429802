<template>
  <QPage padding>
    <QCard>
      <BatchCard
        :id="id"
        @back="back"
        @saved="back"
      />
    </QCard>
  </QPage>
</template>

<script setup lang="ts">

import BatchCard from '@/components/BatchCard.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import { useI18n } from 'vue-i18n';
import navigateBack from '@/helpers/navigateBack';
import type { Batch } from '@/graphql/types';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

useBreadcrumbs(t('Edit Batch'));

defineProps<{
  id: string;
}>();

function back(batch: Batch) {
  navigateBack({ name: ROUTES.PRODUCTS_EDIT, params: { id: batch.product.id } });
}

</script>

<i18n lang="yaml">
ru:
  Edit Batch: Партия
en:
  Edit Batch: Edit Batch
</i18n>
