<template>
  <QInnerLoading :showing="fetching" />

  <ProductEditMobile
    v-if="data?.product"
    :id="data.product.id"
    @back="emit('update:product-id', null)"
  />
</template>

<script setup lang="ts">

import ProductForCard from '@/graphql/fragments/ProductForCard';
import type { Product, QueryProductArgs, Scalars } from '@/graphql/types';
import ProductEditMobile from '@/views/Products/ProductEditMobile.vue';
import { gql, useQuery } from '@urql/vue';
import { computed } from 'vue';

const props = defineProps<{
  productId: Scalars['ID'];
}>();

const emit = defineEmits<{
  (e: 'update:product-id', productId: Scalars['ID'] | null): void;
}>();

const { data, fetching } = useQuery<{ product: Product }, QueryProductArgs>({
  query: gql`
    ${ProductForCard}

    query GetProductForEditFromMobileCellCard($id: ID!) {
      product(id: $id) {
        ...ProductForCard
      }
    }
  `,
  variables: computed(() => ({ id: props.productId })),
});

</script>
