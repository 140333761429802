<template>
  <QCard>
    <QLinearProgress
      :indeterminate="fetching"
      instant-feedback
    />

    <CardTitle>
      {{ t('Accounting Models') }}

      <QSpace />

      <QBtn
        :to="{ name: ROUTES.ACCOUNTING_MODELS_NEW }"
        flat
        round
        icon="mdi-plus"
        color="green"
        :title="t('Add')"
      />
    </CardTitle>

    <QSeparator />

    <BaseAlert
      v-if="getPrimaryError()"
      type="error"
      dismissible
      @input="clearErrors()"
    >
      {{ getPrimaryError() }}
    </BaseAlert>

    <QList>
      <AccountingModelListItem
        v-for="accountingModel in accountingModels"
        :key="accountingModel.id"
        :accounting-model="accountingModel"
        @delete="accountingModelDeleted()"
        @error="fillErrorsFromGraphQLError($event)"
      />
    </QList>
  </QCard>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import CardTitle from '@/components/CardTitle.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import type { AccountingModel } from '@/graphql/types';
import { gql, useQuery } from '@urql/vue';
import { useI18n } from 'vue-i18n';
import AccountingModelListItem from '@/views/WarehouseSettings/AccountingModelListItem.vue';
import { computed } from 'vue';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const { fillErrorsFromGraphQLError, clearErrors, getPrimaryError } = useErrorHandling();

const { data, fetching, executeQuery } = useQuery<{ data: AccountingModel[] }>({
  query: gql`query GetAccountingModels { data: accountingModels { id name } }`,
});

const accountingModels = computed(() => data.value?.data ?? []);

function accountingModelDeleted(): void {
  clearErrors();
  executeQuery();
}

</script>

<i18n lang="yaml">
ru:
  Accounting Models: Модели учёта
en:
  Accounting Models: Accounting Models
</i18n>
