<template>
  <QBtn
    v-if="store.transfer?.movements.length > 0"
    icon="mdi-history"
    @click="emit('click')"
  >
    {{ t('History') }}
  </QBtn>
</template>

<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import useTransferProcessStore from '@/stores/transferProcess';

const { t } = useI18n();

const emit = defineEmits<{
  (e: 'click'): void;
}>();

const store = useTransferProcessStore();

</script>

<i18n lang="yaml">
ru:
  History: История

en:
  History: History
</i18n>
