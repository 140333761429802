<template>
  <EditableListCard
    :title="t('Product Categories')"
    :items-query="query"
    :item-draft="getDraft"
    :item-key="u => u.id"
    :item-title="u => u.name"
    :delete-item="deleteCategory"
    item-editable
    item-deletable
    :item-filter="filterCategory"
  >
    <template #edit="{ item, on }">
      <ProductCategoryEdit
        :category="item"
        @save="on.save($event)"
        @cancel="on.cancel()"
      />
    </template>
  </EditableListCard>
</template>

<script setup lang="ts">

import type { ProductCategory } from '@/graphql/types';
import EditableListCard from '@/views/WarehouseSettings/EditableListCard.vue';
import ProductCategoryEdit from '@/views/WarehouseSettings/ProductCategoryEdit.vue';
import { gql, useMutation } from '@urql/vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const query = gql`
  query GetCategoriesForWarehouseSettingsList { data: productCategories { id name } }
`;

function getDraft(): ProductCategory {
  return {
    id:   null!,
    name: '',
  };
}

const { executeMutation: doDeleteCategory } = useMutation(gql`
  mutation DeleteProductCategory($id: ID!) { deleteProductCategory(id: $id) }
`);

async function deleteCategory(category: ProductCategory) {
  const { error } = await doDeleteCategory({ id: category.id });

  if (error) {
    throw error;
  }
}

function filterCategory(category: ProductCategory, searchString: string): boolean {
  return category.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase());
}

</script>

<i18n lang="yaml">
ru:
  Product Categories: Группы товаров
en:
  Product Categories: Product Categories
</i18n>
