<template>
  <ConfirmsAction
    v-if="[SupplyStateEnum.ARRIVING, SupplyStateEnum.COMPLETED].includes(supply.state)"
    :confirm-text="supply.state === SupplyStateEnum.ARRIVING ? t('Complete') : t('Reopen')"
    @confirmed="toggle"
  >
    <template #activator="{ prompt }">
      <QBtn
        v-if="supply.state === SupplyStateEnum.ARRIVING"
        icon="mdi-check-all"
        :label="t('Complete')"
        :loading="fetching"
        @click="prompt"
      />
      <QBtn
        v-else
        icon="mdi-restore"
        :label="t('Reopen')"
        :loading="fetching"
        @click="prompt"
      />
    </template>
  </ConfirmsAction>
</template>

<script setup lang="ts">
import ConfirmsAction from '@/components/ConfirmsAction.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import {
  type MutationToggleSupplyCompletionArgs,
  type Supply,
  SupplyStateEnum,
} from '@/graphql/types';
import { gql, useMutation } from '@urql/vue';
import { useQuasar } from 'quasar';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  supply: Supply;
}>();

const emit = defineEmits<{
  'update:state': [newState: SupplyStateEnum];
}>();

const {
  executeMutation: doComplete,
  fetching,
} = useMutation<{ supply: Supply }, MutationToggleSupplyCompletionArgs>(
  gql`
    mutation ToggleSupplyCompletionMutation($supplyId: ID!, $complete: Boolean!) {
      supply: toggleSupplyCompletion(supplyId: $supplyId, complete: $complete) { id state }
    }
  `,
);

const q = useQuasar();

const { getPrimaryErrorFromGraphQLError } = useErrorHandling();

async function toggle() {
  const { data, error } = await doComplete({
    supplyId: props.supply.id,
    complete: props.supply.state === SupplyStateEnum.ARRIVING,
  });

  if (error) {
    q.notify({
      type: 'negative',
      message: getPrimaryErrorFromGraphQLError(error),
    });
  }

  if (data) {
    emit('update:state', data.supply.state);
  }
}

</script>

<i18n lang="yaml">
ru:
  Reopen: Возобновить

en:
  Reopen: Reopen
</i18n>
