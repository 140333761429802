<template>
  <QSelect
    ref="select"
    v-bind="$attrs"
    v-model="value"
    :options="[true, false]"
    :option-disable="optionDisable"
    :option-label="optionLabel"
  />
</template>

<script setup lang="ts">

import { useVModel } from '@vueuse/core';
import { ref } from 'vue';

const props = defineProps<{
  modelValue: boolean;
  trueText: string;
  falseText: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const value = useVModel(props, 'modelValue', emit);

const optionDisable = () => false;
const optionLabel = (option: boolean) => option ? props.trueText : props.falseText;

const select = ref();

defineExpose({ select });

</script>
