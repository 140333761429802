<template>
  <EditBatchData
    :is-open="isOpen"
    :batch="storageUnit.batch"
    :product="storageUnit.batch.product"
    :saving="saving"
    :error="primaryError !== t('The given data was invalid.') ? primaryError : ''"
    @update:is-open="emit('update:isOpen', $event)"
    @save="saveBatch"
  />
</template>

<script setup lang="ts">

import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import type {
  Batch,
  InventoryBatchDataInput,
  MutationGetBatchForProductArgs,
  MutationUpdateStorageUnitBatchArgs,
  StorageUnit,
} from '@/graphql/types';
import { gql, useClientHandle } from '@urql/vue';
import ProductForBatch from '@/graphql/fragments/ProductForBatch';
import useErrorHandling from '@/composables/useErrorHandling';
import StorageUnitForArrival from '@/graphql/fragments/StorageUnitForArrival';
import EditBatchData from '@/components/Mobile/EditBatchData.vue';

const { t } = useI18n();

const { client: urql } = useClientHandle();

// noinspection LocalVariableNamingConventionJS
const { fillErrorsFromGraphQLError, primaryError } = useErrorHandling();

const props = defineProps<{
  isOpen: boolean;
  storageUnit: StorageUnit;
}>();

const saving = ref(false);

const emit = defineEmits<{
  (e: 'update:isOpen', open: boolean): void;
  (e: 'saved', storageUnit: StorageUnit): void;
}>();

async function saveBatch(batch: InventoryBatchDataInput): Promise<void> {
  saving.value = true;

  const { data, error } = await urql.mutation<{ batch: Batch }, MutationGetBatchForProductArgs>(
    gql`
      mutation GetOrCreateProductBatchFromMobileDialog(
        $productId: ID!,
        $number: String,
        $productionDate: DateTime,
        $expirationDate: DateTime,
      ) {
        batch: getBatchForProduct(
          productId: $productId,
          number: $number,
          productionDate: $productionDate,
          expirationDate: $expirationDate,
        ) {
          id
          product {
            ...ProductForBatch
          }
          number
          name
          productionDate
          expirationDate
        }
      }
      ${ProductForBatch}
    `,
    {
      productId:      props.storageUnit.productPack.product.id,
      number:         batch.number ?? null,
      productionDate: batch.productionDate ?? null,
      expirationDate: batch.expirationDate ?? null,
    },
  );

  if (error) {
    fillErrorsFromGraphQLError(error);
    saving.value = false;
    return;
  }

  const {
    data: storageUnitData,
    error: updateError,
  } = await urql.mutation<{ storageUnit: StorageUnit }, MutationUpdateStorageUnitBatchArgs>(gql`
    mutation UpdateStorageUnitBatchInBatchMobileDialog($storageUnitId: ID!, $batchId: ID!) {
      storageUnit: updateStorageUnitBatch(storageUnitId: $storageUnitId, batchId: $batchId) {
        ...StorageUnitForArrival
      }
    }
    ${StorageUnitForArrival}
  `, {
    storageUnitId: props.storageUnit.id,
    batchId:       data!.batch.id,
  });

  if (updateError) {
    fillErrorsFromGraphQLError(updateError);
    saving.value = false;
    return;
  }

  saving.value = false;
  emit('saved', storageUnitData!.storageUnit);
  emit('update:isOpen', false);
}

</script>
