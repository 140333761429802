<template>
  <QDialog
    v-model="isOpen"
    :maximized="q.platform.is.mobile"
  >
    <QCard class="full-height column">
      <CardTitle>
        {{ t('Photo capture') }}
      </CardTitle>

      <BaseAlert
        v-if="error"
        type="error"
      >
        {{ error }}
      </BaseAlert>

      <QLinearProgress
        v-else-if="availableCameras === null"
        indeterminate
      />

      <BaseAlert
        v-else-if="availableCameras.length === 0"
        type="warning"
      >
        {{ t('No cameras available') }}
      </BaseAlert>

      <QCardSection v-else>
        <QSelect
          v-model="selectedCamera"
          :options="availableCameras"
          :label="t('Camera')"
          :hint="selectedCamera ? null : t('Select Camera')"
          option-label="label"
          option-value="deviceId"
          return-object
        />
      </QCardSection>

      <QCardSection class="q-pa-none col">
        <Camera
          v-if="isOpen"
          ref="camera"
          :resolution="resolutionFlipped"
          @error="cameraError($event)"
          @started="streamingStarted()"
          @stopped="streamingStopped()"
        />
      </QCardSection>

      <QSeparator />

      <QCardActions align="between">
        <QBtn
          flat
          @click="isOpen = false"
        >
          {{ t('Cancel') }}
        </QBtn>

        <QBtn
          v-if="selectedCamera"
          :icon="streaming ? 'mdi-camera' : 'mdi-refresh'"
          @click="toggleStream()"
        >
          {{ t(streaming ? 'Capture' : 'Retry') }}
        </QBtn>
        <QBtn
          color="success"
          :disabled="!selectedCamera || streaming"
          icon="mdi-check"
          :loading="hasProgress()"
          @click="capture()"
        >
          {{ t('Done') }}
        </QBtn>
      </QCardActions>
    </QCard>
  </QDialog>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import CardTitle from '@/components/CardTitle.vue';
import useProgressHandling from '@/composables/useProgressHandling';
import { useLocalStorage, useVModel } from '@vueuse/core';
import { useQuasar } from 'quasar';
import Camera from 'simple-vue-camera';
import { computed, onErrorCaptured, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import type { Resolution } from 'simple-vue-camera/dist/components/resolution';

const { t } = useI18n();

const q = useQuasar();

const { progressStarted, hasProgress } = useProgressHandling();

const camera = ref<InstanceType<typeof Camera>>();

const ls = useLocalStorage('photoCapture', {
  recentCamera: null as MediaDeviceInfo | null,
});

const props = withDefaults(defineProps<{
  modelValue: boolean;
  filenamePrefix?: string;
  resolution?: Resolution;
}>(), {
  filenamePrefix: 'photo-',
  resolution: () => ({ width: 1080, height: 1920 }),
});

const isOpen = useVModel(props);

const emit = defineEmits<{
  (e: 'captured', value: Blob): void;
}>();

const availableCameras = ref<MediaDeviceInfo[] | null>(null);

const selectedCamera = ref<MediaDeviceInfo | null>(null);

const streaming = ref(false);

const error = ref('');

// noinspection JSSuspiciousNameCombination
const resolutionFlipped = computed(() => ({
  // Разрешение предпросмотра почему-то принимает неправильные размеры.
  // Не разобрался, почему так происходит.
  width:  props.resolution.height,
  height: props.resolution.width,
}));

watch(isOpen, function toggled(isVisible): void {
  if (!isVisible) {
    selectedCamera.value = null;
    availableCameras.value = null;
  }
});

watch(selectedCamera, function selectedCameraChanged(newCamera): void {
  if (newCamera) {
    ls.value.recentCamera = newCamera;
    camera.value!.changeCamera(newCamera.deviceId);
  } else {
    camera.value!.stop();
  }
});

function streamingStarted(): void {
  streaming.value = true;
}

function streamingStopped(): void {
  streaming.value = false;
}

onErrorCaptured((e, instance): boolean | void => {
  if (instance !== camera.value) {
    return;
  }

  if (e.message.startsWith('Media devices not available')) {
    availableCameras.value = [];
    return false;
  }

  error.value = e.message;
});

function cameraError(e: Error) {
  if (e instanceof DOMException && e.code === DOMException.NOT_FOUND_ERR) {
    availableCameras.value = [];
    return;
  }

  error.value = e.message;
}

watch(camera, async function gotCameras(cameraRef) {
  if (!cameraRef) {
    return;
  }
  const cameras = await cameraRef.devices(['videoinput'])
    .catch(() => []);

  availableCameras.value = cameras;

  selectedCamera.value = cameras.length === 1
    ? cameras[0]
    : ls.value.recentCamera;
});

function toggleStream(): void {
  if (streaming.value) {
    camera.value!.pause();
  } else {
    camera.value!.resume();
  }

  streaming.value = !streaming.value;
}

async function capture(): Promise<void> {
  const done = progressStarted();

  const blob = (await camera.value!.snapshot(props.resolution, 'image/jpeg'))!;
  const file = new File([blob], generateFilename());
  emit('captured', file);
  isOpen.value = false;

  done();
}

function generateFilename() {
  return props.filenamePrefix + new Date().toISOString() + '.jpg';
}

</script>

<i18n lang="yaml">
ru:
  Photo capture: Сделать фото с камеры
  No cameras available: Нет доступных камер
  Camera: Камера
  Select Camera: Выберите камеру
  Capture: Сделать фото

en:
  Photo capture: Photo capture
  No cameras available: No cameras available
  Camera: Camera
  Select Camera: Select Camera
  Capture: Capture
</i18n>
