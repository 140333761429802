<template>
  <QDialog
    seamless
    position="bottom"
    transition-duration="100"
    :model-value="!!value"
    no-focus
    no-refocus
  >
    <QCard
      class="bg-blue text-white"
    >
      <QCardSection class="row items-center">
        <div>
          <QIcon
            name="mdi-barcode-scan"
            size="sm"
          />
        </div>
        <code class="q-ml-sm">{{ value }}</code>
      </QCardSection>
    </QCard>
  </QDialog>

  <input
    ref="field"
    v-model="value"
    tabindex="-1"
    virtualkeyboardpolicy="manual"
    style="position: fixed; left: -1000px;"
  >
</template>

<script setup lang="ts">

import useSpeaker from '@/composables/useSpeaker';
import { BARCODE_MIN_LENGTH } from '@/constants';
import { useEventListener, useFocus, watchDebounced } from '@vueuse/core';
import { ref, watch } from 'vue';

const props = defineProps<{
  filter?: RegExp | ((key: string) => boolean);
  skipInputIfBarcode?: boolean;
  speakDigits?: boolean;
}>();

const emit = defineEmits<{
  input: [value: string];
  barcode: [value: string];
  backspace: [];
}>();

const field = ref<HTMLInputElement>();

const value = ref('');

const { focused } = useFocus(field);

// Скопировано из onStartTyping для добавления возможности ввода точки.
// https://github.com/vueuse/vueuse/blob/v10.9.0/packages/core/onStartTyping/index.ts

function isFocusedElementEditable() {
  const { activeElement, body } = document;

  if (!activeElement) {
    return false;
  }

  // If not element has focus, we assume it is not editable, too.
  if (activeElement === body) {
    return false;
  }

  // Assume <input> and <textarea> elements are editable.
  switch (activeElement.tagName) {
    case 'INPUT':
    case 'TEXTAREA':
      return true;
  }

  // Check if any other focused element id editable.
  return activeElement.hasAttribute('contenteditable');
}

function isTypedCharValid({
  key,
  keyCode,
  metaKey,
  ctrlKey,
  altKey,
}: KeyboardEvent) {
  if (props.filter instanceof RegExp && !props.filter.test(key)) {
    return false;
  }

  if (typeof props.filter === 'function' && !props.filter(key)) {
    return false;
  }

  if (metaKey || ctrlKey || altKey) {
    return false;
  }

  if (key === '.' || key === ',') {
    return true;
  }

  // 0...9
  if (keyCode >= 48 && keyCode <= 57) {
    return true;
  }

  // A...Z
  if (keyCode >= 65 && keyCode <= 90) {
    return true;
  }

  // a...z
  if (keyCode >= 97 && keyCode <= 122) {
    return true;
  }

  // All other keys.
  return false;
}

useEventListener('keydown', handleKeyDown, { passive: true });

function handleKeyDown(event: KeyboardEvent) {
  if (event.key === 'Backspace') {
    emit('backspace');
  } else if (!isFocusedElementEditable() && isTypedCharValid(event) && !focused.value) {
    focused.value = true;
  }
}

const debouncedValue = ref('');

watchDebounced(value, value => {
  debouncedValue.value = value;
}, { debounce: 200 });

watch(debouncedValue, val => {
  focused.value = false;

  if (!value.value) {
    return;
  }

  emitValue(val);

  // При использовании `useDebounce` иногда возникает ситуация:
  //   1. Вводим "1"
  //   2. Ждем ~200 мс - срабатывает watch на debounced-значение "1",
  //      значение value обнуляется
  //   3. Снова вводим "1"
  //   4. Ждем ~200 мс - watch на debounced-значение "1" НЕ срабатывает,
  //      так как предыдущее значение тоже было "1".
  // То есть, пустое debounced-значение было пропущено.
  // Из-за этого фокус из поля ввода не уходит, компонент как будто ждет продолжения ввода.
  //
  // Поэтому вместо `useDebounce` используем ref и вручную обнуляем его вместе с value.
  debouncedValue.value = '';
  value.value = '';
});

const speaker = useSpeaker();

function emitValue(value: string) {
  if (value.length >= BARCODE_MIN_LENGTH) {
    emit('barcode', value);
    if (props.skipInputIfBarcode) {
      return;
    }
  }

  if (/^\d$/.test(value) && props.speakDigits) {
    speaker.speak(value);
  }

  emit('input', value);
}

</script>
