<template>
  <QChip
    v-if="difference.loss"
    color="red-3"
    text-color="black"
  >
    {{ Math.abs(difference.loss) }}
  </QChip>
  <QChip
    v-if="difference.enter"
    color="yellow-4"
    text-color="black"
  >
    {{ difference.enter }}
  </QChip>
</template>

<script setup lang="ts">

import type { Inventory, Movement } from '@/graphql/types';
import * as R from 'ramda';
import { computed } from 'vue';

export type Difference = {
  enter: null | number;
  loss: null | number;
}

const props = defineProps<{
  inventory: Inventory;
}>();

const difference = computed((): Difference => {
  const totalMovementsDiff = (mv: Movement[]): number => mv
    .reduce((acc, mv) => acc + ((mv.storageFrom ? -mv.amount : mv.amount)
      * mv.storable.productPack.quantityInMinMeasurementUnits), 0);

  const process = R.pipe(
    R.groupBy<Movement>(mv => mv.storable.productPack.product.id),
    R.values,
    R.map(totalMovementsDiff),
    R.partition(n => n > 0),
    R.map(R.sum),
    R.zipObj(['enter', 'loss']),
  );

  return process(props.inventory.movements);
});

</script>
