<template>
  <QBtn
    color="positive"
    icon="mdi-plus"
    :label="t('Create')"
    v-bind="$attrs"
    @click="dialog = true"
  />
  <QDialog
    v-model="dialog"
    :persistent="fetching"
  >
    <QCard style="min-width: 400px;">
      <CardTitle>{{ t('Create Inventory') }}</CardTitle>
      <QSeparator />
      <QCardSection>
        <StoragesSelect v-model="storage" />
      </QCardSection>
      <QSeparator />
      <PrimaryErrorBanner animated />
      <QCardActions align="between">
        <QBtn
          :label="t('Cancel')"
          :disable="fetching"
          @click="dialog = false"
        />
        <QBtn
          :label="t('Create')"
          :disable="!storage"
          :loading="fetching"
          color="positive"
          @click="createInventory"
        />
      </QCardActions>
    </QCard>
  </QDialog>
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import StoragesSelect from '@/components/StoragesSelect.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import type { Cell, Container } from '@/graphql/types';
import { gql, useMutation } from '@urql/vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { clearErrors, fillErrorsFromGraphQLError, PrimaryErrorBanner } = useErrorHandling();

const { t } = useI18n();

const emit = defineEmits<{
  success: [];
}>();

const dialog = ref(false);

const storage = ref<Cell | Container | null>(null);

const { executeMutation, fetching } = useMutation(gql`
  mutation CreateInventory($storageId: ID!) {
    enqueueInventory(storageId: $storageId, entireStorage: false) {
      id
    }
  }
`);

async function createInventory() {
  clearErrors();
  const { error } = await executeMutation({ storageId: storage.value!.id });
  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }
  dialog.value = false;
  emit('success');
}

</script>
