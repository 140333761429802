<template>
  <div class="column no-wrap">
    <FiltersToolbar v-if="withSearch || !noRefreshButton">
      <template #search>
        <SearchInput
          v-if="withSearch"
          v-model="searchString"
          :debounce="debounce"
          :blurred-search="blurredSearch"
          class="col"
        />
        <QSpace v-else />
        <QBtn
          round
          flat
          icon="mdi-refresh"
          :loading="loading"
          @click="reload"
        />
      </template>
    </FiltersToolbar>

    <div
      ref="listWrapperEl"
      class="scroll-y"
    >
      <QList
        :ref="qList => { listEl = unrefElement(qList) }"
        separator
        class="relative-position"
        style="min-height: 150px;"
      >
        <template v-for="item in items">
          <slot
            name="item"
            v-bind="{
              item,
              selected: selectedItems?.includes(item),
              toggle: toggleSelectedItem.bind(null, item),
            }"
          />
        </template>
        <QItem class="justify-center">
          <QSpinnerOval
            v-if="loading && !resultIsStale"
            size="md"
          />
          <QItemSection
            v-else-if="items.length === 0 && noDataLabel"
            class="text-center"
          >
            {{ noDataLabel }}
          </QItemSection>
          <QItemSection
            v-else-if="items.length > 0 && !result.pageInfo.hasNextPage && noMoreDataLabel"
            class="text-center"
          >
            {{ noMoreDataLabel }}
          </QItemSection>
        </QItem>
      </QList>
    </div>
  </div>
</template>

<script setup lang="ts">

import SearchInput from '@/components/BaseTable/SearchInput.vue';
import useReportFilters from '@/composables/useReportFilters';
import type { ReportFilterInput } from '@/graphql/types';
import type { Filter, ReportFilter, ReportListOptions } from '@/types/reports';
import {
  StorageSerializers,
  unrefElement,
  useInfiniteScroll,
  useLocalStorage,
  useScroll,
  watchOnce,
} from '@vueuse/core';
import { findLastIndex, without } from 'ramda';
import { computed, nextTick, onBeforeMount, ref, watch } from 'vue';
import type { Connection } from '@/types';

type PersistentOptions = {
  searchString: string | null;
  filters: Filter[];
  firstBatchSize: number;
  scroll: number;
};

type TItem = Record<string, unknown>;

const props = withDefaults(defineProps<{
  result: Connection<TItem>;
  firstBatchSize?: number;
  batchSize?: number;
  selectedItems?: TItem[];
  withSearch?: boolean;
  debounce?: number;
  availableFilters?: ReportFilter[];
  fixedFilters?: ReportFilterInput[];
  storagePrefix?: string;
  blurredSearch?: boolean;
  loading?: boolean;
  noDataLabel?: string;
  noMoreDataLabel?: string;
  noRefreshButton?: boolean;
  scrollTarget?: HTMLElement;
}>(), {
  debounce: 500,
  firstBatchSize: 20,
  batchSize: 10,
});

const emit = defineEmits<{
  (e: 'update:selected-items', items: TItem[]): void;
  (e: 'refresh', options: ReportListOptions | null): void;
  (e: 'load-more'): void;
  (e: 'single-item-found', item: TItem, searchString: string | null): void;
}>();

// noinspection LocalVariableNamingConventionJS
const {
  filters,
  filtersForInput,
  restoreFilters,
  DefaultMobileToolbar: FiltersToolbar,
} = useReportFilters(props.availableFilters, props.fixedFilters);

const searchString = ref<string | null>('');

const resultIsStale = ref(false);

const batchSize = ref(props.firstBatchSize);

const endCursor = computed(() =>
  resultIsStale.value ? null : props.result.pageInfo.endCursor ?? null);

const items = ref<TItem[]>([]);

const storedOptions = props.storagePrefix ? useLocalStorage<PersistentOptions>(
  props.storagePrefix,
  null,
  { serializer: StorageSerializers.object },
) : null;

// noinspection NestedConditionalExpressionJS
const options = computed((): ReportListOptions => ({
  query:  searchString.value || '',
  filter: filtersForInput.value,
  first:  batchSize.value,
  after:  endCursor.value,
}));

const listWrapperEl = ref<HTMLElement>();
const listEl = ref<HTMLElement>();

const scrollAfterFirstLoad = ref(0);
const { y: scroll } = useScroll(listWrapperEl, { throttle: 50 });

const scrollRefreshTarget = computed(() => props.scrollTarget ?? listWrapperEl.value);

useInfiniteScroll(scrollRefreshTarget, () => {
  if (props.result.pageInfo.hasNextPage) {
    refresh();
  }
}, {
  direction: 'bottom',
});

watch([searchString, filters, scroll], ([searchString, filters, scroll]) => {
  if (!storedOptions || !listWrapperEl.value) {
    return;
  }

  storedOptions.value = {
    searchString,
    filters,
    firstBatchSize: batchSizeForReload(),
    scroll,
  };
});

function batchSizeForReload() {
  // Вычисление количества элементов, которое нужно загрузить,
  // чтобы "воспроизвести" текущее состояние списка.
  //
  // Если просто брать текущее общее количество элементов, то может возникнуть ситуация:
  //   1. Пользователь долго листал, подгрузилось уже 1000 элементов
  //   2. Листает в начало списка, при этом количество элементов не меняется
  //   3. Заходит в список повторно - все 1000 элементов грузятся сразу, хотя они не нужны
  // Поэтому сохраняем количество элементов только до последнего видимого.

  const scrolledCount = findLastIndex<HTMLElement>(
    // Находим индекс последнего видимого элемента
    el => el.offsetTop <=
      listWrapperEl.value!.scrollTop + listWrapperEl.value!.offsetHeight,
    [...listEl.value!.children] as HTMLElement[],
  ) + 1; // Добавляем 1 к индексу, чтобы получить количество

  // "Округляем" количество в большую сторону до ближайшего значения,
  // которое может быть получено при прокрутке,
  // чтобы прокрутка после восстановления не отличалась от прокрутки без сохранения.
  //
  // Пример: firstBatchSize = 20, batchSize = 10.
  // При прокрутке до 33 элемента "округляем" до 40,
  // так как это 20 + 10 + 10 (начальная загрузка 20 + две подгрузки по 10).
  //
  // Если этого не сделать, получим ситуацию:
  //   1. Загружается страница, видим элементы 1-20
  //   2. Прокручиваем два раза, видим элементы 21-30, 31-40
  //   3. Прокручиваем до 33, обновляем страницу, подгружаются 33 элемента.
  //   4. Прокручиваем дальше - видим 34-43, 44-53 и т.д.,
  //      хотя логичнее после обновления подгрузить 40 и продолжать подгружать 41-50, 51-60 и т.д.
  return scrolledCount <= props.firstBatchSize
    ? props.firstBatchSize
    : (Math.ceil((scrolledCount - props.firstBatchSize) / props.batchSize) * props.batchSize
      + props.firstBatchSize);
}

onBeforeMount(refresh);

if (storedOptions?.value) {
  const opts = storedOptions.value;
  searchString.value = opts.searchString;
  filters.value = restoreFilters(opts.filters);
  batchSize.value = opts.firstBatchSize || props.firstBatchSize;
  scrollAfterFirstLoad.value = opts.scroll ?? 0;
}

watch([searchString, filtersForInput], () => {
  resultIsStale.value = true;
  batchSize.value = props.firstBatchSize;
  refresh();
});

watch(() => props.result, result => {
  const newItems = result.edges.map(e => e.node);
  if (resultIsStale.value) {
    items.value = newItems;
    resultIsStale.value = false;
  } else {
    items.value = [...items.value, ...newItems];
  }

  batchSize.value = props.batchSize;
});

watchOnce(() => props.result, () => {
  nextTick(() => {
    listWrapperEl.value!.scroll({ top: scrollAfterFirstLoad.value });
  });
});

function refresh(): void {
  emit('refresh', options.value);
}

function toggleSelectedItem(item: TItem) {
  if (!props.selectedItems) {
    return;
  }
  if (props.selectedItems.includes(item)) {
    emit('update:selected-items', without([item], props.selectedItems));
  } else {
    emit('update:selected-items', [...props.selectedItems, item]);
  }
}

const justSearched = ref(false);

watch(searchString, () => {
  justSearched.value = true;
});

watch(items, items => {
  if (items.length === 1 && justSearched.value && searchString.value) {
    emit('single-item-found', items[0], searchString.value);
  }
  // Перед поиском элементы списка сбрасываются, этот обработчик тоже сработает.
  // Поэтому добавляем дополнительную проверку: не сбрасываем флаг на время поиска.
  if (!(props.loading && items.length === 0)) {
    justSearched.value = false;
  }
});

function reload() {
  batchSize.value = batchSizeForReload();
  resultIsStale.value = true;
  refresh();
}

watch(resultIsStale, (stale, wasStale) => {
  if (wasStale && !stale) {
    emit('update:selected-items', []);
  }
});

defineExpose({
  resetSearch: () => {
    searchString.value = '';
  },
  reload,
});

</script>
