<template>
  <ReportList
    ref="list"
    v-bind="$attrs"
    :result="result"
    :loading="fetching"
    @refresh="refresh"
  >
    <template
      v-for="slot in Object.keys($slots)"
      #[slot]="bind"
    >
      <slot
        :name="slot"
        v-bind="bind"
      />
    </template>
  </ReportList>
</template>

<script setup lang="ts">

import ReportList from '@/components/Mobile/ReportList.vue';
import type { ReportListOptions } from '@/types/reports';
import { useQuery } from '@urql/vue';
import type { DocumentNode } from 'graphql';
import { equals } from 'ramda';
import { computed, ref, watch } from 'vue';
import type { Connection } from '@/types';

type TItem = Record<string, unknown>;

const props = defineProps<{
  graphqlQuery: DocumentNode;
}>();

const emit = defineEmits<{
  (e: 'data-received', data: object[]): void;
}>();

const reportOptions = ref<ReportListOptions>(null!);

const { data, fetching, executeQuery } = useQuery<{ result: Connection<TItem> }>({
  query:     props.graphqlQuery,
  variables: reportOptions,
  pause:     computed(() => !reportOptions.value),
});

watch(data, data => {
  if (data?.result) {
    result.value = data.result;
    emit('data-received', data.result.edges.map(e => e.node));
  }
});

const result = ref<Connection<TItem>>({
  pageInfo: {
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
    hasNextPage: false,
  },
  edges: [],
});

function refresh(options?: ReportListOptions): void {
  if (!options || equals(options, reportOptions.value)) {
    // При ручном обновлении опции не меняются,
    // поэтому запускам обновление вручную.
    executeQuery();
  } else {
    // В противном случае изменение будет обнаружено
    // и обновление произойдет автоматически.
    reportOptions.value = options;
  }
}

const list = ref();

defineExpose({
  resetSearch: () => list.value.resetSearch(),
  reload: () => list.value.reload(),
});

</script>
