<template>
  <QPage
    class="column no-wrap scroll-y"
    v-bind="$attrs"
    :style-fn="styleFn"
  >
    <slot />
  </QPage>
</template>

<script setup lang="ts">

function styleFn(offset: number, height: number) {
  // Основано на QPage.style
  // noinspection NestedConditionalExpressionJS
  return {
    height: height === 0
      ? (offset === 0 ? '100vh' : `calc(100vh - ${offset}px)`)
      : ((height - offset) + 'px'),
  };
}

</script>
