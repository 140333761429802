<template>
  <QItem>
    <QItemSection avatar>
      <QBtn
        exact
        flat
        round
        icon="mdi-pencil"
        :title="t('Edit')"
        :to="{ name: ROUTES.ACCOUNTING_MODELS_EDIT, params: { id: String(accountingModel.id) } }"
      />
    </QItemSection>
    <QItemSection>
      {{ accountingModel.name }}
    </QItemSection>
    <QItemSection side>
      <QBtn
        flat
        round
        icon="mdi-delete"
        color="red"
        :title="t('Delete')"
        :loading="hasProgress()"
        @click="deleteAccountingModel()"
      />
    </QItemSection>
  </QItem>
</template>

<script setup lang="ts">

import useProgressHandling from '@/composables/useProgressHandling';
import type { AccountingModel, Mutation, MutationDeleteAccountingModelArgs } from '@/graphql/types';
import { useI18n } from 'vue-i18n';
import { gql, useClientHandle } from '@urql/vue';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const { progressStarted, hasProgress } = useProgressHandling();

const { client: urql } = useClientHandle();

const props = defineProps<{
  accountingModel: AccountingModel;
}>();

const emit = defineEmits<{
  (e: 'delete'): void;
  (e: 'error', error: Error): void;
}>();

async function deleteAccountingModel(): Promise<void> {
  const done = progressStarted();

  const { error } = await urql.mutation<Pick<Mutation, 'deleteAccountingModel'>>(
    gql`mutation DeleteAccountingModel($id: ID!) { deleteAccountingModel(id: $id) }`,
    { id: props.accountingModel.id } as MutationDeleteAccountingModelArgs,
  );

  if (error) {
    emit('error', error);
  } else {
    emit('delete');
  }

  done();
}

</script>
