<template>
  <QInput
    v-model="range.from"
    :error="v.from.$error"
    :error-message="v.from.$errors[0]?.$message as (string | undefined)"
    dense
    :mask="inputMask"
    :label="t('Start Date')"
    no-error-icon
  />
  <QInput
    v-model="range.to"
    :error="v.to.$error"
    :error-message="v.to.$errors[0]?.$message as (string | undefined)"
    dense
    :mask="inputMask"
    :label="t('End Date')"
    no-error-icon
  />
  <QBtn
    flat
    round
    icon="mdi-calendar"
  >
    <QPopupProxy v-model="pickerOpen">
      <QDate
        v-model="rangeForPicker"
        range
        today-btn
        no-unset
        :mask="format"
      />
    </QPopupProxy>
  </QBtn>
</template>

<script setup lang="ts">

import { dateValidator, required } from '@/setup/validation';
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import dayjs from 'dayjs';
import { computed, onBeforeMount, reactive, ref, type UnwrapRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

type DatesRange = [from: string, to: string];

const { t } = useI18n();

const props = defineProps<{
  initialValue: DatesRange | null;
  format: string;
  inputMask: string;
}>();

const emit = defineEmits<{
  'pick': [value: DatesRange | null];
}>();

const range = reactive({
  from: null as string | null,
  to: null as string | null,
});

onBeforeMount(() => {
  if (props.initialValue) {
    range.from = props.initialValue[0];
    range.to = props.initialValue[1];
  } else {
    range.from = dayjs().format(props.format);
    range.to = range.from;
  }
});

const v = useVuelidate({
  from: {
    required,
    date: dateValidator(props.format),
    lessThenTo: helpers.withMessage(
      t('Exceeds End Date'),
      (from: string, { to }: UnwrapRef<typeof range>) =>
        !dayjs(from, props.format).isAfter(dayjs(to, props.format)),
    ),
  },
  to: {
    required,
    date: dateValidator(props.format),
    graterThenFrom: helpers.withMessage(
      t('Behind Start Date'),
      (to: string, { from }: UnwrapRef<typeof range>) =>
        !dayjs(to, props.format).isBefore(dayjs(from, props.format)),
    ),
  },
}, range);

watch(range, async ({ from, to }) => {
  if (await v.value.$validate()) {
    emit('pick', [from!, to!]);
  } else {
    emit('pick', null);
  }
});

const pickerOpen = ref(false);

// Если в качестве диапазона выбран один день, QDate возвращает его в виде строки даты.
// И в качестве входного значения для таких случаев тоже требуется строка.
const rangeForPicker = computed<{ from: string; to: string } | string | null>({
  get() {
    const { from, to } = range;
    return from && to ? (from === to ? from : { from, to }) : null;
  },
  set(newRange) {
    pickerOpen.value = false;
    if (!newRange) {
      range.from = null;
      range.to = null;
    } else if (typeof newRange === 'string') {
      range.from = newRange;
      range.to = newRange;
    } else {
      range.from = newRange.from;
      range.to = newRange.to;
    }
  },
});

</script>

<i18n lang="yaml">
ru:
  Start Date: Дата начала
  End Date: Дата окончания
  Exceeds End Date: Превышает дату окончания
  Behind Start Date: Меньше даты начала
en:
  Start Date: Start Date
  End Date: End Date
  Exceeds End Date: Exceeds End Date
  Behind Start Date: Behind Start Date
</i18n>
