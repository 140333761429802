<template>
  <!--
  width: 100% нужен временно, пока слайд имеет класс inline-block
  и может использоваться как в ScrollCarousel, так и в SwiperCarousel.
  После полного перевода на SwiperCarousel
  убрать width: 100% здесь и inline-block у ProductSlide
  -->
  <ProductSlide
    :product-pack="item.storable.productPack"
    :actual-amount="displayAmount"
    :total-amount="totalProductAmount"
    :show-total-amount="totalProductAmount !== amountInMinUnits"
    :expected-amount="expectedAmount"
    :amount-badge-color="amountBadgeColor"
    style="width: 100%"
    @amount-click="handleAmountClick"
  >
    <template #top="{ product }">
      <div
        v-clamp="batch.product.accountingModel.byBatch ? 2 : 3"
        class="text-h5 cursor-pointer"
        @click="editingProduct = batch.product"
      >
        {{ product.name }}
      </div>
      <ProductSlideBatch
        v-if="batch.product.accountingModel.byBatch"
        :batch-name="formatBatchName(batch, batch.product.accountingModel, t)"
        :accounting-model="batch.product.accountingModel"
        class="cursor-pointer"
        @click="editingBatchData = true"
      />

      <EditStorageUnitBatch
        v-if="batch.product.accountingModel?.batchDisplayFormat"
        v-model:is-open="editingBatchData"
        :storage-unit="item.storable"
        @saved="emit('storage-unit-saved', $event)"
      />

      <ProductEditDialog
        v-if="editingProduct"
        :id="editingProduct.id"
        @cancel="editingProduct = null"
      />
    </template>
    <template
      v-if="allProductMovements.length > 1"
      #under-amount
    >
      <CarouselIndicators
        :count="allProductMovements.length"
        :active="allProductMovements.indexOf(item)"
        :limit="5"
        :indicator-size="7"
      />
    </template>
  </ProductSlide>
</template>

<script setup lang="ts">

import CarouselIndicators from '@/components/Mobile/CarouselIndicators.vue';
import ProductSlide from '@/components/Mobile/ProductSlide.vue';
import ProductSlideBatch from '@/components/Mobile/ProductSlideBatch.vue';
import vClamp from '@/directives/clamp';
import type { Movement, Product, StorageUnit } from '@/graphql/types';
import formatBatchName from '@/helpers/formatBatchName';
import useProductArrivalStore from '@/stores/productArrival';
import EditStorageUnitBatch from '@/views/Mobile/Arrival/EditStorageUnitBatch.vue';
import ProductEditDialog from '@/views/Mobile/ProductEditDialog.vue';
import { useSwiper } from 'swiper/vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  item: Movement;
  displayAmount: number | string;
  allItems: Movement[];
}>();

const emit = defineEmits<{
  (e: 'storage-unit-saved', storageUnit: StorageUnit): void;
}>();

const amountBadgeColor = computed(() => {
  if (!store.supply) {
    return;
  }

  if (totalProductAmount.value < expectedAmount.value) {
    return 'yellow-3';
  }
  if (totalProductAmount.value > expectedAmount.value) {
    return 'red-3';
  }
  return 'green-3';
});

const store = useProductArrivalStore();

const expectedAmount = computed(() => store.getExpectedAmount(props.item.storable.productPack.product)
  - store.getOtherArrivalsAmount(props.item.storable.productPack.product));

const editingBatchData = ref(false);

const batch = computed(() => props.item.storable.batch);

const allProductMovements = computed(() => props.allItems
  .filter(s => s.storable.productPack.product.id === props.item.storable.productPack.product.id));

const amountInMinUnits = computed(() => Number(
  (props.item.amount * props.item.storable.productPack.quantityInMinMeasurementUnits)
    .toPrecision(3)
));

const totalProductAmount = computed(() => Number(allProductMovements.value
  .reduce((a, m) => a + m.amount * m.storable.productPack.quantityInMinMeasurementUnits, 0)
  .toPrecision(3)));

const sw = useSwiper();

function handleAmountClick() {
  if (allProductMovements.value.length <= 1) {
    return;
  }
  const index = (allProductMovements.value.indexOf(props.item) + 1) % allProductMovements.value.length;

  const newSlide = props.allItems.indexOf(allProductMovements.value[index]);

  sw.value.slideTo(newSlide);
}

const editingProduct = ref<Product | null>(null);

</script>
