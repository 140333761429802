<template>
  <QDialog
    :model-value="isOpen"
    position="bottom"
    @update:model-value="emit('update:isOpen', $event)"
  >
    <QCard>
      <BaseAlert
        v-if="error"
        type="error"
      >
        {{ error }}
      </BaseAlert>
      <QCardSection>
        <div class="row q-col-gutter-sm">
          <div class="col-12">
            <NonEditableField
              v-if="accountingModel.batchNumberRequired"
              :label="t('Batch Number')"
              :error="v.number.$error"
              :error-message="v.number.$errors[0]?.$message"
            >
              {{ v.number.$model }}
            </NonEditableField>
          </div>
          <div class="col-6">
            <DatePicker
              v-if="accountingModel.batchProductionDateRequired"
              v-model="v.productionDate.$model"
              :label="t('Production Date')"
              :error="v.productionDate.$error"
              :error-message="v.productionDate.$errors[0]?.$message"
            />
          </div>
          <div class="col-6">
            <DatePicker
              v-if="accountingModel.batchExpirationDateRequired"
              v-model="v.expirationDate.$model"
              :label="t('Expiration Date')"
              :error="v.expirationDate.$error"
              :error-message="v.expirationDate.$errors[0]?.$message"
            />
          </div>
        </div>
      </QCardSection>
      <ButtonsRow v-slot="{ buttonProps }">
        <QBtn
          v-bind="buttonProps"
          icon="mdi-close-circle-outline"
          @click="emit('update:isOpen', false)"
        >
          {{ t('Close') }}
        </QBtn>
        <QBtn
          v-bind="buttonProps"
          icon="mdi-content-save"
          :loading="saving"
          @click="handleSave"
        >
          {{ t('Save') }}
        </QBtn>
      </ButtonsRow>

      <div>
        <VirtualKeyboard local />
        <BlurredInput :filter="/^\d$/" />
      </div>
    </QCard>
  </QDialog>
</template>
<script setup lang="ts">
import BaseAlert from '@/components/BaseAlert.vue';
import DatePicker from '@/components/DatePicker.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import NonEditableField from '@/components/NonEditableField.vue';
import useBatchDataForm from '@/composables/useBatchDataForm';
import useOmniInput from '@/composables/useOmniInput';
import type { InventoryBatchData, Product } from '@/graphql/types';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
    batch: InventoryBatchData;
    product: Product;
    isOpen: boolean;
    error?: string;
    saving?: boolean;
}>();

const emit = defineEmits<{
    'save': [batch: InventoryBatchData];
    'update:isOpen': [isOpen: boolean];
}>();

const accountingModel = computed(() => props.product.accountingModel);

const input = useOmniInput({
  skip:     computed(() => !props.isOpen || !accountingModel.value.batchNumberRequired),
  priority: 100,
});
const { VirtualKeyboard, BlurredInput } = input;

const { vuelidate: v, data: editableBatch } = useBatchDataForm(
  computed(() => props.batch),
  computed(() => props.product),
  accountingModel,
);

watch(input.value, value => {
  v.value.number.$model = value;
});

watch(() => props.batch, batch => {
  input.reset(batch.number ?? '');
}, { immediate: true });

function handleSave() {
  emit('save', editableBatch.value);
}

</script>

<i18n lang="yaml">
ru:
  Batch Number: Номер партии
  Production Date: Дата пр-ва
  Expiration Date: Дата окон. срока

en:
  Batch Number: Batch Number
  Production Date: Production Date
  Expiration Date: Expiration Date
</i18n>
