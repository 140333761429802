<template>
  <div class="inline-block">
    <!--
    Фиксируем высоту, чтобы верстка не прыгала при переключении между товарами с разными данными
    (разная длина названия, отсутствие/наличие фото).
    -->
    <div
      class="q-mx-sm"
      style="height: 96px;"
    >
      <slot
        name="top"
        v-bind="{ product }"
      >
        <div
          v-clamp="3"
          class="text-h5"
          :class="{ 'cursor-pointer': titleClickable }"
          @click="emit('title-click')"
        >
          {{ product.name }}
        </div>
      </slot>
    </div>
    <QItem
      dense
      style="height: 52px;"
    >
      <QItemSection
        v-if="product.photos.length > 0"
        avatar
      >
        <QAvatar @click.stop="photosDialog = true">
          <ProductPhoto :product="product" />
        </QAvatar>
        <ProductPhotosDialog
          v-model:is-open="photosDialog"
          :product="product"
        />
      </QItemSection>
      <QItemSection top>
        <QItemLabel caption>
          {{ t('SKU') }}
        </QItemLabel>
        <QItemLabel>{{ product.sku }}</QItemLabel>
      </QItemSection>
      <QItemSection side>
        <div class="row no-wrap items-center">
          <ConfirmsAction
            v-if="deletable && Number(actualAmount) > 0"
            should-prompt
            :confirm-text="t('Yes')"
            :cancel-text="t('No')"
            @confirmed="emit('delete')"
          >
            <template #activator="{ prompt }">
              <QBtn
                flat
                round
                dense
                icon="mdi-delete"
                color="red"
                @click.stop="prompt"
              />
            </template>
            <template #title>
              {{ t('Remove Item?') }}
            </template>
          </ConfirmsAction>
          <div class="row no-wrap items-center">
            <slot name="before-amount" />
            <QChip
              v-if="showTotalAmount"
              size="lg"
              dense
              color="transparent"
              class="q-mr-none"
              clickable
              @click.stop="emit('amount-click')"
            >
              {{ amountInBaseUnits }}
            </QChip>
            <div class="column no-wrap items-center">
              <div
                class="text-caption"
                style="line-height: 0.6"
              >
                <span v-if="productPack?.smallerProductPack">
                  {{ actualAmount }} {{ productPack.measurementUnit.shortName }}
                </span>
                <span v-else>&nbsp;</span>
              </div>
              <QChip
                dense
                size="lg"
                :color="amountBadgeColor"
                text-color="black"
                square
                class="q-mx-none"
                clickable
                @click.stop="emit('amount-click')"
              >
                {{ showTotalAmount ? totalAmount : amountInBaseUnits }}
                /
                <span class="q-ml-xs">
                  <QSkeleton
                    v-if="expectedAmountLoading"
                    width="1ch"
                    height="1em"
                  />
                  <template v-else>
                    {{ expectedAmount }}
                  </template>
                </span>
              </QChip>
              <slot name="under-amount">
                <div style="height: 7px;" />
              </slot>
            </div>
          </div>
        </div>
      </QItemSection>
    </QItem>
    <slot name="bottom" />
  </div>
</template>

<script setup lang="ts">
import ConfirmsAction from '@/components/ConfirmsAction.vue';
import ProductPhoto from '@/components/ProductPhoto.vue';
import ProductPhotosDialog from '@/components/ProductPhotosDialog.vue';
import vClamp from '@/directives/clamp';
import type { Product, ProductPack } from '@/graphql/types';
import { useEventBus } from '@vueuse/core';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = withDefaults(defineProps<{
  productPack: ProductPack;
  actualAmount: number | string;
  totalAmount?: number | string;
  showTotalAmount?: boolean;
  expectedAmount: number;
  expectedAmountLoading?: boolean;
  amountBadgeColor?: string;
  titleClickable?: boolean;
  deletable?: boolean;
}>(), {
  deletable: true,
});

const emit = defineEmits<{
  delete: [];
  'amount-click': [];
  'title-click': [];
}>();

defineSlots<{
  top(props: { product: Product }): unknown;
  'above-amount'(): unknown;
  'before-amount'(): unknown;
  'under-amount'(): unknown;
  bottom(): unknown;
}>();

const product = computed(() => props.productPack.product);

const photosDialog = ref(false);

const { on: onCloseDialogs } = useEventBus('close-dialogs');

onCloseDialogs(() => {
  photosDialog.value = false;
});

const amountInBaseUnits = computed(() => {
  const actualAmount = Number(props.actualAmount);
  if (Number.isNaN(actualAmount)) {
    return props.actualAmount;
  }
  // С помощью toPrecision избавляемся от проблем с точностью
  // (когда выводится 19.200000000000003 вместо 19.2).
  // Повторно преобразуем в число, чтобы целые числа выводились как 19, а не 19.0.
  return Number(((props.productPack?.quantityInMinMeasurementUnits ?? 1) * actualAmount).toPrecision(3));
});

</script>

<i18n lang="yaml">
ru:
  Remove Item?: Удалить элемент?

en:
  Remove Item?: Remove Item?
</i18n>
