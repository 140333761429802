<template>
  <QBtn
    icon="mdi-content-copy"
    :title="t('Copy to Clipboard')"
    v-bind="$attrs"
    @click="copy"
  />
</template>

<script setup lang="ts">

import copyToClipboard from 'copy-to-clipboard';
import { useQuasar } from 'quasar';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const q = useQuasar();

const props = defineProps<{
  text: string;
}>();

function copy() {
  copyToClipboard(props.text);
  q.notify({
    message: t('Copied'),
    type:    'positive',
  });
}

</script>

<i18n lang="yaml">
ru:
  Copy to Clipboard: Скопировать в буфер обмена
  Copied: Скопировано
en:
  Copy to Clipboard: Copy to Clipboard
  Copied: Copied
</i18n>
