<template>
  <slot :dimensions="editableDimensions" />
</template>

<script setup lang="ts">

import type { Dimensions, MakeMaybe } from '@/graphql/types';
import { useVModel } from '@vueuse/core';
import { reactive, watch } from 'vue';

type NullableDimensions = MakeMaybe<Dimensions, keyof Dimensions>;

const props = defineProps<{
  modelValue: NullableDimensions | null;
}>();

const model = useVModel(props);

const editableDimensions = reactive<NullableDimensions>(model.value ?? {
  length: null,
  width: null,
  height: null,
});

watch(editableDimensions, newValue => {
  model.value = dimensionsEmpty(newValue) ? null : { ...newValue };
});

function dimensionsEmpty(dimensions: NullableDimensions): boolean {
  // eslint-disable-next-line unicorn/explicit-length-check
  return !dimensions.length && !dimensions.width && !dimensions.height;
}

</script>
