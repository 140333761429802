<template>
  <ConfirmsAction
    should-prompt
    :confirm-text="t('Withdraw')"
    @confirmed="createLoss"
  >
    <template #title>
      {{ t('Withdraw selected stocks?') }}
    </template>
    <template #activator="{ prompt }">
      <QBtn
        :label="t('Withdraw')"
        color="negative"
        icon="mdi-trash-can"
        :loading="fetching"
        @click="prompt"
      />
    </template>
  </ConfirmsAction>
</template>

<script setup lang="ts">

import ConfirmsAction from '@/components/ConfirmsAction.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import type { MutationCreateLossArgs, StockInput } from '@/graphql/types';
import { gql, useMutation } from '@urql/vue';
import { useQuasar } from 'quasar';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const q = useQuasar();

const { getPrimaryErrorFromGraphQLError } = useErrorHandling();

const props = defineProps<{
  stocks: StockInput[];
}>();

const emit = defineEmits<{
  done: [];
}>();

const {
  executeMutation,
  fetching,
} = useMutation<unknown, MutationCreateLossArgs>(
  gql`
    mutation CreateLoss($stocks: [StockInput!]!) {
      createLoss(stocks: $stocks) { id }
    }
  `
);

async function createLoss() {
  const { error } = await executeMutation({ stocks: props.stocks });
  if (error) {
    q.notify({
      type: 'negative',
      message: getPrimaryErrorFromGraphQLError(error),
      position: 'top',
    });
  } else {
    emit('done');
  }
}

</script>

<i18n lang="yaml">
ru:
  Withdraw selected stocks?: Списать выбранные остатки?
  Withdraw: Списать

en:
  Withdraw selected stocks?: Withdraw selected stocks?
  Withdraw: Withdraw
</i18n>
