<template>
  <QCard>
    <CardTitle>
      {{ t('Two Factor Authentication') }}
      <QBadge
        rounded
        :label="t(twoFactorEnabled ? 'Enabled' : 'Disabled')"
        :color="twoFactorEnabled ? 'green' : 'red'"
      />
      <template #subtitle>
        {{ t('Add additional security') }}
      </template>
    </CardTitle>

    <QSeparator />

    <QCardSection>
      {{ t('When 2FA is enabled') }}
    </QCardSection>

    <QCardSection v-if="qrCodeUrl">
      <p>
        {{ t('2FA is now enabled') }}
      </p>

      <QImg :src="qrCode" />
    </QCardSection>

    <template v-if="recoveryCodes.length > 0">
      <QCardSection>
        {{ t('Store these codes securely') }}
      </QCardSection>

      <QCardSection>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <pre v-html="recoveryCodes.join('\n')" />
      </QCardSection>
    </template>

    <QSeparator />

    <QCardActions>
      <template v-if="twoFactorEnabled">
        <ConfirmsPassword
          v-if="recoveryCodes.length > 0"
          @confirmed="regenerateRecoveryCodes()"
        >
          <template #default="{ startConfirming }">
            <QBtn
              type="submit"
              :loading="hasProgress('regenerating')"
              icon="mdi-refresh"
              @click="progressStarted('regenerating'); startConfirming()"
            >
              {{ t('Regenerate Recovery Codes') }}
            </QBtn>
          </template>
        </ConfirmsPassword>
        <ConfirmsPassword
          v-if="recoveryCodes.length === 0"
          @confirmed="showRecoveryCodes()"
        >
          <template #default="{ startConfirming }">
            <QBtn
              type="submit"
              :loading="hasProgress('getting-codes')"
              @click="progressStarted('getting-codes'); startConfirming()"
            >
              {{ t('Show Recovery Codes') }}
            </QBtn>
          </template>
        </ConfirmsPassword>
        <ConfirmsPassword @confirmed="disableTwoFactorAuthentication()">
          <template #default="{ startConfirming }">
            <QBtn
              type="submit"
              color="red"
              :loading="hasProgress('disabling')"
              @click="progressStarted('disabling'); startConfirming()"
            >
              {{ t('Disable') }}
            </QBtn>
          </template>
        </ConfirmsPassword>
      </template>

      <ConfirmsPassword
        v-else
        @confirmed="enableTwoFactorAuthentication()"
        @canceled="progressFinished('enabling')"
      >
        <template #default="{ startConfirming }">
          <QBtn
            type="submit"
            color="primary"
            :loading="hasProgress('enabling')"
            @click="progressStarted('enabling'); startConfirming()"
          >
            {{ t('Enable') }}
          </QBtn>
        </template>
      </ConfirmsPassword>
    </QCardActions>
  </QCard>
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import ConfirmsPassword from '@/components/ConfirmsPassword.vue';
import useBackend from '@/composables/useBackend';
import useProgressHandling from '@/composables/useProgressHandling';
import useStore from '@/stores/root';
import { useQRCode } from '@vueuse/integrations/useQRCode';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { progressStarted, progressFinished, hasProgress } = useProgressHandling<
  | 'enabling'
  | 'regenerating'
  | 'getting-codes'
  | 'disabling'
>();

const backend = useBackend();

const store = useStore();

const user = computed(() => store.user!);

const qrCodeUrl = ref<string | null>(null);

const qrCode = useQRCode(qrCodeUrl as Ref<string>);

const recoveryCodes = ref<string[]>([]);

async function enableTwoFactorAuthentication(): Promise<void> {
  const finished = progressStarted('enabling');

  await backend.enableTwoFactorAuth();

  const newUrl = await backend.getTwoFactorQrCodeUrl();
  const newCodes = await backend.getTwoFactorRecoveryCodes();

  await store.updateUser();

  qrCodeUrl.value = newUrl;
  recoveryCodes.value = newCodes;

  finished();
}

async function showRecoveryCodes(): Promise<void> {
  const finished = progressStarted('getting-codes');

  recoveryCodes.value = await backend.getTwoFactorRecoveryCodes();

  finished();
}

async function regenerateRecoveryCodes(): Promise<void> {
  const finished = progressStarted('regenerating');

  await backend.regenerateRecoveryCodes();

  recoveryCodes.value = await backend.getTwoFactorRecoveryCodes();

  finished();
}

async function disableTwoFactorAuthentication(): Promise<void> {
  const finished = progressStarted('disabling');

  await backend.disableTwoFactorAuth();
  await store.updateUser();

  qrCodeUrl.value = null;
  recoveryCodes.value = [];

  finished();
}

const twoFactorEnabled = computed((): boolean => {
  return user.value.twoFactorEnabled;
});

</script>

<i18n lang="yaml">
en:
  Two Factor Authentication: Two Factor Authentication
  Enabled: Enabled
  Disabled: Disabled
  Add additional security: Add additional security to your account using two factor authentication
  When 2FA is enabled: >
    When two factor authentication is enabled,
    you will be prompted for a secure, random token during authentication.
    You may retrieve this token from your phone's Google Authenticator application
  Regenerate Recovery Codes: Regenerate Recovery Codes
  Show Recovery Codes: Show Recovery Codes
  Enable: Enable
  Disable: Disable
  2FA is now enabled: >
    Two factor authentication is now enabled.
    Scan the following QR code using your phone's authenticator application.
  Store these codes securely: >
    Store these recovery codes in a secure password manager.
    They can be used to recover access to your account
    if your two factor authentication device is lost
ru:
  Two Factor Authentication: Двухфакторная аутентификация
  Enabled: Включена
  Disabled: Отключена
  Add additional security: >
    Усильте безопасность вашего аккаунта с помощью двухфакторной аутентификации
  When 2FA is enabled: >
    Когда включена двухфакторная аутентификация,
    при входе у вас будет запрошен код безопасности.
    Вы можете получить его с помощью приложения Google Authenticator
  Regenerate Recovery Codes: Создать новые резервные коды
  Show Recovery Codes: Показать резервные коды
  Enable: Включить
  Disable: Отключить
  2FA is now enabled: >
    Двухфакторная аутентификация включена.
    Отсканируйте QR-код ниже с помощью приложения для аутентификации
  Store these codes securely: >
    Сохраните эти резервные коды в надежном месте.
    Они могут быть использованы для восстановления доступа к аккаунту
    в случае потери доступа к устройству для аутентификации
</i18n>
