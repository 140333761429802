<template>
  <TransferTaskDialog
    v-if="transferTask"
    :transfer="transferTask"
    :model-value="!!transferTask"
    @update:model-value="emit('close')"
  >
    <template #error>
      <PrimaryErrorBanner />
    </template>
    <template #product-count>
      {{ transferredAmount }} / {{ plannedAmount }}
    </template>
    <template #buttons="{ buttonProps }">
      <QBtn
        v-if="unfinishedTransfer"
        v-bind="buttonProps"
        icon="mdi-play"
        @click="continueUnfinishedTransfer"
      >
        {{ t('Continue Transfer') }}
      </QBtn>
      <QBtn
        v-else
        v-bind="buttonProps"
        icon="mdi-play"
        :loading="fetchingNewTransfer || fetchingTransfers"
        :disable="!canCreateTransfer"
        @click="createTransferByTask(transferTask!)"
      >
        {{ t('Create Transfer') }}
      </QBtn>
    </template>
  </TransferTaskDialog>
</template>

<script setup lang="ts">

import type {
  MutationCreateTransferByTaskArgs,
  QueryTransfersForTaskArgs,
  Transfer,
  TransferTask,
} from '@/graphql/types';
import { TransferStateEnum } from '@/graphql/types';
import TransferTaskDialog from '@/views/Mobile/Transfer/TransferTaskDialog.vue';
import { gql, useMutation, useQuery } from '@urql/vue';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import * as R from 'ramda';
import ROUTES from '@/router/routeNames';
import useErrorHandling from '@/composables/useErrorHandling';
import { isNil } from 'ramda';

const { t } = useI18n();

const props = defineProps<{
  transferTask: TransferTask|null;
}>();

const router = useRouter();
const { clearErrors, fillErrorsFromGraphQLError, PrimaryErrorBanner } = useErrorHandling();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const transfersForTask = ref<Transfer[]>([]);

const { data, fetching: fetchingTransfers } = useQuery<{ transfers: Transfer[] }, QueryTransfersForTaskArgs>({
  query: gql`
    query GetTransferForMobileDetails($transferTaskId: ID!) {
      transfers: transfersForTask(transferTaskId: $transferTaskId) {
        id
        state
      }
    }
  `,
  variables: computed(() => ({ transferTaskId: props.transferTask?.id })),
  pause: computed(() => isNil(props.transferTask))
});

watch( data , data => {
  clearErrors();
  transfersForTask.value = data!.transfers;
});

const { executeMutation: createTransferMutation, fetching: fetchingNewTransfer } = useMutation<{ transfer: Transfer }, MutationCreateTransferByTaskArgs>(gql`
  mutation CreateTransferByTask($transferTaskId: ID!) {
    transfer: createTransferByTask(transferTaskId: $transferTaskId) {
      id
    }
  }
`
);

async function createTransferByTask(task: TransferTask) {
  clearErrors();
  const { error, data } = await createTransferMutation({ transferTaskId: task.id });

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  router.push({ name: ROUTES.TRANSFER_PROCESS, params: { transferId: data!.transfer.id } });
}

async function continueUnfinishedTransfer() {
  router.push({ name: ROUTES.TRANSFER_PROCESS, params: { transferId: unfinishedTransfer.value.id } });
}

const unfinishedTransfer = computed(() => transfersForTask.value.find( t => t.state !== TransferStateEnum.COMPLETED));

const canCreateTransfer = computed(() => props.transferTask?.state !== TransferStateEnum.COMPLETED
    && props.transferTask?.items.some((i) => i.plannedAmount > i.amountInTransfers));

const transferredAmount = computed(() => R.sum(props.transferTask?.items.map(
  i => i.productPack.quantityInMinMeasurementUnits * i.transferredAmount,
) ?? []));

const plannedAmount = computed(() => R.sum(props.transferTask?.items.map(
  i => i.productPack.quantityInMinMeasurementUnits * i.plannedAmount,
) ?? []));

</script>

<i18n lang="yaml">
ru:
  Continue Transfer: Продолжить перемещение
  Create Transfer: Создать перемещение
  No Tasks: Задания отсутствуют

en:
  Continue Transfer: Continue Transfer
  Create Transfer: Create Transfer
  No Tasks: No Tasks

</i18n>
