ru:
  "{n} orders": >
    {n} заказов
    | {n} заказ
    | {n} заказа
    | {n} заказов
  "{n} products": >
    {n} товаров
    | {n} товар
    | {n} товара
    | {n} товаров
en:
  "{n} orders": >
    {n} order
    | {n} orders
  "{n} products": >
    {n} product
    | {n} products
