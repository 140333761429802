<template>
  <QPage padding>
    <div class="row q-col-gutter-lg">
      <div class="col-12 col-md-6 offset-md-3">
        <UpdateProfileInformationForm />
      </div>

      <div class="col-12 col-md-6 offset-md-3">
        <UpdatePasswordForm />
      </div>

      <div
        v-if="user.email"
        class="col-12 col-md-6 offset-md-3"
      >
        <TwoFactorAuthenticationForm />
      </div>

      <div class="col-12 col-md-6 offset-md-3">
        <QCard>
          <CardTitle>{{ t('App Language') }}</CardTitle>
          <QCardSection>
            <LocaleSelector />
          </QCardSection>
        </QCard>
      </div>
      <div class="col-12 col-md-6 offset-md-3">
        <SpeechRateSettings />
      </div>
      <div class="col-12 col-md-6 offset-md-3">
        <KeyboardSettings />
      </div>
      <div class="col-12 col-md-6 offset-md-3">
        <QCard>
          <CardTitle>{{ t('App Theme') }}</CardTitle>

          <QCardSection>
            <QOptionGroup
              v-model="darkMode"
              :options="darkModeOptions"
            />
          </QCardSection>
        </QCard>
      </div>
      <div class="col-12 col-md-6 offset-md-3">
        <QCard>
          <CardTitle>{{ t('Debug') }}</CardTitle>

          <QCardSection>
            <QCheckbox
              :model-value="!!cookies.get('xhprof')"
              :label="t('Enable Profiling')"
              @update:model-value="$event ? cookies.set('xhprof', 1, { path: '/' }) : cookies.remove('xhprof', { path: '/' })"
            />
          </QCardSection>
        </QCard>
      </div>
    </div>
  </QPage>
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import LocaleSelector from '@/views/LocaleSelector.vue';
import KeyboardSettings from '@/views/Profile/KeyboardSettings.vue';
import SpeechRateSettings from '@/views/Profile/SpeechRateSettings.vue';
import { useStorage } from '@vueuse/core';
import type { QOptionGroupProps } from 'quasar';
import { useQuasar } from 'quasar';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import TwoFactorAuthenticationForm from './TwoFactorAuthenticationForm.vue';
import UpdatePasswordForm from './UpdatePasswordForm.vue';
import UpdateProfileInformationForm from './UpdateProfileInformationForm.vue';
import useStore from '@/stores/root';
import { storeToRefs } from 'pinia';
import useGlobalCookies from '@/composables/useGlobalCookies';

const { t } = useI18n();

const store = useStore();

const { user } = storeToRefs(store);

const q = useQuasar();

const ls = useStorage('app', {
  darkMode: false as typeof q.dark.mode,
});

useBreadcrumbs(t('Profile'));

const darkMode = ref(q.dark.mode);

const darkModeOptions: QOptionGroupProps['options'] = [
  { label: t('Light'), value: false },
  { label: t('Dark'), value: true },
  { label: t('Auto (Sync with OS)'), value: 'auto' },
];

watch(darkMode, mode => {
  q.dark.set(mode);
  ls.value.darkMode = mode;
});

const cookies = useGlobalCookies();

</script>

<i18n lang="yaml">
ru:
  App Language: Язык приложения
  Profile: Профиль
  App Theme: Тема приложения
  Light: Светлая
  Dark: Темная
  Auto (Sync with OS): Авто (как в системе)
  Debug: Отладка
  Enable Profiling: Включить профилирование

en:
  App Language: App Language
  Profile: Profile
  App Theme: App Theme
  Light: Light
  Dark: Dark
  Auto (Sync with OS): Auto (Sync with OS)
  Debug: Debug
  Enable Profiling: Enable Profiling
</i18n>
