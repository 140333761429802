<template>
  <SwiperCarousel
    v-if="errors.length > 0"
    v-model:slide="slide"
    :items="errors"
  >
    <template #item="{ item: error, index: i }">
      <BaseAlert
        type="error"
        style="min-height: 40px"
      >
        <div class="row no-wrap justify-between items-center">
          <div>
            {{ error }}
          </div>
          <div class="text-no-wrap">
            {{ prefix(i) }}
          </div>
        </div>
      </BaseAlert>
    </template>
  </SwiperCarousel>
  <BaseAlert
    v-else
    type="error"
    invisible
  />
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import SwiperCarousel from '@/components/Mobile/SwiperCarousel.vue';
import { ref } from 'vue';

const props = defineProps<{
  errors: string[];
}>();

const slide = ref(0);

function prefix(slide: number) {
  if (props.errors.length <= 1) {
    return '';
  }

  return (slide + 1) + ' / ' + props.errors.length;
}

</script>
