<template>
  <EditableListCard
    :title="t('Carriers')"
    :items-query="query"
    :item-draft="getDraft"
    :item-key="u => u.id"
    :item-title="u => u.name"
    :delete-item="deleteCarrier"
    item-editable
    item-deletable
    :item-filter="filterCarrier"
  >
    <template #edit="{ item, on }">
      <CarrierEdit
        :carrier="item"
        @save="on.save($event)"
        @cancel="on.cancel()"
      />
    </template>
  </EditableListCard>
</template>

<script setup lang="ts">

import type { Carrier } from '@/graphql/types';
import CarrierEdit from '@/views/WarehouseSettings/CarrierEdit.vue';
import EditableListCard from '@/views/WarehouseSettings/EditableListCard.vue';
import { gql, useMutation } from '@urql/vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const query = gql`
  query GetCarriersForWarehouseSettingsList { data: carriers {
    id name
  } }
`;

function getDraft(): Carrier {
  return {
    id:   null!,
    name: '',
  };
}

const { executeMutation: doDeleteCarrier } = useMutation(gql`
  mutation DeleteCarrier($id: ID!) { deleteCarrier(id: $id) }
`);

async function deleteCarrier(carrier: Carrier) {
  const { error } = await doDeleteCarrier({ id: carrier.id });

  if (error) {
    throw error;
  }
}

function filterCarrier(carrier: Carrier, searchString: string): boolean {
  return carrier.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase());
}

</script>

<i18n lang="yaml">
ru:
  Carriers: Способы доставки
en:
  Carriers: Carriers
</i18n>
