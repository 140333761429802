<template>
  <QTd :props="column">
    <QSkeleton v-if="column.loading" />
    <template v-else-if="variant === 'link'">
      <LinkField
        dense
        v-bind="$attrs"
      >
        <slot>{{ column.value }}</slot>
      </LinkField>
    </template>
    <template v-else-if="variant === 'pencil'">
      <QBtn
        v-if="column.value || showEmpty"
        flat
        round
        icon="mdi-pencil"
        :title="t('Edit')"
        dense
        v-bind="$attrs"
        class="q-mr-xs"
      />
      <slot>{{ column.value }}</slot>
    </template>
  </QTd>
</template>

<script setup lang="ts">

import type { QTdProps } from 'quasar';
import { useI18n } from 'vue-i18n';
import LinkField from '@/components/LinkField.vue';

const { t } = useI18n();

withDefaults(defineProps<{
  column: QTdProps['props'];
  showEmpty?: boolean;
  variant?: 'pencil' | 'link';
  separateIcon?: boolean;
}>(), {
  variant: 'pencil',
});

</script>
