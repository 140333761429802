<template>
  <AuthCard :title="t('Login Title')">
    <QInput
      v-model="login"
      dense
      :label="t('Login or E-mail')"
      type="email"
      required
      autofocus
      autocomplete="username"
      v-bind="qErrorsFor('login')"
    />
    <QInput
      v-model="password"
      dense
      :label="t('Password')"
      :type="showPassword ? 'text' : 'password'"
      required
      autocomplete="current-password"
      v-bind="qErrorsFor('password')"
    >
      <template #append>
        <QIcon
          :name="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          class="cursor-pointer"
          @click="showPassword = !showPassword"
        />
      </template>
    </QInput>

    <div class="row items-center justify-between">
      <QBtn
        type="submit"
        color="primary"
        :label="t('Login')"
        icon="mdi-login"
        :loading="hasProgress()"
        @click.prevent="doLogin()"
      />
      <QCheckbox
        v-model="remember"
        :label="t('Remember me')"
      />
    </div>

    <template
      v-if="primaryError"
      #error
    >
      {{ t(primaryError) }}
    </template>

    <template #actions>
      <QBtn
        v-if="!loginOnly"
        flat
        :to="{ name: ROUTES.AUTH_REGISTER }"
      >
        {{ t('Register') }}
      </QBtn>

      <div class="col-grow" />

      <QBtn
        v-if="!loginOnly"
        flat
        :to="{ name: ROUTES.AUTH_FORGOT_PASSWORD }"
      >
        {{ t('Forgot your password?') }}
      </QBtn>
    </template>
  </AuthCard>
</template>

<script setup lang="ts">

import useBackend from '@/composables/useBackend';
import useErrorHandling from '@/composables/useErrorHandling';
import useProgressHandling from '@/composables/useProgressHandling';
import AuthCard from '@/views/Auth/AuthCard.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const {
  fillErrorsFromHttpError,
  clearErrors,
  qErrorsFor,
  primaryError,
} = useErrorHandling();
const { progressStarted, hasProgress } = useProgressHandling();

const backend = useBackend();

defineProps<{
  loginOnly?: boolean;
}>();

const emit = defineEmits<{
  (e: 'next'): void;
  (e: 'login'): void;
}>();

const showPassword = ref(false);

const login = ref('');

const password = ref('');

const remember = ref(true);

async function doLogin(): Promise<void> {
  clearErrors();

  const finished = progressStarted();

  try {
    const loginResult = await backend.login(login.value, password.value, remember.value);

    const isTwoFactor = loginResult.two_factor;

    if (isTwoFactor) {
      emit('next');
    } else {
      emit('login');
    }
  } catch (e) {
    await fillErrorsFromHttpError(e);
    if (primaryError.value === 'The given data was invalid.'
      || primaryError.value === t('The given data was invalid.')) {
      primaryError.value = '';
    }
  } finally {
    finished();
  }
}

</script>

<i18n lang="yaml">
ru:
  Remember me: Запомнить меня
  Login or E-mail: Логин или email
  Forgot your password?: Забыли пароль?
  Register: Регистрация
  Login Title: Вход

en:
  Remember me: Remember me
  Login or E-mail: Login or E-mail
  Forgot your password?: Forgot your password?
  Register: Register
  Login Title: Login
</i18n>
