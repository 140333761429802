<template>
  <EditableListCard
    :title="t('Measurement Units')"
    :items-query="query"
    :item-draft="getDraft"
    :item-key="u => u.id"
    :item-title="u => u.name"
    :item-subtitle="u => u.shortName"
    :delete-item="deleteUnit"
    item-editable
    :item-deletable="u => !u.isStandard"
    :item-filter="filterUnit"
  >
    <template #edit="{ item, on }">
      <MeasurementUnitEdit
        :unit="item"
        @save="on.save($event)"
        @cancel="on.cancel()"
      />
    </template>
  </EditableListCard>
</template>

<script setup lang="ts">

import type { MeasurementUnit, Mutation, MutationDeleteMeasurementUnitArgs } from '@/graphql/types';
import { MeasurementUnitClassEnum } from '@/graphql/types';
import { useI18n } from 'vue-i18n';
import EditableListCard from '@/views/WarehouseSettings/EditableListCard.vue';
import { gql, useClientHandle } from '@urql/vue';
import MeasurementUnitEdit from './MeasurementUnitEdit.vue';

const { t } = useI18n();

const { client: urql } = useClientHandle();

const query = gql`
  query GetMeasurementUnits { data: measurementUnits {
    id name shortName isStandard isFractional
  } }
`;

function getDraft(): MeasurementUnit {
  return {
    id:           null!,
    shortName:    '',
    name:         '',
    class:        MeasurementUnitClassEnum.BOXES,
    isFractional: false,
    isStandard:   false,
  };
}

async function deleteUnit(unit: MeasurementUnit) {
  const { error } = await urql.mutation<Pick<Mutation, 'deleteMeasurementUnit'>, MutationDeleteMeasurementUnitArgs>(
    gql`mutation DeleteMeasurementUnit($id: ID!) { deleteMeasurementUnit(id: $id) }`,
    { id: unit.id },
  );

  if (error) {
    throw error;
  }
}

function filterUnit(unit: MeasurementUnit, searchString: string): boolean {
  return unit.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase());
}

</script>

<i18n lang="yaml">
ru:
  Measurement Units: Единицы упаковки
en:
  Measurement Units: Measurement Units
</i18n>
