<template>
  <ConfirmsAction
    :confirm-text="t('Yes')"
    :cancel-text="t('No')"
    @confirmed="emit('complete')"
  >
    <template #title>
      {{ t('Complete Transfer?') }}
    </template>
    <template #activator="{ prompt }">
      <QBtn
        v-bind="$attrs"
        icon="mdi-check-outline"
        @click="prompt"
      >
        {{ t('Complete') }}
      </QBtn>
    </template>
  </ConfirmsAction>
</template>
<script setup lang="ts">
import ConfirmsAction from '@/components/ConfirmsAction.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const emit = defineEmits<{
  complete: [];
}>();

</script>
