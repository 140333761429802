<template>
  <div class="row justify-center q-mt-md">
    <AuthCard :title="t('Forgot Password')">
      {{ t('Forgot your password?') }}
      <QInput
        v-model="email"
        :label="t('Email')"
        type="email"
        required
        autofocus
        autocomplete="email"
        v-bind="qErrorsFor('email')"
      />

      <template
        v-if="successMessage"
        #success
      >
        {{ t(successMessage) }}
      </template>

      <template
        v-if="getPrimaryError()"
        #error
      >
        {{ t(getPrimaryError()) }}
      </template>

      <template #actions>
        <QBtn
          flat
          :to="{ name: ROUTES.AUTH_LOGIN }"
        >
          {{ t('Login') }}
        </QBtn>

        <QSpace />

        <QBtn
          type="submit"
          color="primary"
          :loading="hasProgress()"
          icon="mdi-email-send"
          @click.prevent="submit()"
        >
          {{ t('Email Password Reset Link') }}
        </QBtn>
      </template>
    </AuthCard>
  </div>
</template>

<script setup lang="ts">

import useBackend from '@/composables/useBackend';
import useErrorHandling from '@/composables/useErrorHandling';
import useProgressHandling from '@/composables/useProgressHandling';
import AuthCard from '@/views/Auth/AuthCard.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const route = useRoute();

const backend = useBackend();

const { fillErrorsFromHttpError, clearErrors, getPrimaryError, qErrorsFor } = useErrorHandling();

const { progressStarted, hasProgress } = useProgressHandling();

const email = ref((route.query.email || null) as string | null);

const successMessage = ref('');

async function submit(): Promise<void> {
  clearErrors();
  successMessage.value = '';

  const finished = progressStarted();

  try {
    const result = await backend.forgotPassword(email.value!);
    successMessage.value = result.message;
  } catch (e) {
    fillErrorsFromHttpError(e);
  } finally {
    finished();
  }
}

</script>

<i18n lang="yaml">
en:
  Forgot your password?: >
    Forgot your password? No problem.
    Just let us know your email address
    and we will email you a password reset link that will allow you to choose a new one
  Email Password Reset Link: Email Password Reset Link
  Forgot Password: Forgot Password

ru:
  Forgot your password?: >
    Забыли пароль? Не проблема.
    Сообщите свой E-mail и мы отправим вам ссылку для восстановления
  Email Password Reset Link: Отправить ссылку
  Forgot Password: Восстановление пароля
</i18n>
