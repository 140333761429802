<template>
  <ErrorsCarousel :errors="currentItemErrors" />
  <SwiperCarousel
    v-if="store.movements.length > 0"
    ref="stocksCarousel"
    v-model:slide="currentIndex"
    :items="store.movements"
  >
    <template #item="{ item, index }">
      <ArrivalProductSlide
        :item="item"
        :display-amount="index === currentIndex ? (inputValue || '0') : item.amount"
        :all-items="store.movements"
        @storage-unit-saved="emit('storage-unit-saved', { index, storageUnit: $event })"
        @delete="emit('save-amount', { index, amount: 0 })"
      />
    </template>
    <template #container-end="{ index, total }">
      <ScrollCarouselCounter
        :current="index + 1"
        :total="total"
        :suggested="total"
        @to-current="currentIndex = total - 1"
      />
    </template>
  </SwiperCarousel>
  <BlurredInput
    speak-digits
    skip-input-if-barcode
    @barcode="emit('barcode', $event)"
  />
  <VirtualKeyboard
    v-if="currentItem"
    v-model:visible="keyboardVisible"
    :show-comma-key="amountIsFractional"
    @enter="commitAmount(currentIndex)"
  />
</template>

<script setup lang="ts">

import ErrorsCarousel from '@/components/Mobile/ErrorsCarousel.vue';
import ScrollCarouselCounter from '@/components/Mobile/ScrollCarouselCounter.vue';
import SwiperCarousel from '@/components/Mobile/SwiperCarousel.vue';
import useOmniInput from '@/composables/useOmniInput';
import type { Movement, Scalars, StorageUnit } from '@/graphql/types';
import useProductArrivalStore from '@/stores/productArrival';
import ArrivalProductSlide from '@/views/Mobile/Arrival/ArrivalProductSlide.vue';
import { computed, ref, watch } from 'vue';

const props = defineProps<{
  errorMessages: Record<Scalars['ID'], string[]>;
}>();

const emit = defineEmits<{
  (e: 'barcode', value: string): void;
  (e: 'edit', movement: Movement): void;
  (e: 'amount-changed', index: number): void;
  (e: 'save-amount', payload: { index: number; amount: number }): void;
  (e: 'storage-unit-saved', payload: { index: number; storageUnit: StorageUnit }): void;
}>();

const stocksCarousel = ref<InstanceType<typeof SwiperCarousel> | null>(null);

const store = useProductArrivalStore();

const currentIndex = ref<number>(0);

const currentItem = computed(() => store.movements[currentIndex.value]);

const currentItemErrors = computed(() => {
  const movementId = currentItem.value?.id;

  return movementId ? (props.errorMessages[movementId] ?? []) : [];
});

const amountIsFractional = computed(() => currentItem.value?.storable.productPack.measurementUnit.isFractional ?? false);

const keyboardVisible = ref(false);

function handleMovementScanned(movement: Movement) {
  const newIndex = store.movements.findIndex(m => m.id === movement.id);

  if (newIndex !== currentIndex.value) {
    currentIndex.value = newIndex;

    keyboardVisible.value = false;
  }

  input.reset(String(movement.amount));
}

function updateStockAmount(amount: number): void {
  currentItem.value.amount = amount;

  emit('amount-changed', currentIndex.value);
}

const input = useOmniInput({
  replace: value => {
    // Не используем skip, т.к. нужна возможность сканирования ШК.
    if (!currentItem.value) {
      return '';
    }
    return amountIsFractional.value
      ? value.replace(/,/g, '.').replace(/[^\d.]/g, '')
      : value.replace(/\D/g, '');
  },
});
const { BlurredInput, VirtualKeyboard, value: inputValue } = input;

watch(currentItem, item => {
  input.reset(String(item?.amount ?? ''));
}, { immediate: true });

watch(inputValue, value => {
  if (!input.isPristine.value && !Number.isNaN(Number(value))) {
    updateStockAmount(Number(value));
  }
});

watch(keyboardVisible, (visible, wasVisible) => {
  if (wasVisible && !visible) {
    commitAmount(currentIndex.value);
  }
});

watch(currentItem, (newItem, oldItem) => {
  const index = store.movements.indexOf(oldItem);
  if (index >= 0) {
    commitAmount(index);
  }
  input.reset(newItem ? String(newItem.amount) : '');
});

function commitAmount(index: number) {
  input.selectAll();
  if (input.isPristine.value) {
    return;
  }
  input.markAsPristine();

  emit('save-amount', {
    index,
    amount: store.movements[index].amount,
  });
}

defineExpose({
  slideTo: (index: number) => {
    currentIndex.value = index;
  },
  movementScanned: handleMovementScanned,
});

</script>
