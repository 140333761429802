<template>
  <ContainerEditDesktop v-if="!preferences.terminalMode" />
  <ContainerEditMobile
    v-else
    @goto-container="router.replace({ params: { id: $event } })"
  />
</template>

<script setup lang="ts">

import useLocalPreferences from '@/composables/useLocalPreferences';
import ContainerEditDesktop from '@/views/Containers/ContainerEditDesktop.vue';
import ContainerEditMobile from '@/views/Containers/ContainerEditMobile.vue';
import { useRouter } from 'vue-router';

const preferences = useLocalPreferences();

const router = useRouter();

</script>
