<template>
  <QItem
    v-bind="$attrs"
    class="list-item-default-height"
  >
    <QItemSection class="col-grow">
      <QItemLabel v-if="!idIsDraft(inventory.id)">
        {{ t('# {text}', { text: inventory.id }) }}
      </QItemLabel>
      <QItemLabel>
        {{ formatDate(inventory.createdAt, 'DD.MM.YY LT') }}
      </QItemLabel>
      <QItemLabel>
        <QIcon
          v-if="inventory.isEntireStorage"
          name="mdi-select-group"
          left
          :title="t('Entire Storage')"
        />
        <QIcon
          v-else
          left
          name="mdi-package"
          :title="t('Some Products')"
        />

        {{ t('{n} products', inventory.items.length) }}
      </QItemLabel>
    </QItemSection>
    <QItemSection
      side
      class="dense-labels justify-between"
    >
      <QItemLabel class="ellipsis">
        {{ inventory.storage.storageCell.name }}
      </QItemLabel>
      <QItemLabel
        v-if="inventory.storage.__typename === 'Container'"
        class="ellipsis"
      >
        <QIcon
          name="mdi-tray-full"
          size="xs"
          left
        />
        {{ inventory.storage.name }}
      </QItemLabel>
      <QItemLabel>
        <QChip
          v-if="inventory.state"
          :color="stateColor"
          text-color="black"
          dense
          class="q-ma-none"
        >
          {{ t(`inventoryState.${inventory.state}`) }}
        </QChip>
      </QItemLabel>
    </QItemSection>
  </QItem>
</template>

<script setup lang="ts">

import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import * as badgeColors from '@/helpers/badgeColors';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { PendingInventory } from '@/types/inventory';
import { idIsDraft } from '@/views/Mobile/Inventory/useInventoryState';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const props = defineProps<{
  inventory: PendingInventory;
}>();

const stateColor = computed(() => badgeColors.forOperationState({
  __typename: 'Inventory',
  state: props.inventory.state!,
}));

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/counts.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/inventory.yaml"></i18n>
