<template>
  <QBtn
    v-bind="$attrs"
    :loading="progress.fetching || loading"
    :disable="containers.length === 0"
    @click="open = true"
  >
    {{ t('Ship') }}
  </QBtn>
  <QDialog v-model="open">
    <QCard>
      <PrimaryErrorBanner />
      <CardTitle>
        {{ t('Ship {n} containers?', containers.length) }}
      </CardTitle>

      <QSeparator />

      <QCardActions>
        <QSpace />

        <QBtn
          :loading="progress.shipping"
          @click="open = false"
        >
          {{ t('Cancel') }}
        </QBtn>
        <QBtn
          color="primary"
          icon="mdi-check"
          :loading="progress.shipping"
          @click="shipContainers"
        >
          {{ t('Confirm') }}
        </QBtn>
      </QCardActions>
    </QCard>
  </QDialog>
</template>

<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import type {
  Container,
  MutationShipContainersArgs,
} from '@/graphql/types';
import { gql, useClientHandle } from '@urql/vue';
import { reactive, ref } from 'vue';
import CardTitle from '@/components/CardTitle.vue';
import useErrorHandling from '@/composables/useErrorHandling';

const { t }                                        = useI18n();
const { client: urql }                             = useClientHandle();
const { primaryError, fillErrorsFromGraphQLError, clearErrors, PrimaryErrorBanner } = useErrorHandling();

const props = defineProps<{
  containers: Container[];
  loading?: boolean;
}>();

const emit = defineEmits<{
  (e: 'completed'): void;
}>();

const open = ref(false);

const progress = reactive({
  fetching: false,
  shipping: false,
});

async function shipContainers(): Promise<void> {
  if (props.containers.length === 0) {
    return;
  }

  clearErrors();
  progress.shipping = true;

  const { error } = await urql.mutation<unknown, MutationShipContainersArgs>(
    gql`mutation ShipContainerBatchAction($containersIds: [ID!]!) { shipContainers(containersIds: $containersIds) { id } }`,
    { containersIds: props.containers.map(c => c.id) },
  );

  if (error) {
    fillErrorsFromGraphQLError(error);
  }

  progress.shipping = false;

  if (!primaryError.value) {
    emit('completed');
    open.value = false;
  }
}

</script>

<i18n lang="yaml">
ru:
  Ship {n} containers?: >
    Отгрузить {n} контейнеров?
    | Отгрузить контейнер?
    | Отгрузить {n} контейнера?
    | Отгрузить {n} контейнеров?
  Ship: Отгрузить
  Ship Containers?: Отгрузить контейнеры?

en:
  Ship {n} containers?: >
    Ship container?
    | Ship {n} containers?
  Ship: Ship
  Ship Containers?: Ship Containers?
</i18n>
