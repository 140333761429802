<template>
  <FullHeightPage v-if="preferences.terminalMode">
    <GraphQLReportList
      ref="list"
      :graphql-query="mobileQuery"
      :available-filters="availableFilters"
      with-search
      class="col"
      blurred-search
      storage-prefix="cellsList.mobileReport"
      @single-item-found="handleSingleCellFound"
    >
      <template #item="{ item }">
        <QItem :to="{ name: ROUTES.CELLS_EDIT, params: { id: item.id } }">
          <QItemSection>
            <QItemLabel>
              {{ item.name }}
            </QItemLabel>
            <QItemLabel>
              {{ item.barcode }}
            </QItemLabel>
          </QItemSection>

          <QItemSection side>
            <QChip
              v-if="item.cellsArea"
              :class="forCellAreaKind(item.cellsArea.kind)"
              dense
              class="q-mr-none"
            >
              {{ item.cellsArea.name }}
            </QChip>
            <QItemLabel>
              <template v-if="item.isEmpty">
                {{ t('Empty') }}
              </template>
              <template v-else>
                {{ t('{n} products', item.stocks.length) }}
              </template>
            </QItemLabel>
          </QItemSection>
        </QItem>
      </template>
    </GraphQLReportList>
  </FullHeightPage>
  <QPage v-else>
    <GraphQLQueryTable
      ref="report"
      :graphql-query="query"
      :fields="tableFields"
      :available-filters="availableFilters"
      :row-is-deleted="entityIsDeleted"
      storage-prefix="cellsList.report"
      keep-page-in-url
      :deletion-filter="deletionFilterForSoftDeletable"
      sticky-header
      class="col"
      card-container-class="q-list--separator column"
      @single-item-found="handleSingleCellFound"
    >
      <template #after-search-string="{ options }">
        <QBtn
          color="primary"
          :to="{ name: ROUTES.CELLS_NEW }"
          icon="mdi-table-large-plus"
        >
          {{ t('Add Cell') }}
        </QBtn>

        <CellsExcelImportExport
          :options="options"
          @success="report.refresh()"
        />
      </template>

      <template #batch-actions="{ rows, refresh, clear }">
        <SimpleTableBatchActions :rows-count="rows.length">
          <DeletionWithValidation
            v-if="rows.some(item => item.deletedAt === null)"
            :items="rows"
            :delete="deleteCell"
            :validate="validateCell"
            @deleted="refresh()"
          >
            <template #validation-error-isNotEmpty="{ items }">
              <BaseAlert type="error">
                {{
                  t(
                    'Cells {names} with stocks cannot be deleted',
                    { names: items.map(c => c.name).join(', ') },
                    items.length,
                  )
                }}
              </BaseAlert>
            </template>
            <template #validation-error-isImmutable="{ items }">
              <BaseAlert type="error">
                {{
                  t(
                    'Primary cells {names} cannot be deleted',
                    { names: items.map(c => c.name).join(', ') },
                    items.length,
                  )
                }}
              </BaseAlert>
            </template>
            <template #confirmation>
              <QCardSection class="text-body1">
                {{ getConfirmMessageForDeletion(rows) }}
              </QCardSection>
            </template>
          </DeletionWithValidation>

          <RestoreItemsButton
            :items="rows"
            :restore="restoreCell"
            @restored="refresh()"
          >
            <template #confirmation>
              <div class="text-h6">
                {{ getConfirmMessageForRestoring(rows) }}
              </div>
            </template>
          </RestoreItemsButton>

          <PrintDialog
            v-slot="{ open }"
            v-model:barcode-type="barcodeType"
            template-name="cell-barcode"
            use-count
            :title="t('Selected for print {size}', {size: rows.length})"
            :instances="printInstancesByCells(rows)"
            @count-changed="printCount=$event"
            @print="clear();"
          >
            <QBtn
              class="bg-white text-black q-ml-sm"
              icon="mdi-printer"
              @click="open()"
            >
              {{ t('Print') }}
            </QBtn>
          </PrintDialog>
        </SimpleTableBatchActions>
      </template>

      <template #body-cell-title="column">
        <BodyCellLink
          :column="column"
          :to="{ name: ROUTES.CELLS_EDIT, params: { id: column.row.id } }"
        />
      </template>
    </GraphQLQueryTable>
  </QPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import FullHeightPage from '@/components/FullHeightPage.vue';
import type GraphQLQueryTableType from '@/components/GraphQLQueryTable.vue';
import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import GraphQLReportList from '@/components/Mobile/GraphQLReportList.vue';
import PrintDialog from '@/components/PrintDialog.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import useLocalPreferences from '@/composables/useLocalPreferences';
import type { PrintTemplateInstance } from '@/composables/usePrintService';
import getCellsAreasForReportFilter from '@/graphql/shorthands/getCellsAreasForReportFilter';
import type {
  Cell,
  CellsArea,
  MutationDeleteCellArgs,
  MutationRestoreCellArgs,
} from '@/graphql/types';
import { PrintBarcodeTypeEnum } from '@/graphql/types';
import { forCellAreaKind } from '@/helpers/badgeColors';
import {
  createBoolean,
  createDatesRangeOperator,
  createList,
  deletionFilterForSoftDeletable,
  entityIsDeleted,
} from '@/helpers/reports';
import ROUTES from '@/router/routeNames';
import type { FunctionValidationRule } from '@/types';
import type { ReportFilter, TableColumn } from '@/types/reports';
import CellsExcelImportExport from '@/views/Cells/CellsExcelImportExport.vue';
import DeletionWithValidation from '@/views/DeletionWithValidation.vue';
import RestoreItemsButton from '@/views/RestoreItemsButton.vue';
import SimpleTableBatchActions from '@/views/SimpleTableBatchActions.vue';
import useDocumentsPrintingState from '@/views/useDocumentsPrintingState';
import { gql, useClientHandle } from '@urql/vue';
import { QPage } from 'quasar';
import { isNil } from 'ramda';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const { t } = useI18n();

const { dateFormatter } = useLocalizedFormatters();

const preferences = useLocalPreferences();

useBreadcrumbs(t('Cells'));

const { client: urql } = useClientHandle();

const report = ref<InstanceType<typeof GraphQLQueryTableType>>(null);

const state = useDocumentsPrintingState();

const barcodeType = ref(state.value.lastCellBarcodeType ?? PrintBarcodeTypeEnum.BARCODE);

const printCount = ref(1);

function printInstancesByCells(rows: Cell[]): PrintTemplateInstance[] {
  return rows.map(c => ({ cellId: c.id, barcodeType: barcodeType.value }))
    .map(p => {
      return { count: printCount.value, params: p, };
    });
}

const tableFields: TableColumn<Cell>[] = [
  {
    label:     t('ID'),
    name:      'id',
    field:     'id',
    sortable:  true,
    mandatory: true,
  },
  {
    label:    t('Created at'),
    name:     'createdAt',
    field:    'createdAt',
    format:    dateFormatter(FORMATS.DATETIME),
    align:    'left',
    sortable: true,
  },
  {
    label:         t('Name'),
    name:          'title',
    field:         'name',
    requestFields: ['id', 'name'],
    align:         'left',
    sortable:      true,
  },
  {
    label:    t('Barcode'),
    name:     'barcode',
    field:    'barcode',
    align:    'left',
    sortable: true,
  },
  {
    label:  t('Area'),
    name:   'area',
    field:  'cellsArea',
    format: (area?: CellsArea) => area?.name ?? '',
    align:  'left',
  },
  {
    label:  t('Is Empty'),
    name:   'isEmpty',
    field:  'isEmpty',
    format: v => isNil(v) ? '' : (v ? t('Yes') : t('No')),
    align:  'left',
  },
];

const availableFilters: ReportFilter[] = [
  {
    field:                 'createdAt',
    label:                 t('Created at'),
    hideLabelWhenSelected: true,
    operators:             [
      createDatesRangeOperator(),
    ],
  },
  {
    field:                 'cellsArea',
    label:                 t('Area'),
    hideLabelWhenSelected: true,
    operators:             [
      createList('in', getCellsAreasForReportFilter),
    ],
  },
  {
    field:                 'isEmpty',
    label:                 t('Is Empty'),
    hideLabelWhenSelected: true,
    operators:             [
      createBoolean('is null', ' - ', {
        trueLabel: t('Is Empty'),
        falseLabel: t('Is Not Empty'),
      }),
    ],
  },
];

const mobileQuery = gql`
  query GetCellsListMobile(
    $first: Int!,
    $after: Cursor,
    $query: String,
    $filter: [ReportFilterInput!],
  ) {
    result: cellsList(
      first: $first,
      after: $after,
      query: $query,
      filter: $filter,
    ) {
      edges {
        cursor
        node {
          id
          name
          barcode
          isEmpty
          deletedAt
          stocks { storageUnit { id } }
          cellsArea { id name kind }
          createdAt
          isMutable
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const query = gql`
  query GetCells(
    $page: Int,
    $perPage: Int!,
    $query: String,
    $filter: [ReportFilterInput!],
    $sort: [ReportSortInput!]!,
  ) {
    result: cellsReport(
      page: $page,
      perPage: $perPage,
      query: $query,
      filter: $filter,
      sort: $sort,
    ) {
      data {
        id
        name
        barcode
        isEmpty
        deletedAt
        stocks { storageUnit { id } }
        cellsArea { id name kind }
        createdAt
        isMutable
      }
      total
    }
  }
`;

watch(barcodeType, (): void => {
  state.value.lastCellBarcodeType = barcodeType.value;
});

async function deleteCell(cell: Cell): Promise<void> {
  await urql.mutation<unknown, MutationDeleteCellArgs>(
    gql`mutation DeleteCell($id: ID!) { deleteCell(id: $id) }`,
    { id: cell.id },
  );
}

function validateCell(cell: Cell): ReturnType<FunctionValidationRule> {
  if (!cell.isEmpty) {
    return 'isNotEmpty';
  }

  if (!cell.isMutable) {
    return 'isImmutable';
  }

  return true;
}

function getConfirmMessageForDeletion(items: Cell[]): string {
  const notDeletedItems = items.filter(item => item.deletedAt === null);
  return t('Delete {n} cells?', { name: notDeletedItems[0].name }, notDeletedItems.length);
}

function getConfirmMessageForRestoring(items: Cell[]): string {
  const deletedItems = items.filter(item => item.deletedAt !== null);
  return t('Restore {n} cells?', { name: deletedItems[0].name }, deletedItems.length);
}

async function restoreCell(cell: Cell): Promise<void> {
  await urql.mutation<unknown, MutationRestoreCellArgs>(
    gql`mutation RestoreCell($id: ID!) { restoreCell(id: $id) { id } }`,
    { id: cell.id }
  );
}

const router = useRouter();

const list = ref();

function handleSingleCellFound(cell: Cell, searchString: string) {
  if (searchString === cell.barcode) {
    if (list.value) {
      list.value.resetSearch();
    }
    if (report.value) {
      report.value.resetSearch();
    }
    router.push({ name: ROUTES.CELLS_EDIT, params: { id: cell.id } });
  }
}

</script>

<i18n lang="yaml" src="../plugins/i18n/sharedMessages/counts.yaml"></i18n>
<i18n lang="yaml" src="../plugins/i18n/sharedMessages/printing.yaml"></i18n>

<i18n lang="yaml">
ru:
  Add Cell: Добавить ячейку
  Area: Зона
  Is Empty: Пустая
  Is Not Empty: Непустая
  Only empty cells can be deleted: Удалить можно только пустые ячейки

  Cells {names} with stocks cannot be deleted: >
    Нельзя удалить ячейки {names}, для которых есть остатки
    | Нельзя удалить ячейку {names}, для которой есть остатки
    | Нельзя удалить ячейки {names}, для которых есть остатки
    | Нельзя удалить ячейки {names}, для которых есть остатки

  Primary cells {names} cannot be deleted: >
    Нельзя удалить ячейки {names}, являющиеся основными
    | Нельзя удалить ячейку {names}, являющуюся основной
    | Нельзя удалить ячейки {names}, являющиеся основными
    | Нельзя удалить ячейки {names}, являющиеся основными

  Delete {n} cells?: >
    Удалить {n} ячеек?
    | Удалить ячейку {name}?
    | Удалить {n} ячейки?
    | Удалить {n} ячеек?

  Restore {n} cells?: >
    Восстановить {n} ячеек?
    | Восстановить ячейку {name}?
    | Восстановить {n} ячейки?
    | Восстановить {n} ячеек?

en:
  Add Cell: Add Cell
  Area: Area
  Is Empty: Is Empty
  Is Not Empty: Is Not Empty
  Only empty cells can be deleted: Only empty cells can be deleted

  Cells {names} with stocks cannot be deleted: >
    Cell {names} with stocks cannot be deleted
    | Cells {names} with stocks cannot be deleted

  Primary cells {names} cannot be deleted: >
    Primary cell {names} cannot be deleted
    | Primary cells {names} cannot be deleted

  Delete {n} cells?: >
    Delete cell {name}?
    | Delete {n} cells?

  Restore {n} cells?: >
    Restore cell {name}?
    | Restore {n} cells?
</i18n>
