<template>
  <QBtn
    v-if="modelValue"
    flat
    round
    icon="mdi-filter-remove"
    color="red"
    :title="t('Cancel')"
    @click="emit('update:model-value', null)"
  />
  <QBtn
    v-else
    flat
    round
    icon="mdi-filter-plus"
    :title="t('Add Filter')"
    @click="emit('update:model-value', {
      field: null,
      operator: null,
      value: null,
    })"
  />
</template>

<script setup lang="ts">

import type { MakeMaybe } from '@/graphql/types';
import type { Filter } from '@/types/reports';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

type FilterDraft = MakeMaybe<Filter, keyof Filter>;

defineProps<{
  modelValue: FilterDraft | null;
}>();

const emit = defineEmits<{
  (e: 'update:model-value', filter: FilterDraft | null): void;
}>();

</script>

<i18n lang="yaml">
ru:
  Add Filter: Добавить фильтр

en:
  Add Filter: Add Filter
</i18n>
