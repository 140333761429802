<template>
  <ScrollCarousel
    v-if="actualStocks.length > 0"
    :items="actualStocks"
    :model-value="slide"
    @update:model-value="emit('update:slide', $event)"
  >
    <template #item="{ item, index }">
      <InventoryProcessCarouselSlide
        :item="item"
        :actual-stocks="actualStocks"
        :display-amount="index === slide ? (inputValue || '0') : item.amount"
        :expected-amount="expectedAmount(item)"
        :expected-amount-loading="storageStocksLoading"
        class="inline-block"
        @update:slide="emit('update:slide', $event)"
        @delete="emit('submit-amount', { index, amount: 0 })"
      />
    </template>

    <template #indicators="{ index, total }">
      <ScrollCarouselCounter
        :current="index + 1"
        :total="total"
        :suggested="total"
        @to-current="emit('update:slide', total - 1)"
      />
    </template>
  </ScrollCarousel>
  <BlurredInput
    speak-digits
    skip-input-if-barcode
    @barcode="emit('barcode', $event)"
  />
  <VirtualKeyboard
    v-if="currentItem"
    v-model:visible="keyboardVisible"
    :show-comma-key="amountIsFractional"
    @enter="commitAmount(slide)"
  />
</template>

<script setup lang="ts">

import ScrollCarousel from '@/components/Mobile/ScrollCarousel.vue';
import ScrollCarouselCounter from '@/components/Mobile/ScrollCarouselCounter.vue';
import useOmniInput from '@/composables/useOmniInput';
import type { Stock } from '@/graphql/types';
import type { ActualInventoryStock } from '@/types/inventory';
import InventoryProcessCarouselSlide
  from '@/views/Mobile/Inventory/InventoryProcessCarouselSlide.vue';
import { computed, nextTick, ref, watch } from 'vue';

const props = defineProps<{
  actualStocks: ActualInventoryStock[];
  slide: number;
  storageStocks: Stock[];
  storageStocksLoading: boolean;
}>();

const emit = defineEmits<{
  'barcode': [value: string];
  'update:slide': [slide: number];
  'input-amount': [amount: number];
  'submit-amount': [payload: { index: number; amount: number }];
}>();

const currentItem = computed(() => props.actualStocks[props.slide]);

function expectedAmount(stock: ActualInventoryStock) {
  return props.storageStocks
    .filter(s => s.storageUnit.productPack.product.id === stock.storable.product.id)
    .reduce((a, s) => a + s.amount * s.storageUnit.productPack.quantityInMinMeasurementUnits, 0);
}

const amountIsFractional = computed(() => currentItem.value?.storable.measurementUnit.isFractional ?? false);

const input = useOmniInput({
  replace: value => {
    // Не используем skip, т.к. нужна возможность сканирования ШК.
    if (!currentItem.value) {
      return '';
    }
    return amountIsFractional.value
      ? value.replace(/,/g, '.').replace(/[^\d.]/g, '')
      : value.replace(/\D/g, '');
  },
});
const { BlurredInput, VirtualKeyboard, value: inputValue } = input;

watch(currentItem, item => {
  input.reset(String(item?.amount ?? ''));
}, { immediate: true });
watch(inputValue, value => {
  if (!input.isPristine.value && !Number.isNaN(Number(value))) {
    emit('input-amount', Number(value));
  }
});

const keyboardVisible = ref(false);

watch(keyboardVisible, (visible, wasVisible) => {
  if (wasVisible && !visible) {
    commitAmount(props.slide);
  }
});

watch(currentItem, (_, oldItem) => {
  const index = props.actualStocks.indexOf(oldItem);
  if (index >= 0) {
    nextTick(() => {
      commitAmount(index);
    });
  }
});

function commitAmount(index: number) {
  input.selectAll();
  if (input.isPristine.value) {
    return;
  }
  input.markAsPristine();

  emit('submit-amount', {
    index,
    amount: props.actualStocks[index].amount,
  });
}

defineExpose({
  resetInput: () => input.reset(String(currentItem.value.amount)),
});

</script>
