<template>
  <QBtn
    v-if="isSupported"
    flat
    round
    icon="mdi-fullscreen"
    :title="t('Fullscreen Mode')"
    @click="toggle"
  />
</template>

<script setup lang="ts">

import { useFullscreen } from '@vueuse/core';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { toggle, isSupported } = useFullscreen();

</script>

<i18n lang="yaml">
ru:
  Fullscreen Mode: Полноэкранный режим
en:
  Fullscreen Mode: Fullscreen Mode
</i18n>
