<template>
  <ExcelExport
    v-if="store.can(Ability.ImportExcel)"
    :type="ExcelImportTypeEnum.SUPPLIER_RETURNS"
    :mutation-export="mutationExport"
    :options="options"
  />
  <ExcelImport
    :type="ExcelImportTypeEnum.SUPPLIER_RETURNS"
    :mutation-validate="mutationValidate"
    :mutation-import="mutationImport"
    :success-notification="n => t('Imported {n} returns', n)"
    @success="emit('success')"
  >
    <template #info-for-upload-file>
      {{ t('File must contain returns only for add or update (not full returns list)') }}
    </template>

    <template #import-btn-title>
      {{ t('Update Returns') }}
    </template>

    <template #row-with-error-message="{ rowsCount }">
      {{ t('{n} returns with errors', rowsCount) }}
    </template>

    <template #validation-success="{ rowsCount }">
      {{ t('Validation success. You can update {n} returns', rowsCount) }}
    </template>
  </ExcelImport>
</template>

<script setup lang="ts">

import { ExcelImportTypeEnum } from '@/graphql/types';
import { Ability } from '@/types/backend';
import type { ReportOptions } from '@/types/reports';
import ExcelExport from '@/views/ExcelExport.vue';
import ExcelImport from '@/views/ExcelImport.vue';
import { gql } from '@urql/vue';
import { useI18n } from 'vue-i18n';
import useStore from '@/stores/root';

const { t } = useI18n();
const store = useStore();
defineProps<{
  options?: ReportOptions;
}>();

const emit = defineEmits<{
  (e: 'success'): void;
}>();

const mutationImport = gql`
  mutation ImportSupplierReturns($type: ExcelImportTypeEnum!, $file: Upload) {
    importedCount: importExcelFile(type: $type, file: $file)
  }
`;

const mutationValidate = gql`
  mutation ValidateSupplierReturnsImportFile($type: ExcelImportTypeEnum!, $file: Upload) {
    validationResult: validateExcelImportFile(type: $type, file: $file) {
      isValid
      rowsCount
      validationErrorMessage
      fileWithErrorsUrl
    }
  }
`;

const mutationExport = gql`
  mutation GenerateSupplierReturnsFile($type: ExcelImportTypeEnum!, $searchString: String, $filter: [ReportFilterInput!]) {
    exportFilePath: generateExcelExportFile(type: $type, searchString: $searchString, filter: $filter)
  }
`;

</script>
