<template>
  <FullHeightPage>
    <GraphQLReportList
      :graphql-query="mobileQuery"
      :available-filters="availableFilters"
      :fixed-filters="fixedFilters"
      with-search
      :storage-prefix="storagePrefix"
      class="col"
      blurred-search
    >
      <template #item="{ item }: { item: Supply }">
        <QItem
          :to="supplyRoute(item)"
          class="list-item-default-height"
        >
          <QItemSection class="dense-labels no-wrap">
            <QItemLabel>
              {{ t('{id} dated {date}', {
                id: item.externalId,
                date: formatDate(item.createdAt, FORMATS.DATETIME),
              }) }}
            </QItemLabel>
            <QItemLabel class="row justify-between">
              <span>
                {{ item.counterparty?.name }}
              </span>
              <span v-if="item.plannedArrivalDate">
                <QIcon
                  name="mdi-truck-cargo-container"
                  class="q-mr-xs"
                />
                {{ formatDate(item.plannedArrivalDate, FORMATS.DATE) }}
              </span>
            </QItemLabel>
            <QItemLabel class="row justify-between">
              <span>
                {{ t('{n} products', R.uniqBy(i => i.productPack.product.sku, item.items).length) }}
              </span>
              <QChip
                dense
                :color="forSupplyState(item)"
                text-color="black"
                class="q-ma-none"
              >
                {{ t('supplyState.' + item.state) }}
              </QChip>
            </QItemLabel>
          </QItemSection>
        </QItem>
      </template>
    </GraphQLReportList>
  </FullHeightPage>
</template>

<script setup lang="ts">

import FullHeightPage from '@/components/FullHeightPage.vue';
import GraphQLReportList from '@/components/Mobile/GraphQLReportList.vue';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type { ReportFilterInput, Supply } from '@/graphql/types';
import { SupplyStateEnum } from '@/graphql/types';
import { forSupplyState } from '@/helpers/badgeColors';
import * as reports from '@/helpers/reports';
import { createDatesRangeOperator, enumValues } from '@/helpers/reports';
import type { ReportFilter } from '@/types/reports';
import { gql } from '@urql/vue';
import * as R from 'ramda';
import { useI18n } from 'vue-i18n';
import type { RouteLocationRaw } from 'vue-router';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const props = withDefaults(defineProps<{
  supplyRoute: (supply: Supply) => RouteLocationRaw;
  extraFilters?: ReportFilter[];
  fixedFilters: ReportFilterInput[];
  storagePrefix: string;
}>(), {
  extraFilters: () => [],
});

const mobileQuery = gql`
  query GetSuppliesMobile(
    $first: Int!,
    $after: Cursor,
    $query: String,
    $filter: [ReportFilterInput!],
  ) {
    result: suppliesList(
      first: $first,
      after: $after,
      query: $query,
      filter: $filter,
    ) {
      edges {
        cursor
        node {
          id
          externalId
          createdAt
          plannedArrivalDate
          counterparty { id name }
          state
          items { id productPack { id product { id sku } } }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const availableFilters: ReportFilter[] = [
  {
    field:                 'createdAt',
    label:                 t('Created at'),
    hideLabelWhenSelected: true,
    operators:             [
      createDatesRangeOperator(),
    ],
  },
  ...props.extraFilters,
  {
    field:                 'state',
    label:                 t('State'),
    hideLabelWhenSelected: true,
    operators:             [
      reports.createList('in', enumValues(SupplyStateEnum, v => t(`supplyState.${v}`)), t('reportFilterOperator.in')),
    ],
  },
  {
    field:                 'product',
    label:                 t('SKU'),
    hideLabelWhenSelected: true,
    operators:             [
      reports.createPrimitive('=', 'string'),
      reports.createPrimitive('in', 'multiString', {
        label: t('reportFilterOperator.in'),
      }),
    ],
  },
];

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/counts.yaml"></i18n>
