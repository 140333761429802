<template>
  <QPage padding>
    <div
      v-if="canDeleteOperationsHistory"
      class="q-px-none q-gutter-x-sm wrap-xs q-pb-sm"
    >
      <EraseWarehouseData />
      <StocksExcelImportExport />
    </div>
    <div class="row q-col-gutter-md">
      <div class="col-12 col-md-4 column no-wrap">
        <OtherWarehouseSettings />
        <MeasurementUnitsSettings class="q-mt-md" />
        <CarriersSettings class="q-mt-md" />
      </div>
      <div class="col-12 col-md-4">
        <SuppliersSettings />
        <ProductCategoriesSettings class="q-mt-md" />
      </div>
      <div class="col-12 col-md-4">
        <PrintersList />
        <AccountingModelsList class="q-mt-md" />
      </div>
    </div>
  </QPage>
</template>

<script setup lang="ts">

import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useCan from '@/composables/useCan';
import AccountingModelsList from '@/views/WarehouseSettings/AccountingModelsList.vue';
import CarriersSettings from '@/views/WarehouseSettings/CarriersSettings.vue';
import EraseWarehouseData from '@/views/WarehouseSettings/EraseWarehouseData.vue';
import OtherWarehouseSettings from '@/views/WarehouseSettings/OtherWarehouseSettings.vue';
import PrintersList from '@/views/WarehouseSettings/PrintersList.vue';
import ProductCategoriesSettings from '@/views/WarehouseSettings/ProductCategoriesSettings.vue';
import SuppliersSettings from '@/views/WarehouseSettings/SuppliersSettings.vue';
import { useI18n } from 'vue-i18n';
import { Ability } from '@/types/backend';
import MeasurementUnitsSettings from './MeasurementUnitsSettings.vue';
import StocksExcelImportExport from '@/views/Storage/StocksExcelImportExport.vue';

const { t } = useI18n();

useBreadcrumbs([t('Warehouse Settings')]);

const canDeleteOperationsHistory = useCan(Ability.DeleteOperationsHistory);

</script>

<i18n lang="yaml">
ru:
  Warehouse Settings: Настройки склада
en:
  Warehouse Settings: Warehouse Settings
</i18n>
