<template>
  <ConfirmsAction
    should-prompt
    @confirmed="restoreItems()"
  >
    <template #activator="{ prompt }">
      <QBtn
        v-if="items.some(item => item.deletedAt !== null)"
        class="bg-white text-black q-ml-sm"
        @click="prompt()"
      >
        {{ t('Restore') }}
      </QBtn>
    </template>
    <QCardSection>
      <slot name="confirmation" />
    </QCardSection>
  </ConfirmsAction>
</template>

<script setup lang="ts">

import ConfirmsAction from '@/components/ConfirmsAction.vue';
import { useI18n } from 'vue-i18n';

type TItem = object;

const { t } = useI18n();

const props = defineProps<{
  items: TItem[];
  restore: (item: TItem) => void;
}>();

const emit = defineEmits<{
  (e: 'restored'): void;
}>();

async function restoreItems(): Promise<void> {
  await Promise.all(props.items.map(item => props.restore(item)));
  emit('restored');
}

</script>

