<template>
  <QSelect
    v-model="selectedPrinter"
    :options="printers"
    :loading="printersLoading"
    :label="t('Select printer')"
    option-label="displayName"
    option-value="id"
    :bottom-slots="!hideHardwareSettingsLink"
  >
    <template #prepend>
      <PrinterStatus :printer="selectedPrinter" />
    </template>
    <template #option="scope">
      <QItem v-bind="scope.itemProps">
        <QItemSection avatar>
          <PrinterStatus
            :printer="scope.opt"
            size="sm"
          />
        </QItemSection>
        <QItemSection>
          {{ scope.opt.displayName }}
        </QItemSection>
      </QItem>
    </template>
    <template
      v-if="!hideHardwareSettingsLink && canEditWarehouseSettings"
      #hint
    >
      <RouterLink :to="{ name: ROUTES.WAREHOUSE_SETTINGS }">
        {{ t('Go to Hardware Settings') }}
      </RouterLink>
    </template>
  </QSelect>
</template>

<script setup lang="ts">

import PrinterStatus from '@/components/PrinterStatus.vue';
import useCan from '@/composables/useCan';
import useDocumentsPrinting from '@/composables/useDocumentsPrinting';
import type { PrinterUnion } from '@/types';
import { Ability } from '@/types/backend';
import { onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const {
  selectedPrinter,
  printers,
  printersLoading,
  loadPrinters,
} = useDocumentsPrinting();

defineProps<{
  hideHardwareSettingsLink?: boolean;
}>();

const emit = defineEmits<{
  (e: 'select', printer: PrinterUnion | null): void;
}>();

watch(selectedPrinter, printer => {
  emit('select', printer);
}, { immediate: true });

onMounted(loadPrinters);

const canEditWarehouseSettings = useCan(Ability.EditWarehouseSettings);

</script>

<i18n lang="yaml">
ru:
  Select printer: Выберите принтер
  Go to Hardware Settings: Перейти в настройки оборудования
en:
  Select printer: Select printer
  Go to Hardware Settings: Go to Hardware Settings
</i18n>

<style scoped lang="scss">

:deep(.q-field__prepend:empty) {
  padding-right: 0;
}

</style>
