<template>
  <FullHeightPage>
    <StocksReport
      storage-prefix="stocksList.report"
      keep-page-in-url
    >
      <template
        v-if="canImport"
        #after-search-string="{ options }"
      >
        <ExcelExport
          :type="ExcelImportTypeEnum.STOCKS"
          :mutation-export="mutationExport"
          :options="options"
        />
        <StocksExportLink />
      </template>
    </StocksReport>
  </FullHeightPage>
</template>

<script setup lang="ts">

import FullHeightPage from '@/components/FullHeightPage.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useCan from '@/composables/useCan';
import { ExcelImportTypeEnum } from '@/graphql/types';
import { Ability } from '@/types/backend';
import StocksExportLink from '@/views/Storage/StocksExportLink.vue';
import StocksReport from '@/views/Storage/StocksReport.vue';
import { useI18n } from 'vue-i18n';
import ExcelExport from '@/views/ExcelExport.vue';
import { gql } from '@urql/vue';

const { t } = useI18n();

useBreadcrumbs(t('Stocks'));

const canImport = useCan(Ability.ImportExcel);

const mutationExport = gql`
  mutation GenerateStocksFileForStocksList($type: ExcelImportTypeEnum!, $searchString: String,$filter: [ReportFilterInput!]) {
    exportFilePath: generateExcelExportFile(type: $type, searchString: $searchString, filter: $filter)
  }
`;

</script>
