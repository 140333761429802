<template>
  <QDialog
    v-if="q.screen.xs"
    :model-value="true"
    maximized
    @update:model-value="$event || emit('cancel')"
  >
    <QCard
      class="column no-wrap"
      style="max-height: 100%"
    >
      <QToolbar class="bg-primary text-white">
        <QBtn
          v-if="editingIndex === null"
          flat
          round
          :icon="filter.field ? 'mdi-arrow-left' : 'mdi-close'"
          @click="back()"
        />
        <QBtn
          v-else
          flat
          round
          icon="mdi-close"
          @click="emit('cancel')"
        />
        <QToolbarTitle>
          <template v-if="!filter.field">
            {{ t('Filter') }}
          </template>
          <template v-else>
            {{ filterLabel(selectedFilter) }}
          </template>
        </QToolbarTitle>

        <FullscreenToggle />
      </QToolbar>
      <QList v-if="!filter.field">
        <QItem
          v-for="f in filters"
          :key="f.field"
          clickable
          @click="filter.field = f.field"
        >
          <QItemSection>
            <QItemLabel>{{ filterLabel(f) }}</QItemLabel>
          </QItemSection>
        </QItem>
      </QList>
      <QCardSection v-if="showDefaultOperatorPicker">
        <QSelect
          v-model="filter.operator"
          :options="selectedFilter.operators"
          :disable="!filter.field"
        />
      </QCardSection>
      <div class="scroll-y hide-when-empty">
        <ValuePicker
          v-model="filter.value"
          :operator="filter.operator"
          :other-filters="otherFilters"
          :mobile="true"
          @save-filter="emit('save', filter)"
          @cancel="back"
        >
          <template
            v-if="filter.operator?.operatorSlot"
            #operator
          >
            <QSelect
              v-model="filter.operator"
              :options="selectedFilter.operators"
              :disable="!filter.field"
            />
          </template>
        </ValuePicker>
      </div>
    </QCard>
  </QDialog>

  <template v-else>
    <QSelect
      v-model="filter.field"
      :options="filters"
      option-value="field"
      :option-label="filterLabel"
      emit-value
      map-options
      :label="t('Filter')"
      dense
      bottom-slots
      style="width: 300px;"
    />
    <QSelect
      v-if="showDefaultOperatorPicker"
      v-model="filter.operator"
      :options="selectedFilter.operators"
      :disable="!filter.field"
      dense
      style="width: 300px;"
    />
    <ValuePicker
      v-model="filter.value"
      :operator="filter.operator"
      :other-filters="otherFilters"
      :mobile="false"
    >
      <template
        v-if="filter.operator?.operatorSlot"
        #operator="{ selectProps }"
      >
        <QSelect
          v-model="filter.operator"
          v-bind="selectProps"
          :options="selectedFilter.operators"
          :disable="!filter.field"
        />
      </template>
    </ValuePicker>
    <QBtn
      flat
      round
      icon="mdi-check"
      color="success"
      :disable="hasInvalidValue"
      @click="emit('save', filter)"
    />
    <QBtn
      flat
      round
      icon="mdi-close"
      color="error"
      @click="$emit('cancel')"
    />
  </template>
</template>

<script setup lang="ts">

import FullscreenToggle from '@/components/FullscreenToggle.vue';
import type { MakeMaybe, Scalars } from '@/graphql/types';
import type {
  Filter,
  FilterOperatorBoolean,
  FilterOperatorList,
  FilterOperatorString,
  FilterValue,
  ReportFilter,
  ValuePickerProps,
} from '@/types/reports';
import { useQuasar } from 'quasar';
import { type Component, computed, h, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import ValuePickerBoolean from './BaseTable/ValuePicker/ValuePickerBoolean.vue';
import ValuePickerDate from './BaseTable/ValuePicker/ValuePickerDate.vue';
import ValuePickerList from './BaseTable/ValuePicker/ValuePickerList.vue';
import ValuePickerMultiString from './BaseTable/ValuePicker/ValuePickerMultiString.vue';
import ValuePickerString from './BaseTable/ValuePicker/ValuePickerString.vue';

const { t } = useI18n();

const q = useQuasar();

const props = defineProps<{
  filters: ReportFilter[];
  otherFilters: Filter[];
  filterDraft: Filter | null;
  editingIndex: number | null;
}>();

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'save', filter: Filter): void;
}>();

const filter = reactive<MakeMaybe<Filter, keyof Filter>>({
  field: null,
  operator: null,
  value: null,
});

watch(() => props.filterDraft, function(newDraft): void {
  if (!newDraft) {
    return;
  }
  Object.assign(filter, newDraft);
}, { immediate: true });

const selectedFilter = computed(() => props.filters.find(f => f.field === filter.field)!);

function filterLabel(filter: ReportFilter): string {
  return filter.label;
}

const hideOperatorIfSingle = computed(() => [
  '=',
  'in',
  'is null',
  'is not null',
  'is same date',
  'in date range',
].includes(String(filter.operator?.value))
  && selectedFilter.value.operators.length === 1);

const showDefaultOperatorPicker = computed(() => filter.field && !hideOperatorIfSingle.value && !filter.operator?.operatorSlot);

type ValuePickerPropsString = ValuePickerProps<FilterValue<string>, FilterOperatorString>;
type ValuePickerPropsBoolean = ValuePickerProps<FilterValue<boolean>, FilterOperatorBoolean>;
type ValuePickerPropsList<TModel> = ValuePickerProps<FilterValue<TModel>[], FilterOperatorList>;
type ValuePickerPropsCombined<TModel> = ValuePickerProps<FilterValue<TModel> | FilterValue<TModel>[], FilterOperatorList>;

const ValuePicker: Component<ValuePickerProps> = (props, { slots }) => {
  if (!filter.field || !filter.operator) {
    return null;
  }

  switch (filter.operator.type) {
    case 'string':
      return h(ValuePickerString, props as ValuePickerPropsString, slots);
    case 'multiString':
      return h(ValuePickerMultiString, props as ValuePickerPropsList<string>, slots);
    case 'boolean':
      return h(ValuePickerBoolean, props as ValuePickerPropsBoolean, slots);
    case 'date':
      return h(ValuePickerDate, props as ValuePickerPropsString, slots);
    case 'list':
    case 'multiList':
      return h(ValuePickerList, props as ValuePickerPropsCombined<Scalars['ID']>, slots);
    case 'custom':
      return h(filter.operator.render, props, slots);
    default:
      throw new Error(`Unsupported field type: ${filter.operator?.type}`);
  }
};

const hasInvalidValue = computed((): boolean => {
  return !filter.value
    || (Array.isArray(filter.value) && filter.value.length === 0);
});

watch(() => filter.field, function filterFieldChanged(): void {
  if (!selectedFilter.value) {
    return;
  }
  filter.operator = selectedFilter.value.operators[0];
});

watch(() => filter.operator, (op) => {
  if (!op || !op.keepFilterValue) {
    filter.value = null;
  }
});

function back(): void {
  if (filter.operator) {
    filter.field = null;
    filter.operator = null;
  } else if (filter.field) {
    filter.field = null;
    filter.operator = null;
  } else {
    emit('cancel');
  }
}

</script>

<i18n lang="yaml">
ru:
  Filter: Фильтр
en:
  Filter: Filter
</i18n>
