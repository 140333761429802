<template>
  <QPage padding>
    <QCard>
      <BaseAlert
        v-if="getPrimaryError()"
        type="error"
      >
        {{ getPrimaryError() }}
      </BaseAlert>
      <QCardSection
        v-if="fetching && !rack.id"
        class="text-center"
      >
        <QCircularProgress
          indeterminate
          size="150px"
          font-size="0.1em"
          color="primary"
          show-value
        >
          {{ t('Loading') }}
        </QCircularProgress>
      </QCardSection>
      <template v-else>
        <CardTitle>
          <span :class="{ 'text-grey': !fullName }">
            {{ t('Rack {name}', { name: fullName }) || t('New Rack') }}
          </span>

          <QSpace />

          <template v-if="!isNew">
            <QBtn
              flat
              round
              icon="mdi-delete"
              color="error"
              :title="t('Delete')"
              :loading="hasProgress('deleting')"
              @click="deleteRack()"
            />
          </template>
        </CardTitle>
        <QCardSection>
          <div class="row q-col-gutter-md">
            <div class="col-1">
              <QInput
                v-model="rack.prefix"
                :label="t('Prefix')"
              />
            </div>
            <div class="col-1">
              <QInput
                v-model="rack.row"
                :label="t('Row')"
                :rules="[notEmptyRule]"
              />
            </div>
            <div class="col-1">
              <QInput
                v-model="rack.name"
                :label="t('Number')"
                :rules="[notEmptyRule]"
              />
            </div>
            <div class="col-9" />
          </div>
        </QCardSection>

        <QSeparator />

        <RackCells
          :rack="rack"
          @add-cells="addCells($event)"
          @remove-cells="removeCells($event)"
          @save-with-callback="save().then(() => $event())"
        />
      </template>

      <QSeparator />

      <QCardActions>
        <QBtn
          exact
          :to="{ name: ROUTES.RACKS_LIST }"
          icon="mdi-arrow-left"
        >
          {{ t('Back') }}
        </QBtn>

        <QSpace />

        <ConfirmsAction
          v-if="!isNew"
          :confirm-text="t('Clone')"
          should-prompt
          @confirmed="cloneRack"
        >
          <template #title>
            {{ t('Clone rack?') }}
          </template>
          <template #activator="{ prompt }">
            <QBtn
              color="success"
              icon="mdi-content-copy"
              :loading="cloning"
              @click="prompt"
            >
              {{ t('Clone') }}
            </QBtn>
          </template>
        </ConfirmsAction>
        <QBtn
          color="success"
          icon="mdi-content-save"
          :loading="hasProgress('applying')"
          :disable="!canSave"
          @click="save()"
        >
          {{ t('Apply') }}
        </QBtn>
        <QBtn
          color="primary"
          icon="mdi-content-save"
          :disabled="!canSave"
          :loading="hasProgress('saving')"
          @click="saveAndGoToList()"
        >
          {{ t('Save') }}
        </QBtn>
      </QCardActions>
    </QCard>
  </QPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import CardTitle from '@/components/CardTitle.vue';
import ConfirmsAction from '@/components/ConfirmsAction.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useErrorHandling from '@/composables/useErrorHandling';
import useProgressHandling from '@/composables/useProgressHandling';
import useValidationRules from '@/composables/useValidationRules';
import type { MutationCloneRackArgs } from '@/graphql/types';
import type { Cell, MutationSaveRackArgs, Rack, Warehouse } from '@/graphql/types';
import RackCells from '@/views/Racks/RackEdit/RackCells.vue';
import { gql, useClientHandle, useMutation, useQuery } from '@urql/vue';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const router = useRouter();

const { client: urql } = useClientHandle();

const {
  fillErrorsFromGraphQLError,
  setPrimaryError,
  getPrimaryError,
  clearErrors,
} = useErrorHandling();

const { progressStarted, hasProgress } = useProgressHandling<'deleting' | 'saving' | 'applying'>();

const { notEmptyRule } = useValidationRules();

const props = defineProps<{
  id?: number;
}>();

useBreadcrumbs([
  t('Racks'),
  props.id ? t('Edit') : t('New'),
]);

const rackFragment = gql`
  fragment RackForCard on Rack {
    id
    prefix
    row
    name
    cells {
      id
      createdAt
      name
      barcode
      cellsArea { id name }
      isEmpty
    }
  }
`;

const { data, error, fetching } = useQuery<{ rack: Rack }>({
  query:     gql`
    ${rackFragment}
    query GetRack($id: ID!) { rack(id: $id) {
      ...RackForCard
    } }
  `,
  variables: computed(() => ({
    id: props.id,
  })),
  pause:     computed(() => !props.id),
});
watch(error, fillErrorsFromGraphQLError);
watch(data, data => {
  rack.value = data!.rack;
});
const rack = ref<Rack>({
  id:        null,
  prefix:    null,
  row:       '',
  name:      '',
  cells:     [],
  warehouse: {} as Warehouse,
});

// noinspection NestedConditionalExpressionJS
const fullName = computed(() => rack.value.row && rack.value.name
  ? `${rack.value.prefix ? `${rack.value.prefix}-` : ''}${rack.value.row}-${rack.value.name}`
  : null);

const isNew = computed(() => !rack.value.id);

async function deleteRack(): Promise<void> {
  if (rack.value.cells.some(c => !c.isEmpty)) {
    setPrimaryError(t('Rack has non empty Cells'));
    return;
  }

  const done = progressStarted('deleting');

  const { error } = await urql.mutation(
    gql`mutation DeleteRack($id: ID!) { deleteRack(id: $id) }`,
    { id: rack.value.id },
  );

  done();

  if (error) {
    fillErrorsFromGraphQLError(error);
  } else {
    await router.push({ name: ROUTES.RACKS_LIST });
  }
}

const canSave = computed(() => rack.value.row && rack.value.name
  && rack.value.cells.every(c => c.name));

async function doSave(): Promise<boolean> {
  clearErrors();

  const { error, data } = await urql.mutation<{ rack: Rack }, MutationSaveRackArgs>(
    gql`
      ${rackFragment}

      mutation SaveRack(
        $id: ID,
        $prefix: String,
        $row: String!,
        $name: String!,
        $cells: [RackCellInput!]!
      ) {
        rack: saveRack(id: $id, prefix: $prefix, row: $row, name: $name, cells: $cells) {
          ...RackForCard
        }
      }
    `,
    {
      id:     rack.value.id,
      prefix: rack.value.prefix,
      row:    rack.value.row,
      name:   rack.value.name,
      cells:  rack.value.cells?.map(c => ({
        name:        c.name!,
        cellsAreaId: c.cellsArea.id,
      })) ?? [],
    },
  );

  if (error) {
    fillErrorsFromGraphQLError(error);
    return false;
  }

  const newId = Number(data!.rack.id);

  if (newId && props.id !== newId) {
    await router.push({
      name:   ROUTES.RACKS_EDIT,
      params: { id: String(newId) },
    });
  } else {
    rack.value = data!.rack;
  }

  return true;
}

async function save(): Promise<void> {
  const done = progressStarted('applying');

  await doSave();

  done();
}

async function saveAndGoToList(): Promise<void> {
  const done = progressStarted('saving');

  if (await doSave()) {
    // noinspection ES6MissingAwait
    router.push({ name: ROUTES.RACKS_LIST });
  }

  done();
}

function addCells(cells: Cell[]) {
  rack.value.cells.push(...cells);
}

function removeCells(cells: Cell[]) {
  for (const cellToRemove of cells) {
    const index = rack.value.cells.indexOf(cellToRemove);
    if (index >= 0) {
      rack.value.cells.splice(index, 1);
    }
  }
}

const {
  executeMutation: doCloneRack,
  fetching:        cloning,
} = useMutation<{ rack: Rack }, MutationCloneRackArgs>(
  gql`
    mutation CloneRackFromCard($id: ID!) {
      rack: cloneRack(id: $id) { id }
    }
  `
);

async function cloneRack() {
  clearErrors();
  const { data, error } = await doCloneRack({
    id: rack.value.id,
  });

  if (error) {
    fillErrorsFromGraphQLError(error);
  } else {
    router.push({ name: ROUTES.RACKS_EDIT, params: { id: data!.rack.id } });
  }
}

</script>

<i18n lang="yaml">
ru:
  New Rack: Новый стеллаж
  Prefix: Префикс
  Row: Проход
  Rack {name}: Стеллаж {name}
  Rack has non empty Cells: Стеллаж имеет не пустые ячейки
  Clone: Дублировать
  Clone rack?: Дублировать стеллаж?
en:
  New Rack: New Rack
  Prefix: Prefix
  Row: Row
  Rack {name}: Rack {name}
  Rack has non empty Cells: Rack has non empty Cells
  Clone: Clone
  Clone rack?: Clone rack?
</i18n>
