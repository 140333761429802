<template>
  <div class="col column no-wrap">
    <ScanStoragesPair
      :expected-storages="store.storagesPairsToScan[0] ?? null"
      :search-storage="searchStorage"
      :storage-to-rules="storageToRules"
      :disable="completing"
      class="col"
      @scan:storage-from="handleScanStorageFrom"
      @scan:storage-to="handleScanStorageTo"
    />

    <PrimaryErrorBanner animated />
    <ButtonsRow v-slot="{ buttonProps }">
      <KeyboardToggleButton v-bind="buttonProps" />
      <QBtn
        v-bind="buttonProps"
        :disable="completing"
        icon="mdi-close-circle-outline"
        @click="emit('cancel')"
      >
        {{ t('Cancel') }}
      </QBtn>
      <QBtn
        v-if="store.transfer!.task"
        v-bind="buttonProps"
        icon="mdi-format-list-checks"
        @click="emit('show-task')"
      >
        {{ t('Task') }}
      </QBtn>
      <TransferHistoryButton
        v-bind="buttonProps"
        @click="emit('show-history')"
      />
      <TransferCompleteButton
        v-if="store.movements.length > 0"
        v-bind="buttonProps"
        :loading="completing"
        @complete="complete"
      />
    </ButtonsRow>
  </div>
</template>

<script setup lang="ts">
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import ScanStoragesPair from '@/components/ScanStoragesPair.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useOmniInput from '@/composables/useOmniInput';
import useSpeaker from '@/composables/useSpeaker';
import StockForTransfer from '@/graphql/fragments/StockForTransfer';
import StorageForTransfer from '@/graphql/fragments/StorageForTransfer';
import StorageUnitForTransfer from '@/graphql/fragments/StorageUnitForTransfer';
import { type Cell, type Container, type Stock, type Storage } from '@/graphql/types';
import navigateBack from '@/helpers/navigateBack';
import ROUTES from '@/router/routeNames';
import useTransferProcessStore from '@/stores/transferProcess';
import type { StoragesScanStep } from '@/types';
import TransferCompleteButton from '@/views/Mobile/Transfer/TransferCompleteButton.vue';
import TransferHistoryButton from '@/views/Mobile/Transfer/TransferHistoryButton.vue';
import { gql, useClientHandle } from '@urql/vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const speaker = useSpeaker();

const { fillErrorsFromGraphQLError, clearErrors, PrimaryErrorBanner } = useErrorHandling();

const { t } = useI18n();

const store = useTransferProcessStore();

const emit = defineEmits<{
  'cancel': [];
  'show-task': [];
  'show-history': [];
}>();

const storageFromScanQuery = gql`
  ${StorageForTransfer}
  query GetStorageByBarcodeForTransfer($barcode: String!) {
    storage: storageByBarcode(barcode: $barcode) { ...StorageForTransfer }
  },
`;

const storageToScanQuery = gql`
  ${StorageForTransfer}
  ${StorageUnitForTransfer}
  ${StockForTransfer}
  query GetSecondStorageByBarcodeForTransfer($transferId: ID!, $barcode: String!) {
    storage: storageByBarcode(barcode: $barcode) {
      ...StorageForTransfer
    }
    stocks: stocksByStorageBarcode(transferId: $transferId, barcode: $barcode) {
      ...StockForTransfer
    }
  }
`;

const { client: urql } = useClientHandle();

async function searchStorage(barcode: string, step: StoragesScanStep) {
  const { data, error } = await urql.query<{ storage: Storage | null; stocks?: Stock[] }>(step === 'from' ? storageFromScanQuery : storageToScanQuery, {
    barcode,
    transferId: store.transfer!.id,
  });

  if (error) {
    throw error;
  }

  if (data!.stocks) {
    store.stocksInStorageTo = data!.stocks;
  }

  return data!.storage;
}

function handleScanStorageFrom(storage: Storage) {
  store.storageFrom = storage as Container | Cell;
  store.updateTransferState();
  store.availableStorages.push(storage);
}

function handleScanStorageTo(storage: Storage) {
  store.storageTo = storage;
  store.updateTransferState();
  store.availableStorages.push(storage);
}

const completing = ref(false);

async function complete() {
  clearErrors();
  completing.value = true;

  const { error } = await store.completeTransfer();

  if (error) {
    fillErrorsFromGraphQLError(error);
    completing.value = false;
    return;
  }

  speaker.speak(t('Completed'));

  completing.value = false;

  navigateBack({ name: ROUTES.TRANSFER_DASHBOARD });
}

const storageToRules = [
  store.validation.storageToCellTypeRule,
  store.validation.storageToContainerTypeRule,
  store.validation.storageFromCellTypeRule,
  store.validation.storageFromContainerTypeRule,
];

const { KeyboardToggleButton }  = useOmniInput({ skip: true });
</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/speaking.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>
