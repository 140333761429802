<template>
  <CellEditDesktop v-if="!preferences.terminalMode" />
  <CellEditMobile v-else />
</template>

<script setup lang="ts">

import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useLocalPreferences from '@/composables/useLocalPreferences';
import CellEditDesktop from '@/views/CellEditDesktop.vue';
import CellEditMobile from '@/views/CellEditMobile.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const preferences = useLocalPreferences();

useBreadcrumbs(t('Cells'));

</script>
