<template>
  <QDialog
    v-model="isOpen"
    :persistent="completing"
  >
    <QCard>
      <CardTitle>
        {{ title }}
      </CardTitle>

      <QSeparator />

      <PrimaryErrorBanner animated />

      <QCardActions>
        <QBtn
          :label="t('No')"
          :disable="completing"
          @click="isOpen = false"
        />
        <QBtn
          :label="label"
          color="primary"
          icon="mdi-check-all"
          :loading="completing"
          @click="completeShipment"
        />
      </QCardActions>
    </QCard>
  </QDialog>
  <QBtn
    :label="label"
    icon="mdi-check-all"
    :loading="loadingSelections"
    :disable="!canCompleteShipment"
    @click="isOpen = true"
  />
</template>

<script setup lang="ts">

import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { gql, useMutation, useQuery } from '@urql/vue';
import type {
  QuerySelectionsByShipmentAndStatesArgs,
  Selection,
  Shipment,
} from '@/graphql/types';
import { SelectionStateEnum, ShipmentStateEnum } from '@/graphql/types';
import useErrorHandling from '@/composables/useErrorHandling';
import CardTitle from '@/components/CardTitle.vue';

const { t } = useI18n();

// noinspection LocalVariableNamingConventionJS
const { fillErrorsFromGraphQLError, clearErrors, PrimaryErrorBanner } = useErrorHandling();

const props = defineProps<{
  shipment: Shipment;
  title: string;
  label: string;
}>();

const emit = defineEmits<{
  completed: [];
}>();

const isOpen = ref(false);

watch(isOpen, clearErrors);

const {
  executeMutation: doComplete,
  fetching: completing,
} = useMutation(gql`
  mutation CompleteShipment($shipmentId: ID!) {
    completeShipment(shipmentId: $shipmentId)
  }
`);

const {
  data: selectionsData,
  fetching: loadingSelections,
} = useQuery<{ selections: Selection[] }, QuerySelectionsByShipmentAndStatesArgs>({
  query: gql`
    query GetSelectionsByOrderForCompletionDialog($shipmentId: ID!, $states: [SelectionStateEnum!]!) {
      selections: selectionsByShipmentAndStates(
        shipmentId: $shipmentId,
        states: $states
      ) { id state }
    }
  `,
  variables: {
    shipmentId: props.shipment.id,
    states:     [
      SelectionStateEnum.CREATED,
      SelectionStateEnum.SELECTING,
      SelectionStateEnum.SELECTED,
    ],
  },
  pause: computed(() => !props.shipment.id),
});

const canCompleteShipment = computed(() => props.shipment.state !== ShipmentStateEnum.COMPLETED
&& selectionsData.value?.selections.length === 0);

async function completeShipment() {
  clearErrors();

  const { error } = await doComplete({ shipmentId: props.shipment.id });

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  isOpen.value = false;

  emit('completed');
}

</script>
