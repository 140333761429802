<template>
  <QCard>
    <CardTitle>
      {{ t('Create API Token') }}
      <template #subtitle>
        {{ t('API tokens allow third-party services to authenticate') }}
      </template>
    </CardTitle>

    <PrimaryErrorBanner />

    <QCardSection>
      <QInput
        v-model="tokenName"
        :label="t('Name')"
        :rules="[notEmptyRule]"
        autofocus
        counter
        v-bind="qErrorsFor('name')"
      >
        <template #counter>
          <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
          {{ tokenName.length }} / 255
        </template>
      </QInput>
    </QCardSection>

    <QSeparator />
    <QCardActions>
      <QSpace />

      <QBtn
        color="primary"
        :loading="creatingToken"
        :disable="!tokenName"
        icon="mdi-key-plus"
        @click="createApiToken"
      >
        {{ t('Create') }}
      </QBtn>
    </QCardActions>

    <CreatedTokenDialog v-model:token="createdToken" />
  </QCard>
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import {
  AccessTokenAbilityEnum,
  type MutationCreateAccessTokenArgs,
} from '@/graphql/types';
import CreatedTokenDialog from '@/views/API/ApiTokenManager/CreatedTokenDialog.vue';
import { gql, useMutation } from '@urql/vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import useValidationRules from '@/composables/useValidationRules';

const { t } = useI18n();

const { fillErrorsFromGraphQLError, PrimaryErrorBanner, qErrorsFor } = useErrorHandling();
const { notEmptyRule } = useValidationRules();

const emit = defineEmits<{
  (e: 'created'): void;
}>();

const tokenName = ref('');

const createdToken = ref('');

const {
  executeMutation: doCreateToken,
  fetching: creatingToken,
} = useMutation<{ token: string }, MutationCreateAccessTokenArgs>(gql`
  mutation CreateAccessToken($name: String, $permissions: [AccessTokenAbilityEnum!]!) {
    token: createAccessToken(name: $name, permissions: $permissions)
  }
`);

async function createApiToken(): Promise<void> {
  const { data, error } = await doCreateToken({
    name: tokenName.value,
    permissions: [AccessTokenAbilityEnum.API],
  });

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }
  createdToken.value = data!.token;
  tokenName.value = '';
  emit('created');
}
</script>

<i18n lang="yaml">
en:
  Please copy your new API token: >
    Please copy your new API token. For your security, it won''t be shown again.
  Create API Token: Create API Token
  API tokens allow third-party services to authenticate: >
    API tokens allow third-party services to authenticate on your behalf
  Permissions: Permissions
ru:
  Please copy your new API token: >
    Пожалуйста, скопируйте свой новый API токен. Для вашей безопасности он не будет показан снова
  Create API Token: Создать новый API токен
  API tokens allow third-party services to authenticate: >
    API токены позволяют сторонним службам работать с нашим приложением от вашего имени
  Permissions: Разрешения
</i18n>
