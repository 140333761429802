<template>
  <div class="q-pa-sm">
    <QSelect
      v-model="selectedLabelCountOption"
      :options="labelCountOptions"
      :label="t('Label count')"
    />
  </div>
  <div class="q-pa-sm">
    <VirtualKeyboardPolicyContext
      v-if="selectedLabelCountOption === t('Specific amount')"
      #="{ policy, toggle, shouldShowToggle }"
    >
      <QInput
        ref="countField"
        v-model.number="printCountForInput"
        type="number"
        step="1"
        min="1"
        :rules="[notEmptyRule, positiveIntegerOrNullRule]"
        :label="t('Count')"
        :virtualkeyboardpolicy="policy"
        autofocus
      >
        <template
          v-if="shouldShowToggle"
          #append
        >
          <VirtualKeyboardToggleBtn @toggle="countField.focus();" />
        </template>
      </QInput>
    </VirtualKeyboardPolicyContext>
    <QSelect
      v-else-if="productPack && selectedLabelCountOption === t('By supply')"
      v-model="selectedSupply"
      :loading="fetchingEntities"
      :options="suppliesOptions"
      :option-label="supplyOptionLabel"
      :label="t('Supply')"
    />
    <QSelect
      v-else-if="productPack && selectedLabelCountOption === t('By carrier orders')"
      v-model="selectedCarrierOrderCount"
      :loading="fetchingEntities"
      :options="carrierOrderCountOptions"
      :option-label="carrierPrintCountsOptions"
      :label="t('Shipment Order')"
      class="ellipsis"
    />
    <QSelect
      v-else-if="productPack"
      v-model="selectedOrder"
      :loading="fetchingEntities"
      :options="ordersOptions"
      :option-label="orderOptionLabel"
      :label="t('Shipment Order')"
      class="ellipsis"
    />
    <BlurredInput
      v-if="selectedLabelCountOption === t('Specific amount')"
    />
  </div>
</template>

<script setup lang="ts">

import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type {
  ProductBarcodesCountToPrint,
  ProductPack,
  QueryProductBarcodesCountsByCarriersForPrintArgs,
  Supply,
  QueryCustomerOrdersForProductPackPrintArgs,
  QuerySuppliesForProductPackPrintArgs, SupplyOrShipmentPrintCount,
} from '@/graphql/types';
import useDocumentsPrintingState from '@/views/useDocumentsPrintingState';
import { gql, useClientHandle } from '@urql/vue';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import useValidationRules from '@/composables/useValidationRules';
import {
  VirtualKeyboardPolicyContext,
  VirtualKeyboardToggleBtn,
} from '@/composables/useVirtualKeyboardPolicy';
import useOmniInput from '@/composables/useOmniInput';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const { client: urql } = useClientHandle();

const { notEmptyRule, positiveIntegerOrNullRule } = useValidationRules();

const props = defineProps<{
  productPack: ProductPack;
  useBlurredInput?: boolean;
}>();

const emit = defineEmits<{
  (e: 'countChanged', count: number): void;
  (e: 'fetching', value: boolean);
}>();

const countField = ref();

const supplyFragment = gql`
  fragment SupplyForProductPackPrint on SupplyOrShipmentPrintCount {
      document {
      ...on SupplierDelivery{
        id
        externalId
        createdAt
      }
    }
    count
  }
`;

const orderFragment = gql`
  fragment CustomerOrderForProductPackPrint on SupplyOrShipmentPrintCount {
    document {
      ...on CustomerOrder{
        id
        externalId
        carrier {
          id
          name
        }
        customer {
          id
          name
        }
      }
    }
    count
  }
`;

onMounted(async () => {
  loadSupplies();
  loadOrders();
  loadCarrierOrdersCount();
});

const fetchingEntities = ref(false);

watch(fetchingEntities, (value) => emit('fetching', value));

const input = useOmniInput({
  skip: computed(() => !props.useBlurredInput),
  replace: value => value.replace(/\D/g, '')
});
const { BlurredInput, value: inputValue } = input;

watch(inputValue, value => {
  printCount.value = Number.parseInt(value);
});

async function loadSupplies() {
  fetchingEntities.value = true;

  const { data: suppliesData } = await urql.query<{ supplies: Supply[] }, QuerySuppliesForProductPackPrintArgs>(
    gql`
        query GetSuppliesPrintCountsByProduct($productId: ID!, $lastUsedSupplyId: ID) {
        supplies: suppliesForProductPackPrint(productId: $productId, lastUsedSupplyId: $lastUsedSupplyId) {
            ...SupplyForProductPackPrint
        }
      }
      ${supplyFragment}
    `,
    {
      productId:               props.productPack.product.id,
      lastUsedSupplyId: state.value.lastProductPackSupplyId,
    },
  );

  suppliesOptions.value = suppliesData!.supplies;

  if (!state.value.lastProductPackSupplyId) {
    fetchingEntities.value = false;
    return;
  }

  selectedSupply.value   = suppliesOptions.value.find(o => o.document.id === state.value.lastProductPackSupplyId);
  fetchingEntities.value = false;
}

async function loadOrders() {
  fetchingEntities.value = true;

  const { data: ordersData } = await urql.query<{ orders: SupplyOrShipmentPrintCount[] }, QueryCustomerOrdersForProductPackPrintArgs>(
    gql`
        query GetSupplyOrShipmentPrintCountByProduct($productId: ID!, $lastUsedCustomerOrderId: ID) {
        orders: customerOrdersForProductPackPrint(productId: $productId, lastUsedCustomerOrderId: $lastUsedCustomerOrderId) {
          ...CustomerOrderForProductPackPrint
        }
      }
      ${orderFragment}
    `,
    {
      productId:               props.productPack.product.id,
      lastUsedCustomerOrderId: state.value.lastProductPackOrderId,
    },
  );

  ordersOptions.value = ordersData!.orders;

  if (!state.value.lastProductPackOrderId) {
    fetchingEntities.value = false;
    return;
  }

  selectedOrder.value = ordersOptions.value.find(o => o.document.id === state.value.lastProductPackOrderId);
  fetchingEntities.value = false;
}

const carrierOrderCountOptions  = ref<ProductBarcodesCountToPrint[]>([]);
const selectedCarrierOrderCount = ref<ProductBarcodesCountToPrint | null>(null);

watch(selectedCarrierOrderCount, (value: ProductBarcodesCountToPrint | null) => {
  if (!value) {
    return;
  }
  state.value.lastProductPackCarrierName = value.carrierName;
});

function carrierPrintCountsOptions(carrierPrintCounts: ProductBarcodesCountToPrint) {
  return `${carrierPrintCounts.carrierName ?? t('Not specified')} (${carrierPrintCounts.count})` ;
}

async function loadCarrierOrdersCount() {
  fetchingEntities.value = true;

  const { data: carrierPrintCountsData } = await urql.query<{ carrierPrintCounts: ProductBarcodesCountToPrint[] },
      QueryProductBarcodesCountsByCarriersForPrintArgs>(
        gql`
            query GetCarrierOrderPrintCounts($productId: ID!) {
            carrierPrintCounts: productBarcodesCountsByCarriersForPrint(productId: $productId) {
                carrierName
                count
            }
          }
        `,
        { productId: product.value.id }
      );

  carrierOrderCountOptions.value = carrierPrintCountsData!.carrierPrintCounts;

  if (!state.value.lastProductPackCarrierName) {
    fetchingEntities.value = false;
    return;
  }

  if (carrierOrderCountOptions.value.some(o => o.carrierName === state.value.lastProductPackCarrierName)) {
    selectedCarrierOrderCount.value   = carrierOrderCountOptions.value
      .find(o => o.carrierName === state.value.lastProductPackCarrierName)!;
  }

  fetchingEntities.value = false;
}

const state = useDocumentsPrintingState();

const printCount = ref<number>(function () {
  return state.value.lastProductPackLabelCountOption === t('Specific amount') ? state.value.lastProductPackLabelCount ?? 0 : 0;
}());

const product = computed(() => props.productPack.product);

const labelCountOptions = computed((): string[] =>
// Для составных упаковок отображаем только вариант "Указанное количество"
  props.productPack.smallerProductPack === null
    ? [t('Specific amount'), t('By supply'), t('By order'), t('By carrier orders')]
    :[t('Specific amount')]
);

const selectedLabelCountOption = ref<string>(state.value.lastProductPackLabelCountOption ?? t('Specific amount'));

watch(selectedLabelCountOption, (value: string) => {
  state.value.lastProductPackLabelCountOption = value;
});

watch([selectedLabelCountOption, selectedCarrierOrderCount], ([selectedLabelCountOption, selectedCarrierOrderCount]) => {
  if (selectedLabelCountOption === t('By carrier orders') && selectedCarrierOrderCount) {
    printCount.value = selectedCarrierOrderCount.count!;
  }
});

const suppliesOptions = ref<SupplyOrShipmentPrintCount[]>([]);
const selectedSupply = ref<SupplyOrShipmentPrintCount | null>(null);

function supplyOptionLabel(supply: SupplyOrShipmentPrintCount) {
  return `${t('{id} dated {date}', {
    id: supply.document.externalId,
    date: formatDate(supply.document.createdAt, FORMATS.DATE),
  })} (${supply.count} ${props.productPack.measurementUnit.shortName})`;
}

watch(selectedSupply, (value: SupplyOrShipmentPrintCount | null) => {
  if (!value) {
    return;
  }
  state.value.lastProductPackSupplyId = value.document.id;
});

watch([selectedLabelCountOption, selectedSupply], ([selectedLabelCountOption, selectedSupply]) => {
  if (selectedLabelCountOption === t('By supply') && selectedSupply) {
    printCount.value = selectedSupply.count;
  }
});

watch(printCount, (printCount) => {
  state.value.lastProductPackLabelCount = printCount;
  emit('countChanged', printCount);
}, { immediate: true });

const printCountForInput = computed({
  get: () => printCount.value,
  set: (value) => {
    printCount.value = value || 0;
  },
});

const ordersOptions = ref<SupplyOrShipmentPrintCount[]>([]);
const selectedOrder = ref<SupplyOrShipmentPrintCount | null>(null);

watch(selectedOrder, (value: SupplyOrShipmentPrintCount | null) => {
  if (!value) {
    return;
  }
  state.value.lastProductPackOrderId = value.document.id;
});

watch([selectedLabelCountOption, selectedOrder], ([selectedLabelCountOption, selectedOrder]) => {
  if (selectedLabelCountOption === t('By order') && selectedOrder) {
    printCount.value = selectedOrder.count;
  }
});

function orderOptionLabel(order: SupplyOrShipmentPrintCount) {
  return `${order.document.externalId} ${order.document.carrier?.name ?? ''} ${order.document.customer?.name ?? ''}`
      + `(${order.count} ${props.productPack.measurementUnit.shortName})`;
}
</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/printing.yaml"></i18n>

<i18n lang="yaml">
ru:
  Primary: Основной
  Select barcode type: Выберите тип ШК
en:
  Primary: Primary
  Select barcode type: Select barcode type
</i18n>
