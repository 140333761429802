<template>
  <HorizontalPropsList
    v-if="!hideHeader"
    v-slot="{ item }"
  >
    <component
      :is="item"
      :caption="t('Number')"
    >
      {{ transfer.id }}
    </component>
    <component
      :is="item"
      :caption="t('Created at')"
    >
      {{ formatDate(transfer.createdAt) }}
    </component>
    <component
      :is="item"
      :caption="t('Products')"
    >
      {{ productsCount }}
    </component>
  </HorizontalPropsList>

  <ScrollCarousel
    v-if="storagesSlides.length > 0"
    v-model="storagesCurrentSlide"
    :items="storagesSlides"
    class="q-pa-sm"
    :show-indicators="storagesSlides.length > 1"
  >
    <template #item="{ item: { from, to } }">
      <div class="inline column no-wrap items-stretch">
        <div class="text-center text-h5">
          <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
          {{ from.name }} &rarr; {{ to.name }}
        </div>
      </div>
    </template>
  </ScrollCarousel>
  <FilterableList
    :items="slideMovements"
    with-search
    :item-meets-search-string="movementMeetsSearchString"
    :debounce="0"
    class="col"
  >
    <template #item="{ item }">
      <ProductListItem
        :product="item.storable.productPack.product"
        :to="{ name: ROUTES.PRODUCTS_EDIT, params: { id: item.storable.productPack.product.id } }"
      >
        <template #top-right>
          <span>
            {{ item.amount * item.storable.productPack.quantityInMinMeasurementUnits }}
            {{ item.storable.productPack.minMeasurementUnit.shortName }}
          </span>
        </template>
      </ProductListItem>
    </template>
  </FilterableList>
</template>

<script setup lang="ts">

import FilterableList from '@/components/FilterableList.vue';
import ScrollCarousel from '@/components/Mobile/ScrollCarousel.vue';
import ProductListItem from '@/components/ProductListItem.vue';
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import type { Transfer } from '@/graphql/types';
import HorizontalPropsList from '@/views/Mobile/HorizontalPropsList.vue';
import { uniqBy } from 'ramda';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import movementMeetsSearchString from '@/helpers/movementMeetsSearchString';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const props = defineProps<{
  transfer: Transfer;
  hideHeader?: boolean;
}>();

const storagesSlides = computed(() => uniqBy(
  p => p.key,
  props.transfer!.movements
    .map(m => ({
      from: m.storageFrom!,
      to: m.storageTo!,
      key: `${m.storageFrom!.id}_${m.storageTo!.id}`,
    })),
));

const storagesCurrentSlide = ref(0);

const productsCount = computed(() => uniqBy(
  m => m.storable.productPack.product.id,
  props.transfer!.movements,
).length);

const slideMovements = computed(() => {
  const { to, from } = storagesSlides.value[storagesCurrentSlide.value];
  return props.transfer.movements
    .filter(m => m.storageFrom?.id === from.id && m.storageTo?.id === to.id);
});

</script>
