<template>
  <QDialog
    v-if="queueItem"
    :model-value="true"
    @update:model-value="emit('update:queue-item', null)"
  >
    <QCard v-if="documentLoading">
      <CardTitle>
        <QSkeleton
          type="text"
          width="600px"
        />
      </CardTitle>

      <QCardSection>
        <QSkeleton type="text" />
      </QCardSection>

      <QCardActions>
        <QSpace />

        <QSkeleton type="QBtn" />
      </QCardActions>
    </QCard>
    <QCard
      v-else-if="document"
      style="width: 600px;"
    >
      <CardTitle>{{ document.title }}</CardTitle>

      <QSeparator />

      <!-- Пока просто выводим всю имеющуюся информацию. -->
      <!-- Надо уточнять, в каком виде какой шаблон выводить. -->

      <QCardSection>
        <b>×{{ queueItem.count }}</b>
      </QCardSection>
      <QCardSection>
        <pre>{{ docData }}</pre>
      </QCardSection>

      <QSeparator />

      <QCardActions>
        <QSpace />

        <QBtn @click="emit('update:queue-item', null)">
          {{ t('Close') }}
        </QBtn>
      </QCardActions>
    </QCard>
    <QCard
      v-else
      style="width: 600px;"
    >
      <QCardSection>
        <BaseAlert type="error">
          {{ documentError }}
        </BaseAlert>
      </QCardSection>
    </QCard>
  </QDialog>
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import type { PrintDocument, PrintQueueItem } from '@/graphql/types';
import { gql, useQuery } from '@urql/vue';
import * as R from 'ramda';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import BaseAlert from '@/components/BaseAlert.vue';

const { t } = useI18n();

const props = defineProps<{
  queueItem: PrintQueueItem | null;
}>();

const emit = defineEmits<{
  (e: 'update:queue-item', item: null): void;
}>();

const { data: documentResult, fetching: documentLoading, error: documentError } = useQuery<{ document: PrintDocument }>({
  query: gql`
    query HydratePrintDocument($template: String!, $params: String!) {
      document: printDocument(template: $template, params: $params) {
        title template params
        ...on ProductPackBarcodePrintDocument {
          pack { product { name } }
          barcode
        }
        ...on ContainerBarcodePrintDocument {
          container { id }
        }
      }
    }
  `,
  variables: computed(() => ({
    template: props.queueItem?.document.template,
    params:   props.queueItem?.document.params,
  })),
  pause: computed(() => !props.queueItem),
});
const document = computed<PrintDocument | undefined>(() => documentResult.value?.document);

const docData = computed((): object => {
  return R.omit(['__typename', 'title', 'template', 'params'], document.value);
});

</script>
