<template>
  <CardTitle>
    {{ t('Batches') }}
  </CardTitle>
  <BaseTable
    :fields="headers"
    :rows="batches"
    :loading="fetching"
    storage-prefix="productEdit.batchesList"
  >
    <template #body-cell-name="column">
      <BodyCellLink
        :column="column"
        :to="{ name: ROUTES.BATCHES_EDIT, params: { id: String(column.row.id) } }"
        show-empty
      />
    </template>
  </BaseTable>
</template>

<script setup lang="ts">

import BaseTable from '@/components/BaseTable.vue';
import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import CardTitle from '@/components/CardTitle.vue';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type { Batch, Product, QueryProductBatchesArgs } from '@/graphql/types';
import ROUTES from '@/router/routeNames';
import { gql, useQuery } from '@urql/vue';
import type { QTableColumn } from 'quasar';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { dateFormatter } = useLocalizedFormatters();

const props = defineProps<{
  product: Product;
}>();

const { data, fetching, executeQuery } = useQuery<{ batches: Batch[] }, QueryProductBatchesArgs>({
  query:     gql`
    query GetProductBatches($productId: ID!) {
      batches: productBatches(productId: $productId) {
        id
        name
        number
        productionDate
        expirationDate
      }
    }
  `,
  variables: computed(() => ({
    productId: props.product.id,
  })),
  pause:     computed(() => props.product.deletedAt !== null),
});
const batches = computed<Batch[]>(() => data.value?.batches ?? []);

const headers: QTableColumn<Batch>[] = [
  {
    label: t('Name'),
    name:  'name',
    field: 'name',
    align: 'left',
  },
  {
    label: t('Number'),
    name:  'number',
    field: 'number',
  },
  {
    label:    t('Production Date'),
    name:     'productionDate',
    field:    'productionDate',
    format:    dateFormatter(FORMATS.DATE),
    align:    'left',
    sortable: true,
  },
  {
    label:    t('Expiration Date'),
    name:     'expirationDate',
    field:    'expirationDate',
    format:    dateFormatter(FORMATS.DATE),
    align:    'left',
    sortable: true,
  },
];

defineExpose({
  refresh: executeQuery,
});

</script>

<i18n lang="yaml">
ru:
  Batches: Партии
  Production Date: Дата производства
  Expiration Date: Годен до

en:
  Batches: Batches
  Production Date: Production Date
  Expiration Date: Expiration Date
</i18n>
