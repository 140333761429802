<template>
  <BarcodeDetectingField
    v-bind="$attrs"
    v-model="weight"
    type="number"
    min="0.001"
  />
</template>

<script setup lang="ts">

import { useVModel } from '@vueuse/core';
import { computed } from 'vue';
import BarcodeDetectingField from '@/components/BarcodeDetectingField.vue';

const props = defineProps<{
  modelValue: number | null;
}>();

const model = useVModel(props);

const weight = computed({
  get(): string {
    return typeof model.value === 'number'
      ? String(model.value / 1000)
      : '';
  },

  set(value: string) {
    model.value = value ? Number.parseFloat(value) * 1000 : null;
  },
});

</script>
