<template>
  <template v-if="actions.length > 0">
    <p class="text-subtitle1">
      {{ t('Recent Actions') }}
    </p>
    <div class="row q-col-gutter-md">
      <div
        v-for="action in visibleActions"
        :key="action.route"
        class="col-6 col-sm-4 col-md-2 col-lg-1"
      >
        <RouterLink :to="{ name: action.route }">
          <QCard
            :class="[`bg-${action.color ?? 'grey'}`, 'text-white']"
            @click.capture="remember(action)"
          >
            <QResponsive ratio="1">
              <QCardSection>
                <div class="full-height column justify-center items-center">
                  <QIcon
                    :size="iconSize"
                    :name="action.icon"
                  />
                  {{ t(action.title) }}
                </div>
              </QCardSection>
            </QResponsive>
          </QCard>
        </RouterLink>
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">

import useQuickAccess from '@/composables/useQuickAccess';
import { useQuasar } from 'quasar';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const q = useQuasar();

const { t } = useI18n();

const { actions, remember } = useQuickAccess();

const iconSize = computed(() => {
  switch (q.screen.name) {
    case 'xs':
      return '64px';
    case 'sm':
    case 'md':
      return '96px';
    case 'lg':
    case 'xl':
      return '64px';
    default:
      return '64px';
  }
});

const visibleActions = computed(() => actions.value.slice(0, {
  xs: 6,
  sm: 6,
  md: 12,
  lg: 12,
  xl: 12,
}[q.screen.name]));

</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/navigation.yaml"></i18n>

<i18n lang="yaml">
ru:
  Recent Actions: Последние действия
en:
  Recent Actions: Recent Actions
</i18n>
