<template>
  <Teleport to="#teleport-target-invisible">
    <a
      ref="link"
      :href="url"
      class="invisible"
    />
  </Teleport>
</template>

<script setup lang="ts">

import { nextTick, ref, watch } from 'vue';

const props = defineProps<{
  url: string | null;
  immediate?: boolean;
}>();

const link = ref<HTMLAnchorElement>();

watch(() => props.url, () => {
  if (props.url && props.immediate) {
    nextTick(download);
  }
});

function download() {
  link.value?.click();
}

defineExpose({ download });

</script>
