<template>
  <MaximizedDialog
    model-value
    transition-show="slide-up"
    transition-hide="slide-down"
    :title="t('Inventory')"
    @close="navigateBack({ name: ROUTES.OPERATIONS_LIST })"
  >
    <InventoryHeader
      :storage="inventory.storage"
      :entire-storage="inventory.isEntireStorage"
    />
    <QList
      dense
      separator
    >
      <InventoryProductListItem
        v-for="movement in inventory.movements"
        :key="movement.id"
        :product-pack="movement.storable.productPack"
        :diff="movement.storageTo ? movement.amount : -movement.amount"
      />

      <template v-if="inventory.state !== InventoryStateEnum.COMPLETED">
        <InventoryProductListItem
          v-for="item in inventory.items"
          :key="item.id"
          :product-pack="item.storable"
          :diff="item.diff"
        />
      </template>
    </QList>
  </MaximizedDialog>
</template>

<script setup lang="ts">

import MaximizedDialog from '@/components/MaximizedDialog.vue';
import type { Inventory } from '@/graphql/types';
import { InventoryStateEnum } from '@/graphql/types';
import { useI18n } from 'vue-i18n';
import InventoryProductListItem from './InventoryProductListItem.vue';
import InventoryHeader from './InventoryHeader.vue';
import navigateBack from '@/helpers/navigateBack';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

defineProps<{
  inventory: Inventory;
}>();

</script>
