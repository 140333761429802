<template>
  <div
    class="row items-start"
    :class="{ 'no-wrap': !mobile, 'q-pa-md': mobile }"
  >
    <QSelect
      v-model="rangeType"
      :options="[
        DateRangeType.TODAY,
        DateRangeType.YESTERDAY,
        DateRangeType.LAST_WEEK,
        DateRangeType.LAST_MONTH,
        DateRangeType.CUSTOM,
      ]"
      :option-label="rangeTypeLabel"
      emit-value
      dense
      bottom-slots
      :class="{ 'col-12': mobile }"
      :style="{ width: mobile ? undefined : '160px' }"
    />

    <div
      v-if="rangeType === DateRangeType.CUSTOM"
      class="row no-wrap items-start"
    >
      <ValuePickerCustomDateRange
        :initial-value="initialValueForCustomPicker"
        :format="format"
        :input-mask="inputMask"
        @pick="handleCustomRangePicked"
      />
    </div>
  </div>
  <ButtonsRow
    v-if="mobile && modelValue"
    v-slot="{ buttonProps }"
  >
    <QBtn
      v-bind="buttonProps"
      icon="mdi-content-save"
      @click="emit('save-filter')"
    >
      {{ t('Apply') }}
    </QBtn>
  </ButtonsRow>
</template>

<script setup lang="ts">

import ValuePickerCustomDateRange
  from '@/components/BaseTable/ValuePicker/ValuePickerCustomDateRange.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import {
  type DateRange,
  DateRangeType,
  type FilterValue,
  type ReportFilterDateRangeValue,
} from '@/types/reports';
import dayjs from 'dayjs';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  modelValue: FilterValue<ReportFilterDateRangeValue> | null;
  format: string;
  inputMask: string;
  mobile: boolean;
}>();

const emit = defineEmits<{
  'update:modelValue': [value: FilterValue<ReportFilterDateRangeValue> | null];
  'save-filter': [];
}>();

const rangeType = ref<DateRangeType>(null!);

function rangeTypeLabel(range: DateRangeType) {
  return t(`dateRange.${range}`);
}

onBeforeMount(() => {
  if (props.modelValue) {
    rangeType.value = Array.isArray(props.modelValue.value)
      ? DateRangeType.CUSTOM
      : props.modelValue.value;
  } else {
    rangeType.value = DateRangeType.TODAY;
  }
});

const initialValueForCustomPicker = computed<DateRange | null>(() =>
  props.modelValue && Array.isArray(props.modelValue.value)
    ? props.modelValue?.value.map(d => dayjs(d).format(props.format)) as DateRange
    : null);

watch(rangeType, type => {
  if (!type || type === DateRangeType.CUSTOM) {
    emit('update:modelValue', null);
  } else {
    emit('update:modelValue', {
      label: rangeTypeLabel(type),
      value: type,
    });
  }
});

function handleCustomRangePicked(range: DateRange | null) {
  emit('update:modelValue', range ? {
    label: range[0] === range[1] ? range[0] : (range[0] + ' - ' + range[1]),
    value: range.map(date => dayjs(date, props.format, true).format('YYYY-MM-DD')) as DateRange,
  } : null);
}

</script>

<i18n lang="yaml">
ru:
  Start Date: Дата начала
  End Date: Дата окончания
en:
  Start Date: Start Date
  End Date: End Date
</i18n>
