<template>
  <div class="column">
    <div class="col column no-wrap full-width">
      <ErrorsCarousel :errors="currentErrors" />

      <TransferProcessCarousel
        v-if="!store.transfer?.task"
        ref="stocksCarousel"
        v-model:slide="selectedMovementIndex"
        :input-disabled="carouselInputDisabled"
        @barcode-scan="scanField.scan($event)"
        @input-amount="updateSelectedItemAmount"
        @submit-amount="handleSubmitAmount"
      />

      <TransferChangeTargetStorage
        v-model="changeTargetStorageDialog"
        @changed="handleTargetStorageChanged"
      />
      <template v-if="!changeTargetStorageDialog">
        <div
          v-if="!store.transfer?.task"
          class="q-px-lg q-py-sm"
        >
          <BaseScanField
            ref="scanField"
            :hint="t('Scan Product or Storage')"
            :placeholder="t('Product or Storage')"
            :disabled="isScanStorableDisabled"
            no-omni-input-scan
            :search-fn="b => store.searchStorageOrProductPack(b)"
            @scan="handleScan"
            @not-found="handleStorableNotFoundError"
          />
        </div>
        <ScanExpectedStorable
          v-else
          ref="scanPackRef"
          :search-fn="store.searchStorableOrStorage"
          class="col justify-center"
          @scan="handleScan"
          @delete-selected="handleDeleteItem"
        />
      </template>
    </div>

    <ButtonsRow v-slot="{ buttonProps }">
      <QBtn
        v-if="store.transfer?.task"
        v-bind="buttonProps"
        :disable="errorMessages.size > 0"
        icon="mdi-file-tree-outline"
        @click="emit('show-task')"
      >
        {{ t('Plan') }}
      </QBtn>
      <QBtn
        v-if="nextStoragesPair"
        v-bind="buttonProps"
        :disable="errorMessages.size > 0"
        icon="mdi-skip-next"
        @click="emit('change-storage', nextStoragesPair)"
      >
        {{ t('Next Cell') }}
      </QBtn>
      <KeyboardToggleButton
        v-bind="buttonProps"
        :disable="carouselInputDisabled"
      />
      <ConfirmsAction
        :confirm-text="t('Yes')"
        :cancel-text="t('No')"
        @confirmed="handleCancel"
      >
        <template #title>
          {{ t('Cancel Transfer?') }}
        </template>
        <template #activator="{ prompt }">
          <QBtn
            v-bind="buttonProps"
            icon="mdi-close-circle-outline"
            @click="prompt"
          >
            {{ t('Cancel') }}
          </QBtn>
        </template>
      </ConfirmsAction>
      <QBtn
        v-if="!transfer?.task"
        v-bind="buttonProps"
        icon="mdi-check-all"
        :loading="progress.takingAll"
        :disable="progress.completing"
        @click="takeAll()"
      >
        {{ t('Take All') }}
      </QBtn>
      <QBtn
        v-bind="buttonProps"
        :disable="progress.completing"
        icon="mdi-swap-horizontal"
        @click="changeTargetStorageDialog = true"
      >
        {{ t('Change Storage') }}
      </QBtn>
      <TransferCompleteButton
        v-bind="buttonProps"
        :disable="store.movements.length === 0"
        :loading="progress.completing"
        @complete="complete"
      />

      <FixErrorsPrompt
        v-model:slide="slideWithErrorToFix"
        :error="errorMessages.get(store.movements[slideWithErrorToFix!]?.storable.id)"
        @fix="selectedMovementIndex = $event"
      />
    </ButtonsRow>
    <BlurredInput
      skip-input-if-barcode
      speak-digits
      @barcode="scanPackRef.scan($event)"
    />
    <VirtualKeyboard :show-comma-key="amountIsFractional" />
  </div>
</template>

<script setup lang="ts">

import ConfirmsAction from '@/components/ConfirmsAction.vue';
import BaseScanField from '@/components/Mobile/BaseScanField.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import ErrorsCarousel from '@/components/Mobile/ErrorsCarousel.vue';
import FixErrorsPrompt from '@/components/Mobile/FixErrorsPrompt.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useOmniInput from '@/composables/useOmniInput';
import useSpeaker from '@/composables/useSpeaker';
import type {
  Movement,
  ProductPack,
  Stock,
  StorableOrStorage,
  Storage,
  StorageUnit,
} from '@/graphql/types';
import { AskForWeightAndDimensionsOptionEnum } from '@/graphql/types';
import allocateAmountForPackInList from '@/helpers/allocateAmountForPackInList';
import getPackQuantityInBiggerPack from '@/helpers/getPackQuantityInBiggerPack';
import { areSame } from '@/helpers/graphql';
import navigateBack from '@/helpers/navigateBack';
import productPackIsMissingWeightOrDimensions
  from '@/helpers/productPackIsMissingWeightOrDimensions';
import { speechOnProductPackScan } from '@/helpers/speechOnScan';
import ROUTES from '@/router/routeNames';
import useTransferProcessStore from '@/stores/transferProcess';
import ScanExpectedStorable from '@/views/Mobile/ScanExpectedStorable.vue';
import type ScanPack from '@/views/Mobile/Selection/ScanPack.vue';
import TransferChangeTargetStorage from '@/views/Mobile/Transfer/TransferChangeTargetStorage.vue';
import TransferCompleteButton from '@/views/Mobile/Transfer/TransferCompleteButton.vue';
import TransferProcessCarousel from '@/views/Mobile/Transfer/TransferProcessCarousel.vue';
import { useEventListener, whenever } from '@vueuse/core';
import * as R from 'ramda';
import { last } from 'ramda';
import {
  computed,
  nextTick,
  onBeforeUnmount,
  reactive,
  type Ref,
  ref,
  shallowRef,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';

const stocksCarousel: Ref<InstanceType<typeof TransferProcessCarousel>> = ref(null!);

const store = useTransferProcessStore();

const speaker = useSpeaker();

const { t } = useI18n();

// noinspection LocalVariableNamingConventionJS
const {
  primaryError,
  fillErrorsFromGraphQLError,
  clearErrors,
  errorsFor,
} = useErrorHandling();

const progress = reactive({
  takingAll:    false,
  completing:   false,
  savingAmount: false,
});

const props = defineProps<{
  carouselInputDisabled: boolean;
}>();


const amountIsFractional = computed(() => store.selectedSlide?.transferItem.storageUnit.productPack.measurementUnit.isFractional ?? false);

const input = useOmniInput({
  replace: value => {
    // Не используем skip, т.к. нужна возможность сканирования ШК.
    if (!store.canChangeAmount) {
      return '';
    }

    return amountIsFractional.value
      ? value.replace(/,/g, '.').replace(/[^\d.]/g, '')
      : value.replace(/\D/g, '');
  },
});
const { BlurredInput, VirtualKeyboard, KeyboardToggleButton, value: inputValue, toggleCurrentKeyboard } = input;

watch(() => store.selectedSlide, deleteZeroMovement);

watch(() => store.selectedSlideIndex, () => {
  input.selectAll();
  clearErrors();
});

watch(() => store.selectedSlide, (slide) => {
  if (!slide) {
    return;
  }
  input.reset(String(slide.movement?.amount ?? '0'));
}, { immediate: true });

watch(inputValue, value => {
  const numberValue = Number(value);
  if (!Number.isNaN(numberValue)) {
    changeScannedAmount(numberValue);
  }
});

const scanPackRef: Ref<InstanceType<typeof ScanPack>> = ref(null!);

async function changeScannedAmount(amount: number): Promise<void> {
  const slide = store.selectedSlide;
  const diff = amount - (slide.movement?.amount ?? 0);

  if (diff === 0) {
    return;
  }

  const transferItem = slide.transferItem;

  if (diff > 0 && transferItem.transferredAmount + diff > transferItem.plannedAmount) {
    primaryError.value = t(
      'Excess product, only {needed} needed',
      { needed: transferItem.plannedAmount },
    );
    speaker.speak(primaryError.value);
    return;
  }

  const oldZeroSlideIndex = store.zeroSlideIndex;

  const movement = slide.movement!;

  const { error } = await store.updateMovementAmount({ movement, amount });

  if (error) {
    fillErrorsFromGraphQLError(error);
    speaker.speak(primaryError.value);
    return;
  }

  if (amount === 0) {
    zeroMovementToDelete.value = movement;
  }

  clearErrors();

  if (store.currentStorageFullySelected && !store.fullySelected) {
    store.storageFrom = null;
    store.storageTo = null;
  }

  // Если ввели нужное количество на нулевом слайде, переходим к следующему.
  // Нулевой слайд запоминаем до обновления количества, так как после обновления он мог сместиться.
  if (amount === transferItem.plannedAmount && store.selectedSlideIndex === oldZeroSlideIndex) {
    store.selectedSlideIndex++;
  }
}

whenever(() => store.fullySelected, async () => {
  if (store.transfer?.task) {
    await complete();
  }
});

async function handleDeleteItem() {
  input.reset('0');
}

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'show-history'): void;
  (e: 'show-task'): void;
  (e: 'change-storage', storages: [Storage, Storage]): void;
  (e: 'update:carouselInputDisabled', disabled: boolean): void;
}>();

const transfer = computed(() => store.transfer);

const selectedMovementIndex = ref<number>(0);
watch(selectedMovementIndex, () => {
  primaryError.value = '';
});

const errorMessages = reactive(new Map<string, string>());

const currentItemError = computed(() => {
  const storableId = store.movements[selectedMovementIndex.value]?.storable.id;

  return storableId ? errorMessages.get(storableId) : null;
});

const currentErrors = computed(() => [
  primaryError.value,
  currentItemError.value,
].filter(e => !!e));

const itemsWithErrorsIndexes = computed(
  () => store.movements
    .map((m, index) => errorMessages.get(m.storable.id) ? index : null)
    .filter(v => v !== null)
);

const lastEditedAmountIndex = ref<number | null>(null);

async function takeAll(): Promise<void> {
  progress.takingAll = true;

  const { error } = await store.takeAll();

  progress.takingAll = false;

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  selectedMovementIndex.value = Math.max(0, store.movements.length - 1);
}

async function handleSubmitAmount({ index, amount }: {
  index: number;
  amount: number;
}): Promise<void> {
  await convertStocksForMovement(store.movements[index], amount);
  await saveMovementAmount({ index, amount, speakOnError: false });
  lastEditedAmountIndex.value = null;
}

async function convertStocksForMovement(movement: Movement, neededAmount: number) {
  return pickStocksForMovement(movement, neededAmount, (stock, multiplier) => store
    .convertStockPack(stock.storageUnit, movement.storable.productPack, multiplier));
}

async function pickStocksForMovement(
  movement: Movement,
  neededAmount: number,
  onPick?: (stock: Omit<Stock, 'lockedAmount'>, multiplier: number) => unknown,
): Promise<number> {
  return await allocateAmountForPackInList(
    store.stocksInStorageFrom,
    movement.storable.productPack,
    neededAmount,
    s => s.storageUnit.productPack,
    s => {
      let availableAmount = s.amount;
      // Остатки в productItems без учета движений (как было до начала перемещения),
      // поэтому если берем остаток с другой упаковки, учитываем уже взятое количество.
      if (s.storageUnit.productPack.id !== movement.storable.productPack.id) {
        availableAmount -= store.savedMovements
          .filter(m => m.storable.id === s.storageUnit.id)
          .reduce((acc, m) => acc + m.amount, 0);
      }
      return availableAmount;
    },
    onPick,
  );
}

function handleStorableNotFoundError(): void {
  primaryError.value = t('Nothing found');
  speaker.speak(primaryError.value);
}

function clearMovementErrors() {
  if (!R.isEmpty(errorsFor('movements'))) {
    clearErrors();
  }
}

async function handleScan(entity: StorableOrStorage | ProductPack): Promise<void> {
  primaryError.value = '';
  clearMovementErrors();

  emit('update:carouselInputDisabled', true);

  if (areSame(entity, store.storageTo, R.prop('id'))) {
    if (itemsWithErrorsIndexes.value.length > 0) {
      selectedMovementIndex.value = itemsWithErrorsIndexes
        .value[itemsWithErrorsIndexes.value.length - 1]!;

      speaker.speak(currentItemError.value!);

      emit('update:carouselInputDisabled', false);

      return;
    }

    if (!transfer.value?.task) {
      await complete();
    }
  } else {
    switch (entity.__typename) {
      case 'ProductPack':
        await scanProductPack(entity);
        break;
      case 'StorageUnit':
        await scanStorageUnit(entity);
        break;
    }
  }

  emit('update:carouselInputDisabled', false);
}

const selectedItem = computed(() => store.movements[selectedMovementIndex.value]);

async function scanProductPack(productPack: ProductPack) {
  const stock = store.availableStocksFrom.find(
    s => s.storageUnit.productPack.id === productPack.id,
  );

  if (stock && stock.amount >= 1) {
    return scanStorageUnit(stock.storageUnit);
  }

  try {
    const storageUnit = await getStorageUnitWithAnotherPack(productPack);
    if (storageUnit) {
      return scanStorageUnit(storageUnit);
    }
  } catch (error) {
    fillErrorsFromGraphQLError(error);
    speaker.speak(primaryError.value);
    return;
  }

  primaryError.value = t('Product {sku} {name} is missing', {
    sku:  productPack.product.sku,
    name: productPack.product.name,
  });
  speaker.speak(t('Product not found'));
}

async function getStorageUnitWithAnotherPack(productPack: ProductPack): Promise<StorageUnit | null> {
  const otherStorageUnit = store.availableStocksFrom.find(s => {
    const scannedAmount = getPackQuantityInBiggerPack(s.storageUnit.productPack, productPack)
      ?? 1;
    return s.storageUnit.productPack.product.id === productPack.product.id
      && scannedAmount <= s.amount;
  })?.storageUnit ?? null;

  if (!otherStorageUnit) {
    return null;
  }

  return store.convertStockPack(otherStorageUnit, productPack, 1);
}

function shouldNotifyAboutWeightAndDimensions(pack: ProductPack): boolean {
  return pack.product.accountingModel.askForWeightAndDimensions === AskForWeightAndDimensionsOptionEnum.ALWAYS;
}

// Слайд, к которому надо перейти после полного отбора текущего.
const nextSlideIndex = shallowRef<number | null>(null);

watch(() => store.selectedSlideIndex, () => {
  nextSlideIndex.value = null;
});

const zeroMovementToDelete = ref<Movement | null>(null);

async function deleteZeroMovement() {
  if (zeroMovementToDelete.value && zeroMovementToDelete.value.amount === 0) {
    const movement = zeroMovementToDelete.value;
    zeroMovementToDelete.value = null;
    const deletedIndex = store.slides.findIndex(s => s.movement === movement);
    const { error } = await store.deleteMovements([movement]);

    if (error) {
      return;
    }

    // Компенсируем смещение из-за удаленного слайда
    if (deletedIndex < store.selectedSlideIndex) {
      store.selectedSlideIndex--;
    }
  }
}

onBeforeUnmount(deleteZeroMovement);
// Запрос к серверу тут же отменяется, но до сервера он доходит, удаление выполняется.
useEventListener('beforeunload', deleteZeroMovement);

async function scanStorageUnit(storageUnit: StorageUnit) {
  speaker.speak(speechOnProductPackScan(storageUnit.productPack));

  const scannedSlide = store.slideByStorageUnit(storageUnit);
  const scannedItem = scannedSlide?.transferItem;

  const scannedSelectedSlide = scannedSlide === store.selectedSlide;

  if (store.transfer?.task) {
    if (!scannedItem) {
      primaryError.value = t('Wrong Product');
      speaker.speak(t('Wrong Product'));
      return;
    }

    if (scannedItem.transferredAmount === scannedItem.plannedAmount) {
      primaryError.value = t('Excess product, only {needed} needed', {
        needed: scannedItem.plannedAmount,
      });
      speaker.speak(primaryError.value);
      return;
    }
  } else {
    if (productPackIsMissingWeightOrDimensions(storageUnit.productPack)
      && shouldNotifyAboutWeightAndDimensions(storageUnit.productPack)) {
      speaker.speak(t('Specify Weight and Dimensions'));
    }
  }

  await deleteZeroMovement();
  await saveLastEditedAmount(false);

  const lastScanned = store.transfer?.task
    ? store.zeroSlide?.movement
    : last(store.movements);

  if (lastScanned
      && lastScanned.storable.id === storageUnit.id
      && lastScanned.storageFrom!.id === store.storageFrom!.id
      && lastScanned.storageTo!.id === store.storageTo!.id
  ) {
    await saveMovementAmount({
      index: store.movements.findIndex(m => m.id === lastScanned.id),
      amount: lastScanned.amount + 1,
      speakOnError: true
    });

    selectedMovementIndex.value = store.movements.length - 1;
  } else {
    const { data, error } = await store.moveStorageUnit(storageUnit, 1);
    if (error) {
      fillErrorsFromGraphQLError(error);
      const movementError = errorsFor<string[]>('movements')[0];
      if (movementError) {
        primaryError.value = movementError;
      }

      speaker.speak(movementError);
    } else {
      selectedMovementIndex.value = store.movements.findIndex(m => data!.movements[0].id === m.id);
      const savedMovement = store.savedMovements.find(m => m.id === selectedItem.value.id);
      if (savedMovement) {
        savedMovement.amount = selectedItem.value.amount;
      } else {
        store.savedMovements.push(selectedItem.value);
      }
    }
  }

  toggleCurrentKeyboard(false);

  let next: number | null = nextSlideIndex.value;
  if (scannedItem) {
    // Если сканировали товар на выбранном слайде,
    // то после принятия всего товара надо показать следующий за ним,
    // иначе вернуться к выбранному слайду.
    // Это должно работать только если выбран слайд в плане.
    if (next === null && !store.selectedSlide.movement) {
      next = scannedSelectedSlide
        ? store.selectedSlideIndex + 1
        : store.selectedSlideIndex;
    }

    if (!errorMessages.has(scannedItem.storageUnit.id)) {
      if (scannedItem.transferredAmount === scannedItem.plannedAmount && next !== null) {
        store.selectedSlideIndex = next;
        next = null;
      } else {
        store.selectedSlideIndex = store.zeroSlideIndex ?? 0;
        if (next !== null) {
          // После смены selectedSlideIndex сработает watch и nextSlideIndex сбросится.
          // Поэтому откладываем его запись, чтобы не сбросился.
          void nextTick(() => {
            nextSlideIndex.value = next;
          });
        }
      }

      input.reset(String(store.selectedSlide.movement?.amount ?? '0'));
    }
  }

  if (store.transfer?.task && store.currentStorageFullySelected && !store.fullySelected) {
    store.storageFrom = null;
    store.storageTo   = null;
    return;
  }

  if (!store.transfer?.task) {
    stocksCarousel.value.resetInput();
  }
}

watch(() => store.movements, items => {
  if (selectedMovementIndex.value >= items.length) {
    selectedMovementIndex.value = items.length - 1;
  }
});

async function saveLastEditedAmount(speakOnError = true) {
  if (lastEditedAmountIndex.value !== null) {
    await convertStocksForMovement(store.movements[lastEditedAmountIndex.value], store.movements[lastEditedAmountIndex.value].amount);
    await saveMovementAmount({
      index:  lastEditedAmountIndex.value,
      amount: store.movements[lastEditedAmountIndex.value].amount,
      speakOnError,
    });
    lastEditedAmountIndex.value = null;
  }
}

const slideWithErrorToFix = ref<number | null>(null);

async function complete() {
  if (progress.completing) {
    return;
  }

  if (itemsWithErrorsIndexes.value.length > 0) {
    slideWithErrorToFix.value = itemsWithErrorsIndexes.value[0];
    speaker.speak(t('Fix the errors'));
    return;
  }

  progress.completing = true;

  await deleteZeroMovement();
  await saveLastEditedAmount();

  const { error } = await store.completeTransfer();

  if (error) {
    fillErrorsFromGraphQLError(error);
    progress.completing = false;
    return;
  }

  speaker.speak(t('Completed'));

  progress.completing = false;

  await navigateBack({ name: ROUTES.TRANSFER_DASHBOARD });
}

async function validateMovementAmount(movement: Movement, amount: number, speakOnError: boolean) {
  const amountLeft = await pickStocksForMovement(movement, amount);

  if (amountLeft > 0) {
    errorMessages.set(movement.storable.id, t(
      'Not enough product, available {stock}',
      { stock: amount - amountLeft },
    ));
    if (speakOnError) {
      speaker.speak(errorMessages.get(movement.storable.id)!);
    }
  } else {
    errorMessages.delete(movement.storable.id);
  }
}

async function updateSelectedItemAmount(amount: number) {
  clearMovementErrors();
  await validateMovementAmount(selectedItem.value, amount, true);

  selectedItem.value.amount = amount;
  lastEditedAmountIndex.value = selectedMovementIndex.value;

  if (errorMessages.size === 0) {
    clearErrors();
  }
}

async function saveMovementAmount({ index, amount, speakOnError }: {
  index: number;
  amount: number;
  speakOnError: boolean;
}): Promise<void> {
  progress.savingAmount = true;
  const movement = store.movements[index];
  await validateMovementAmount(movement, amount, speakOnError);
  if (amount === 0) {
    store.savedMovements.splice(store.savedMovements.findIndex(m => m.id === movement.id));
    await store.deleteMovements([movement]);
  } else if (!errorMessages.has(movement.storable.id)) {
    const { error } = await store.updateMovementAmount({
      movement: movement,
      amount,
    });
    if (error) {
      fillErrorsFromGraphQLError(error);
      primaryError.value = '';
      errorMessages.set(movement.storable.id, errorsFor<string[]>('amount')[0]);
      speaker.speak(errorsFor<string[]>('amount')[0]);
    } else {
      store.savedMovements.find(m => m.id === movement.id)!.amount = movement.amount;
    }
  } else {
    movement.amount = amount;
  }
  progress.savingAmount = false;
}

const isScanStorableDisabled = computed(
  () => progress.completing || progress.savingAmount || props.carouselInputDisabled
);

function handleCancel() {
  errorMessages.clear();

  emit('cancel');
}

const changeTargetStorageDialog = ref(false);

function handleTargetStorageChanged() {
  primaryError.value = '';
}

const nextStoragesPair = computed<[Storage, Storage] | null>(() => {
  if (store.storagesPairsToScan.length < 2 || !store.storageFrom || !store.storageTo) {
    return null;
  }

  const currentIndex = store.storagesPairsToScan
    .findIndex(([from, to]) => from.id === store.storageFrom!.id
      && to!.id === store.storageTo!.id);
  const nextIndex = currentIndex === store.storagesPairsToScan.length - 1
    ? 0
    : currentIndex + 1;

  return store.storagesPairsToScan[nextIndex] ?? null;
});

const scanField: Ref<InstanceType<typeof BaseScanField>> = ref(null!);

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/speaking.yaml"></i18n>

<i18n lang="yaml">
ru:
  Take All: Взять всё
  Taken: Взято
  Move: Переместить
  The Container Cell is different from the Storage From. Move to the Storage From?: >
    Ячейка контейнера отличается от текущей ячейки. Выполнить перемещение контейнера?
  Plan: План
  Next Cell: Следующая ячейка
  Go to next cell?: Перейти к следующей ячейке?
  Product {sku} {name} is missing: Товар {sku} {name} не найден в ячейке
  Cancel Transfer?: Отменить перемещение?
  Change Storage: Изменить ячейку
  Fix the errors: Исправьте ошибки

en:
  Take All: Take All
  Taken: Taken
  Move: Move
  The Container Cell is different from the Storage From. Move to the Storage From?: >
    The Container Cell is different from the Storage From. Move to the Storage From?
  Plan: Plan
  Next Cell: Next Cell
  Go to next cell?: Go to next cell?
  Product {sku} {name} is missing: Product {sku} {name} is missing
  Cancel Transfer?: Cancel Transfer?
  Change Storage: Change Storage
  Fix the errors: Fix the errors
</i18n>
