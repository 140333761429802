<template>
  <CardTitle>
    {{ t('Stocks by Days') }}
  </CardTitle>
  <GraphQLQueryTable
    :graphql-query="query"
    :fields="tableFields"
    :fixed-filters="fixedFilters"
    row-key="day"
    without-search
    no-toolbar
  />
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type { Product, StockByDay, ReportFilterInput } from '@/graphql/types';
import { gql } from '@urql/vue';
import type { QTableColumn } from 'quasar';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { dateFormatter } = useLocalizedFormatters();

const props = defineProps<{
  product: Product;
}>();

const tableFields: QTableColumn<StockByDay>[] = [
  {
    label:  t('Date'),
    name:   'day',
    field:  'day',
    format: dateFormatter(FORMATS.DATE),
  },
  {
    label:  t('Stock at Start'),
    name:   'amountAtStart',
    field:  'amountAtStart',
  },
  {
    label:  t('Difference'),
    name:   'amountChange',
    field:  'amountChange',
  },
  {
    label:  t('Stock at End'),
    name:   'amountAtEnd',
    field:  stock => (stock.amountAtStart ?? 0) + (stock.amountChange ?? 0),
  },
];

const fixedFilters = computed<ReportFilterInput[]>(() => [
  {
    field:    'product',
    operator: '=',
    value:    JSON.stringify(props.product.id),
  },
]);

const query = gql`
  query GetProductStocksByDay(
    $page: Int,
    $perPage: Int!,
    $query: String,
    $filter: [ReportFilterInput!],
    $sort: [ReportSortInput!]!,
  ) {
    result: stocksByDaysReport(
      page: $page,
      perPage: $perPage,
      query: $query,
      filter: $filter,
      sort: $sort,
    ) {
      data {
        day
        amountAtStart
        amountChange
      }
      total
    }
  }
`;

</script>

<i18n lang="yaml">
ru:
  Stocks by Days: Остатки по дням
  Date: Дата
  Stock at Start: Остаток на начало
  Stock at End: Остаток на конец
  Difference: Изменение

en:
  Stocks by Days: Stocks by Days
  Date: Date
  Stock at Start: Stock at Start
  Stock at End: Stock at End
  Difference: Difference
</i18n>
