<template>
  <div
    v-if="currentInventory?.storage"
    class="justify-top"
  >
    <div class="row justify-center q-pa-mb">
      <h5 class="q-ma-none">
        {{ currentInventory?.storage.name }}
      </h5>
    </div>
  </div>

  <GraphQLQueryScanField
    v-bind="$attrs"
    :query="query"
    :hint="hint"
    :rules="allRules"
    :placeholder="t('Cell or Container')"
    :not-found-message="t('Cell not found')"
    @scan="emit('scan', $event)"
  />
</template>

<script setup lang="ts">

import GraphQLQueryScanField from '@/components/Mobile/GraphQLQueryScanField.vue';
import type { FunctionValidationRule } from '@/types';
import { gql, useClientHandle } from '@urql/vue';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import type { Inventory, QueryPendingStorageInventoryArgs } from '@/graphql/types';
import useErrorHandling from '@/composables/useErrorHandling';
import StorageForInventory from '@/graphql/fragments/StorageForInventory';

const { t } = useI18n();

const { client: urql } = useClientHandle();

const { getPrimaryErrorFromGraphQLError } = useErrorHandling();

const props = defineProps<{
  hint: string;
  rules?: FunctionValidationRule[];
  currentInventory?: Inventory|null;
}>();

const emit = defineEmits<{
  (e: 'scan', storage: Storage): void;
}>();

const query = gql`
  ${StorageForInventory}

  query GetStorageByBarcode($barcode: String!) {
    storageByBarcode(barcode: $barcode) { ...StorageForInventory }
  }
`;

const allRules = computed(() => [
  ...(props.rules ?? []),
  noPendingInventoryRule,
]);

async function noPendingInventoryRule(storage: Storage) {
  const { data, error } = await urql.query<{ inventory: Inventory }, QueryPendingStorageInventoryArgs>(gql`
    query GetPendingStorageInventoryAfterInventoryStorageScan($storageId: ID!) {
      inventory: pendingStorageInventory(storageId: $storageId) { id }
    }
  `, {
    storageId: storage.id,
  });

  if (error) {
    return getPrimaryErrorFromGraphQLError(error);
  }

  if (data?.inventory && data.inventory.id !== props.currentInventory?.id) {
    return t('There is pending inventory {id} for this storage', { id: data.inventory.id });
  }

  return true;
}

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>

<i18n lang="yaml">
ru:
  There is pending inventory {id} for this storage: >
    Для этого места хранения еще не завершена инвентаризация {id}

en:
  There is pending inventory {id} for this storage: >
    There is pending inventory {id} for this storage
</i18n>
