<template>
  <QList
    dense
    separator
  >
    <QItemLabel header>
      {{ t('Products skipped because of empty code') }}
    </QItemLabel>
    <QSeparator />
    <QItem
      v-for="p of result.skippedProductsWithEmptyCode"
      :key="p.id"
    >
      <QItemSection>
        <QItemLabel class="ellipsis">
          <span class="text-grey">
            {{ p.id }}
          </span>
          {{ p.name }}
          <a
            v-if="p.url"
            :href="p.url"
            target="_blank"
          >
            {{ p.url }}
          </a>
        </QItemLabel>
      </QItemSection>
    </QItem>
  </QList>
</template>

<script setup lang="ts">

import type { MoySkladSyncResult } from '@/graphql/types';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps<{
  result: MoySkladSyncResult;
}>();

</script>

<i18n lang="yaml">
ru:
  Products skipped because of empty code: Пропущенные товары с пустым кодом
en:
  Products skipped because of empty code: Products skipped because of empty code
</i18n>
