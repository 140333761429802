<template>
  <QSelect
    :model-value="modelValue"
    use-input
    :options="storages"
    option-label="name"
    option-value="id"
    :label="t('Storage')"
    :loading="fetching"
    :error="!!primaryError"
    :error-message="primaryError"
    @filter="handleFilter"
    @update:model-value="emit('update:model-value', $event)"
  >
    <template #option="{ opt, itemProps }: { opt: StorageUnion, itemProps: QItemProps }">
      <QItem v-bind="itemProps">
        <QItemSection>
          <QItemLabel>{{ opt.name }}</QItemLabel>
          <QItemLabel caption>
            <StorageInfo :storage="opt" />
          </QItemLabel>
        </QItemSection>
      </QItem>
    </template>
    <template #hint>
      <StorageInfo :storage="modelValue" />
    </template>
  </QSelect>
</template>

<script setup lang="ts">
import useErrorHandling from '@/composables/useErrorHandling';
import type { Cell, Container, QueryStoragesArgs } from '@/graphql/types';
import { gql, useQuery } from '@urql/vue';
import type { QItemProps } from 'quasar';
import { type Component, computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

type StorageUnion = Cell | Container;

const { t } = useI18n();

const { primaryError, fillErrorsFromGraphQLError } = useErrorHandling();

defineProps<{
  modelValue: StorageUnion | null;
}>();

const emit = defineEmits<{
  'update:model-value': [storage: StorageUnion];
}>();

const searchString = ref('');

const {
  data,
  fetching,
  error,
} = useQuery<{ storages: StorageUnion[] }, QueryStoragesArgs>({
  query: gql`
    query GetStoragesList($first: Int!, $query: String) {
      storages(first: $first, query: $query) {
        __typename
        id
        name
        barcode
        storageCell { id name }
      }
    }
  `,
  variables: computed(() => ({
    first: 50,
    query: searchString.value,
  })),
});
watch(error, fillErrorsFromGraphQLError);

const storages = computed(() => data.value?.storages ?? []);

function handleFilter(val: string, done: (update: () => void) => void) {
  searchString.value = val;
  done(() => null);
}

const StorageInfo: Component = ({ storage }: { storage: StorageUnion | null }) => storage ? [
  storage.barcode,
  storage.__typename === 'Container' ? [
    ' • ',
    storage.storageCell.name,
  ] : null,
] : null;

</script>

