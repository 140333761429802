<template>
  <QPage
    v-if="fetchingInventory"
    class="row justify-center items-center"
  >
    <QCircularProgress
      indeterminate
      color="primary"
      size="150px"
    />
  </QPage>
  <QPage
    v-else
    class="column"
  >
    <InventoryHeader
      v-if="storage"
      :storage="storage"
      :entire-storage="inventory?.isEntireStorage ?? storedState.current.value.isEntireStorage"
      :inventory="inventory"
    />

    <template v-if="!storage">
      <QInput
        v-if="inventory"
        readonly
        :model-value="inventory.isEntireStorage ? t('Entire Storage') : t('Some Products')"
        :label="t('Inventory Type')"
        class="q-mx-lg q-my-xl"
      />
      <BooleanSelect
        v-else
        ref="isEntireStorageSelect"
        v-model="isEntireStorage"
        :false-text="t('Some Products')"
        :true-text="t('Entire Storage')"
        :label="t('Inventory Type')"
        class="q-mx-lg q-my-xl"
        @popup-hide="handleEntireStorageSelectClose"
      />
      <InventoryScanStorage
        :disabled="hasProgress('completing')"
        :hint="inventory?.storage ? t('Scan the above specified Storage') : t('Scan First Cell')"
        :rules="[storageMatchesSpecifiedRule, storageShouldBeWithCorrectTypeAndContentRule]"
        :current-inventory="inventory"
        class="q-mx-lg q-my-lg"
        @scan="handleStorageScan"
      />
    </template>

    <template v-else>
      <div class="full-width">
        <ErrorsCarousel :errors="[primaryError, batchError].filter(e => !!e)" />
        <InventoryProcessCarousel
          ref="stocksCarousel"
          v-model:slide="selectedStockIndex"
          :actual-stocks="actualStocks"
          :storage-stocks="storageStocks"
          :storage-stocks-loading="stocksLoading"
          @barcode="scanField.scan($event)"
          @input-amount="updateStockAmount"
          @submit-amount="handleSubmitAmount"
        />
      </div>

      <div
        class="q-mx-lg"
        :class="actualStocks.length > 0 ? 'q-my-sm' : 'col column justify-center'"
      >
        <InventoryScan
          ref="scanField"
          :disabled="hasProgress('completing')"
          :inventory-product="inventoryProduct"
          :loading="loadingProductPackBatch"
          @scan="handleProductPackScan($event)"
          @update:scanning="scanning = $event"
        />
      </div>
    </template>

    <Teleport to="#teleport-target-buttons-row">
      <ButtonsRow v-slot="{ buttonProps }">
        <KeyboardToggleButton v-bind="buttonProps" />

        <QBtn
          v-bind="buttonProps"
          icon="mdi-close-circle-outline"
          @click="cancelInventory()"
        >
          {{ t('Cancel') }}
        </QBtn>

        <QBtn
          v-if="storage"
          v-bind="buttonProps"
          :loading="stocksLoading || hasProgress('completing')"
          icon="mdi-check-outline"
          @click="complete"
        >
          {{ t('Complete') }}
        </QBtn>

        <FixErrorsPrompt
          v-model:slide="slideWithErrorToFix"
          :error="stockError(actualStocks[slideWithErrorToFix!])"
          @fix="selectedStockIndex = $event"
        />
      </ButtonsRow>
    </Teleport>
  </QPage>
</template>

<script setup lang="ts">

import BooleanSelect from '@/components/BooleanSelect.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import ErrorsCarousel from '@/components/Mobile/ErrorsCarousel.vue';
import FixErrorsPrompt from '@/components/Mobile/FixErrorsPrompt.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useErrorHandling from '@/composables/useErrorHandling';
import useOmniInput from '@/composables/useOmniInput';
import useProgressHandling from '@/composables/useProgressHandling';
import useSpeaker from '@/composables/useSpeaker';
import useWakeLockWhenMounted from '@/composables/useWakeLockWhenMounted';
import InventoryForProcess from '@/graphql/fragments/InventoryForProcess';
import type {
  AccountingModel,
  Batch,
  Inventory,
  InventoryBatchData,
  InventoryBatchDataInput,
  MutationCreateInventoryArgs,
  Product,
  ProductPack,
  ProductPackWithAmount,
  QueryBatchDataForInventoryProductArgs,
  QueryInventoryArgs,
  QueryStocksInStorageArgs,
  Scalars,
  Stock,
  Storage,
} from '@/graphql/types';
import {
  AskForWeightAndDimensionsOptionEnum,
  ContainerKindEnum,
  InventoryStateEnum,
} from '@/graphql/types';
import batchesDataEquals from '@/helpers/batchesDataEquals';
import isContainer from '@/helpers/isContainer';
import productPackIsMissingWeightOrDimensions
  from '@/helpers/productPackIsMissingWeightOrDimensions';
import { speechOnProductPackScan } from '@/helpers/speechOnScan';
import ROUTES from '@/router/routeNames';
import type { ActualInventoryStock } from '@/types/inventory';
import InventoryProcessCarousel from '@/views/Mobile/Inventory/InventoryProcessCarousel.vue';
import useInventoryState, { idIsDraft } from '@/views/Mobile/Inventory/useInventoryState';
import { gql, useClientHandle, useQuery } from '@urql/vue';
import { useEventBus, useLocalStorage } from '@vueuse/core';
import * as R from 'ramda';
import { complement, omit } from 'ramda';
import { computed, nextTick, onBeforeMount, reactive, type Ref, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import InventoryHeader from './InventoryHeader.vue';
import InventoryScan from './InventoryScan.vue';
import InventoryScanStorage from './InventoryScanStorage.vue';

const { t } = useI18n();

useWakeLockWhenMounted();

useBreadcrumbs(t('Inventory'));

const router = useRouter();

const { client: urql } = useClientHandle();

const speaker = useSpeaker();

const { progressStarted, hasProgress } = useProgressHandling<'completing'>();

const { fillErrorsFromGraphQLError, clearErrors, primaryError } = useErrorHandling();

const props = withDefaults(defineProps<{
  inventoryId?: string | null;
}>(), {
  inventoryId: null,
});

const stocksCarousel  = ref<InstanceType<typeof InventoryProcessCarousel>>(null!);

const isEntireStorage = useLocalStorage('inventory.isEntireStorage', false);

const storage = ref<Storage | null>(null);

const actualStocks = ref<ActualInventoryStock[]>([]);

const selectedStockIndex = ref<number>(0);

const storedState = useInventoryState();

const inventoryProduct = computed((): null | Product => {
  if (!inventory.value) {
    return null;
  }

  const productPacks = R.uniqBy(pp => pp.product.id, inventory.value.productPacks);

  return productPacks.length === 1 ? productPacks[0].product : null;
});

const {
  data: inventoryResult,
  fetching: fetchingInventory,
} = useQuery<{ inventory: Inventory }, QueryInventoryArgs>({
  query: gql`
    query GetInventoryForProcess($id: ID!) {
      inventory: inventory(id: $id) { ...InventoryForProcess }
    }
    ${InventoryForProcess}
  `,
  variables: computed(() => ({ id: props.inventoryId! })),
  pause: computed(() => !props.inventoryId || idIsDraft(props.inventoryId)),
});
watch(inventoryResult, data => {
  inventory.value = data!.inventory;
  if (inventory.value?.state === InventoryStateEnum.CREATED) {
    startInventory();
  }
});
const inventory = ref<Inventory | null>(null);

onBeforeMount(() => {
  if (!props.inventoryId) {
    return;
  }

  const state = storedState.select(props.inventoryId);

  storage.value = state.storage;
  actualStocks.value = state.actualStocks;
  selectedStockIndex.value = state?.currentItemIndex ?? 0;
});

watch(storage, () => {
  batchesByProduct.clear();
});

const {
  data: stocksData,
  error: stocksError,
  fetching: stocksLoading,
} = useQuery<{ stocks: Stock[] }, QueryStocksInStorageArgs>({
  query: gql`
    query StockByStorageForInventoryBatchValidation($storageId: ID!) {
      stocks: stocksInStorage(storageId: $storageId) {
        amount
        storageUnit {
          id
          productPack { id product { id sku } quantityInMinMeasurementUnits }
          batch { id name number productionDate expirationDate }
        }
      }
    }
  `,
  variables: computed(() => ({
    storageId: storage.value?.id as Scalars['ID'],
  })),
  pause: computed(() => !storage.value),
});
watch(stocksError, fillErrorsFromGraphQLError);
watch(stocksData, async data => {
  if (!data) {
    return;
  }

  storageStocks.value = data.stocks;

  batchesByProduct.clear();
  for (const stock of data.stocks) {
    const batches = batchesByProduct.get(stock.storageUnit.productPack.product.id) ?? [];
    batches.push(stock.storageUnit.batch);
    batchesByProduct.set(stock.storageUnit.productPack.product.id, batches);
  }
});

watch([storage, selectedStockIndex], saveProgress);
watch(actualStocks, saveProgress, { deep: true });

async function startInventory(): Promise<void> {
  const { data } = await urql.mutation<{ startInventory: Inventory }>(
    gql`
      mutation StartInventoryForProcess($inventoryId: ID!) {
        startInventory(inventoryId: $inventoryId) { ...InventoryForProcess }
      }

      ${InventoryForProcess}
    `,
    { inventoryId: inventory.value!.id },
  );

  inventory.value = data!.startInventory;
}

function saveProgress(): void {
  if (!storage.value && !inventory.value) {
    storedState.resetCurrent();
    return;
  }

  storedState.updateCurrent({
    storage:          storage.value,
    actualStocks:     actualStocks.value,
    currentItemIndex: selectedStockIndex.value,
  });
}

function handleStorageScan(newStorage: Storage) {
  const entireStorage = inventory.value?.isEntireStorage ?? isEntireStorage.value;

  if (!props.inventoryId) {
    const state = storedState.create(null);
    state.storage = newStorage;
    state.isEntireStorage = entireStorage;
    router.replace({ name: ROUTES.INVENTORY_PROCESS, params: { inventoryId: state.inventoryId } });
  }
  speaker.speak(entireStorage ? t('Entire Storage') : t('Some Products'));
  storage.value = newStorage;
}

const { KeyboardToggleButton, toggleCurrentKeyboard }  = useOmniInput({ skip: true });

const loadingProductPackBatch = ref(false);

function shouldNotifyAboutWeightAndDimensions(pack: ProductPack): boolean {
  return pack.product.accountingModel.askForWeightAndDimensions === AskForWeightAndDimensionsOptionEnum.ALWAYS;
}

async function handleProductPackScan(packAndAmount: ProductPackWithAmount) {
  const pack = packAndAmount.productPack;
  clearErrors();
  speaker.speak(speechOnProductPackScan(pack));
  if (productPackIsMissingWeightOrDimensions(pack) && shouldNotifyAboutWeightAndDimensions(pack)) {
    speaker.speak(t('Specify Weight and Dimensions'));
  }

  const lastScanned = actualStocks.value[actualStocks.value.length - 1];

  if (lastScanned?.storable.id === pack.id) {
    lastScanned.amount = inputAmountBuffer.pop() ?? (lastScanned.amount + packAndAmount.amount);
  } else {
    loadingProductPackBatch.value = true;
    const { data, error } = await urql.query<{ batch: InventoryBatchData }, QueryBatchDataForInventoryProductArgs>(gql`
      query BatchForInventoryProductForInventoryProcess($storageId: ID!, $productPackId: ID!) {
        batch: batchDataForInventoryProduct(storageId: $storageId, productPackId: $productPackId) {
          number
          productionDate
          expirationDate
        }
      }
    `, { storageId: storage.value!.id, productPackId: pack.id });
    loadingProductPackBatch.value = false;

    if (error) {
      fillErrorsFromGraphQLError(error);
      return;
    }

    const newStock = {
      storable: pack,
      batch:    data!.batch,
      amount:   inputAmountBuffer.pop() ?? packAndAmount.amount,
      id:       pack.id,
    };
    if (!isStockValid(newStock)) {
      speaker.speak(t('Specify batch'));
    }
    actualStocks.value.push(newStock);
  }

  selectedStockIndex.value = actualStocks.value.length - 1;

  removeEmptyStocks();

  toggleCurrentKeyboard(false);

  stocksCarousel.value.resetInput();
}

function removeEmptyStocks(): void {
  const currentStock = actualStocks.value[selectedStockIndex.value];

  actualStocks.value = actualStocks.value.filter(s => s.amount > 0);

  const newCurrentIndex = actualStocks.value.indexOf(currentStock);

  if (newCurrentIndex >= 0) {
    selectedStockIndex.value = newCurrentIndex;
  }
}

watch(actualStocks, stocks => {
  if (selectedStockIndex.value >= stocks.length) {
    selectedStockIndex.value = Math.max(0, stocks.length - 1);
  }
});

const slideWithErrorToFix = ref<number | null>(null);

async function complete(): Promise<void> {
  const firstInvalidIndex = actualStocks.value.findIndex(complement(isStockValid));
  if (firstInvalidIndex >= 0) {
    slideWithErrorToFix.value = firstInvalidIndex;
    speaker.speak(t('Fix the errors'));
    return;
  }

  const done = progressStarted('completing');

  const { error } = await createInventory();

  done();

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  storedState.removeCurrent();

  // noinspection ES6MissingAwait
  router.push({ name: ROUTES.INVENTORY_DASHBOARD });
}

async function createInventory() {
  const totalActualStock = (s: ActualInventoryStock[]): ActualInventoryStock => ({
    ...s[0],
    amount: s.reduce((acc, s) => acc + s.amount, 0),
  });

  const collapse = R.pipe(
    R.groupBy<ActualInventoryStock>(s => s.id),
    R.values,
    R.map(totalActualStock),
  );

  return urql.mutation<unknown, MutationCreateInventoryArgs>(
    gql`
      mutation CreateInventory(
        $storageId: ID!,
        $isEntireStorage: Boolean!,
        $inventoryId: ID,
        $actualInventoryStocks: [ActualInventoryStockInput!]!
      ) {
        createInventory(
         storageId: $storageId,
         isEntireStorage: $isEntireStorage,
         inventoryId: $inventoryId,
         actualInventoryStocks: $actualInventoryStocks
        ) { id }
      }
    `,
    {
      isEntireStorage:       inventory.value?.isEntireStorage ?? storedState.current.value!.isEntireStorage,
      storageId:             storage.value!.id,
      inventoryId:           inventory.value?.id ?? null,
      actualInventoryStocks: collapse(actualStocks.value).map(s => ({
        amount:        s.amount,
        productPackId: s.storable.id,
        batchData:     omit(['__typename'], s.batch),
      })),
    },
  );
}

function cancelInventory(): void {
  storedState.removeCurrent();
  router.push({ name: ROUTES.INVENTORY_DASHBOARD });
}

function storageMatchesSpecifiedRule(storage: Storage) {
  if (!inventory.value) {
    return true;
  }

  if (storage.id !== inventory.value.storage!.id) {
    return t('Storage should be equal to specified');
  }

  return true;
}

function storageShouldBeWithCorrectTypeAndContentRule(storage: Storage) {
  if (isContainer(storage) && storage.kind === ContainerKindEnum.ORDERS) {
    return t('Container shouldn\'t contain orders');
  }
  return true;
}

const inputAmountBuffer = reactive<[number] | []>([]);

const scanning = ref(false);

function updateStockAmount(amount: number): void {
  if (scanning.value) {
    // Не используем push, т.к. нужно только одно значение.
    inputAmountBuffer[0] = amount;
    return;
  }

  currentItem.value.amount = amount;
}

const { emit: emitCloseDialogs } = useEventBus('close-dialogs');

watch(scanning, scanning => {
  if (scanning) {
    emitCloseDialogs();
  }
});

function handleSubmitAmount({ index, amount }: {
  index: number;
  amount: number;
}) {
  actualStocks.value[index].amount = amount;
  removeEmptyStocks();
}

const isEntireStorageSelect = ref();

function handleEntireStorageSelectClose() {
  nextTick(() => {
    isEntireStorageSelect.value?.select?.blur();
  });
}

function isStockValid(stock?: ActualInventoryStock) {
  return validStockRule(stock) === true;
}

const batchesByProduct = reactive(new Map<Scalars['ID'], Batch[]>());

const storageStocks = ref<Stock[]>([]);

function getConflictingBatchesForStock(stock: ActualInventoryStock): Batch[] {
  const batches = batchesByProduct.get(stock.storable.product.id) ?? [];

  // Если хотя бы одна партия совпадает с введенной, даже если есть другие, то ошибки нет.
  // Проверка на всякий случай, так как на практике такого не должно быть -
  // - в одной ячейке для одного товара всегда одна партия.
  if (batches.some(b => batchesDataEquals(b, stock.batch))) {
    return [];
  }

  return batches;
}

function validStockRule(stock?: ActualInventoryStock) {
  if (!stock) {
    return true;
  }

  const accModel = stock.storable.product.accountingModel;

  if (!isBatchDataFilled(accModel, stock.batch)) {
    return t('Specify batch');
  }

  const otherBatches = getConflictingBatchesForStock(stock);
  if (otherBatches.length > 0) {
    return t('Storage already contains other batches: {batches}', {
      batches: otherBatches.map(b => b.name).join(', '),
    });
  }

  return true;
}

const batchError = computed(() => stockError(currentItem.value));

function stockError(stock?: ActualInventoryStock) {
  const result = validStockRule(stock);

  return result === true ? null : result;
}

const currentItem = computed(() => actualStocks.value[selectedStockIndex.value]);

function isBatchDataFilled(accModel: AccountingModel, batch: InventoryBatchDataInput) {
  if (!accModel.byBatch) {
    return true;
  }

  return (!accModel.batchExpirationDateRequired || batch.expirationDate)
    && (!accModel.batchNumberRequired || batch.number)
    && (!accModel.batchProductionDateRequired || batch.productionDate);
}

const scanField: Ref<InstanceType<typeof InventoryScan>> = ref(null!);

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/inventory.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/speaking.yaml"></i18n>

<i18n lang="yaml">
ru:
  Scan First Cell: Сканируйте первую ячейку
  Storage should be equal to specified: >
    Место инвентаризации должно соответствовать указанному
  Specify batch: Укажите партию
  "Storage already contains other batches: {batches}": "Место хранения уже содержит другие партии: {batches}"
  Fix the errors: Исправьте ошибки

en:
  Scan First Cell: Scan First Cell
  Storage should be equal to specified: Storage should be equal to specified
  Specify batch: Specify batch
  "Storage already contains other batches: {batches}": "Storage already contains other batches: {batches}"
  Fix the errors: Fix the errors
</i18n>
