<template>
  <div class="text-center mt-16">
    <QCircularProgress
      indeterminate
      color="primary"
      size="100px"
      font-size="0.15em"
      show-value
    >
      <slot>
        {{ t('Loading') }}
      </slot>
    </QCircularProgress>
  </div>
</template>

<script setup lang="ts">

import { useI18n } from 'vue-i18n';

const { t } = useI18n();

</script>
