<template>
  <QDialog
    v-model="dialog"
    :no-backdrop-dismiss="progress.deleting"
  >
    <QCard>
      <CardTitle>
        {{ t('Deletion') }}
      </CardTitle>

      <QSeparator />

      <template
        v-if="validationErrors.size > 0"
      >
        <slot
          name="validation-errors"
          :invalid-items="[...validationErrors.keys()]"
        />
        <template v-for="(invalidItems, slot) in invalidItemsByErrorSlots">
          <slot
            :name="slot"
            :items="invalidItems"
          />
        </template>
      </template>

      <template v-else-if="progress.validating || progress.deleting">
        <QLinearProgress indeterminate />
        <div class="text-center text-body1 q-my-md">
          {{ progress.validating ? t('Validating...') : t('Deleting...') }}
        </div>
      </template>
      <slot
        v-else
        name="confirmation"
      />

      <QSeparator />

      <QCardActions align="right">
        <QBtn
          :disable="progress.deleting"
          @click="dialog = false"
        >
          {{ t('Cancel') }}
        </QBtn>
        <QBtn
          color="red"
          :loading="progress.deleting"
          :disable="progress.validating || progress.deleting || validationErrors.size > 0"
          @click="deleteItems"
        >
          {{ t('Delete') }}
        </QBtn>
      </QCardActions>
    </QCard>
  </QDialog>

  <QBtn
    class="bg-white text-black"
    @click="dialog = true"
  >
    {{ t('Delete') }}
  </QBtn>
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import type { FunctionValidationRule } from '@/types';
import { filter, mapObjIndexed } from 'ramda';
import { computed, reactive, ref, useSlots, watch } from 'vue';
import { useI18n } from 'vue-i18n';

type TItem = object;

const { t } = useI18n();

const progress = reactive({
  validating: false,
  deleting: false,
});

const { fillErrorsFromGraphQLError } = useErrorHandling();

const validationErrors = reactive(new Map<TItem, string>());

const props = defineProps<{
  items: TItem[];
  delete: (item: TItem) => void;
  validate: FunctionValidationRule<TItem>;
}>();

const emit = defineEmits<{
  (e: 'deleted'): void;
}>();

const dialog = ref(false);

watch(dialog, async function dialogToggle(open) {
  validationErrors.clear();

  if (!open) {
    return;
  }

  progress.validating = true;
  try {
    const validationResults = await Promise.all(props.items.map(i => props.validate(i)));

    for (const [index, result] of validationResults.entries()) {
      if (typeof result === 'string') {
        validationErrors.set(props.items[index], result);
      }
    }
  } catch (e) {
    fillErrorsFromGraphQLError(e);
  } finally {
    progress.validating = false;
  }
}, { immediate: true });

async function deleteItems(): Promise<void> {
  progress.deleting = true;

  await Promise.all(props.items.map(i => props.delete(i)));

  progress.deleting = false;
  emit('deleted');
}

const slots = useSlots();

const invalidItemsByErrorSlots = computed(() => {
  const groupedItems = mapObjIndexed<unknown, TItem[]>(
    (_, slotName) =>
      [...validationErrors.entries()]
        .filter(([, error]) => error === slotName.replace(/^validation-error-/, ''))
        .map(([item]) => item),
    slots,
  );

  return filter(items => items.length > 0, groupedItems);
});

</script>

<i18n lang="yaml">
ru:
  Deletion: Удаление
  Validating...: Проверка...
  Deleting...: Удаление...
en:
  Deletion: Deletion
  Validating...: Validating...
  Deleting...: Deleting...
</i18n>
