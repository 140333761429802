<template>
  <QInput
    v-bind="$attrs"
    v-model="unitValue"
    type="number"
  />
</template>

<script setup lang="ts">

import { useVModel } from '@vueuse/core';
import dayjs from 'dayjs';
import type { DurationUnitType } from 'dayjs/plugin/duration';
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  modelValue: string | null;
  unit?: DurationUnitType;
}>(), {
  unit: 'days',
});

const model = useVModel(props, 'modelValue');

const unitValue = computed({
  get(): number | null {
    return model.value
      ? dayjs.duration(model.value).as(props.unit)
      : null;
  },

  set(value: number | null) {
    model.value = value
      ? dayjs.duration({ [props.unit]: value }).toISOString()
      : null;
  },
});

</script>
