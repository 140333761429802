<template>
  <slot :open="open" />
  <QDialog v-model="dialog">
    <QCard style="min-width: 300px;">
      <CardTitle>{{ t('Select Batch') }}:</CardTitle>

      <BaseAlert
        v-if="changingError"
        type="error"
      >
        {{ changingError }}
      </BaseAlert>

      <BatchCard
        v-if="newBatchDialog"
        :product="product"
        hide-title
        @back="newBatchDialog = false"
        @saved="newBatchSaved($event)"
      />
      <QSeparator v-else />

      <QList
        separator
        style="max-height: 50vh;"
        class="scroll-y"
      >
        <QItem
          v-if="!newBatchDialog"
          clickable
          @click="newBatchDialog = true"
        >
          <QItemSection>
            <QItemLabel>
              <QIcon
                name="mdi-plus"
                left
                size="sm"
              />
              {{ t('New Batch') }}
            </QItemLabel>
          </QItemSection>
        </QItem>
        <QItem
          v-for="{batch, stock} in batchesWithStocks"
          :key="batch.id"
          clickable
          @click="changeBatch(batch)"
        >
          <QItemSection>
            {{ batch.name }}
          </QItemSection>
          <QItemSection side>
            {{ stock }} {{ product.mostBasicProductPack.measurementUnit.shortName }}
          </QItemSection>
        </QItem>
      </QList>

      <QInnerLoading :showing="stocksFetching || batchesFetching || changingProgress" />
    </QCard>
  </QDialog>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import BatchCard from '@/components/BatchCard.vue';
import CardTitle from '@/components/CardTitle.vue';
import type { QueryProductBatchesArgs, Scalars, StockReportRow } from '@/graphql/types';
import type {
  Batch,
  MutationChangeStocksBatchArgs,
  Product,
  QueryProductStocksArgs,
  Stock,
} from '@/graphql/types';
import { gql, useClientHandle, useQuery } from '@urql/vue';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  product: Product;
  editingStocks: StockReportRow[];
  cachedBatches: Map<Scalars['ID'], Batch>;
}>();

const emit = defineEmits<{
  (e: 'done'): void;
  (e: 'cache-batches', batches: Batch[]): void;
}>();

const {
  data: stocksData,
  fetching: stocksFetching,
} = useQuery<{ stocks: Stock[] }, QueryProductStocksArgs>({
  query:     gql`
    query GetProductStocks($productId: ID!) {
      stocks: productStocks(productId: $productId) {
        storageUnit {
          id
          productPack {
            quantityInMinMeasurementUnits
          }
          batch { id name }
        }
        amount
      }
    }
  `,
  variables: computed(() => ({
    productId: props.product.id,
  })),
  pause:     computed(() => props.product.deletedAt !== null),
});
const stocks = computed<Stock[]>(() => stocksData.value?.stocks ?? []);

const {
  data: batchesData,
  fetching: batchesFetching,
} = useQuery<{ batches: Batch[] }, QueryProductBatchesArgs>({
  query:     gql`
    query GetProductBatches($productId: ID!) {
      batches: productBatches(productId: $productId) {
        id
        name
      }
    }
  `,
  variables: computed(() => ({
    productId: props.product.id,
  })),
  pause:     computed(() => props.product.deletedAt !== null),
});

// В диалоговом окне смены партии нужно отображать только непустые.
// После смены партии старая партия может стать пустой, но ее все равно нужно выводить.
// Для этого кэшируем во внешнем компоненте (а не в текущем, т.к. он демонтируется)
// все полученные ранее с сервера партии и добавляем их к полученным с сервера для вывода в списке.

watch(batchesData, data => {
  emit('cache-batches', data?.batches ?? []);
});

const allBatches = computed(() => {
  const batchesToPrepend = new Map(props.cachedBatches);
  const queriedBatches = batchesData.value?.batches ?? [];

  for (const batch of queriedBatches) {
    if (batchesToPrepend.has(batch.id)) {
      batchesToPrepend.delete(batch.id);
    }
  }
  return [...batchesToPrepend.values(), ...queriedBatches];
});

const batchesWithStocks = computed(() => allBatches.value.map(batch => ({
  batch,
  stock: batchStock(batch),
})) ?? []);

function batchStock(batch: Batch) {
  return stocks.value.filter(s => s.storageUnit.batch.id === batch.id)
    .map(s => s.amount * s.storageUnit.productPack.quantityInMinMeasurementUnits)
    .reduce((sum, stock) => sum + stock, 0);
}

const changingProgress = ref(false);
const changingError = ref('');

const { client: urql } = useClientHandle();

async function changeBatch(batch: Batch) {
  changingError.value = '';
  changingProgress.value = true;

  if (!batch) {
    return;
  }

  const { error } = await urql.mutation<unknown, MutationChangeStocksBatchArgs>(
    gql`
      mutation ChangeStocksBatchFromProductStocksList($stocks: [StockInput!]!, $batchId: ID!) {
        changeStocksBatch(stocks: $stocks, batchId: $batchId) { storage { id } storageUnit { id } }
      }
    `,
    {
      stocks:  props.editingStocks.map(s => ({
        storageId:     s.storageId!,
        storageUnitId: s.storageUnitId!,
      })),
      batchId: batch.id,
    },
  );

  changingProgress.value = false;

  if (error) {
    changingError.value = error.message;
  } else {
    dialog.value = false;
    emit('done');
  }
}

const dialog = ref(false);

function open() {
  dialog.value = true;
}

watch(dialog, isOpen => {
  if (!isOpen) {
    changingError.value = '';
  }
});

const newBatchDialog = ref(false);

function newBatchSaved(newBatch: Batch) {
  newBatchDialog.value = false;
  changeBatch(newBatch);
}

</script>

<i18n lang="yaml">
ru:
  Select Batch: Выберите партию
  New Batch: Новая партия

en:
  Select Batch: Select Batch
  New Batch: New Batch
</i18n>
