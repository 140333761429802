<template>
  <QCardSection>
    <div class="row items-center q-mb-md wrap-xs">
      <QSelect
        v-model="areaKind"
        :label="t('Cells Area')"
        :options="areaKinds"
        :option-label="areaKindLabel"
        bottom-slots
        class="col-12 col-sm"
      />
    </div>
  </QCardSection>

  <PrimaryErrorBanner animated />

  <QCardActions :vertical="screen.xs">
    <QBtn
      icon="mdi-cancel"
      :label="t('Cancel')"
      @click="emit('close')"
    />

    <QSpace v-if="screen.gt.xs" />

    <QBtn
      icon="mdi-plus"
      :label="t('Create')"
      :loading="creating"
      color="primary"
      @click="create"
    />
  </QCardActions>
</template>

<script setup lang="ts">

import useErrorHandling from '@/composables/useErrorHandling';
import type {
  MutationCreateConsolidationTaskArgs,
} from '@/graphql/types';
import {
  CellsAreaKindEnum,
  type TransferTask,
} from '@/graphql/types';
import { gql, useMutation } from '@urql/vue';
import { useQuasar } from 'quasar';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { fillErrorsFromGraphQLError, PrimaryErrorBanner, clearErrors } = useErrorHandling();

const { t } = useI18n();

const { screen } = useQuasar();

const emit = defineEmits<{
  close: [];
  create: [];
}>();

const areaKind = ref(CellsAreaKindEnum.SELECTION);

const areaKinds = Object.values(CellsAreaKindEnum);

function areaKindLabel(v: CellsAreaKindEnum) {
  return t(`cellsAreaKind.${v}`);
}

async function create() {
  clearErrors();

  const { error } = await doCreate({
    areaKind: areaKind.value,
  });

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  emit('create');
}

const {
  fetching: creating,
  executeMutation: doCreate,
} = useMutation<{ task: TransferTask }, MutationCreateConsolidationTaskArgs>(gql`
  mutation CreateConsolidationTask(
    $areaKind: CellsAreaKindEnum!,
  ) {
    task: createConsolidationTask(areaKind: $areaKind) {
      id
    }
  }
`);
</script>
