<template>
  <slot
    v-bind="{
      generateExportFile,
      generatingFile,
    }"
  >
    <QBtn
      color="primary"
      icon="mdi-download"
      :label="t('Download')"
      v-bind="$attrs"
      :loading="generatingFile"
      @click="generateExportFile"
    />
  </slot>
  <UrlDownloader
    :url="exportFilePath"
    immediate
  />
</template>

<script setup lang="ts">

import type { ExcelImportTypeEnum, MutationGenerateExcelExportFileArgs } from '@/graphql/types';
import { useQuasar } from 'quasar';
import { ref } from 'vue';
import { useMutation } from '@urql/vue';
import type { ReportOptions } from '@/types/reports';
import type { DocumentNode } from 'graphql';
import UrlDownloader from '@/components/UrlDownloader.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const q = useQuasar();

const exportFilePath = ref<string | null>(null);

const props = defineProps<{
  options?: ReportOptions;
  mutationExport: DocumentNode;
  type: ExcelImportTypeEnum;
}>();

const emit = defineEmits<{
  'file-generated': [];
}>();

const {
  executeMutation: doGenerateFile,
  fetching: generatingFile,
} = useMutation<
  { exportFilePath: string },
  MutationGenerateExcelExportFileArgs
>(props.mutationExport);

async function generateExportFile(): Promise<void> {
  exportFilePath.value = null;

  const { error, data } = await doGenerateFile({
    type:         props.type,
    searchString: props.options?.query,
    filter:       props.options?.filter,
  });

  if (error) {
    q.notify({
      message:  error.message,
      position: 'top',
      type:     'negative',
      color:    'error',
    });
  } else {
    exportFilePath.value = data!.exportFilePath;
  }

  emit('file-generated');
}

</script>
