<template>
  <MaximizedDialog
    v-model="isOpen"
    transition-show="slide-up"
    transition-hide="slide-down"
    :title="t('Storage Cell')"
    @close="isOpen = false"
  >
    <div class="col column justify-center">
      <PrimaryErrorBanner animated />
      <QCardSection>
        <GraphQLQueryScanField
          :query="cellQuery"
          :label="t('Scan Cell')"
          :not-found-message="t('Cell not found')"
          :loading="changingCell"
          @scan="handleScan"
          @update:scanning="$event && clearErrors()"
        />
      </QCardSection>
    </div>
  </MaximizedDialog>
  <slot @click="isOpen = true" />
</template>

<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import type { Cell, Container, MutationChangeContainerCellArgs } from '@/graphql/types';
import { gql, useMutation } from '@urql/vue';
import { ref, watch } from 'vue';
import MaximizedDialog from '@/components/MaximizedDialog.vue';
import GraphQLQueryScanField from '@/components/Mobile/GraphQLQueryScanField.vue';
import useErrorHandling from '@/composables/useErrorHandling';

const { t } = useI18n();

const { fillErrorsFromGraphQLError, clearErrors, PrimaryErrorBanner } = useErrorHandling();

const props = defineProps<{
  container: Container;
}>();

const emit = defineEmits<{
  (e: 'update:cell', cell: Cell): void;
  (e: 'update:is-open', isOpen: boolean): void;
}>();

const isOpen = ref(false);

watch(isOpen, isOpen => {
  emit('update:is-open', isOpen);
});

const cellQuery = gql`
  query CellByBarcodeForContainerChange($barcode: String!) {
    cellByBarcode(barcode: $barcode) { id name barcode }
  }
`;

const {
  executeMutation: doChangeCell,
  fetching: changingCell,
} = useMutation<{ container: Container }, MutationChangeContainerCellArgs>(gql`
  mutation ChangeContainerCellFromMobileCard($cellId: ID!, $containerId: ID!) {
    container: changeContainerCell(cellId: $cellId, containerId: $containerId) { id storageCell { id name } }
  }
`);

async function handleScan(cell: Cell) {
  if (cell.id === props.container.storageCell.id) {
    isOpen.value = false;
    return;
  }
  const { data, error } = await doChangeCell({
    cellId: cell.id,
    containerId: props.container.id,
  });

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  isOpen.value = false;
  emit('update:cell', data!.container.storageCell);
}

</script>

<i18n lang="yaml" src="../plugins/i18n/sharedMessages/scanning.yaml"></i18n>
