<template>
  <QCard>
    <CardTitle>
      {{ t('Manage API Tokens') }}
      <template #subtitle>
        {{ t('You may delete any of your existing tokens') }}
      </template>
    </CardTitle>

    <QCardSection>
      <QList>
        <QItem v-if="loading">
          <QLinearProgress
            indeterminate
            size="25px"
          >
            <div class="flex flex-center text-subtitle1">
              {{ t('Loading') }}
            </div>
          </QLinearProgress>
        </QItem>
        <template v-else>
          <QItem
            v-for="token in tokens"
            :key="token.id"
            two-line
          >
            <QItemSection>
              <QItemLabel>
                {{ token.name }} ({{ token.readableToken }})
              </QItemLabel>
              <QItemLabel caption>
                {{ token.lastUsedAt
                  ? t('Created {created}, last used {used}', { created: formatDate(token.createdAt), used: formatDate(token.lastUsedAt) })
                  : t('Created {created}', { created: formatDate(token.createdAt) }) }}
              </QItemLabel>
            </QItemSection>

            <QItemSection side>
              <QBtn
                flat
                round
                icon="mdi-delete"
                color="error"
                :title="t('Delete')"
                @click="deleteToken(token)"
              />
            </QItemSection>
          </QItem>
        </template>
      </QList>
    </QCardSection>

    <DeleteTokenDialog
      v-model:token="deletingToken"
      @delete="emit('change')"
    />
  </QCard>
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import type { PersonalAccessToken } from '@/graphql/types';
import DeleteTokenDialog from '@/views/API/ApiTokenManager/DeleteTokenDialog.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

defineProps<{
  tokens: PersonalAccessToken[];
  loading: boolean;
}>();

const emit = defineEmits<{
  (e: 'change'): void;
}>();

const deletingToken = ref<PersonalAccessToken | null>(null);

function deleteToken(token: PersonalAccessToken): void {
  deletingToken.value = token;
}
</script>

<i18n lang="yaml">
en:
  Manage API Tokens: Manage API Tokens
  You may delete any of your existing tokens: >
    You may delete any of your existing tokens if they are no longer needed
  Created {created}: Created {created}
  Created {created}, last used {used}: Created {created}, last used {used}
  Permissions: Permissions
  API Token Permissions: API Token Permissions
ru:
  Manage API Tokens: Управление API токенами
  You may delete any of your existing tokens: >
    Вы можете удалить любой токен, если он вам больше не нужен
  Created {created}: Создан {created}
  Created {created}, last used {used}: Создан {created}, использован {used}
  Permissions: Разрешения
  API Token Permissions: Разрешения API токена
</i18n>
