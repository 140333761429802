<template>
  <div :class="{ 'q-pa-md': mobile }">
    <QSelect
      ref="select"
      v-bind="$attrs"
      dense
      bottom-slots
      multiple
      use-chips
      use-input
      hide-dropdown-icon
      new-value-mode="add"
      :model-value="modelValue"
      :label="t('Values')"
      @blur="handleBlur"
      @update:model-value="onInput($event)"
      @input-value="handleInputValue"
      @keypress.enter="handleEnter"
    >
      <template #append>
        <QBtn
          round
          dense
          color="positive"
          icon="mdi-plus"
          :disable="!inputValue"
          @click="handleEnter"
        />
      </template>
    </QSelect>
    <ButtonsRow
      v-if="mobile && ((modelValue && modelValue.length > 0) || inputValue)"
      v-slot="{ buttonProps }"
    >
      <QBtn
        v-bind="buttonProps"
        icon="mdi-content-save"
        @click="handleApply"
      >
        {{ t('Apply') }}
      </QBtn>
    </ButtonsRow>
  </div>
</template>

<script setup lang="ts">

import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import type { FilterValue } from '@/types/reports';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  modelValue: FilterValue<string>[] | null;
  mobile: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: FilterValue<string>[] | null): void;
  (e: 'save-filter'): void;
}>();

const inputValue = ref('');

const dontClearInputValue = ref(false);

function handleBlur() {
  dontClearInputValue.value = true;
}

function handleApply() {
  handleEnter();
  emit('save-filter');
}

function handleInputValue(value: string) {
  // При потере фокуса поле ввода сразу очищается.
  // При нажатии "Применить" там уже пустое значение
  // (т.к. потеря фокуса и очистка происходят до срабатывания обработчика кнопки),
  // из-за чего оно не применяется.
  // Поэтому сразу после потери фокуса не меняем inputValue
  // (не заменяем его на пустоту).
  if (dontClearInputValue.value) {
    dontClearInputValue.value = false;
    return;
  }
  inputValue.value = value;
}

function onInput(newValue: (FilterValue<string> | string)[]): void {
  emit('update:modelValue', newValue.map(v => typeof v === 'string' ? ({
    label: v,
    value: v,
  }) : v));
}

const select = ref();

function handleEnter() {
  if (!inputValue.value) {
    return;
  }
  onInput([
    ...(props.modelValue ?? []),
    {
      label: inputValue.value,
      value: inputValue.value,
    },
  ]);

  select.value.updateInputValue('', true);
}

</script>

<i18n lang="yaml">
ru:
  Values: Значения
en:
  Values: Values
</i18n>

<style scoped>

:deep(.q-chip) {
  margin-left: 0;
}

:deep(.q-field__input) {
  margin-top: 3px;
  margin-bottom: 2px;
}

</style>
