<template>
  <AuthCard :title="t('Login Title')">
    {{ t(usingRecoveryCodes
      ? 'Confirm access by recovery codes'
      : 'Confirm access by authentication code') }}

    <QInput
      v-model="code"
      :label="t(usingRecoveryCodes ? 'Recovery Code' : 'Code')"
      type="text"
      required
      autocomplete="off"
      v-bind="qErrorsFor(usingRecoveryCodes ? 'recovery_code' : 'code')"
      autofocus
    />

    <template
      v-if="getPrimaryError()"
      #error
    >
      {{ t(getPrimaryError()) }}
    </template>

    <template #actions>
      <QBtn
        flat
        @click="emit('back')"
      >
        {{ t('Back') }}
      </QBtn>

      <QSpace />

      <QBtn
        flat
        @click="usingRecoveryCodes = !usingRecoveryCodes"
      >
        {{ t(usingRecoveryCodes
          ? 'Use an authentication code'
          : 'Use a recovery code') }}
      </QBtn>
      <QBtn
        type="submit"
        color="primary"
        :loading="hasProgress()"
        icon="mdi-login"
        @click.prevent="login()"
      >
        {{ t('Login') }}
      </QBtn>
    </template>
  </AuthCard>
</template>

<script setup lang="ts">

import useBackend from '@/composables/useBackend';
import useErrorHandling from '@/composables/useErrorHandling';
import useProgressHandling from '@/composables/useProgressHandling';
import AuthCard from '@/views/Auth/AuthCard.vue';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { fillErrorsFromHttpError, clearErrors, getPrimaryError, qErrorsFor } = useErrorHandling();
const { progressStarted, hasProgress } = useProgressHandling();

const backend = useBackend();

const emit = defineEmits<{
  (e: 'login'): void;
  (e: 'back'): void;
}>();

const usingRecoveryCodes = ref(false);

const code = ref('');

watch(usingRecoveryCodes, function(): void {
  clearErrors();
});

async function login(): Promise<void> {
  const finished = progressStarted();

  try {
    await backend.twoFactorChallenge(code.value, usingRecoveryCodes.value);
    emit('login');
  } catch (e) {
    fillErrorsFromHttpError(e);
  } finally {
    finished();
  }
}

</script>

<i18n lang="yaml">
en:
  Confirm access by authentication code: >
    Please confirm access to your account by entering the authentication code
    provided by your authenticator application
  Confirm access by recovery codes: >
    Please confirm access to your account by entering one of your emergency recovery codes
  Code: Code
  Recovery Code: Recovery Code
  Use a recovery code: Use a recovery code
  Use an authentication code: Use an authentication code
  Login Title: Вход

ru:
  Confirm access by authentication code: >
    Подтвердите доступ к аккаунту, введя код, созданный приложением для аутентификации
  Confirm access by recovery codes:
    Подтвердите доступ к аккаунту, введя один из резервных кодов
  Code: Код
  Recovery Code: Резервный код
  Use a recovery code: Использовать резервный код
  Use an authentication code: Использовать код из приложения
  Login Title: Login
</i18n>
