<template>
  <QBtn
    flat
    round
    icon="mdi-printer-settings"
    :title="t('Print Queue')"
    :loading="hasProgress('loading')"
  >
    <QBadge
      v-if="queues.length > 0"
      :label="queues.length"
      floating
      rounded
      color="red"
    />
    <QMenu style="width: 300px;">
      <QCard>
        <QCardSection
          v-if="getPrimaryError()"
          class="text-center"
        >
          <QIcon
            name="mdi-alert-circle-outline"
            color="error"
          />
          {{ t('Error. Try later') }}
        </QCardSection>
        <QItem v-if="hasProgress('loading')">
          <QItemSection>
            <QItemLabel>
              <QSkeleton
                type="text"
                width="200px"
              />
            </QItemLabel>
            <QItemLabel caption>
              <QSkeleton type="text" />
            </QItemLabel>
          </QItemSection>
        </QItem>
        <QList v-else-if="queues.length > 0">
          <template
            v-for="queue in queues"
            :key="queue.id"
          >
            <PrintQueueMenuQueue :queue="queue" />

            <QSeparator />
          </template>
        </QList>
        <QCardSection
          v-else
          class="text-center"
        >
          <QIcon name="mdi-text-box-check" />
          {{ t('No queued documents') }}
        </QCardSection>
        <QExpansionItem
          v-if="printedQueues.length > 0"
          dense
          :label="t('Printed documents')"
        >
          <QList>
            <template
              v-for="queue in printedQueues"
              :key="queue.id"
            >
              <PrintQueueMenuQueue :queue="queue" />
            </template>
          </QList>
        </QExpansionItem>
      </QCard>
    </QMenu>
  </QBtn>
</template>

<script setup lang="ts">

import usePrintService from '@/composables/usePrintService';
import useProgressHandling from '@/composables/useProgressHandling';
import echo from '@/setup/echo';
import useStore from '@/stores/root';
import PrintQueueMenuQueue from '@/views/Printing/PrintQueueMenuQueue.vue';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import useErrorHandling from '@/composables/useErrorHandling';

const { t } = useI18n();

const { progressStarted, hasProgress } = useProgressHandling<'loading'>();

const { loadQueues, queues, printedQueues } = usePrintService();

const { fillErrorsFromGraphQLError, getPrimaryError } = useErrorHandling();


onMounted(async function mounted(): Promise<void> {
  const done = progressStarted('loading');

  try {
    await loadQueues();
  } catch (error) {
    fillErrorsFromGraphQLError(error);
  }

  done();
});

const store = useStore();

echo.private('warehouses.' + store.user!.warehouse.id + '.printing').listen('.printQueue.updated', loadQueues);

</script>

<i18n lang="yaml">
ru:
  Error. Try later: Ошибка. Попробуйте позже.
  Printed documents: Распечатанные документы
  No queued documents: Документов в очереди нет
  Print Queue: Очередь печати
en:
  Error. Try later: Error. Try later
  Printed documents: Printed documents
  No queued documents: No queued documents
  Print Queue: Print Queue
</i18n>
