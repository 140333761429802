<template>
  <QItem
    v-if="isItemAvailable(item)"
    :to="{ name: item.route }"
    @click="rememberAction(item)"
  >
    <QItemSection avatar>
      <QAvatar :icon="item.icon" />
    </QItemSection>
    <QItemSection>{{ t(item.title) }}</QItemSection>
  </QItem>
</template>

<script setup lang="ts">

import useNavigationItems, { type NavigationItem } from '@/composables/useNavigationItems';
import useQuickAccess from '@/composables/useQuickAccess';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { remember: rememberAction } = useQuickAccess();

const props = defineProps<{
  route?: string;
  item?: NavigationItem;
}>();

const { isItemAvailable, getItemByRoute } = useNavigationItems();

const item = computed(() => props.item ?? getItemByRoute(props.route!));

</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/navigation.yaml"></i18n>
