<template>
  <QCard>
    <CardTitle>{{ t('Speech Rate') }}</CardTitle>
    <QCardSection>
      <QSlider
        :model-value="speechRate"
        :min="0.1"
        :max="10"
        :step="0.1"
        snap
        markers
        label-always
        @change="speechRate = $event"
      />
      <QInput v-model="testSpeech">
        <template #append>
          <QBtn
            flat
            round
            icon="mdi-play"
            :title="t('Test')"
            @click="speaker.speak(testSpeech)"
          />
        </template>
      </QInput>
    </QCardSection>
  </QCard>
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import useLocalPreferences from '@/composables/useLocalPreferences';
import useSpeaker from '@/composables/useSpeaker';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const preferences = useLocalPreferences();

const speaker = useSpeaker();

const speechRate = ref(preferences.speechRate);

const testSpeech = ref(t('Speech Test'));

watch(speechRate, speechRate => {
  preferences.speechRate = speechRate;
});
</script>

<i18n lang="yaml">
ru:
  Speech Rate: Скорость речи
  Speech Test: Проверка речи
  Test: Проверка
en:
  Speech Rate: Speech Rate
  Speech Test: Speech Test
  Test: Test
</i18n>
