<template>
  <div>
    <FilterChip
      v-for="(filter, i) in filters"
      :key="listKey(filter)"
      :filter="filter"
      :index="i"
    />
  </div>
</template>

<script setup lang="ts">

import useListKeys from '@/composables/useListKeys';
import type { Filter, ReportFilter } from '@/types/reports';
import { QChip } from 'quasar';
import { h } from 'vue';

const listKey = useListKeys();

const props = defineProps<{
  filters: Filter[];
  availableFilters: ReportFilter[];
  editingIndex: number | null;
}>();

const emit = defineEmits<{
  (e: 'edit', filter: Filter): void;
  (e: 'remove', filter: Filter): void;
}>();

// noinspection FunctionNamingConventionJS
function FilterChip({ filter, index }: { filter: Filter; index: number }) {
  const selected = index === props.editingIndex;

  const filterDefinition = props.availableFilters.find(f => f.field === filter.field);
  const label = filterDefinition?.hideLabelWhenSelected
    ? null
    : filterDefinition?.label ?? filter.field;

  const operator = filter.operator.label;

  const value = Array.isArray(filter.value)
    ? filter.value.map(f => f.label).join(', ')
    : filter.value.label;

  return h(QChip, {
    color:        selected ? 'info' : 'grey-4',
    textColor:    'black',
    selected,
    iconSelected: 'mdi-pencil',
    removable:    true,
    class:        'multiline-chip',
    onClick:      () => emit('edit', filter),
    onRemove:     () => emit('remove', filter),
  }, () => [
    ...(label ? [
      label,
      ' ',
      operator,
      ' ',
    ] : []),
    value,
  ]);
}

</script>
