<template>
  <ExcelExport
    v-if="store.can(Ability.ImportExcel)"
    :type="ExcelImportTypeEnum.SUPPLIES"
    :mutation-export="mutationExport"
    :options="options"
  />
  <ExcelImport
    :type="ExcelImportTypeEnum.SUPPLIES"
    :mutation-validate="mutationValidate"
    :mutation-import="mutationImport"
    :success-notification="n => t('Imported {n} supplies', n)"
    @success="emit('success')"
  >
    <template #info-for-upload-file>
      {{ t('File must contain supplies only for add or update (not full supplies list)') }}
    </template>

    <template #import-btn-title>
      {{ t('Update Supplies') }}
    </template>

    <template #row-with-error-message="{ rowsCount }">
      {{ t('{n} supplies with errors', rowsCount) }}
    </template>

    <template #validation-success="{ rowsCount }">
      {{ t('Validation success. You can update {n} supplies', rowsCount) }}
    </template>
  </ExcelImport>
</template>

<script setup lang="ts">

import { ExcelImportTypeEnum } from '@/graphql/types';
import { Ability } from '@/types/backend';
import type { ReportOptions } from '@/types/reports';
import ExcelExport from '@/views/ExcelExport.vue';
import ExcelImport from '@/views/ExcelImport.vue';
import { gql } from '@urql/vue';
import { useI18n } from 'vue-i18n';
import useStore from '@/stores/root';

const { t } = useI18n();

const store = useStore();

defineProps<{
  options?: ReportOptions;
}>();

const emit = defineEmits<{
  (e: 'success'): void;
}>();

const mutationImport = gql`
  mutation ImportSupplies($type: ExcelImportTypeEnum!, $file: Upload) {
    importedCount: importExcelFile(type: $type, file: $file)
  }
`;

const mutationValidate = gql`
  mutation ValidateSuppliesFile($type: ExcelImportTypeEnum!, $file: Upload) {
    validationResult: validateExcelImportFile(type: $type, file: $file) {
      isValid
      rowsCount
      validationErrorMessage
      fileWithErrorsUrl
    }
  }
`;

const mutationExport = gql`
  mutation GenerateSuppliesFile($type: ExcelImportTypeEnum!, $searchString: String,$filter: [ReportFilterInput!]) {
    exportFilePath: generateExcelExportFile(type: $type, searchString: $searchString, filter: $filter)
  }
`;

</script>

<i18n lang="yaml">
ru:
  Update Supplies: Обновить поступления
  File must contain supplies only for add or update (not full supplies list): >
    Файл должен содержать только поступления для добавления или обновления
    (не весь список поступлений)
  Validation success. You can update {n} supplies: >
    Проверка прошла успешно. Вы можете обновить {n} поступлений
    | Проверка прошла успешно. Вы можете обновить {n} поступление
    | Проверка прошла успешно. Вы можете обновить {n} поступления
    | Проверка прошла успешно. Вы можете обновить {n} поступлений
  "{n} supplies with errors": >
    {n} поступлений с ошибками
    | {n} поступление с ошибками
    | {n} поступления с ошибками
    | {n} поступлений с ошибками
  Imported {n} supplies: >
    Импортировано {n} поступлений
    | Импортировано {n} поступление
    | Импортировано {n} поступления
    | Импортировано {n} поступлений

en:
  Update Supplies: Update Supplies
  File must contain supplies only for add or update (not full supplies list): >
    File must contain supplies only for add or update (not full supplies list)
  Validation success. You can update {n} supplies: >
    Validation success. You can update {n} supply
    | Validation success. You can update {n} supplies
  "{n} supplies with errors": >
    {n} supply with errors
    | {n} supplies with errors
  Imported {n} supplies: >
    Imported {n} supply
    | Imported {n} supplies
</i18n>
