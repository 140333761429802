<template>
  <QChip
    :color="chipColor"
    text-color="black"
    dense
    square
    size="sm"
    class="q-mx-none"
  >
    <slot>
      {{ actualAmount }}
      /
      {{ itemAmount({ amount: expectedAmount, productPack: productPack }) }}
    </slot>
  </QChip>
</template>

<script setup lang="ts">

import type { ProductPack } from '@/graphql/types';
import { computed } from 'vue';
import itemAmount from '@/helpers/itemAmount';

const props = defineProps<{
  expectedAmount: number;
  actualAmount: number;
  productPack: ProductPack;
}>();

// noinspection NestedConditionalExpressionJS
const chipColor = computed(
  () => props.actualAmount === props.expectedAmount
    ? 'green-3'
    : (props.actualAmount > props.expectedAmount
      ? 'yellow-3'
      : 'red-3'),
);

</script>
