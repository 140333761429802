<template>
  <QPage class="full-height column items-center justify-center">
    <QIcon
      size="100px"
      name="mdi-emoticon-sad"
    />
    <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
    <h1>
      404
    </h1>
  </QPage>
</template>

<script setup lang="ts">

import useBreadcrumbs from '@/composables/useBreadcrumbs';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

useBreadcrumbs(t('Not Found'));

</script>

<i18n lang="yaml">
ru:
  Not Found: Страница не найдена
en:
  Not Found: Not Found
</i18n>
